import React, { Component } from "react";

import NoSaved from "../../media/SVGs/NoSaved";

//style
import "./ForumContStyle.css";

class Forum404 extends Component {
  render() {
    return (
      <div className="posts-container">
        <div className="No-content Forum404">
          404
          <p className="">Not all those who wander are lost... but you are</p>
        </div>
      </div>
    );
  }
}

export default Forum404;
