import React, { Component } from "react";
// import { Link } from "react-router-dom";
import { HashLink as Link } from "react-router-hash-link";
import { HashLink } from "react-router-hash-link";

import splash from "../media/images/flipit-landing-page.png";
import desktop from "../media/images/Flipit-Desktop.png";
import laptop from "../media/images/Flipit-Laptop.png";
import mobile from "../media/images/Flipit-Mobile.png";
// import console from "../media/images/Flipit-Console.png";

import FloorPlanSvg from "../media/SVGs/FloorPlan";
import MobileSvg from "../media/SVGs/Mobile";
import CommunitySvg from "../media/SVGs/Community";
import DotsSVG from "../media/SVGs/Dots";

import AppStore from "../media/badges/AppStore";
import PlayStore from "../media/badges/PlayStore";

// import mobileSVG from "../../media/SVGs/Mobile.png";

import Button from "../components/buttons/Button";
import BetaCard from "../components/cards/BetaCard";
import Card from "../components/cards/Card";
import PricingWidget from "../components/home/PricingWidget";
import ConsoleWidget from "../components/home/ConsoleWidget";
import ContentSectionComun from "../components/home/ContentSectionComun";
import ContentSectionCont from "../components/home/ContentSectionCont";
import ContentSectionFet from "../components/home/ContentSectionFet";

import OneCircle from "../media/icons/OneCircle";
import TwoCircle from "../media/icons/TwoCircle";
import ThreeCircle from "../media/icons/ThreeCircle";
import ChevronRight from "../media/icons/ChevronRight";
import CheckCircle from "../media/icons/CheckCircle";

import BubbleBack from "../components/backgrounds/BubbleBack";

//images
import SplashPop from "../components/home/SplashPop";
import MapIcon from "../components/home/MapIcon";
import SplashPopSVG from "../media/SVGs/SplashPop";
import FlippabilitySearch from "../media/icons/console/FlippabilitySearch";

import DevOverlay from "../components/DevOverlay";
import DesktopDots from "../media/SVGs/DesktopDots";
import LaptopDots from "../media/SVGs/LaptopDots";
import PhoneDots from "../media/SVGs/PhoneDots";

//consoleIcons
import Dashboard from "../media/icons/console/Dashboard";
import TrendAnalysis from "../media/icons/console/TrendAnalysis";
import Watchlist from "../media/icons/console/Watchlist";
import ProjectBuilder from "../media/icons/console/ProjectBuilder";
import Financing from "../media/icons/console/Financing";
import Todo from "../media/icons/console/Todo";
import Budgeting from "../media/icons/console/Budgeting";
import Contractors from "../media/icons/console/Contractors";
import Suppliers from "../media/icons/console/Suppliers";
import Listing from "../media/icons/console/Listing";
import Financials from "../media/icons/console/Financials";
import Archive from "../media/icons/console/Archive";
import Realtors from "../media/icons/console/Realtors";

//backgrounds
import Backgrnd from "../media/SVGs/Comun-back";
import BackgrndC from "../media/SVGs/Cont-back";
import BackgrndF from "../media/SVGs/Fet-back";

//Styling
import "./home.css";

class home extends Component {
  constructor(props) {
    super(props);
    this.state = { height: 0, animate: false };
    this.contentbox = React.createRef();
    this.bannerScroll = this.bannerScroll.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    window.addEventListener("scroll", this.bannerScroll, false);
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.bannerScroll, false);
    window.removeEventListener("resize", this.updateWindowDimensions);
  }
  updateWindowDimensions() {
    let trigger =
      window.innerWidth < 768
        ? window.innerHeight + window.innerHeight * 0.3
        : window.innerHeight + 48;
    this.setState({ height: trigger });
  }

  bannerScroll() {
    const { height, animate } = this.state;
    var container = this.contentbox.current.getBoundingClientRect();
    var adjustment = height - window.innerWidth * 0.1;

    if (container.bottom < adjustment && !animate) {
      return this.setState({
        animate: true,
      });
    }
    if (container.bottom > adjustment && animate) {
      return this.setState({
        animate: false,
      });
    }
  }

  render() {
    const scrollWidthOffset = (el) => {
      const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
      const yOffset = -80;
      window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
    };

    return (
      <div
        className={`home-background ${
          this.state.animate ? "home-background-grey" : ""
        }`}
      >
        <a className="page-link-top" id="home-top" />
        <div className="splash">
          <div className="splash-cont-wrap ">
            <div className="splash-white-fade centered-col"></div>
            <div className="splash-white-fade centered-col"></div>
            <div className="splash-textWrap centered-col">
              <h1>
                Find the Best{" "}
                <span style={{ color: "#34a8fe" }}>Opportunities</span>.
              </h1>
              <h2 className="desktop-only">
                Flipit virtually flips millions of properties to determine the
                most profitable flipping opportunities in the market!
              </h2>
              <h2 className="mobile-only">
                FlipIt virtually flips millions of properties to identify the
                most profitable opportunities!
              </h2>
              <div className="centered">
                <Link
                  to="/auth/signup"
                  className="button button-blue"
                  color="#35A8F6"
                >
                  <div className="button-correction">Sign up</div>
                </Link>
                <HashLink
                  to="/#info-card"
                  smooth
                  scroll={(el) => scrollWidthOffset(el)}
                >
                  <Button className="button button-white">
                    <div className="button-correction">Learn more</div>
                  </Button>
                </HashLink>
              </div>
            </div>
            <div className="splash-popupWrap centered-col">
              <SplashPop />
              <div className="splash-map-icon-wrap">
                <MapIcon active="1" right="30%" top="16%" delay=".25" />
                <MapIcon active="3" right="16%" top="60%" delay=".5" />
                <MapIcon active="2" left="16%" top="76%" delay=".65" />
                <MapIcon active="4" left="28%" top="26%" delay=".75" />
              </div>
            </div>
          </div>

          <img src={splash} className="Flipit-splash" alt="splash" />
        </div>
        <div className="small-section sizing" id="info-card">
          <Card className="card-shdow-sml info-card">
            <div className="info-card-header">
              <div>How it works</div>
            </div>
            <div className="info-card-content">
              <div>
                <div className="centered">
                  <div className="info-card-icon-wrap centered-col">
                    <OneCircle colorPrimary="#34a8fe" size="24px" />
                  </div>
                  <h2>Find it</h2>
                </div>
                <p>
                  Flipit virtually flips every property in a designated market,
                  cutting research time to 0, and leaving you with a pool of the
                  most profitable opportunities!
                </p>
              </div>
              <div>
                <div className="centered">
                  <div className="info-card-icon-wrap centered-col">
                    <TwoCircle colorPrimary="#34a8fe" size="24px" />
                  </div>
                  <h2>Flip it</h2>
                </div>
                <p>
                  After a property is selected, Flipit builds a
                  property-specific 'to-do' list with material and labor cost
                  estimates as well as budgeting tools.
                </p>
              </div>
              <div>
                <div className="centered">
                  <div className="info-card-icon-wrap centered-col">
                    <ThreeCircle colorPrimary="#34a8fe" size="24px" />
                  </div>

                  <h2>Sell it</h2>
                </div>
                <p>
                  When a property is ready to sell, Flipit auto-generates a
                  listing, incorporating all completed projects. This listing
                  can be exported to all major platforms.
                </p>
              </div>
            </div>
          </Card>
        </div>
        <div className="spacer"></div>
        <ConsoleWidget height={this.state.height} />

        <ContentSectionComun
          height={this.state.animate}
          title="Best community on the flipping planet"
          text="Flipit is more than just a tool, it’s a community of investors, builders, and property flippers. Best of all, access to the community is completely free and open to anyone. "
          buttonText="Go to forum"
          linkTo="/forum"
          backgroundSvg={<Backgrnd />}
        />
        <ContentSectionCont
          height={this.state.animate}
          title="The right people for the right job"
          text="Realtors, Contractors, Suppliers, Financiers – Don’t have your own network? No problem! Flipit connects you with professionals and businesses perfect for the job at hand."
          buttonText="Get started"
          linkTo="/auth/signup"
          backgroundSvg={<BackgrndC />}
        />
        <ContentSectionFet
          height={this.state.animate}
          title="Take your system to the next level"
          text="Already have a flipping system or your own method of selecting properties? Build and deploy it on Flipit’s platform to get nationwide results instantly."
          buttonText="Go to forum"
          linkTo="/forum"
          backgroundSvg={<BackgrndF />}
        />

        <div className="spacer"></div>
        <section className="sizing" ref={this.contentbox}>
          <div className="sizing home-PriceCards-header">
            <div className="home-console-header">
              <h2>Affordable Pricing</h2>
              <p>
                Only pay for what you need. With Flipit’s pricing plans you can
                start for free and upgrade to the plan that works for you.
              </p>
            </div>
          </div>
          <PricingWidget />
          <Link to="/pricing#pricing-top" className="home-price-link outskrts">
            <div className="centered">
              <CheckCircle colorPrimary="#34A8FE" size="32px" />
              <div className="price-link-text-wrap">
                Vew all plans
                <p className="">Chose the plan thats right for you!</p>
              </div>
            </div>
            <ChevronRight colorPrimary="#34A8FE" size="24px" />
          </Link>
        </section>
        <div className="spacer"></div>
        <BetaCard />
        <div className="spacer"></div>
      </div>
    );
  }
}

export default home;

// <img src={console} alt="Flipit-Console " />
//
// <div className="console-product-button-wrap">
//   <ConsoleProduct
//     product="Flippability Search"
//     description="Very brief description"
//     default="active"
//     icon={<FlippabilitySearch size="24px" colorPrimary="#fff" />}
//   />
//   <ConsoleProduct
//     product="Project Builder"
//     description="Very brief description"
//     default=""
//     icon={<FlippabilitySearch size="24px" colorPrimary="#fff" />}
//   />
//   <ConsoleProduct
//     product="To-do"
//     description="Very brief description"
//     default=""
//     icon={<FlippabilitySearch size="24px" colorPrimary="#fff" />}
//   />
//   <ConsoleProduct
//     product="Auto Listing"
//     description="Very brief description"
//     default=""
//     icon={<FlippabilitySearch size="24px" colorPrimary="#fff" />}
//   />
//   <Link to="/products">
//     <button
//       className="button button-white centered"
//       style={{ fontSize: "1rem" }}
//     >
//       More prodcuts
//       <ChevronRight colorPrimary="#34a8fe" size="24px" />
//     </button>
//   </Link>
// </div>
