import React, { Component } from "react";

//Styling
import "./PageHeaderStyle.css";

class PageHeader extends Component {
  render() {
    return (
      <div
        className={`allpage-header ${
          this.props.image ? "outskrts allpage-header-image" : ""
        }`}
        style={{ width: `${this.props.image ? "100%" : "80%"}` }}
      >
        <div
          className={`${
            this.props.image ? "allpage-heater-partition-lft" : ""
          }`}
        >
          <h5>{this.props.title}</h5>
          <h1>{this.props.line1}</h1>
        </div>
        <div
          className={`${
            this.props.image
              ? "allpage-heater-partition-rit allpage-heater-image"
              : ""
          }`}
        >
          {this.props.image ? this.props.image : ""}
        </div>
      </div>
    );
  }
}

export default PageHeader;
