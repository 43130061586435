import React from "react";

const Comment = props => (
  <svg
    className={props.className}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
  >
    <path
      fill={props.colorPrimary}
      d="M13.943 15.191c0-.48-.16-.855-.48-1.123-.313-.268-.868-.546-1.663-.833-.796-.287-1.446-.564-1.952-.833-1.643-.867-2.465-2.058-2.465-3.574 0-.755.204-1.42.61-1.994.412-.58.993-1.03 1.742-1.347.75-.325 1.591-.487 2.526-.487.912 0 1.728.175 2.448.524.726.35 1.29.849 1.69 1.497.4.643.601 1.38.601 2.209h-3.049c0-.555-.16-.986-.479-1.291-.313-.306-.74-.46-1.28-.46-.546 0-.979.132-1.298.394-.314.256-.47.583-.47.983 0 .349.174.667.522.954.348.28.961.574 1.838.88.877.3 1.597.624 2.16.973 1.37.849 2.056 2.018 2.056 3.51 0 1.191-.418 2.127-1.254 2.807-.837.68-1.984 1.02-3.441 1.02-1.028 0-1.96-.197-2.796-.59-.83-.399-1.458-.942-1.882-1.628C7.21 16.09 7 15.294 7 14.396h3.066c0 .73.174 1.27.523 1.619.354.343.926.514 1.716.514.505 0 .903-.115 1.193-.346.296-.237.445-.568.445-.992z"
    />
  </svg>
);

export default Comment;

//      d="M9,22A1,1 0 0,1 8,21V18H4A2,2 0 0,1 2,16V4C2,2.89 2.9,2 4,2H20A2,2 0 0,1 22,4V16A2,2 0 0,1 20,18H13.9L10.2,21.71C10,21.9 9.75,22 9.5,22V22H9Z"
