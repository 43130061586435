import React, { Component } from "react";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import {
  clearErrors,
  unlikeForumPost,
  likeForumPost,
} from "../../redux/actions/dataActions";

//styling
import "./ForumPostStyle.css";

//components
import MoreDropDown from "./components/MoreDropDown";
import LinkPreview from "./components/LinkPreview";
import Button from "../buttons/Button";

import SavePop from "./components/SavePop";
import SharePop from "./components/SharePop";
import ReportPop from "./components/ReportPop";
import DeletePop from "./components/DeletePop";

//icons
import ArrowUp from "../../media/icons/ArrowUp";
import ArrowDown from "../../media/icons/ArrowDown";
import Comment from "../../media/icons/Comment";
import Bookmark from "../../media/icons/Bookmark";
import Share from "../../media/icons/Share";
import Delete from "../../media/icons/Delete";
import Report from "../../media/icons/Report";

let adjustment = window.innerWidth < 1200 ? 250 : 5;

class ForumPost extends Component {
  constructor(props) {
    super(props);
    this.state = {
      status: 0,
    };
    this.goToPost = this.goToPost.bind(this);
    this.goToUser = this.goToUser.bind(this);
    this.likeClick = this.likeClick.bind(this);
    this.unlikeClick = this.unlikeClick.bind(this);
    this.tagClick = this.tagClick.bind(this);
  }
  componentDidUpdate(nextProps, prevProps) {
    const {
      post: { forumPostId },
      user: { likes },
    } = this.props;
    const index = likes.findIndex((like) => like.compId === forumPostId);
    if (nextProps.user.likes !== likes && index !== -1) {
      this.setState({
        status: likes[index].state,
      });
    }
  }

  goToPost(e) {
    this.props.hist.push(
      `/forum/post/${this.props.post.forumPostId}/${this.props.post.title
        .split(" ")
        .join("-")}`
    );
  }

  goToUser(e) {
    e.stopPropagation();
    this.props.hist.push(`/user/${this.props.post.username}`);
  }

  likeClick(e) {
    e.stopPropagation();
    let incrament = this.state.status === 1 ? 0 : 1;
    let adjust = this.state.status > 0 ? -1 : this.state.status < 0 ? 2 : 1;
    this.handleLike(incrament, adjust);
  }
  unlikeClick(e) {
    e.stopPropagation();
    let incrament = this.state.status === -1 ? 0 : -1;
    let adjust = this.state.status < 0 ? 1 : this.state.status > 0 ? -2 : -1;
    this.handleLike(incrament, adjust, 1);
  }
  handleLike(incrament, adjust, actionType) {
    const {
      post: { forumPostId, likeCount },
      unlikeForumPost,
      likeForumPost,
    } = this.props;
    let newCount = likeCount + adjust;
    let pac = {
      id: forumPostId,
      count: newCount,
      state: incrament,
      type: "forumPost",
    };

    this.setState({ status: incrament });
    if (actionType) return unlikeForumPost(pac);
    likeForumPost(pac);
  }

  tagClick(e) {
    e.stopPropagation();
  }

  render() {
    const {
      user: { credentials, likes },
      cardView,
      data: { toDelete },
      post: {
        title,
        body,
        username,
        createdAt,
        commentCount,
        likeCount,
        userImage,
        topics,
        docType,
        preview,
        forumPostId,
      },
    } = this.props;

    const dopElm = [
      {
        icon: Bookmark,
        lable: "Save",
        comp: SavePop,
      },
      {
        icon: Share,
        lable: "Share",
        comp: SharePop,
      },
    ];
    username === credentials.username || credentials.accountType === "admin"
      ? dopElm.push({
          icon: Delete,
          lable: "Delete",
          comp: DeletePop,
        })
      : dopElm.push({
          icon: Report,
          lable: "Repot",
          comp: ReportPop,
        });

    TimeAgo.addLocale(en);
    const timeAgo = new TimeAgo("en-US");
    let topicTags = topics ? (
      topics.map((topic) => (
        <div
          key={Math.random()}
          className="post-top-type post-card-header-tag centered"
          onClick={this.tagClick}
        >
          {!topic ? "General" : topic}
        </div>
      ))
    ) : (
      <div
        className="post-top-type post-card-header-tag centered"
        onClick={this.tagClick}
      >
        General
      </div>
    );

    let previewComp = preview ? (
      preview.type === "image" ? (
        <img
          className="post-card-content-image"
          src={preview.preview}
          alt={preview.altTxt}
        />
      ) : (
        <LinkPreview preData={preview.preview[0]} bodyTxt={body} />
      )
    ) : (
      <p>{body.length > 150 ? body.substring(0, 150) + "..." : body}</p>
    );

    let cards = (
      <div
        className={`post-card-container ${
          toDelete === forumPostId ? "post-delete-animation" : ""
        }`}
        onClick={(e) => this.goToPost(e)}
      >
        <div className="post-card-header outskrts">
          <div className="centered">
            <div
              className="post-doc-type post-card-header-tag centered"
              onClick={this.tagClick}
            >
              {docType ? docType : "Post"}
            </div>
            {topicTags}
          </div>
          <MoreDropDown
            elements={dopElm}
            compId={forumPostId}
            delFunc={this.deletePost}
            compType={"post"}
          />
        </div>
        <div className="post-card-content">
          <Link
            to={`/forum/post/${
              this.props.post.forumPostId
            }/${this.props.post.title.split(" ").join("-")}`}
          >
            <h3>{title}</h3>
          </Link>
          <h6>{createdAt && timeAgo.format(new Date(createdAt), "twitter")}</h6>
          {previewComp}
        </div>
        <div className="post-card-footer outskrts">
          <div
            className="post-card-footer-profile-wrap centered"
            onClick={this.goToUser}
          >
            <img src={userImage} className="prof-pic-24" alt="profilePicture" />
            <p style={{ color: "#34a8fe" }}>{username}</p>
          </div>
          <div className="post-card-footer-comment-wrap centered">
            <p>{commentCount}</p> <Comment colorPrimary="#D2D6D9" size="18px" />
          </div>
          <div className="post-card-footer-like-wrap centered">
            <button
              className={`centered ${
                this.state.status <= 0 ? "" : "active-like-button"
              }`}
              onClick={this.likeClick}
            >
              <ArrowUp className="" colorPrimary="#D2D6D9" size="24px" />
            </button>
            <div
              className={`${this.state.status === 0 ? "" : "active-like-text"}`}
            >
              <p>{likeCount}</p>
            </div>

            <button
              className={`centered ${
                this.state.status >= 0 ? "" : "active-like-button"
              }`}
              onClick={this.unlikeClick}
            >
              <ArrowDown className="" colorPrimary="#D2D6D9" size="24px" />
            </button>
          </div>
        </div>
      </div>
    );

    let list = "list view";

    return cardView ? cards : list;
  }
}

ForumPost.propTypes = {
  likeForumPost: PropTypes.func.isRequired,
  unlikeForumPost: PropTypes.func.isRequired,
  clearErrors: PropTypes.func.isRequired,
  UI: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user,
  UI: state.UI,
  data: state.data,
});

export default connect(mapStateToProps, {
  clearErrors,
  likeForumPost,
  unlikeForumPost,
})(ForumPost);
