import React, { Component } from "react";

//styling
import "./MobileDropdownStyle.css";

//icons
import ArrowDown from "../../media/icons/ArrowDown";
// import Filter from "../../media/icons/Filter";

class MobileDropdown extends Component {
  state = {
    open: false
  };

  clicked() {
    // console.log("clicked");
    // this.setState({ open: !this.state.open });
    let newState = this.state.open;
    this.setState({
      open: !newState
    });
  }

  render() {
    return (
      <div
        className="mobile-seciton-item"
        style={
          !this.state.open ? { background: "none" } : { background: "#F9F9FA" }
        }
      >
        <div
          className="mobile-seciton-item-head outskrts"
          onClick={this.clicked.bind(this)}
        >
          <div className="centered">
            <div
              className="mobile-seciton-item-head-icon centered-col"
              style={{ background: this.props.color }}
            >
              {this.props.icon}
            </div>
            <h4>{this.props.itemName}</h4>
          </div>
          <div
            style={
              !this.state.open
                ? { transform: "rotate(0)" }
                : { transform: "rotate(180deg)" }
            }
          >
            <ArrowDown colorPrimary={this.props.color} size="24px" />
          </div>
        </div>

        <div
          className="mobile-seciton-item-body"
          style={
            !this.state.open
              ? { height: 0, paddingBottom: 0 }
              : { height: "auto", paddingBottom: "24px" }
          }
        >
          <div>
            Section: <b>{this.props.section}</b>
          </div>

          <div>
            Allowance: <b>{this.props.planLimit}</b>
          </div>
          <div>
            <span>{this.props.description}</span>
          </div>
        </div>
      </div>
    );
  }
}

export default MobileDropdown;
