import React from "react";

const Realtors = props => (
  <svg
    className={props.className}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
  >
    <path
      fill={props.colorPrimary}
      d="M10 13c-.35.59-.64 1.24-.81 1.93C6.5 15.16 3.9 16.42 3.9 17v1.1h5.3c.17.68.45 1.32.8 1.9H2v-3c0-2.66 5.33-4 8-4zm0-9a4 4 0 013.18 6.43c-.86.32-1.63.83-2.27 1.47L10 12a4 4 0 110-8zm0 1.9a2.1 2.1 0 100 4.2 2.1 2.1 0 000-4.2zM15.8 21v-3.882h2.4V21h3v-5.177H23L17 10l-6 5.823h1.8V21h3z"
    />
  </svg>
);

export default Realtors;
