import React from "react";

function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 417 351">
      <path
        fill="#ACB7BD"
        d="M93 82.8a4.8 4.8 0 110-9.6 4.8 4.8 0 010 9.6zM93 72a6 6 0 100 12.002A6 6 0 0093 72zM264 62.8a4.8 4.8 0 110-9.599 4.8 4.8 0 010 9.599zm0-10.8a6 6 0 100 12 6 6 0 000-12zM75 207.571h-3.857V211h-1.286v-3.429H66v-1.142h3.857V203h1.286v3.429H75v1.142zM183 67.143h-3.857V71h-1.286v-3.857H174v-1.286h3.857V62h1.286v3.857H183v1.286zM351 120.143h-3.857V124h-1.286v-3.857H342v-1.286h3.857V115h1.286v3.857H351v1.286zM324 204a5.99 5.99 0 00-4.243 1.757 6.003 6.003 0 000 8.486A6.003 6.003 0 00330 210a5.99 5.99 0 00-1.757-4.243A6.003 6.003 0 00324 204zM59.5 135c-.854 0-1.699.155-2.487.457a6.552 6.552 0 00-2.11 1.3C53.686 137.883 53 139.409 53 141c0 1.591.685 3.117 1.904 4.243.603.557 1.32.999 2.109 1.3A6.963 6.963 0 0059.5 147c1.724 0 3.377-.632 4.596-1.757C65.316 144.117 66 142.591 66 141a5.6 5.6 0 00-.495-2.296 6.002 6.002 0 00-1.409-1.947 6.552 6.552 0 00-2.109-1.3A6.963 6.963 0 0059.5 135z"
      ></path>
      <g filter="url(#filter0_d)">
        <rect
          width="187"
          height="98"
          x="130"
          y="96"
          fill="#E1E7E9"
          rx="10"
        ></rect>
        <rect
          width="145"
          height="5"
          x="151"
          y="139"
          fill="#C7D1D9"
          rx="2.5"
        ></rect>
        <rect
          width="145"
          height="5"
          x="151"
          y="152"
          fill="#C7D1D9"
          rx="2.5"
        ></rect>
        <rect
          width="89"
          height="5"
          x="151"
          y="165"
          fill="#C7D1D9"
          rx="2.5"
        ></rect>
        <rect
          width="61"
          height="5"
          x="151"
          y="120"
          fill="#C6D2D9"
          rx="2.5"
        ></rect>
      </g>
      <g filter="url(#filter1_d)">
        <rect
          width="187"
          height="98"
          x="100"
          y="149"
          fill="#fff"
          rx="10"
        ></rect>
      </g>
      <rect
        width="145"
        height="5"
        x="121"
        y="191"
        fill="#E3E7EB"
        rx="2.5"
      ></rect>
      <rect
        width="145"
        height="5"
        x="121"
        y="204"
        fill="#E3E7EB"
        rx="2.5"
      ></rect>
      <rect
        width="89"
        height="5"
        x="121"
        y="217"
        fill="#E3E7EB"
        rx="2.5"
      ></rect>
      <rect
        width="61"
        height="5"
        x="121"
        y="172"
        fill="#C7D1D9"
        rx="2.5"
      ></rect>
      <defs>
        <filter
          id="filter0_d"
          width="387"
          height="298"
          x="30"
          y="0"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="4"></feOffset>
          <feGaussianBlur stdDeviation="50"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0.67451 0 0 0 0 0.717647 0 0 0 0 0.741176 0 0 0 0.5 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow"
            result="shape"
          ></feBlend>
        </filter>
        <filter
          id="filter1_d"
          width="387"
          height="298"
          x="0"
          y="53"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="4"></feOffset>
          <feGaussianBlur stdDeviation="50"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0.67451 0 0 0 0 0.717647 0 0 0 0 0.741176 0 0 0 0.5 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow"
            result="shape"
          ></feBlend>
        </filter>
      </defs>
    </svg>
  );
}

export default Icon;
