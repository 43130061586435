import {
  SET_USER,
  SET_ERRORS,
  CLEAR_ERRORS,
  LOADING_UI,
  LOADING_USER,
  STOP_LOADING_USER,
  SET_UNAUTHENTICATED,
  SET_MAILLISTSUB,
  SET_MAILLISTUNSUB,
  LOADING_SUB,
  // STOP_LOADING_UI
  // SET_NEWUSER,
  // UNSET_NEWUSER
} from "../types";
import axios from "axios";
import firebase from "../../firebase";

// import thunk from "redux-thunk";
// import store from "../store";

export const authStateChng = (user) => (dispatch) => {
  if (user) {
    user
      .getIdToken()
      .then((token) => {
        setAuthorizationHeader(token);
        dispatch(getUserData(user));
        dispatch({ type: CLEAR_ERRORS });
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: SET_ERRORS,
          payload: err.response.data,
        });
      });
  } else {
    delete axios.defaults.headers.common["Authorization"];
    dispatch({ type: SET_UNAUTHENTICATED });
  }
};

export const loginUser = (userData) => (dispatch) => {
  dispatch({ type: LOADING_UI });
  firebase
    .auth()
    .signInWithEmailAndPassword(userData.email, userData.password)
    .catch((err) => {
      console.log(err);
      dispatch({
        type: SET_ERRORS,
        payload: err,
      });
    });
};

export const loginGoogle = () => (dispatch) => {
  dispatch({ type: LOADING_UI });
  var provider = new firebase.auth.GoogleAuthProvider();
  firebase
    .auth()
    .signInWithPopup(provider)
    .catch((err) => {
      console.log(err);
      dispatch({
        type: SET_ERRORS,
        payload: err,
      });
    });
};

export const signupUser = (newUserData) => (dispatch) => {
  // dispatch({ type: LOADING_UI, type: SET_NEWUSER });
  dispatch({ type: LOADING_UI });

  firebase
    .auth()
    .createUserWithEmailAndPassword(newUserData.email, newUserData.password)
    .catch((err) => {
      dispatch({ type: LOADING_UI });
      console.log(err);
      //error.code;
      //error.message
      dispatch({
        type: SET_ERRORS,
        payload: err,
      });
    });
};

export const profileCreate = (payload, history) => (dispatch) => {
  dispatch({ type: LOADING_UI });
  axios
    .post("/signup/setProfile", payload.userData)
    .then((res) => {
      return payload.formData && axios.post("/user/image", payload.formData);
    })
    .then(() => {
      return axios.get("/user");
    })
    .then((res) => {
      dispatch({
        type: SET_USER,
        payload: res.data,
      });
      dispatch({ type: CLEAR_ERRORS });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: SET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const uploadImage = (formData) => (dispatch) => {
  dispatch({ type: LOADING_USER });
  axios
    .post("/user/image", formData)
    .then(() => {
      dispatch(getUserData());
    })
    .catch((err) => console.log(err));
};

export const logoutUser = (history) => (dispatch) => {
  firebase
    .auth()
    .signOut()
    .then(() => {
      history.push("/");
    })
    .catch(function (error) {
      console.log(error);
    });
};

export const getUserData = (history) => (dispatch) => {
  //TODO: do not dipactch load user if new user is signign up
  dispatch({ type: LOADING_USER });
  axios
    .get("/user")
    .then((res) => {
      let location = window.location.href.toString();
      if (res.data.setupProfile) {
        console.log("no user data");
        if (!location.includes("/auth/signup/profile")) {
          window.location.href = "/auth/signup/profile";
        }
        dispatch({ type: STOP_LOADING_USER });
      } else {
        console.log("got userdata");
        dispatch({
          type: SET_USER,
          payload: res.data,
        });
      }
    })
    .catch((err) => {
      console.log(err.message);
      console.log(err);
    });
};

export const setPlan = (planData, history) => (dispatch) => {
  axios
    .get("/user/plan")
    .then((res) => {
      console.log("success");
      history.push("/forum");
    })
    .catch((err) => {
      console.log(err.message);
      console.log(err);
    });
};

//--------------------------------------------------------------------

//--------------------------------------------------------------------

export const subscribeToMailist = (emailData) => (dispatch) => {
  dispatch({ type: LOADING_SUB });
  axios
    .post("/mailingList/subscribe", emailData)
    .then((res) => {
      dispatch({ type: SET_MAILLISTSUB });
      return res.data.response;
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: SET_ERRORS,
        payload: err.response,
      });
    });
};
export const unsubscribeToMailist = (emailData) => (dispatch) => {
  dispatch({ type: LOADING_SUB });
  axios
    .post("/mailingList/unsubscribe", emailData)
    .then((res) => {
      dispatch({ type: SET_MAILLISTUNSUB });
      return res.data.response;
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: SET_ERRORS,
        payload: err.response,
      });
    });
};

export const betaSignup = (betaSignupData) => (dispatch) => {
  dispatch({ type: LOADING_UI });
  axios
    .post("/beta/signup", betaSignupData)
    .then((res) => {
      dispatch(getUserData());
      dispatch({ type: CLEAR_ERRORS });
    })
    .catch((err) => {
      dispatch({
        type: SET_ERRORS,
        payload: err.response.data,
      });
    });
};

const setAuthorizationHeader = (token) => {
  const FBIdToken = `Bearer ${token}`;
  axios.defaults.headers.common["Authorization"] = FBIdToken;
};
