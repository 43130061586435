import React from "react";

const Menu = props => (
  <svg
    className={props.className}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
  >
    <path
      fill={props.colorPrimary}
      d="M18.35,6H3.05C2.58,6,2.2,6.45,2.2,7s0.38,1,0.85,1h15.3c0.47,0,0.85-0.45,0.85-1S18.82,6,18.35,6z"
    />
    <path
      fill={props.colorPrimary}
      d="M21.2,11h-18c-0.55,0-1,0.45-1,1s0.45,1,1,1h18c0.55,0,1-0.45,1-1S21.76,11,21.2,11z"
    />
    <path
      fill={props.colorPrimary}
      d="M13.2,16h-10c-0.5,0-1,0.45-1,1s0.5,1,1,1h10c0.5,0,1-0.45,1-1S13.7,16,13.2,16z"
    />
  </svg>
);

export default Menu;

// <!-- Generator: Adobe Illustrator 23.1.0, SVG Export Plug-In  -->
// <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="25px"
// 	 height="24px" viewBox="0 0 25 24" style="enable-background:new 0 0 25 24;" xml:space="preserve">
// <style type="text/css">
// 	.st0{fill:#1C252E;}
// </style>
// <defs>
// </defs>
// <path class="st0" d="M18.35,6H3.05C2.58,6,2.2,6.45,2.2,7s0.38,1,0.85,1h15.3c0.47,0,0.85-0.45,0.85-1S18.82,6,18.35,6z"/>
// <path class="st0" d="M21.2,11h-18c-0.55,0-1,0.45-1,1s0.45,1,1,1h18c0.55,0,1-0.45,1-1S21.76,11,21.2,11z"/>
// <path class="st0" d="M13.2,16h-10c-0.5,0-1,0.45-1,1s0.5,1,1,1h10c0.5,0,1-0.45,1-1S13.7,16,13.2,16z"/>
// <rect class="st0" width="25" height="24"/>
// </svg>
