import React from "react";

const Facebook = props => (
  <svg
    className={props.className}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
  >
    <path
      fill={props.colorPrimary}
      d="M17,2v4h-2c-0.69,0-1,0.81-1,1.5V10h3v4h-3v8h-4v-8H7v-4h3V6c0-1.06,0.42-2.08,1.17-2.83
      	C11.92,2.42,12.94,2,14,2H17z"
    />
  </svg>
);

export default Facebook;
