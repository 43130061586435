import React, { Component } from "react";
import { HashLink as Link } from "react-router-hash-link";

import "./supportStyling.css";

//compopnents
import Card from "../components/cards/Card";
import BubbleBack from "../components/backgrounds/BubbleBack";
import Button from "../components/buttons/Button";
import Dropdown from "../components/dropdowns/Dropdown";
import SupportPerson from "../media/SVGs/SupportPerson";
import PageHeader from "../components/allPages/PageHeader";
import BetaCard from "../components/cards/BetaCard";
import ContentCard from "../components/cards/ContentCard";

//IOcons
import Check from "../media/SVGs/Check";
import SupportTicket from "../media/SVGs/SupportTicket";
import None from "../media/SVGs/None";
import MoreDots from "../media/icons/MoreDots";
import Search from "../media/icons/Search";
import ChevronRight from "../media/icons/ChevronRight";
import ChevronDown from "../media/icons/ChevronDown";
import CheckCircle from "../media/icons/CheckCircle";
import FullArrowRight from "../media/icons/FullArrowRight";
import NewPost from "../media/icons/NewPost";

class support extends Component {
  render() {
    return (
      <div className="support-page-wrap">
        <a className="page-link-top" id="support-top" />
        <BubbleBack />
        <div className="sizing support-header">
          <PageHeader
            title="Support"
            line1="What can we help you with?"
            image={<SupportPerson />}
          />
        </div>
        <div className="sizing">
          <div className="support-card ">
            <div className="support-card-head outskrts">
              <form className="support-card-head-search ">
                <Search colorPrimary="#707070" size="24" />
                <input type="text" placeholder="Describe your proplem..." />
              </form>
              <div className="support-card-head-button centered">
                <Button className="button suppor-button button-blue">
                  <div className="desktop-only">Ask the Community</div>
                  <NewPost
                    className="mobile-only"
                    colorPrimary="#fff"
                    size="24"
                  />
                </Button>
              </div>
            </div>
            <div className="support-card-body">
              <div className="support-card-body-header">
                <h6>Issue</h6>
                <h6 className="desktop-only">Created</h6>
                <h6>Solved</h6>
              </div>
              <div className="support-card-body-post">
                <h3>
                  Is there a good way to save your favorite properties
                  without...
                </h3>
                <p className="desktop-only">September 16, 2020</p>
                <Check />
              </div>
              <div className="support-card-body-post">
                <h3>I am having an issue with something that is...</h3>
                <p className="desktop-only">October 30, 2020</p>
                <Check />
              </div>
              <div className="support-card-body-post">
                <h3>How far from down town do you look?</h3>
                <p className="desktop-only">November 1, 2020</p>
                <Check />
              </div>
              <div className="support-card-body-post">
                <h3>Can you turn off notifications at night?</h3>
                <p className="desktop-only">December 20, 2020</p>
                <Check />
              </div>
              <div className="support-card-body-post">
                <h3>What subscription level do you need in order to get...</h3>
                <p className="desktop-only">November 27, 2020</p>
                <Check />
              </div>
              <div className="support-card-body-post">
                <h3>I am having an issue with something that is...</h3>
                <p className="desktop-only">September 6, 2020</p>
                <Check />
              </div>
              <div className="support-card-body-post">
                <h3>
                  Is there a good way to save your favorite properties
                  without...
                </h3>
                <p className="desktop-only">September 16, 2020</p>
                <Check />
              </div>
              <div className="support-card-body-post">
                <h3>I am having an issue with something that is...</h3>
                <p className="desktop-only">October 30, 2020</p>
                <Check />
              </div>
              <div className="support-card-body-post">
                <h3>How far from down town do you look?</h3>
                <p className="desktop-only">November 1, 2020</p>
                <Check />
              </div>
              <div className="support-card-body-post">
                <h3>Can you turn off notifications at night?</h3>
                <p className="desktop-only">December 20, 2020</p>
                <Check />
              </div>
            </div>
            <div className="support-card-footer outskrts">
              <div className=""></div>
              <div className="centered">
                <h6>Rows per page:</h6>
                <Dropdown
                  className=""
                  list=""
                  primary="30"
                  placeholder=""
                  color="#707070"
                />
                <h6> 1-6 of 6</h6>
                <button
                  className="button svg-button"
                  style={{ transform: "rotate(180deg)" }}
                >
                  <ChevronRight colorPrimary="#707070" size="24" />
                </button>

                <button className="button svg-button">
                  <ChevronRight colorPrimary="#707070" size="24" />
                </button>
              </div>
            </div>
          </div>
        </div>

        <ContentCard
          title="Have a more personal problem?"
          text="For problems that are specific to your account, start a private
          ticket and a member of the Flipit support team will help you
          resolve the issue."
          media={<SupportTicket />}
          button={
            <Link to="/support/create-a-ticket">
              <button
                style={{ height: "40px", padding: "6px 24px" }}
                className="button button-blue suppor-button"
              >
                <div>Start a ticket</div>
              </button>
            </Link>
          }
        />
      </div>
    );
  }
}

export default support;
