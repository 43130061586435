import React from "react";

const ChevronDown = props => (
  <svg
    className={props.className}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
  >
    <path fill={props.colorPrimary} d="M7,10L12,15L17,10H7Z" />
  </svg>
);

export default ChevronDown;
