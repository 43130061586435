import {
  SET_FORUMPOSTS,
  LOADING_DATA,
  LIKE_FORUMPOST,
  UNLIKE_FORUMPOST,
  DELETE_FORUMPOST,
  DELETE_PREVIEW,
  SET_ERRORS,
  POST_FORUMPOST,
  CLEAR_ERRORS,
  LOADING_UI,
  SET_FORUMPOST,
  STOP_LOADING_UI,
  SUBMIT_COMMENT,
  DELETE_FORUMCOM,
  LOADING_COMMENT
} from "../types";
import axios from "axios";

// Get all forumPosts
export const getForumPosts = (sortFilter, start) => dispatch => {
  //get x number more forum posts statting at start

  dispatch({ type: LOADING_DATA });

  axios
    .get(`/forumPosts/${sortFilter ? sortFilter : "score"}`)
    .then(res => {
      dispatch({
        type: SET_FORUMPOSTS,
        payload: res.data
      });
    })
    .catch(err => {
      dispatch({
        type: SET_FORUMPOSTS,
        payload: []
      });
    });
};
export const getForumPost = forumPostId => dispatch => {
  dispatch({ type: LOADING_UI });
  axios
    .get(`/forumPost/${forumPostId}`)
    .then(res => {
      dispatch({
        type: SET_FORUMPOST,
        payload: res.data
      });
      dispatch({ type: STOP_LOADING_UI });
    })
    .catch(err => console.log(err));
};

// export const getTopTopics = () => (dispatch) => {
//   // dispatch({ type: LOADING_UI });
//   axios
//     .get(`/forumPost/${forumPostId}`)
//     .then((res) => {
//       dispatch({
//         type: SET_TOPTOPICS,
//         payload: res.data,
//       });
//     })
//     .catch((err) => console.log(err));
// };

// Post a forumPost
export const postForumPost = (newForumPost, history) => dispatch => {
  dispatch({ type: LOADING_UI });
  console.log(newForumPost);
  axios
    .post("/forumPost", newForumPost)
    .then(res => {
      dispatch({
        type: POST_FORUMPOST,
        payload: res.data
      });
      dispatch(clearErrors());
      history && history.push("/forum");
    })
    .catch(err => {
      dispatch({
        type: SET_ERRORS,
        payload: err.response.data
      });
    });
};
//add image to post
export const uploadPostImage = formData => dispatch => {
  dispatch({ type: LOADING_UI });
  axios
    .post("/addImage", formData)
    .then(res => {
      console.log(res);
      console.log(res.url);
      dispatch(STOP_LOADING_UI);
    })
    .catch(err => console.log(err));
};

export const addTopics = (newTopics, history) => dispatch => {
  dispatch({ type: LOADING_UI });
  axios
    .post("/addTopics", newTopics)
    .then(res => {
      dispatch({
        type: STOP_LOADING_UI
      });
      dispatch(clearErrors());
      history && history.push("/forum");
    })
    .catch(err => {
      dispatch({
        type: SET_ERRORS,
        payload: err.response.data
      });
    });
};
// Like a forumPost
export const likeForumPost = pac => dispatch => {
  //update comp count
  dispatch({
    type: LIKE_FORUMPOST,
    payload: pac
  });

  axios.get(`/forumPost/${pac.id}/like`).catch(err => console.log(err));
};
// Unlike a forumPost
export const unlikeForumPost = pac => dispatch => {
  dispatch({
    type: LIKE_FORUMPOST,
    payload: pac
  });

  axios.get(`/forumPost/${pac.id}/unlike`).catch(err => console.log(err));
};
// Submit a comment
export const submitComment = (commentData, closeFunc) => dispatch => {
  dispatch({ type: LOADING_COMMENT });
  axios
    .post(`/forumPost/${commentData.postId}/comment`, commentData)
    .then(res => {
      let repackage = {
        id: res.data.id,
        data: res.data,
        comments: []
      };
      dispatch({
        type: SUBMIT_COMMENT,
        payload: repackage
      });
      dispatch(clearErrors());
      if (closeFunc) return closeFunc();
    })
    .catch(err => {
      console.log(err);
      dispatch({
        type: SET_ERRORS,
        payload: err
      });
    });
};
export const deleteForumPost = forumPostId => dispatch => {
  // dispatch({ type: DELETE_FORUMPOST, payload: forumPostId });
  dispatch({ type: DELETE_PREVIEW, payload: forumPostId });
  axios
    .delete(`/forumPost/${forumPostId}/delete`)
    .then(() => {
      dispatch({ type: DELETE_FORUMPOST, payload: forumPostId });
    })
    .catch(err => console.log(err));
};
// delete a comment
export const deleteComment = commentId => dispatch => {
  console.log("delete: ", commentId);
  dispatch({ type: DELETE_PREVIEW, payload: commentId });

  axios
    .delete(`/forumComment/${commentId}/delete`)
    .then(() => {
      console.log("it worked-------------");
      dispatch({ type: DELETE_FORUMCOM, payload: commentId });
    })
    .catch(err => {
      console.log(err);
      dispatch({
        type: SET_ERRORS,
        payload: err
      });
    });
};
export const getUserData = userHandle => dispatch => {
  dispatch({ type: LOADING_DATA });
  axios
    .get(`/user/${userHandle}`)
    .then(res => {
      dispatch({
        type: SET_FORUMPOSTS,
        payload: res.data.forumPosts
      });
    })
    .catch(() => {
      dispatch({
        type: SET_FORUMPOSTS,
        payload: null
      });
    });
};

export const clearErrors = () => dispatch => {
  dispatch({ type: CLEAR_ERRORS });
};
