import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";

//styling
import "./ProfileBadgeStyle.css";

//Components
import Spinner from "../loading/Spinner";

//redux stuff
import { setPlan } from "../../redux/actions/userActions";

// icons
import Admin from "../../media/icons/badges/Admin";
import Beta from "../../media/icons//Beta";
import Free from "../../media/icons/badges/FreeUser";
import Flipper from "../../media/icons/badges/Flipper";
import ProFlipper from "../../media/icons/badges/ProFlipper";
import Enterprise from "../../media/icons/badges/Enterprise";

class PlansWidget extends Component {
  render() {
    const { beta, accountType, loading, outline, classAdg } = this.props;

    const components = {
      admin: Admin,
      beta: Beta,
      free: Free,
      flipper: Flipper,
      pro: ProFlipper,
      ent: Enterprise
    };

    let outlineColor = !outline ? "#fff" : outline;
    var MyComponent =
      components[!beta || accountType == "admin" ? accountType : "beta"];

    return (
      <div
        className={`prof-badg-cont centered-col ${classAdg ? classAdg : ""}`}
        style={{ background: outlineColor }}
      >
        <div className="prof-badg-wrap centered-col">
          <Link to="/plans" className="centered-col">
            {!MyComponent ? (
              <Spinner />
            ) : (
              <MyComponent colorPrimary="#BDBDBD" size="18px" />
            )}
          </Link>
        </div>
      </div>
    );
  }
}
export default PlansWidget;
