import React from "react";

const Search = props => (
  <svg
    className={props.className}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
  >
    <path
      fill={props.colorPrimary}
      d="M19,13H13V19H11V13H5V11H11V5H13V11H19V13Z"
    />
  </svg>
);

export default Search;
