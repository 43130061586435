import React, { Component } from "react";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";
import { withRouter } from "react-router-dom";

import { HashLink as Link } from "react-router-hash-link";

//axios
import axios from "axios";
//redux stuff
import { connect } from "react-redux";
// import PropTypes from "prop-types";

//Styling
import "./UserBannerStyle.css";

//compnents
import Dropdown from "../../dropdowns/Dropdown";
import TabBar from "../../tabs/TabBar";
import SearchBar from "../../search/SearchBar";
import Spinner from "../../loading/Spinner";

//images
// import Placeholder from "../../../media/images/placeholder.jpg";

//icon
// import Search from "../../../media/icons/Search";
import Beta from "../../../media/icons/Beta";
import Filter from "../../../media/icons/Filter";
import ChevronDown from "../../../media/icons/ChevronDown";

class Banner extends Component {
  constructor(props) {
    super(props);

    this.state = {
      lineWidth: 0,
      linePosition: 0,
      loadingUser: true,
      username: this.usernameFromURL()
    };
  }
  componentDidMount() {
    this.isThisTheRightPage() && this.retreveUser();
  }

  componentDidUpdate(prevProps) {
    let newPath = this.props.location.pathname;
    let oldPath = prevProps.location.pathname;
    if (newPath.split("/")[2] !== oldPath.split("/")[2]) {
      console.log(newPath.split("/")[2]);
      this.isThisTheRightPage() && this.retreveUser();
    }
  }

  isThisTheRightPage() {
    if (this.props.user.credentials.username === this.usernameFromURL()) {
      return this.props.history.push("/profile");
    }
    return true;
  }
  usernameFromURL() {
    return this.props.location.pathname.split("/")[2];
    // let locationTrunc = this.props.location.pathname.replace("/user/", "");
    // return locationTrunc.substr(
    //   0,
    //   locationTrunc.indexOf("/") !== -1
    //     ? locationTrunc.indexOf("/")
    //     : locationTrunc.length
    // );
  }
  retreveUser() {
    this.setState({
      loadingUser: true
    });
    axios
      .get(`/user/${this.usernameFromURL()}`)
      .then(res => {
        this.setState({
          username: res.data.user.username,
          email: res.data.user.email,
          userId: res.data.user.userId,
          imageUrl: res.data.user.imageUrl,
          created: res.data.user.createdAt,
          loadingUser: false
        });
      })
      .catch(err => console.log(err));
  }

  render() {
    TimeAgo.addLocale(en);

    const timeAgo = new TimeAgo("en-US");
    const tabPathRoot = `/user/${this.state.username}`;
    const ProfileTabs = {
      Tab: {
        text: "Posts",
        path: `${tabPathRoot}`,
        className: "banner-tab",
        activeClassName: "active-banner-tab"
      },
      CommentsTab: {
        text: "Comments",
        path: `${tabPathRoot}/comments`,
        className: "banner-tab",
        activeClassName: "active-banner-tab"
      },
      FlipsTab: {
        text: "Flips",
        path: `${tabPathRoot}/flips`,
        className: "banner-tab",
        activeClassName: "active-banner-tab"
      }
    };
    return (
      <div>
        <div className="banner-header-user outskrts">
          <div className="centered">
            <div className="banner-profile-pic-container">
              <div className="banner-profile-pic-wrap centered-col">
                {this.state.loadingUser ? (
                  <Spinner color="#939eab" size="24px" />
                ) : (
                  <img src={this.state.imageUrl} alt="Profile Pic" />
                )}
              </div>
              <div className="banner-profile-badge-wrap centered-col">
                <div className="banner-profile-badge centered-col">
                  <Beta colorPrimary="#BDBDBD" size="16px" />
                </div>
              </div>
            </div>
            <div className="banner-user-info">
              <h2>
                {this.state.loadingUser ? "... " : `@${this.state.username}`}
              </h2>
              <p style={{ fontSize: "0.75rem" }}>
                {this.state.loadingUser
                  ? "..."
                  : `member since ${timeAgo.format(
                      new Date(this.state.created)
                    )}`}
              </p>
              <p
                style={{ color: "#BDBDBD", opacity: "1", fontSize: "0.875rem" }}
              >
                {this.state.loadingUser ? "..." : `Beta User`}
              </p>
            </div>
          </div>
          <div className="banner-numbs-container centered">
            <div className="banner-numbs-wrap">
              <div className="">0</div>
              <div className="">Flips</div>
            </div>
            <div className="banner-numbs-div"></div>
            <div className="banner-numbs-wrap">
              <div className="">0</div>
              <div className="">Answers</div>
            </div>
            <div className="banner-numbs-div"></div>
            <div className="banner-numbs-wrap">
              <div className="">0</div>
              <div className="">Posts</div>
            </div>
          </div>
        </div>
        <div className="banner-tabs outskrts sizing">
          <div className="banner-profile-button-wrap">
            <Link to="forum/messages">
              <button className="button button-blue">Message</button>
            </Link>
            <button className="button button-blue-outline">Follow</button>
          </div>
          <button className="banner-profile-button-wrap-noessential button button-silver-text">
            Report
          </button>
        </div>
        <div className="banner-tabs outskrts sizing">
          <div style={{ height: "100%" }}>
            <TabBar
              content={ProfileTabs}
              root={`/user/${this.state.username}`}
            />
          </div>
          <div className="banner-tabs-rit centered">
            <Dropdown
              className="mobile-only"
              list=""
              primary="Trending"
              placeholder=""
            >
              <div className="centered">
                Category
                <ChevronDown colorPrimary="#fff" size="24px" />
              </div>
            </Dropdown>
            <Dropdown list="" primary="Trending" placeholder="">
              <Filter className="mobile-only" colorPrimary="#fff" size="24px" />
            </Dropdown>
            <div className="banenr-searchbar-container">
              <SearchBar />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user
});

export default connect(mapStateToProps)(withRouter(Banner));
