import React, { Component } from "react";
import { HashLink as Link } from "react-router-hash-link";
import PropTypes from "prop-types";
import axios from "axios";

//redux stuff
import { connect } from "react-redux";

import "./pricingStyle.css";
import "./InputFormStyle.css";

//compopnents
import BetaCard from "../components/cards/BetaCard";
import Card from "../components/cards/Card";
import Button from "../components/buttons/Button";
import FormBack from "../components/backgrounds/FormBack";
import MobilePricing from "../components/mobile/MobilePricing";
import AuthForm from "../components/auth/AuthForm";

import PageHeader from "../components/allPages/PageHeader";
import CardHeader from "../components/allPages/CardHeader";
import ContentCard from "../components/cards/ContentCard";
import Spinner from "../components/loading/Spinner";

//IOcons
import Check from "../media/SVGs/Check";
import None from "../media/SVGs/None";
import BetaPeople from "../media/SVGs/BetaPeople";
import ArrowDown from "../media/icons/ArrowDown";
import Information from "../media/icons/Information";
import PricingContact from "../media/SVGs/PricingContact";

//city autocomplete
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng
} from "react-places-autocomplete";

class pricing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      response: false
    };

    this.betaAdressInput = React.createRef();
  }

  handleChange = address => {
    this.setState({ address });
  };
  handleSelect = address => {
    geocodeByAddress(address)
      .then(results => {
        this.setState({ address: results[0].formatted_address });
        return getLatLng(results[0]);
      })
      .then(latLng => {
        this.setState({ latLng: [latLng.lat, latLng.lng] });
      })
      .catch(error => console.error("Error", error));
  };
  handleradioSelect = e => {
    this.setState({
      [e.currentTarget.name]: e.currentTarget.value
    });
  };
  handleSubmit = event => {
    event.preventDefault();
    this.setState({
      loading: true
    });

    const submissionData = {
      fname: this.state.fname,
      lname: this.state.lname,
      address: this.state.address,
      latLng: this.state.latLng,
      age: this.state.age,
      experience: this.state.experience,
      proactive: this.state.proactive,
      priceRange: this.state.range
    };

    axios
      .post("/beta/signup", submissionData)
      .then(res => {
        console.log(res.data);

        if (res.data.application === "waitlisted") {
          let fullResponce =
            res.data.response +
            "But things change constantly, so we’ve added you to the wait list and If a spot becomes available, we’ll let you know.";
          this.setState({
            loading: false,
            title: res.data.title,
            response: fullResponce
          });
        } else if (res.data.application === "accepted") {
          let fullResponce =
            res.data.response +
            `  You now have full access to the console as well as the beta community on the ` +
            (
              <span>
                <Link to="/forum">Flipit forum</Link>
              </span>
            ) +
            `. Check your email for more details.`;
          this.setState({
            loading: false,
            title: res.data.title,
            response: fullResponce
          });
        }
      })
      .catch(err => {
        this.setState({
          loading: false
        });
        console.log(err);
      });
  };

  render() {
    const {
      user: { authenticated }
    } = this.props;

    let inputForm = (
      <div className="pricing-table-wrap-free">
        <h3>Application</h3>
        <p>
          The Flipit Beta Program grants participants free access to our full
          set of available (and not yet available) features! All we ask in
          return is that you provide us with feedback when prompted. Please
          answer a few quick questions so that we can get to know you a little
          better
        </p>
        <div className="pricing-table-wrap-divider"></div>

        <div className="form-wrapper">
          <form onSubmit={this.handleSubmit}>
            <div className="input-wrapper centered">
              <div className="input-text">
                First Name
                <input
                  required
                  type="text"
                  id="fname"
                  name="fname"
                  placeholder="Enter your first name"
                  onChange={this.handleradioSelect}
                />
              </div>
              <div className="input-text">
                Last Name
                <input
                  required
                  type="text"
                  id="lname"
                  name="lname"
                  placeholder="Enter your Last name"
                  onChange={this.handleradioSelect}
                />
              </div>
            </div>
            <div className="input-wrapper centered">
              <div className="input-text">
                <div className="outskrts" style={{ width: "100%" }}>
                  <div>Address</div>
                  <div style={{ color: "#757575" }}>Why?</div>
                </div>

                <PlacesAutocomplete
                  value={this.state.address}
                  onChange={this.handleChange}
                  onSelect={this.handleSelect}
                >
                  {({
                    getInputProps,
                    suggestions,
                    getSuggestionItemProps,
                    loading
                  }) => (
                    <div style={{ width: "100%" }}>
                      <input
                        required
                        {...getInputProps({
                          placeholder: "Enter your address",
                          className: "location-search-input"
                        })}
                      />
                      <div
                        className={`autocomplete-dropdown-container form-input-suggestions ${
                          suggestions.length > 1
                            ? "autocomplet-drop-shadow"
                            : ""
                        }`}
                      >
                        {loading && <div>Loading...</div>}
                        {suggestions.map(suggestion => {
                          const className = suggestion.active
                            ? "suggestion-item--active"
                            : "suggestion-item";
                          // inline style for demonstration purpose
                          const style = suggestion.active
                            ? {
                                backgroundColor: "#f4f6f8",
                                cursor: "pointer"
                              }
                            : {
                                backgroundColor: "#ffffff",
                                cursor: "pointer"
                              };
                          return (
                            <div
                              {...getSuggestionItemProps(suggestion, {
                                className,
                                style
                              })}
                            >
                              <span>{suggestion.description}</span>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </PlacesAutocomplete>
              </div>
            </div>

            <div className="question-wrapper">
              <p>How old are you?</p>
              <div className="question-answer-wrapper">
                <input
                  required
                  type="radio"
                  id="age-<30"
                  name="age"
                  value="<30"
                  onChange={this.handleradioSelect}
                />
                <label for="age-<30">Younger than 30</label>
                <br />
                <input
                  type="radio"
                  id="age-30-39"
                  name="age"
                  value="30-39"
                  onChange={this.handleradioSelect}
                />
                <label for="age-30-39">30 – 39</label>
                <br />
                <input
                  type="radio"
                  id="age-40-49"
                  name="age"
                  value="40-49"
                  onChange={this.handleradioSelect}
                />
                <label for="age-40-49">40 – 49</label>
                <br />
                <input
                  type="radio"
                  id="age-50-60"
                  name="age"
                  value="50-60"
                  onChange={this.handleradioSelect}
                />
                <label for="age-50-60">50 – 60</label>
                <br />
                <input
                  type="radio"
                  id="age->60"
                  name="age"
                  value=">60"
                  onChange={this.handleradioSelect}
                />
                <label for="age->60">Wiser than 60</label>
                <br />
              </div>
            </div>
            <div className="question-wrapper">
              <p>Have you ever flipped a property?</p>
              <div className="question-answer-wrapper">
                <input
                  required
                  type="radio"
                  id="experience-none"
                  name="experience"
                  value="none"
                  onChange={this.handleradioSelect}
                />
                <label for="experience-none">
                  No, I’ve never even owned a property
                </label>
                <br />
                <input
                  type="radio"
                  id="experience-little"
                  name="experience"
                  value="little"
                  onChange={this.handleradioSelect}
                />
                <label for="experience-little">
                  No, but am currently a homeowner
                </label>
                <br />
                <input
                  type="radio"
                  id="experience-medium"
                  name="experience"
                  value="medium"
                  onChange={this.handleradioSelect}
                />
                <label for="experience-medium">
                  Yes, I have bought, flipped, and sold one property
                </label>
                <br />
                <input
                  type="radio"
                  id="experience-expert"
                  name="experience"
                  value="expert"
                  onChange={this.handleradioSelect}
                />
                <label for="experience-expert">
                  Yes, I have flipped many properties.
                </label>
                <br />
              </div>
            </div>
            <div className="question-wrapper">
              <p>Would you purchase a property in the next 6 months?</p>
              <div className="question-answer-wrapper">
                <input
                  required
                  type="radio"
                  name="proactive"
                  id="proactive-not-looking"
                  value="not looking"
                  onChange={this.handleradioSelect}
                />
                <label for="proactive-not-looking">
                  No, I have no interest in purchasing a property
                </label>
                <br />
                <input
                  type="radio"
                  id="proactive-active"
                  name="proactive"
                  value="active"
                  onChange={this.handleradioSelect}
                />
                <label for="proactive-active">
                  Maybe, if the right opportunity presented itself
                </label>
                <br />
                <input
                  type="radio"
                  id="proactive-very-active"
                  name="proactive"
                  value="very active"
                  onChange={this.handleradioSelect}
                />
                <label for="proactive-very-active">
                  Yes, I am actively looking for a good deal
                </label>

                <br />
              </div>
            </div>
            <div className="question-wrapper">
              <p>
                If you were to flip a property, what would your ideal price
                range be (purchase price and repair expenses)?
              </p>
              <div className="question-answer-wrapper">
                <input
                  type="checkbox"
                  id="range<50"
                  name="range"
                  value="<50"
                  onChange={this.handleradioSelect}
                />
                <label for="range<50">Less than $50k</label>
                <br />
                <input
                  type="checkbox"
                  id="range50-100"
                  name="range"
                  value="50-100"
                  onChange={this.handleradioSelect}
                />
                <label for="range50-100">$50 - $100k</label>
                <br />
                <input
                  type="checkbox"
                  id="range100-250"
                  name="range"
                  value="100-250"
                  onChange={this.handleradioSelect}
                />
                <label for="range100-250">$100 - $250k</label>
                <br />
                <input
                  type="checkbox"
                  id="range250-500"
                  name="range"
                  value="250-500"
                  onChange={this.handleradioSelect}
                />
                <label for="range250-500">$250 - $500k</label>
                <br />
                <input
                  type="checkbox"
                  id="range>500"
                  name="range"
                  value=">500"
                  onChange={this.handleradioSelect}
                />
                <label for="range>500">More than $500k</label>
                <br />
              </div>
            </div>
            <button type="submit" className="button button-blue">
              {this.state.loading ? (
                <div className="">
                  <Spinner color="#fff" bordWidth="3px" size="16px" />
                </div>
              ) : (
                "Submit application"
              )}
            </button>
          </form>
        </div>

        <div className="pricing-table-wrap-divider"></div>
        <div className="beta-form-legal">
          All data collected here is ONLY used to determine program eligibility
          and is not disclosed to any other third parties.
        </div>
      </div>
    );
    let signinForm = (
      <div className="beta-auth-container">
        <AuthForm />
      </div>
    );
    let formResponce = (
      <div className="">
        <div className="pricing-table-wrap-free">
          <a className="page-item-link" id="pricingTableLink" />
          <h3>{this.state.title}</h3>
          <p>{this.state.response}</p>
          <div className="outskrts" style={{ width: "256px", margin: "auto" }}>
            <Link to="/">
              <button className="button button-blue">Return home</button>
            </Link>
            <Link to="/forum">
              <button className="button button-white">Go to forum</button>
            </Link>
          </div>
        </div>
      </div>
    );
    let inputFormWrap = (
      <Card className="card-shdow-sml table-card-wrap">
        <div className="pricing-table-wrap">
          {!this.state.response ? inputForm : formResponce}
        </div>
      </Card>
    );

    return (
      <div className="pricing-page-wrap form-card-container">
        <a className="page-link-top" id="beta-top" />
        <FormBack />
        <div className="sizing pricing-header">
          <PageHeader
            title="Join the beta"
            line1="Access to the latest tools"
          />
        </div>
        <section className="sizing">
          {authenticated ? inputFormWrap : signinForm}
        </section>

        <div className="spacer"></div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user
});

export default connect(mapStateToProps)(pricing);
