import React from "react";

const BellOutline = props => (
  <svg
    className={props.className}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
  >
    <path
      fill={props.colorPrimary}
      fillRule="evenodd"
      d="M20 2l2 2s-2 2-2 4c0 1 .5 2.5 1 4s1 3 1 4c0 4-10 6-10 6S2 20 2 16c0-1 .5-2.5 1-4s1-3 1-4c0-2-2-4-2-4l2-2c1.15.64 2.6 1 4 1s2.86-.37 4-1c1.14.63 2.6 1 4 1s2.85-.36 4-1zm-9 14v-3h2v3h3v-5h2l-6-5-6 5h2v5h3z"
      clipRule="evenodd"
    />
  </svg>
);

export default BellOutline;
