import React from "react";

const TrendAnalysis = props => (
  <svg
    className={props.className}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
  >
    <path
      fill={props.colorPrimary}
      d="M19 12h2L11 2 1 12h2v9h3.5v-2H5v-8l6-6 6 6v1.5h2V12z"
    />
    <path
      fill={props.colorPrimary}
      d="M9.273 19.333l.318.047 2.908-3.047a1.342 1.342 0 01.331-1.273 1.217 1.217 0 011.794 0c.338.347.446.84.332 1.273l1.635 1.714.318-.047c.115 0 .223 0 .318.047l2.272-2.38c-.044-.1-.044-.214-.044-.334 0-.353.134-.692.372-.943.239-.25.563-.39.9-.39.338 0 .662.14.9.39.239.25.373.59.373.943 0 .354-.134.693-.373.943-.238.25-.562.39-.9.39-.114 0-.223 0-.318-.046L18.137 19c.045.1.045.213.045.333 0 .354-.134.693-.373.943s-.562.39-.9.39c-.337 0-.661-.14-.9-.39a1.366 1.366 0 01-.373-.943l.045-.333-1.635-1.713a1.466 1.466 0 01-.637 0l-2.908 3.046.045.334c0 .353-.135.692-.373.942-.239.25-.563.391-.9.391-.338 0-.662-.14-.9-.39A1.366 1.366 0 018 20.666c0-.354.134-.693.373-.943.238-.25.562-.39.9-.39z"
    ></path>
  </svg>
);

export default TrendAnalysis;
