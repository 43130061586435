import React from "react";

function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 358 357">
      <path
        fill="#ACB7BD"
        d="M89 58.8a4.8 4.8 0 110-9.6 4.8 4.8 0 010 9.6zM89 48a6 6 0 100 12.002A6 6 0 0089 48zM239 64.8a4.8 4.8 0 110-9.599 4.8 4.8 0 010 9.599zm0-10.8a6 6 0 100 12 6 6 0 000-12zM72 184.571h-3.857V188h-1.286v-3.429H63v-1.142h3.857V180h1.286v3.429H72v1.142zM168 67.143h-3.857V71h-1.286v-3.857H159v-1.286h3.857V62h1.286v3.857H168v1.286zM303 97.143h-3.857V101h-1.286v-3.857H294v-1.286h3.857V92h1.286v3.857H303v1.286zM288 175a5.99 5.99 0 00-4.243 1.757 6.003 6.003 0 000 8.486A6.003 6.003 0 00294 181a5.99 5.99 0 00-1.757-4.243A6.003 6.003 0 00288 175zM57 113a5.992 5.992 0 00-4.243 1.757 6.002 6.002 0 001.947 9.786A6 6 0 1057 113z"
      ></path>
      <g filter="url(#filter0_d)">
        <rect
          width="157.086"
          height="48.369"
          x="100"
          y="204"
          fill="#fff"
          rx="10"
        ></rect>
        <rect
          width="121.805"
          height="4.243"
          x="117.639"
          y="220.123"
          fill="#C7D1D9"
          rx="2.121"
        ></rect>
        <rect
          width="43"
          height="4"
          x="118"
          y="231"
          fill="#C7D1D9"
          rx="2"
        ></rect>
      </g>
      <g filter="url(#filter1_d)">
        <rect
          width="157.086"
          height="48.369"
          x="100"
          y="96"
          fill="#fff"
          rx="10"
        ></rect>
        <rect
          width="121.805"
          height="4.243"
          x="117.639"
          y="112.123"
          fill="#C7D1D9"
          rx="2.121"
        ></rect>
        <rect
          width="73"
          height="4"
          x="118"
          y="123"
          fill="#C7D1D9"
          rx="2"
        ></rect>
      </g>
      <rect
        width="157.086"
        height="48.369"
        x="100"
        y="150"
        fill="#fff"
        rx="10"
      ></rect>
      <rect
        width="121.805"
        height="4.243"
        x="117.639"
        y="166.123"
        fill="#C7D1D9"
        rx="2.121"
      ></rect>
      <rect width="106" height="4" x="118" y="177" fill="#C7D1D9" rx="2"></rect>
      <defs>
        <filter
          id="filter0_d"
          width="357.086"
          height="248.369"
          x="0"
          y="108"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="4"></feOffset>
          <feGaussianBlur stdDeviation="50"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0.67451 0 0 0 0 0.717647 0 0 0 0 0.741176 0 0 0 0.5 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow"
            result="shape"
          ></feBlend>
        </filter>
        <filter
          id="filter1_d"
          width="357.086"
          height="248.369"
          x="0"
          y="0"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="4"></feOffset>
          <feGaussianBlur stdDeviation="50"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0.67451 0 0 0 0 0.717647 0 0 0 0 0.741176 0 0 0 0.5 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow"
            result="shape"
          ></feBlend>
        </filter>
      </defs>
    </svg>
  );
}

export default Icon;
