import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="104"
      height="74"
      fill="none"
      viewBox="0 0 104 74"
    >
      <path fill="#fff" d="M0 0H104V74H0z"></path>
      <path
        fill="#E5EAEE"
        d="M32.788 55.808l6.107-5.328a9.72 9.72 0 0111.149-1.15 9.72 9.72 0 0012.844-3.08l.465-.696a10.623 10.623 0 019.722-4.69l9.26.775c1.5.125 2.979.438 4.402.93l5.658 1.959c11.303 3.912 13.615 18.88 4.02 26.02a14.892 14.892 0 01-12.906 2.393l-14.039-3.93a20 20 0 00-9.56-.302L41.23 72.69c-6.597 1.406-12.594-4.151-11.694-10.836a9.75 9.75 0 013.253-6.045z"
      ></path>
      <ellipse
        cx="90.552"
        cy="55.495"
        fill="#CDD1D4"
        fillOpacity="0.5"
        rx="6.162"
        ry="3.825"
      ></ellipse>
      <path
        fill="#CDD1D4"
        d="M88.848 55.07v-6.162l1.912-.85v7.012c-.425.637-1.7.637-1.912 0z"
      ></path>
      <path
        fill="#F0F3F6"
        d="M89.913 13c-3.4 10.695-10.284 32.169-10.624 32.508 3.4 5.95 18.06 6.375 21.247 0L89.913 13z"
      ></path>
      <path
        fill="#CDD1D4"
        d="M100.53 45.508L89.907 13l-3.825 36.685c5.512 1.227 12.385-.05 14.448-4.177z"
      ></path>
      <ellipse
        cx="78.34"
        cy="63.245"
        fill="#CDD1D4"
        fillOpacity="0.5"
        rx="4.301"
        ry="2.034"
      ></ellipse>
      <path
        fill="#CDD1D4"
        d="M76.023 63.245v-5.594l2.647-1.78v7.374c-.661.51-1.985.51-2.647 0z"
      ></path>
      <path
        fill="#F0F3F6"
        d="M85.287 51.549c0 3.651-3.555 6.611-7.94 6.611-4.386 0-7.94-2.96-7.94-6.611 0-3.652 3.554-17.038 7.94-17.038 4.385 0 7.94 13.386 7.94 17.038z"
      ></path>
      <path
        fill="#CDD1D4"
        d="M77.346 58.16c4.386 0 7.94-2.96 7.94-6.612 0-3.651-3.554-17.037-7.94-17.037 2.853 13.86 3.101 20.077-3.639 22.915a9.216 9.216 0 003.64.734z"
      ></path>
      <ellipse
        cx="54.682"
        cy="61.095"
        fill="#CDD1D4"
        fillOpacity="0.5"
        rx="4.557"
        ry="2.155"
      ></ellipse>
      <path
        fill="#CDD1D4"
        d="M52.227 61.096v-5.927l2.804-1.886v7.813c-.701.539-2.103.539-2.804 0z"
      ></path>
      <path
        fill="#F0F3F6"
        d="M62.044 47.883c0 4.547-3.766 8.232-8.412 8.232-4.647 0-8.413-3.685-8.413-8.232 0-4.546 3.766-21.213 8.413-21.213 4.646 0 8.412 16.667 8.412 21.213z"
      ></path>
      <path
        fill="#CDD1D4"
        d="M53.392 56.115c4.779 0 8.653-3.685 8.653-8.232 0-4.546-3.874-21.213-8.653-21.213 3.109 17.257 3.379 24.998-3.966 28.532a8.974 8.974 0 003.966.913z"
      ></path>
      <ellipse
        cx="44.2"
        cy="63.908"
        fill="#CDD1D4"
        fillOpacity="0.5"
        rx="3.055"
        ry="1.445"
      ></ellipse>
      <path
        fill="#CDD1D4"
        d="M42.555 63.91v-3.975l1.88-1.265v5.24c-.47.36-1.41.36-1.88 0z"
      ></path>
      <path
        fill="#F0F3F6"
        d="M49.137 55.6c0 2.593-2.525 4.696-5.64 4.696-3.116 0-5.642-2.103-5.642-4.697s2.526-12.103 5.641-12.103c3.116 0 5.641 9.51 5.641 12.103z"
      ></path>
      <path
        fill="#CDD1D4"
        d="M43.495 60.296c3.116 0 5.641-2.103 5.641-4.697s-2.525-12.103-5.64-12.103c2.026 9.846 2.202 14.263-2.586 16.279a6.546 6.546 0 002.586.521z"
      ></path>
      <ellipse
        cx="20.568"
        cy="65.269"
        fill="#E5EAEE"
        rx="2.689"
        ry="1.793"
      ></ellipse>
      <ellipse
        cx="21.067"
        cy="58.65"
        fill="#CDD1D4"
        fillOpacity="0.5"
        rx="3.055"
        ry="1.445"
      ></ellipse>
      <path
        fill="#CDD1D4"
        d="M19.422 58.65v-3.973l1.88-1.265v5.239c-.47.361-1.41.361-1.88 0z"
      ></path>
      <path
        fill="#F0F3F6"
        d="M26.004 50.341c0 2.594-2.525 4.697-5.64 4.697-3.116 0-5.641-2.103-5.641-4.697s2.525-12.103 5.64-12.103c3.116 0 5.641 9.51 5.641 12.103z"
      ></path>
      <path
        fill="#CDD1D4"
        d="M20.363 55.038c3.115 0 5.64-2.103 5.64-4.697s-2.525-12.103-5.64-12.103c2.026 9.846 2.202 14.263-2.586 16.279a6.547 6.547 0 002.586.52z"
      ></path>
      <ellipse
        cx="9.653"
        cy="59.94"
        fill="#E5EAEE"
        rx="5.282"
        ry="3.278"
      ></ellipse>
      <path
        fill="#CDD1D4"
        d="M8.2 59.576v-5.282l1.638-.728v6.01c-.364.546-1.457.546-1.639 0z"
      ></path>
      <path
        fill="#F0F3F6"
        d="M9.106 23.516C6.192 32.682.291 51.089 0 51.38c2.914 5.1 15.48 5.464 18.212 0L9.106 23.516z"
      ></path>
      <path
        fill="#CDD1D4"
        d="M18.208 51.38L9.102 23.516 5.824 54.96c4.725 1.052 10.616-.043 12.384-3.58z"
      ></path>
    </svg>
  );
}

export default Icon;
