import React, { Component } from "react";
import axios from "axios";

//styling
import "./DefPostsStyle.css";
import "../cards/cardStyle.css";

//compnents
import Button from "../buttons/Button";
import ForumPost from "../cards/ForumPost";
import Spinner from "../loading/Spinner";
import Banner from "../header/Banner";

//icons
import NewPost from "../../media/icons/NewPost";

//images
// import NoPosts from "../../media/icons/NewPost";

class UsersPosts extends Component {
  render() {
    let recentPost = this.props.data ? (
      this.props.data.forumPosts.map((forumPost, i) => (
        <ForumPost key={forumPost.forumPostId} post={forumPost} />
      ))
    ) : (
      <div style={{ padding: "8px" }}>
        <Spinner color="#939eab" size="24px" />
      </div>
    );

    return <div className="posts-container">{recentPost}</div>;
  }
}

export default UsersPosts;
