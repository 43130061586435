import React from "react";

const Listing = props => (
  <svg
    className={props.className}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
  >
    <path
      fill={props.colorPrimary}
      d="M24 13l-4-4v3h-9v2h9v3l4-4zm-6 3v5H2v-9H.1L10 2.1l7.9 7.9h-2.8L10 4.9l-6 6V19h12v-3h2z"
    />
  </svg>
);

export default Listing;
