import React, { Component } from "react";
import { HashLink as Link } from "react-router-hash-link";
import PropTypes from "prop-types";
import axios from "axios";

//redux stuff
import { connect } from "react-redux";

import "./pricingStyle.css";
import "./InputFormStyle.css";

//compopnents
import BetaCard from "../components/cards/BetaCard";
import Card from "../components/cards/Card";
import Button from "../components/buttons/Button";
import FormBack from "../components/backgrounds/FormBack";
import MobilePricing from "../components/mobile/MobilePricing";
import AuthForm from "../components/auth/AuthForm";

import PageHeader from "../components/allPages/PageHeader";
import CardHeader from "../components/allPages/CardHeader";
import ContentCard from "../components/cards/ContentCard";
import Spinner from "../components/loading/Spinner";

//IOcons
import Check from "../media/SVGs/Check";
import None from "../media/SVGs/None";
import BetaPeople from "../media/SVGs/BetaPeople";
import AlertIcon from "../media/icons/AlertIcon";
import Information from "../media/icons/Information";
import PricingContact from "../media/SVGs/PricingContact";

//city autocomplete
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng
} from "react-places-autocomplete";

class pricing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      response: false
    };
  }

  handleradioSelect = e => {
    this.setState({
      [e.currentTarget.name]: e.currentTarget.value
    });
  };
  handleSubmit = event => {
    event.preventDefault();
    this.setState({
      loading: true
    });

    const submissionData = {
      lname: this.state.lname,
      fname: this.state.fname,
      email: this.state.email,
      subject: this.state.subject,
      message: this.state.message
    };
    console.log(submissionData);

    axios
      .post("/contactForm/submit", submissionData)
      .then(res => {
        if (res.data.error) {
          console.log(res.data.console);
        }
        this.setState({
          loading: false,
          title: res.data.title,
          response: res.data.response
        });
      })
      .catch(err => {
        this.setState({
          loading: false
        });
        console.log(err);
      });
  };

  render() {
    const {
      user: { authenticated }
    } = this.props;

    let entForm = (
      <div className="outskrts suportMsg">
        <p>
          Please tell us about your operation; how many properties you own,
          average property value, number of employees, do you have a website,
          etc.
        </p>
      </div>
    );
    // {this.state.subject === "Enterprise plan" ? entForm : ""}
    //   let entForm =(
    //     <div className="input-wrapper centered">
    //       <div className="input-text">
    //         Properties
    //         <input
    //           required
    //           type="text"
    //           id="fname"
    //           name="fname"
    //           placeholder="Enter your first name"
    //           onChange={this.handleradioSelect}
    //         />
    //       </div>
    //       <div className="input-text">
    //         Last Name
    //         <input
    //           required
    //           type="text"
    //           id="lname"
    //           name="lname"
    //           placeholder="Enter your Last name"
    //           onChange={this.handleradioSelect}
    //         />
    //       </div>
    //     </div>
    //   )

    let suportMsg = (
      <div className="outskrts suportMsg">
        <AlertIcon colorPrimary="#DFE2E6" size="24px" />
        <p>
          The fastest way to resolve technical issues is through the{" "}
          <span>
            <Link to="/support">support page</Link>
          </span>
          . You can check to see if others have had the same issue or submit a
          support ticket that will be sent to a developer.
        </p>
      </div>
    );
    let inputForm = (
      <div className="pricing-table-wrap-free">
        <h3>Contact form</h3>
        <p>
          Interested in the{" "}
          <span>
            <Link to="/pricing#enterprise">Enterprise Plan</Link>
          </span>
          , have a question, or just want someone to talk to? Don’t hesitate to
          reach out. One of our Flipit team members will get back to you as soon
          as possible.
        </p>
        <div className="pricing-table-wrap-divider"></div>

        <div className="form-wrapper">
          <form onSubmit={this.handleSubmit}>
            <div className="input-wrapper centered">
              <div className="input-text">
                First Name
                <input
                  required
                  type="text"
                  id="fname"
                  name="fname"
                  placeholder="Enter your first name"
                  onChange={this.handleradioSelect}
                />
              </div>
              <div className="input-text">
                Last Name
                <input
                  required
                  type="text"
                  id="lname"
                  name="lname"
                  placeholder="Enter your Last name"
                  onChange={this.handleradioSelect}
                />
              </div>
            </div>
            <div className="input-wrapper centered">
              <div className="input-text">
                Email address
                <input
                  required
                  type="email"
                  pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                  id="emailinput"
                  name="email"
                  placeholder="Enter your email"
                  onChange={this.handleradioSelect}
                />
              </div>
              <div className="input-text">
                Subject
                <select
                  id="cars"
                  name="subject"
                  onChange={this.handleradioSelect}
                >
                  <option value="" disabled selected>
                    Select a subject
                  </option>
                  <option value="Enterprise plan">Enterprise plan</option>
                  <option value="Business inquiry">Business inquiry</option>
                  <option value="Feedback">Feedback</option>
                  <option value="Employment">Employment</option>
                  <option value="Support">Support</option>
                  <option value="Other">Other</option>
                </select>
              </div>
            </div>
            {this.state.subject === "Support" ? suportMsg : ""}
            {this.state.subject === "Enterprise plan" ? entForm : ""}
            <div className="input-wrapper centered">
              <div className="input-text" style={{ gridColumn: "1 / 3" }}>
                Message
                <textarea
                  required
                  maxlength="50"
                  id="contactFormMsg"
                  name="message"
                  placeholder={
                    this.state.subject === "Enterprise plan"
                      ? "Tell us about your operation..."
                      : this.state.subject === "Employment"
                      ? "Tell us a little bit about yourself..."
                      : "Spill the beans..."
                  }
                  onChange={this.handleradioSelect}
                />
              </div>
            </div>
            <div className="centered" style={{ justifyContent: "flex-end" }}>
              <button
                type="submit"
                className="button button-blue"
                style={{ padding: "6px 24px" }}
              >
                {this.state.loading ? (
                  <div className="">
                    <Spinner color="#fff" bordWidth="3px" size="16px" />
                  </div>
                ) : (
                  "Submit form"
                )}
              </button>
            </div>
          </form>
        </div>

        <div
          className="pricing-table-wrap-divider"
          style={{ margin: "48px 0 24px" }}
        ></div>
        <div className="beta-form-legal">
          Data collected in this form is ONLY used to provide context for the
          message and/or inquiry
        </div>
      </div>
    );
    let formResponce = (
      <div className="">
        <div className="pricing-table-wrap-free">
          <a className="page-item-link" id="pricingTableLink" />
          <h3>{this.state.title}</h3>
          <p>{this.state.response}</p>
          <div className="outskrts" style={{ width: "256px", margin: "auto" }}>
            <Link to="/">
              <button className="button button-blue">Return home</button>
            </Link>
            <Link to="/forum">
              <button className="button button-white">Go to forum</button>
            </Link>
          </div>
        </div>
      </div>
    );

    return (
      <div className="pricing-page-wrap form-card-container">
        <a className="page-link-top" id="contact-top" />
        <FormBack />
        <div className="sizing pricing-header">
          <PageHeader title="Contact us" line1="What's on your mind?" />
        </div>
        <section className="sizing">
          <Card className="card-shdow-sml table-card-wrap">
            <div className="pricing-table-wrap">
              {!this.state.response ? inputForm : formResponce}
            </div>
          </Card>
        </section>

        <div className="spacer"></div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user
});

export default connect(mapStateToProps)(pricing);
