import React, { Component } from "react";
// import { Link } from "react-router-dom";

import { HashLink as Link } from "react-router-hash-link";

//Styling
import Card from "../cards/Card";
import Button from "../buttons/Button";

import "./PriceCardStyle.css";
import PriceCard from "./PriceCard";

//consoleIcons
import Dashboard from "../../media/icons/console/Dashboard";

import FlippabilitySearch from "../../media/icons/console/FlippabilitySearch";
import TrendAnalysis from "../../media/icons/console/TrendAnalysis";
import Watchlist from "../../media/icons/console/Watchlist";
import ProjectBuilder from "../../media/icons/console/ProjectBuilder";
import Financing from "../../media/icons/console/Financing";
import Todo from "../../media/icons/console/Todo";
import Budgeting from "../../media/icons/console/Budgeting";
import Contractors from "../../media/icons/console/Contractors";
import Suppliers from "../../media/icons/console/Suppliers";
import Listing from "../../media/icons/console/Listing";
import Financials from "../../media/icons/console/Financials";
import Archive from "../../media/icons/console/Archive";
import Realtors from "../../media/icons/console/Realtors";

import Forum from "../../media/icons/Forum";
import ForumFollow from "../../media/icons/ForumFollow";
import InstantMessaging from "../../media/icons/InstantMessaging";

// const PriceCard = this.props => (
class PricingWidget extends Component {
  // state = {
  //   active: false
  // };

  constructor() {
    super();
    this.state = {
      active: 2,
      isHovered: false
    };
    this.handleHover = this.handleHover.bind(this);
    this.toggleHover = this.toggleHover.bind(this);
    this.card1 = React.createRef();
    this.card2 = React.createRef();
    this.card3 = React.createRef();
  }

  componentDidMount() {}

  render() {
    const hovClass = this.state.isHovered ? "card-shdow-lg" : "";
    return (
      <div className="home-PriceCard-container">
        <div
          id="priceCard-1"
          ref={this.card1}
          onMouseEnter={this.toggleHover}
          onMouseLeave={this.toggleHover}
        >
          <PriceCard
            active={this.state.active === 1 ? true : false}
            className=""
            plan="Community Plan"
            price="FREE"
            text={
              <div className="price-includes-container centered-col">
                <Link to="/forum" className="centered">
                  <Forum size="24px" />
                  Forum Access
                </Link>
                <Link to="/products#ProjectBuilder" className="centered">
                  <ForumFollow colorPrimary="#757575" size="24px" />
                  Follow the pros
                </Link>
                <Link to="/products#ProjectBuilder" className="centered">
                  <InstantMessaging colorPrimary="#757575" size="24px" />
                  Messaging
                </Link>
              </div>
            }
            link="/signup"
            buttontext="join now"
          />
        </div>
        <div
          id="priceCard-2"
          ref={this.card2}
          onMouseEnter={this.toggleHover}
          onMouseLeave={this.toggleHover}
        >
          <PriceCard
            active={this.state.active === 2 ? true : false}
            className=""
            plan="Flipper Plan"
            price="$65"
            text={
              <div className="price-includes-container centered-col">
                <Link to="/products#FlippabilitySearch" className="centered">
                  <FlippabilitySearch size="24px" />
                  Flippability
                </Link>
                <Link to="/products#Todo" className="centered">
                  <Todo colorPrimary="#757575" size="24px" />
                  To-do List
                </Link>
                <Link to="/products#Listing" className="centered">
                  <Listing colorPrimary="#757575" size="24px" />
                  Auto Listing
                </Link>
              </div>
            }
            link="/pricing#pricing-table-link"
            buttontext="Learn more"
          />
        </div>
        <div
          id="priceCard-3"
          ref={this.card3}
          onMouseEnter={this.toggleHover}
          onMouseLeave={this.toggleHover}
        >
          <PriceCard
            active={this.state.active === 3 ? true : false}
            className=""
            plan="Pro-Flipper Plan"
            price="$199"
            text={
              <div className="price-includes-container centered-col">
                <Link to="/products#TrendAnalysis" className="centered">
                  <TrendAnalysis size="24px" />
                  Trend Analysis
                </Link>
                <Link to="/products#Budgeting" className="centered">
                  <Budgeting colorPrimary="#757575" size="24px" />
                  Budgeting
                </Link>
                <Link to="/products#Financials" className="centered">
                  <Financials colorPrimary="#757575" size="24px" />
                  Financial Reports
                </Link>
              </div>
            }
            link="/pricing#pricingTableLink"
            buttontext="Learn more"
          />
        </div>
      </div>
    );
  }

  toggleHover(e) {
    // if (!this.state.isHovered) {
    console.log(e.currentTarget.id.split("-").pop());
    const activeCard = e.currentTarget.id.split("-").pop();
    // }
    this.setState({ active: Number(activeCard) });
    // this.setState({ isHovered: !this.state.isHovered });
  }

  handleHover(e) {
    console.log("hobvering om");
    console.log(e.target);

    // console.log(e.target ? e.target.id : "nptarget");
    // onMouseEnter={if(this.state.active !== 1) {this.setState({ active: 1 }})}
    // onMouseLeave={if(this.state.active !== 2) {this.setState({ active: 2 })}}
    // this.setState(prevState => ({
    //   isHovered: !prevState.isHovered
    // }));
  }
}
export default PricingWidget;
