import React, { Component } from "react";

//Styling
import "./FilterColumnStyle.css";

//icons
import Collapse from "../../../media/icons/ChevronDown";
import MapIcon from "../../../media/icons/MapIcon";
import Close from "../../../media/SVGs/None";

//city autocomplete
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng
} from "react-places-autocomplete";

class ContSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: false,
      open: false,
      selected: "Location..."
    };
    this.betaAdressInput = React.createRef();
    this.node = React.createRef();
  }
  componentDidUpdate() {}
  getSnapshotBeforeUpdate(prevProps, prevState) {
    if (prevState.open !== this.state.open) {
      this.state.open
        ? document.addEventListener("click", this.handleClick)
        : document.removeEventListener("click", this.handleClick);
    }
    return null;
  }
  handleChange = address => {
    this.setState({ address });
  };
  handleSelect = address => {
    geocodeByAddress(address)
      .then(results => {
        this.setState({ address: results[0].formatted_address });
        return getLatLng(results[0]);
      })
      .then(latLng => {
        this.setState({ latLng: [latLng.lat, latLng.lng] });
      })
      .catch(error => console.error("Error", error));
  };

  // <div className="centered">
  //   <MapIcon size="24" />
  //   <div>{this.state.selected}</div>
  // </div>

  render() {
    const { collapsed, selected, open } = this.state;
    const { title } = this.props;

    return (
      <div className="filter-col-cont-blk">
        <div
          className="filter-col-cont-srch-container outskrts"
          style={{ opacity: !collapsed ? 1 : 0 }}
        >
          {this.state.focused ? (
            ""
          ) : (
            <MapIcon
              size="24"
              colorPrimary={!this.state.latLng ? "#949ca6" : "#34a8fe"}
            />
          )}

          <PlacesAutocomplete
            value={this.state.address}
            onChange={this.handleChange}
            onSelect={this.handleSelect}
          >
            {({
              getInputProps,
              suggestions,
              getSuggestionItemProps,
              loading
            }) => (
              <div style={{ width: "100%" }}>
                <input
                  required
                  {...getInputProps({
                    placeholder: "Location...",
                    className: "location-search-input"
                  })}
                  style={{
                    color: !this.state.latLng ? "#28323c" : "#34a8fe"
                  }}
                  onFocus={e => this.setState({ focused: true })}
                  onBlur={e => this.setState({ focused: false })}
                />
                <div
                  className={`autocomplete-dropdown-container form-input-suggestions ${
                    suggestions.length > 1 ? "autocomplet-drop-shadow" : ""
                  }`}
                >
                  {loading && <div>Loading...</div>}
                  {suggestions.map(suggestion => {
                    const className = suggestion.active
                      ? "suggestion-item--active"
                      : "suggestion-item";
                    // inline style for demonstration purpose
                    const style = suggestion.active
                      ? {
                          backgroundColor: "#f4f6f8",
                          cursor: "pointer"
                        }
                      : {
                          backgroundColor: "#ffffff",
                          cursor: "pointer"
                        };
                    return (
                      <div
                        {...getSuggestionItemProps(suggestion, {
                          className,
                          style
                        })}
                      >
                        <span>{suggestion.description}</span>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </PlacesAutocomplete>
          {!this.state.latLng ? (
            ""
          ) : (
            <div
              className="filter-col-loc-close"
              onClick={e => this.setState({ latLng: "", address: "" })}
            >
              <Close size="24" />
            </div>
          )}
        </div>
        <div
          className="filter-col-cont-blk-hd outskrts"
          onClick={e => this.setState({ collapsed: !collapsed })}
        >
          <div>{title}</div>
          <Collapse size="24" />
        </div>

        <div
          className="filter-col-cont-blk-cnt-wrap"
          style={{
            height: !collapsed ? `56px` : "0px"
          }}
        >
          <div className="filter-col-cont-blk-cnt outskrts">
            <div></div>
          </div>
        </div>
      </div>
    );
  }
}

export default ContSection;
