import React, { Component, Fragment } from "react";

// import SacePop from ".SavePop";
// import SharePop from ".SavePop";
// import ReportPop from ".SavePop";
// import DeletePop from ".SavePop";

import MoreDots from "../../../media/icons/MoreDots";

class planCard extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  deletePost(id) {
    this.props.closePop();
    this.props.delFunc(id);
  }

  render() {
    return (
      <div
        className="pop-save-wrap popup-container anim-up"
        onClick={(e) => e.stopPropagation()}
      >
        Report Post
      </div>
    );
  }
}

export default planCard;
