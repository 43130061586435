import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="214"
      height="362"
      fill="none"
      viewBox="0 0 214 362"
    >
      <path stroke="#F5F5F5" strokeWidth="2" d="M1 340H22V361H1z"></path>
      <path stroke="#F5F5F5" strokeWidth="2" d="M107 256H128V277H107z"></path>
      <path stroke="#F5F5F5" strokeWidth="2" d="M65 214H86V235H65z"></path>
      <path stroke="#F5F5F5" strokeWidth="2" d="M65 193H86V214H65z"></path>
      <path stroke="#F5F5F5" strokeWidth="2" d="M22 193H43V214H22z"></path>
      <path stroke="#F5F5F5" strokeWidth="2" d="M65 105H86V126H65z"></path>
      <path stroke="#F5F5F5" strokeWidth="2" d="M107 105H128V126H107z"></path>
      <path stroke="#F5F5F5" strokeWidth="2" d="M44 64H65V85H44z"></path>
      <path stroke="#F5F5F5" strokeWidth="2" d="M23 64H44V85H23z"></path>
      <path stroke="#F5F5F5" strokeWidth="2" d="M2 22H23V43H2z"></path>
      <path stroke="#F5F5F5" strokeWidth="2" d="M23 43H44V64H23z"></path>
      <path stroke="#F5F5F5" strokeWidth="2" d="M2 1H23V22H2z"></path>
      <path stroke="#F5F5F5" strokeWidth="2" d="M192 172H213V193H192z"></path>
      <path stroke="#F5F5F5" strokeWidth="2" d="M65 126H86V147H65z"></path>
      <g filter="url(#filter0_dd)">
        <path fill="#fff" d="M65 126H86V147H65z"></path>
        <path
          stroke="#F0F0F0"
          strokeWidth="0.75"
          d="M65.375 126.375H85.625V146.625H65.375z"
        ></path>
      </g>
      <path stroke="#F5F5F5" strokeWidth="2" d="M86 193H107V214H86z"></path>
      <path stroke="#F5F5F5" strokeWidth="2" d="M86 172H128V193H86z"></path>
      <path stroke="#F5F5F5" strokeWidth="2" d="M128 256H149V277H128z"></path>
      <path stroke="#F5F5F5" strokeWidth="2" d="M128 277H149V298H128z"></path>
      <path stroke="#F5F5F5" strokeWidth="2" d="M149 256H170V277H149z"></path>
      <defs>
        <filter
          id="filter0_dd"
          width="71"
          height="71"
          x="40"
          y="116"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="15"></feOffset>
          <feGaussianBlur stdDeviation="12.5"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow"
          ></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="5"></feOffset>
          <feGaussianBlur stdDeviation="5"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.09 0"></feColorMatrix>
          <feBlend
            in2="effect1_dropShadow"
            result="effect2_dropShadow"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect2_dropShadow"
            result="shape"
          ></feBlend>
        </filter>
      </defs>
    </svg>
  );
}

export default Icon;
