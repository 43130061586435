import React, { Component } from "react";
import { NavLink, withRouter } from "react-router-dom";
import { browserHistory } from "react-router";

import axios from "axios";
import { connect } from "react-redux";

//Styling
import "./ProfileBannerStyle.css";

//compnents
// import ForumBanner from "./banners/ForumBanner";
// import UserBanner from "./banners/UserBanner";
// import ProfileBanner from "./banners/ProfileBanner";
// import PostBanner from "./banners/PostBanner";
import placehoderImg from "../../media/images/placeholder.jpg";
import TabBar from "../tabs/TabBar";

class Banner extends Component {
  constructor(props) {
    super(props);

    this.state = {
      lineWidth: 0,
      linePosition: 0,
      loadingUser: true,
      imageUrl: placehoderImg,
      reputation: 0,
      topics: ["topic 1", "topic 2", "topic 3"],
      username: this.usernameFromURL()
    };
  }
  componentDidMount() {
    this.isThisTheRightPage() && this.retreveUser();
  }

  componentDidUpdate(prevProps) {
    let newPath = this.props.location.pathname;
    let oldPath = prevProps.location.pathname;
    if (newPath.split("/")[2] !== oldPath.split("/")[2]) {
      console.log(newPath.split("/")[2]);
      this.isThisTheRightPage() && this.retreveUser();
    }
  }

  isThisTheRightPage() {
    if (this.props.user.credentials.username === this.usernameFromURL()) {
      return this.props.history.push("/profile");
    }
    return true;
  }
  usernameFromURL() {
    return this.props.location.pathname.split("/")[2];
  }
  retreveUser() {
    this.setState({
      loadingUser: true
    });
    axios
      .get(`/user/${this.usernameFromURL()}`)
      .then(res => {
        this.setState({
          username: res.data.user.username,
          email: res.data.user.email,
          userId: res.data.user.userId,
          imageUrl: res.data.user.imageUrl,
          created: res.data.user.createdAt,
          loadingUser: false
        });
      })
      .catch(err => console.log(err));
  }

  render() {
    const { topics } = this.state;

    const tabPathRoot = `/user/${this.state.username}`;
    const ProfileTabs = {
      Tab: {
        text: "Posts",
        path: `${tabPathRoot}`,
        className: "banner-tab",
        activeClassName: "active-banner-tab"
      },
      CommentsTab: {
        text: "Comments",
        path: `${tabPathRoot}/comments`,
        className: "banner-tab",
        activeClassName: "active-banner-tab"
      },
      FlipsTab: {
        text: "Flips",
        path: `${tabPathRoot}/flips`,
        className: "banner-tab",
        activeClassName: "active-banner-tab"
      },
      AboutTab: {
        text: "About",
        path: `${tabPathRoot}/about`,
        className: "banner-tab",
        activeClassName: "active-banner-tab"
      }
    };

    let intrests = topics ? (
      topics.map(topic => (
        <div
          key={Math.random()}
          className="post-top-type post-card-header-tag centered"
          onClick={this.tagClick}
        >
          {!topic ? "General" : topic}
        </div>
      ))
    ) : (
      <div
        className="post-top-type post-card-header-tag centered"
        onClick={this.tagClick}
      >
        General
      </div>
    );

    return (
      <div className="header-banner-container">
        <div className="sizing">
          <div className="usr-banner-top-container outskrts">
            <div
              className=" mobile-only"
              style={{ margin: "16px 0", width: "100%" }}
            >
              <div
                style={{
                  margin: "0 0 4px",
                  fontSize: ".75rem",
                  color: "rgba(255, 255, 255, 0.5)"
                }}
              >
                Interests
              </div>
              <div className="centered">{intrests}</div>
            </div>
            <div className="usr-ban-profile-info">
              <div className="usr-ban-profile-info-imgwrap">
                <img src={this.state.imageUrl} />
                <div className="usr-ban-profile-info-imgwrap-badge"></div>
              </div>
              <div className="usr-ban-profile-info-contrap">
                <div className="">
                  <h1>{this.state.username}</h1>
                  <div className="">
                    {this.state.reputation}{" "}
                    <span style={{ color: "rgba(255, 255, 255, 0.5)" }}>
                      Reputation
                    </span>
                  </div>
                </div>
                <div
                  className="centered desktop-only"
                  style={{ margin: "0 0 6px" }}
                >
                  {intrests}
                </div>
              </div>
            </div>
            <div className="banner-feature-container centered">
              <div className="banner-feature-wrap">
                <div className="">0</div>
                <div className="">Flips</div>
              </div>
              <div className="banner-feature-div"></div>
              <div className="banner-feature-wrap">
                <div className="">0</div>
                <div className="">Answers</div>
              </div>
              <div className="banner-feature-div"></div>
              <div className="banner-feature-wrap">
                <div className="">0</div>
                <div className="">Posts</div>
              </div>
            </div>
          </div>
          <div className="usr-banner-bottom-container outskrts">
            <div className="">
              <TabBar
                content={ProfileTabs}
                root={`/user/${this.state.username}`}
              />
            </div>
            <div className="usr-banner-bottom-buttons centered">
              <button>
                <svg viewBox="0 0 24 24">
                  <path
                    fill="#fff"
                    d="M15,14C12.33,14 7,15.33 7,18V20H23V18C23,15.33 17.67,14 15,14M6,10V7H4V10H1V12H4V15H6V12H9V10M15,12A4,4 0 0,0 19,8A4,4 0 0,0 15,4A4,4 0 0,0 11,8A4,4 0 0,0 15,12Z"
                  />
                </svg>
                <div className="desktop-only" style={{ marginLeft: "10px" }}>
                  Follow
                </div>
              </button>
              <button>
                <svg viewBox="0 0 24 24">
                  <path
                    fill="#fff"
                    d="M17,12V3A1,1 0 0,0 16,2H3A1,1 0 0,0 2,3V17L6,13H16A1,1 0 0,0 17,12M21,6H19V15H6V17A1,1 0 0,0 7,18H18L22,22V7A1,1 0 0,0 21,6Z"
                  />
                </svg>
              </button>
              <button>
                <svg viewBox="0 0 24 24">
                  <path
                    fill="#fff"
                    d="M9.5,3A6.5,6.5 0 0,1 16,9.5C16,11.11 15.41,12.59 14.44,13.73L14.71,14H15.5L20.5,19L19,20.5L14,15.5V14.71L13.73,14.44C12.59,15.41 11.11,16 9.5,16A6.5,6.5 0 0,1 3,9.5A6.5,6.5 0 0,1 9.5,3M9.5,5C7,5 5,7 5,9.5C5,12 7,14 9.5,14C12,14 14,12 14,9.5C14,7 12,5 9.5,5Z"
                  />
                </svg>
              </button>
              <button>
                <svg viewBox="0 0 24 24">
                  <path
                    fill="#fff"
                    d="M12,16A2,2 0 0,1 14,18A2,2 0 0,1 12,20A2,2 0 0,1 10,18A2,2 0 0,1 12,16M12,10A2,2 0 0,1 14,12A2,2 0 0,1 12,14A2,2 0 0,1 10,12A2,2 0 0,1 12,10M12,4A2,2 0 0,1 14,6A2,2 0 0,1 12,8A2,2 0 0,1 10,6A2,2 0 0,1 12,4Z"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user
});

export default connect(mapStateToProps)(withRouter(Banner));

// export default withRouter(Banner);
