import React, { Component } from "react";

//styling
import "./planCardStyle.css";

//Components
import Button from "../buttons/Button";
import PlanCard from "./planCard";

//redux stuff
import { setPlan } from "../../redux/actions/userActions";

// icons
import BetaUser from "../../media/icons//Beta";
import FreeUser from "../../media/icons/badges/FreeUser";
import Flipper from "../../media/icons/badges/Flipper";
import ProFlipper from "../../media/icons/badges/ProFlipper";
import Enterprise from "../../media/icons/badges/Enterprise";

class PlansWidget extends Component {
  constructor(props) {
    super(props);
    this.state = {
      planState: ""
    };
  }

  render() {
    let planState = !this.state.planState
      ? this.props.activePaln
      : this.state.planState;

    let planObject = {
      mebershipPlan: {
        disabled: false,
        selector: "free",
        name: "Membership",
        price: "FREE",
        features: "Forum access, Messaging, etc.",
        altText: "",
        icon: <FreeUser colorPrimary="#fff" size="24px" />
      },
      flipperPlan: {
        disabled: true,
        selector: "flipper",
        name: "Flipper",
        price: 65,
        features: "Flippability search, To-do List",
        altText: "",
        icon: <Flipper colorPrimary="#fff" size="24px" />
      },
      proPlan: {
        disabled: true,
        selector: "proFlipper",
        name: "Pro-Flipper",
        price: 199,
        features: "Trend analysis, Budgeting",
        altText: "",
        icon: <ProFlipper colorPrimary="#fff" size="24px" />
      },
      mentPlan: {
        disabled: true,
        selector: "enterprise",
        name: "Enterprise",
        price: "APPLY",
        features: "Project Builder, Unlimited",
        altText: "Contact us",
        icon: <Enterprise colorPrimary="#fff" size="24px" />
      }
    };

    let plans = Object.entries(planObject).map((plan, i) => (
      <PlanCard
        key={plan[1].selector}
        active={planState}
        planInfo={plan[1]}
        annBill={this.props.annBill}
        onChange={selectedPlan => this.setState({ planState: selectedPlan })}
      />
    ));

    return <div className="plans-widget-container">{plans}</div>;
  }
}
export default PlansWidget;
