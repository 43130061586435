import React, { Component } from "react";
import { HashLink as Link } from "react-router-hash-link";

import "./BetaCardStyle.css";

//compopnents
import Card from "./Card";
import Button from "../buttons/Button";

//SVGS
import BetaPeople from "../../media/SVGs/BetaPeople";

class BetaCard extends Component {
  render() {
    return (
      <div className="sizing">
        <div className="card-shdow-sml pricing-free-card pricing-beta-card">
          <div className="">
            <h3>Access to everything... free!</h3>
            <p>
              Fipit is developing new, money-making features all the time. Join
              our Beta program to gain access and provide feedback on all our{" "}
              <Link className="link-color" to="/pricing">
                available features
              </Link>
              .{"\n"}
            </p>
            <Link to="/beta#beta-top">
              <Button className="button Join-button button-blue">
                <div className="">Join the beta</div>
              </Button>
            </Link>
          </div>
          <div className="betacard-svg-container">
            <BetaPeople />
          </div>
        </div>
      </div>
    );
  }
}

export default BetaCard;
