import React, { Component } from "react";
import { Link } from "react-router-dom";

// import axios from "axios";
import axios from "axios";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

// //firebase
// import firebase from "../firebase";

//styling
import "./AuthStyle.css";
import "../components/cards/cardStyle.css";

//Components
import Button from "../components/buttons/Button";
import ErrHandler from "../components/errors/ErrHandler";
import Spinner from "../components/loading/Spinner";
import AuthBack from "../components/backgrounds/AuthBack";
// import PlanCard from "../components/plans/planCard";
import PlansWidget from "../components/plans/PlansWidget";
import Switch from "../components/allPages/Switch";

//redux stuff
import { connect } from "react-redux";
import { setPlan } from "../redux/actions/userActions";

class signin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      planType: "free",
      annBill: true
    };
  }

  //page handlers
  componentDidMount() {
    let display = this.props.location.pathname;
    this.setState({
      path: this.pathChoser(display)
    });
  }
  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      let display = this.props.location.pathname;
      this.setState({
        path: this.pathChoser(display),
        errors: ""
      });
    }
    if (this.props.errors !== prevProps.errors) {
      this.setState({ errors: this.props.errors });
    }
  }
  pathChoser(display) {
    if (display.includes("/auth/signin")) return "signin";
    if (display == "/auth/signup/profile") return "profile";
    if (display == "/auth/signup/plans") return "plans";
    return "signup";
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.UI.errors) {
      this.setState({
        errors: nextProps.UI.errors
      });
    }
  }

  //input handlers
  handleChange = event => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  //submission
  handlePlanSubmit = event => {
    event.preventDefault();

    const planData = {
      planType: this.state.email,
      annualBilling: this.state.annBill
    };

    this.props.setPlan(planData, this.props.history);
  };

  render() {
    const {
      UI: { loading },
      user: { loadingUser, credentials }
    } = this.props;
    const { errors } = this.state;
    const defaultPlan = credentials ? credentials.accountType : "free";

    const pricingPlans = (
      <div className="">
        <div className="signin-up-wrap">
          <div className="outskrts" style={{ height: "36px" }}>
            <div className=""> Prising plans </div>
            <Link
              target="_blank"
              rel="noopener noreferrer"
              to="/pricing"
              className="pricing-plans-learn-more"
            >
              View plans
            </Link>
          </div>
        </div>
        <PlansWidget annBill={this.state.annBill} activePaln={defaultPlan} />
      </div>
    );

    return (
      <div className="auth-page-wrapper centered-col">
        <AuthBack />
        <div className="card card-shdow-sml signin-container pricing-pans-continer anim-up">
          {pricingPlans}
          <div className="auth-button-wrap plan-auth-button-wrap outskrts">
            <div
              className="plan-switch-container centered"
              onClick={e => this.setState({ annBill: !this.state.annBill })}
            >
              <Switch active={this.state.annBill} />

              {this.state.annBill ? (
                <p>
                  <b>Billed Annually</b> (12% off)
                </p>
              ) : (
                <p>
                  <b>Billed Monthly</b>
                </p>
              )}
            </div>

            <button
              className={`button  button-submit
              `}
              type="submit"
              name="button"
              onClick={this.handlePlanSubmit}
            >
              {loadingUser ? (
                <div className="">
                  <Spinner color="#fff" bordWidth="3px" size="16px" />
                </div>
              ) : (
                "Finish"
              )}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

signin.propTypes = {
  setPlan: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  UI: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  user: state.user,
  UI: state.UI
});

const mapActionsToProps = {};

export default connect(
  mapStateToProps,
  { setPlan }
)(withRouter(signin));
