import React from "react";

const CheckCircle = props => (
  <svg
    className={props.className}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
  >
    <path
      fill={props.colorPrimary}
      d="M19,4H15.5L14.5,3H9.5L8.5,4H5V6H19M6,19A2,2 0 0,0 8,21H16A2,2 0 0,0 18,19V7H6V19Z"
    />
  </svg>
);

export default CheckCircle;
