import React, { Component } from "react";

//Styling
import "./FilterColumnStyle.css";

//icons
import Filter from "../../../media/icons/Filter";
import Collapse from "../../../media/icons/ChevronDown";
import Checkbox from "../../../media/icons/Square";
import CheckedCheckbox from "../../../media/icons/CheckedCheckbox";

class ContSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: false,
      open: false,
      selected: this.props.sorts[0]
    };
    this.handleClick = this.handleClick.bind(this);
    this.node = React.createRef();
  }
  componentDidUpdate() {}
  getSnapshotBeforeUpdate(prevProps, prevState) {
    if (prevState.open !== this.state.open) {
      this.state.open
        ? document.addEventListener("click", this.handleClick)
        : document.removeEventListener("click", this.handleClick);
    }
    return null;
  }
  handleClick(e) {
    if (!this.node.contains(e.target)) {
      this.setState({ open: false });
    }
  }

  setFilter(topic, postFilter) {
    this.setState({
      selected: topic,
      open: false
    });
    console.log(this.props);
    this.props.filterFunc(topic.postFilter);
  }

  render() {
    const { collapsed, selected, open } = this.state;
    const { sorts, title } = this.props;

    let chekBoxItem = sorts.map(topic => (
      <div
        key={Math.random()}
        className={`filter-col-cont-blk-cnt outskrts ${
          this.state.selected.label === topic.label ? "selected-filter" : ""
        }`}
        onClick={e => this.setFilter(topic)}
      >
        <div className="centered">
          {topic.icon}
          <div>{topic.label}</div>
        </div>
        <div></div>
      </div>
    ));

    return (
      <div className="">
        <div className="filter-col-cont-blk">
          <div
            className="filter-col-cont-blk-hd outskrts"
            onClick={e => {
              this.setState({ collapsed: !collapsed });
            }}
          >
            <div>{title}</div>
            <Collapse size="24" />
          </div>
          <div
            className={`filter-drop-wrap ${
              !open ? "" : "filter-drop-wrap-open"
            }`}
            style={{ height: !open ? 0 : `${sorts.length * 32 + 34}px` }}
            ref={node => (this.node = node)}
          >
            {chekBoxItem}
          </div>
          <div
            className="filter-col-cont-blk-cnt-wrap"
            style={{
              height: !collapsed ? `56px` : "0px"
            }}
          >
            <div
              className="filter-col-cont-blk-cnt outskrts filter-drop-elm"
              onClick={e => this.setState({ open: !open })}
            >
              <div className="centered">
                {selected.icon}
                <div>{selected.label}</div>
              </div>
              <div style={{ marginRight: "-12px" }}>
                <Collapse size="24" />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ContSection;
