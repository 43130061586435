import React from "react";

const ChevronDown = props => (
  <svg
    className={props.className}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
  >
    <path
      fill={props.colorPrimary}
      d="M17 19h-3.155v-5.846H9.174V19H6V5h3.173v5.558h4.672V5H17v14z"
    />
  </svg>
);

export default ChevronDown;
