import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { HashLink as Link } from "react-router-hash-link";
import PropTypes from "prop-types";

//redux stuff
import { connect } from "react-redux";
// import { getUserData } from '../../redux/actions/dataActions';
// import { loginUser, logoutUser } from "../../redux/actions/userActions";

//component
import Logo from "../../media/Logo";
import AuthModal from "../modals/AuthModal";
import SideNav from "./SideNav";
import Spinner from "../loading/Spinner";

import Menu from "../../media/icons/Menu";
import Button from "../buttons/Button";

//images
import Placeholder from "../../media/images/placeholder.jpg";

//Styling
import "./Navbar.css";
const lnkStyle = {
  color: "#34a8fe",
};

class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      SideNavState: false,
      show: false,
      authPosition: 0,
    };
    this.navRef = React.createRef();
    this.authRef = React.createRef();
    this.sideNavRef = React.createRef();

    this.handler = this.handler.bind(this);
    this.showAuthModal = this.showAuthModal.bind(this);
  }

  componentDidMount() {
    this.refCallback();
    window.addEventListener("resize", this.refCallback);
  }

  handler() {
    this.setState({
      show: false,
      SideNavState: false,
    });
  }

  showSideNav = () => {
    this.setState({
      ...this.state,
      SideNavState: !this.state.SideNavState,
    });
  };
  showAuthModal = () => {
    this.setState({
      ...this.state,
      show: !this.state.show,
    });
  };

  render() {
    const {
      user: {
        // credentials: { imageUrl },
        credentials,
        loadingUser,
        authenticated,
      },
    } = this.props;

    const retrivingUser = (
      <div className="profile-spinner-wrap centered-col" ref={this.authRef}>
        <Spinner color="#8F9396" bordWidth="3px" size="16px" />
      </div>
    );

    const profileNavJSX = (
      <div className="centered" ref={this.authRef}>
        <button
          className="profile-nav-button centered-col"
          onClick={this.showAuthModal}
        >
          <img src={credentials.imageUrl} alt="Profile" />
        </button>
      </div>
    );

    const signinNavJSX = (
      <div className="auth-container centered" ref={this.authRef}>
        <div className="nav-spacer mobile-large-only"></div>
        <button className="pho-link" onClick={this.showAuthModal}>
          <p>Sign in</p>
        </button>
      </div>
    );

    let profileStatus = authenticated ? profileNavJSX : signinNavJSX;

    return (
      <div className="nav-wrap outskrts sizing" ref={this.navRef}>
        <SideNav handler={this.handler} show={this.state.SideNavState} />

        <div className="centered nav-wrap-left">
          <button
            ref={this.sideNavRef}
            onClick={this.showSideNav}
            className="button svg-button nav-menu mobile-only"
          >
            <Menu className="" colorPrimary="#1C252E" size="24px" />
          </button>
          <NavLink to="/">
            <Logo
              className="nav-Logo"
              colorPrimary="#28323c"
              colorSecondary="#34A8FE"
              height="22"
            />
          </NavLink>
          <div className="desktop-only ">
            <div className="nav-spacer"></div>
            <NavLink to="/forum" activeStyle={lnkStyle}>
              Forum
            </NavLink>
            <NavLink to="/products" activeStyle={lnkStyle}>
              Products
            </NavLink>
            <NavLink to="/pricing" activeStyle={lnkStyle}>
              Pricing
            </NavLink>
            <NavLink to="/support" activeStyle={lnkStyle}>
              Support
            </NavLink>
          </div>
        </div>

        <div className="centered nav-wrap-right">
          <a
            href={
              profileStatus ? "/auth/signup" : "https://console.us-flipit.com"
            }
            className="mobile-large-only"
          >
            Go to console
          </a>
          {profileStatus}
        </div>

        <AuthModal
          handler={this.handler}
          show={this.state.show}
          placement={this.state.authPosition}
        />
      </div>
    );
  }

  refCallback = () => {
    const placement =
      this.navRef.current.getBoundingClientRect().right -
      0.5 * this.authRef.current.offsetWidth +
      156;
    this.setState({
      authPosition: placement,
    });
  };
}

AuthModal.propTypes = {
  // user: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps)(Navbar);
