import React, { Component } from "react";
import PropTypes from "prop-types";
// import firebase from "../../../../firebase";
import { withRouter } from "react-router-dom";

//redux stuff
import { connect } from "react-redux";
import { addTopics, clearErrors } from "../../../../redux/actions/dataActions";

//components
import TopicInput from "./components/TopicInput";
import Spinner from "../../../loading/Spinner";

//consoleIcons

class Following extends Component {
  state = {
    topics: [],
    title: "",
    post: ""
  };
  setTopics = topics => {
    this.setState({ topics });
  };
  handleTitle = e => {
    this.setState({ title: e.target.value });
  };
  handlePost = e => {
    this.setState({ post: e.target.value });
  };
  handleSubmit = event => {
    event.preventDefault();

    let postData = {
      topics: this.state.topics
    };
    // console.log(this.props.history);
    this.props.addTopics(postData, this.props.history);
  };

  render() {
    const { errors } = this.state;
    const {
      classes,
      UI: { loading }
    } = this.props;

    return (
      <div>
        <div className="content-pop-content">
          <div
            className=""
            style={{
              margin: "24px 0",
              fontSize: ".875rem",
              lineHeight: 1.71429
            }}
          >
            To bulk add topics, enter a topic in the input field then either
            press the <b>Enter</b> key or click the <b>Add</b> button on the
            right. When you are finished, click submit.
          </div>
          <div className="topic-container">
            <div className="topic-lable pop-lable">Topics</div>
            <TopicInput passTopics={this.setTopics} limit={100} />
          </div>
        </div>
        <form
          name="tag-submit-from"
          className="outskrts content-pop-footer"
          onSubmit={this.handleSubmit}
        >
          <div></div>
          <div>
            <button className="button button-blue-outline ">Save Draft</button>
            <button
              type="submit"
              className="button button-blue "
              style={{ marginLeft: "12px" }}
            >
              {loading ? (
                <Spinner color="#fff" bordWidth="3px" size="16px" />
              ) : (
                "Submit"
              )}
            </button>
          </div>
        </form>
      </div>
    );
  }
}

addTopics.propTypes = {
  addTopics: PropTypes.func.isRequired,
  clearErrors: PropTypes.func.isRequired,
  UI: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  UI: state.UI
});

export default connect(
  mapStateToProps,
  { addTopics, clearErrors }
)(withRouter(Following));
