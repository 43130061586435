import React, { Component } from "react";
import { HashLink as Link } from "react-router-hash-link";

//redux stuff
import PropTypes from "prop-types";
import { connect } from "react-redux";

//component

//images
import Close from "../../media/SVGs/None";
import Beta from "../../media/icons/Beta";

//Styling
import "./HeaderALertStyle.css";

class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      betaAlert: true
    };
  }

  componentDidMount() {}

  alertClosed() {
    this.setState({
      betaAlert: false
    });
    sessionStorage.setItem("stopBetaAlert", true);
  }

  render() {
    console.log(
      "should check if there are open sport---------------------------"
    );

    const {
      user: { credentials, loadingUser, authenticated }
    } = this.props;

    let statAlert = this.state.betaAlert;
    let storageAlert = !sessionStorage.getItem("stopBetaAlert");
    let credentialsAlert = credentials && credentials.betaTester ? false : true;
    let shouldAlert = statAlert && storageAlert && credentialsAlert;

    return (
      <div
        className={`navbar-alert-cont ${shouldAlert ? "active-nav-alert" : ""}`}
      >
        <div className="sizing outskrts">
          <Link to="/beta#beta-top" className="centered Beta-link-wrap">
            <Beta colorPrimary="#fff" size="20px" />
            <p style={{ fontWeight: 500 }}>Join the Beta</p>
            <p style={{ fontWeight: 300 }}>: Full access, absolutely free!</p>
          </Link>
          <div
            className="nav-alert-close-button"
            onClick={event => this.alertClosed()}
          >
            <Close />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user
});

export default connect(mapStateToProps)(Navbar);
