import React, { Component } from "react";

//styling
import "./TopicInputStyle.css";

class CharRing extends Component {
  state = {
    ringStyle: {
      stroke: "",
      strokeDasharray: ""
    }
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.charCount !== this.props.charCount) {
      // this.setState({ charCount: nextProps.charCount }s);
      this.styleRing();
    }
  }

  styleRing() {
    const r = this.props.radius;
    const circleLength = 2 * Math.PI * r;
    let colored = (circleLength * this.props.charCount) / this.props.limit;
    let gray = circleLength - colored;

    this.setState(state => ({
      ringStyle: {
        stroke: !this.props.charCount
          ? "#b5c0c5"
          : this.props.limit - this.props.charCount <= 0
          ? "rgb(249, 41, 83)"
          : this.props.limit - this.props.charCount <= 4
          ? "rgb(255, 203, 43)"
          : "#039be5",
        strokeDasharray: `${colored} ${gray}`
      }
    }));
  }

  render() {
    return (
      <div className={`tag-input-chrecterCount `}>
        <svg>
          <circle id="gray" cx="50%" cy="50%" r={this.props.radius}></circle>
          <circle
            id="colored"
            style={this.state.ringStyle}
            cx="50%"
            cy="50%"
            r={this.props.radius}
          ></circle>
        </svg>
      </div>
    );
  }
}

export default CharRing;
