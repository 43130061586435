import React from "react";

function BetaPeople() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="501"
      fill="none"
      viewBox="0 0 501 501"
    >
      <circle cx="165.5" cy="422.5" r="78.5" fill="#F4F6F8"></circle>
      <mask
        id="a"
        width="157"
        height="157"
        x="87"
        y="344"
        maskUnits="userSpaceOnUse"
      >
        <circle cx="165.5" cy="422.5" r="78.5" fill="#C4C4C4"></circle>
      </mask>
      <g mask="url(#a)">
        <g filter="url(#filter0_d)">
          <path
            fill="#ADB7BE"
            fillRule="evenodd"
            d="M199.02 499.046c1.888.62 3.897.954 5.979.954 11.046 0 20-9.402 20-21 0-5.113-1.74-9.799-4.632-13.441 6.325-3.527 10.632-10.515 10.632-18.559 0-7.826-4.077-14.652-10.122-18.264A19.908 19.908 0 00223.999 418c0-7.065-3.663-13.275-9.195-16.833a21.881 21.881 0 001.195-7.167c0-11.598-8.954-21-20-21a19.11 19.11 0 00-6.264 1.051 19.64 19.64 0 00-.255-1.158c-3.739-14.852-23.942-22.57-45.125-17.237-21.183 5.332-35.325 21.696-31.586 36.548 1.603 6.367 6.231 11.423 12.665 14.701-2.171 2.756-3.435 6.052-3.435 9.595 0 4.135 1.721 7.934 4.598 10.929C119.72 431.207 114.999 439 114.999 448c0 9.115 4.841 16.991 11.861 20.713-2.374 2.558-3.861 6.22-3.861 10.287 0 3.114.871 5.989 2.345 8.314-2.413 2.548-5.081 4.937-8.344 7.685 27.095 22.186 48.551 16.851 82.02 4.047z"
            clipRule="evenodd"
          ></path>
        </g>
        <path
          fill="#ADB7BE"
          fillRule="evenodd"
          d="M195.019 459.63c-.41.687-.836 1.358-1.277 2.011a19.891 19.891 0 011.89-1.2 21.26 21.26 0 01-.613-.811zm-7.502 9.163A21.725 21.725 0 00184.999 479c0 11.598 8.955 21 20 21 11.046 0 20-9.402 20-21 0-5.113-1.74-9.799-4.632-13.441 6.325-3.527 10.632-10.515 10.632-18.559 0-7.826-4.077-14.652-10.122-18.264A19.908 19.908 0 00223.999 418c0-7.065-3.663-13.275-9.195-16.833a21.881 21.881 0 001.195-7.167c0-11.598-8.954-21-20-21a19.11 19.11 0 00-6.264 1.051 19.64 19.64 0 00-.255-1.158c-3.739-14.852-23.942-22.57-45.125-17.237-21.183 5.332-35.325 21.696-31.586 36.548 1.603 6.367 6.231 11.423 12.665 14.701-2.171 2.756-3.435 6.052-3.435 9.595 0 4.135 1.721 7.934 4.598 10.929C119.72 431.207 114.999 439 114.999 448c0 9.115 4.841 16.991 11.861 20.713-2.374 2.558-3.861 6.22-3.861 10.287 0 7.732 5.373 14 12 14 6.628 0 12-6.268 12-14 0-3.719-1.243-7.1-3.271-9.607a20.604 20.604 0 005.115-3.194c5.615 6.123 12.811 9.801 20.656 9.801 6.665 0 12.861-2.654 18.018-7.207z"
          clipRule="evenodd"
        ></path>
        <path
          fill="#fff"
          d="M140.5 463.999c-8.925 17.343-13.311 22.418-23.5 31 30.441 24.925 53.764 15.113 95-1 7.5-46.499-61-50.403-71.5-30z"
        ></path>
        <path
          fill="#E3E7EB"
          d="M156.504 481c-10.504-5-1.504-17.5 5-25 .814-.939.996-11.906-1-12-29.277-1.386-17.504-29.5-12-50.5 23.499 21 30.924-.749 36 14.5.999 3-5.577 11.482-3 17.5 1.499 3.5 8.999-12.5 14.5-11.5 8.59 1.561 4.496 14.5-10 18-2.314.559-6.497 8.926-7.004 14-.5 5 .32 12.223 2.504 17-10.004 18-17.312 21.659-25 18z"
        ></path>
        <path
          fill="#ADB7BE"
          fillRule="evenodd"
          d="M200.599 468.864C210.971 467.766 219 460.187 219 451c0-9.941-9.402-18-21-18s-21 8.059-21 18c0 2.008.384 3.939 1.091 5.743-3.109 2.201-5.091 5.53-5.091 9.257 0 6.627 6.268 12 14 12 6.581 0 12.101-3.892 13.599-9.136z"
          clipRule="evenodd"
        ></path>
      </g>
      <circle cx="78.5" cy="250.5" r="78.5" fill="#F4F6F8"></circle>
      <mask
        id="b"
        width="157"
        height="157"
        x="0"
        y="172"
        maskUnits="userSpaceOnUse"
      >
        <circle cx="78.5" cy="250.5" r="78.5" fill="#C4C4C4"></circle>
      </mask>
      <g mask="url(#b)">
        <g filter="url(#filter1_d)">
          <path
            fill="#FCFEFF"
            fillRule="evenodd"
            d="M109.011 238.501c17.487 1.993 26.235-12.061 23.995-11.502-6.006 1.5-14.12-.014-14-.5.189-.766 1.664-1.474 3.565-2.389 3.063-1.472 7.233-3.476 8.929-7.11 1.615-3.461-.692-2.663-3.949-1.536-3.8 1.314-8.892 3.075-10.551-.964-11.5-28-47.472-34.639-68.494-17.001-.723.606-.402-1.355-.087-3.28.28-1.713.555-3.397.087-3.22-4.506 1.711-5.22 3.595-5.5 9-.112 2.164-1.876-.791-3.377-3.309-1.078-1.806-2.02-3.386-2.123-2.691-1.064 7.194.007 10.613 4 15.5-10.166 24.676-2.87 46.205 16.968 57.864 1.814 7.663 3.098 18.637.733 27.576C45.822 296.41 33.24 300.527 24 307.501c18.645 85.538 87.63 76.843 102 5.5-9.293-9.51-24.659-15.563-41.251-17.756-4.965-8.58-5.786-18.472-1.749-17.745 22.013 3.964 22.834-5.605 24.423-24.125.382-4.46.809-9.439 1.588-14.874z"
            clipRule="evenodd"
          ></path>
        </g>
        <path
          fill="#ADB7BE"
          d="M24 307.501c26.5-20.001 80.5-16.501 102 5.5-14.37 71.343-83.355 80.038-102-5.5zM109 238.5l-48.494 29.999c-21.363-11.359-29.506-33.5-19-59-3.993-4.887-5.064-8.306-4-15.5.246-1.665 5.307 9.716 5.5 6 .28-5.405.994-7.289 5.5-9 .994-.377-1.365 7.646 0 6.5C69.528 179.861 105.5 186.5 117 214.5c3.08 7.5 18-4.999 14.5 2.5-2.749 5.89-12 7.5-12.494 9.499-.12.486 7.994 2 14 .5 2.24-.559-6.512 13.501-24.006 11.501z"
        ></path>
        <path
          fill="#FCFEFF"
          d="M89 301c18.5 19.5-46 19-33.489 1.999 10.368-14.089 1.687-43.679-.5-45-24.01-14.5-4.01-37 3-6 1.482 6.555 1.06-21.678 1.989-23.999 3-7.499 27 12.5 49.011 10.499C105 266.5 110.315 282.419 83 277.5c-5-.9-2.548 14.49 6 23.5z"
        ></path>
      </g>
      <circle cx="337.5" cy="78.5" r="78.5" fill="#F4F6F8"></circle>
      <mask
        id="c"
        width="157"
        height="157"
        x="259"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <circle cx="337.5" cy="78.5" r="78.5" fill="#ADB7BE"></circle>
      </mask>
      <g mask="url(#c)">
        <g filter="url(#filter2_d)">
          <path
            fill="#ADB7BE"
            fillRule="evenodd"
            d="M365.002 64.997c1.676-2.661 3.209-3.58 5.998-4.997 15.841-52.475-58.013-62.308-63.846-25.13l-.152.13c-7.98 67.304 2.145 67.707 16.413 68.275 2.587.103 5.311.211 8.087.725 1.739.322 1.253 4.918-.167 10.186-3.064 1.254-6.05 3.063-8.914 5.383-15.357 4.444-29.014 12.347-37.919 19.431 4.998 14 68.027 69.561 107-3-10.417-11.335-22.428-17.086-34.713-19.107a34.157 34.157 0 00-2.575-1.5c-1.377-5.071 4.542-33.757 9.286-35.393 29-10 15.5-31.5 1.502-15v-.003z"
            clipRule="evenodd"
          ></path>
        </g>
        <path
          fill="#FCFEFF"
          d="M284.502 139c22-17.5 72.998-40 107-3-38.973 72.562-102.002 17-107 3z"
        ></path>
        <mask
          id="d"
          width="108"
          height="57"
          x="284"
          y="116"
          maskUnits="userSpaceOnUse"
        >
          <path
            fill="#FCFEFF"
            d="M284.502 139c22-17.5 72.998-40 107-3-38.973 72.562-102.002 17-107 3z"
          ></path>
        </mask>
        <g mask="url(#d)">
          <path
            fill="#ADB7BE"
            d="M303 147.999c16.702-41.197 51.5-52.499 75.5-4 8.5 24.501-75.5 24.501-75.5 4z"
          ></path>
        </g>
        <path
          fill="#FCFEFF"
          d="M325.502 128.5c3.992-5.912 9.498-23.853 6-24.5-18.088-3.346-33.927 10.508-24.5-69l10-8.5 48.5 8.5-.5 30C379 48.5 392.5 70 363.5 80c-5 1.724-11.305 33.5-8.998 36 19.498 21.125-31.56 38.345-29 12.5z"
        ></path>
        <path
          fill="#ADB7BE"
          d="M356 70.5c-.931 3 5.069.747 9-5.5 1.676-2.663 3.209-3.582 6-5 16-53-59.5-62.5-64-24 1.714-1.695 5.554-4.998 8.5-1.5 8 9.5 9.644 6.279 23.5 2.5 22-6 21.5 19 17 33.5z"
        ></path>
      </g>
      <circle cx="250.5" cy="250.5" r="78.5" fill="#D0EEFD"></circle>
      <mask
        id="e"
        width="157"
        height="157"
        x="172"
        y="172"
        maskUnits="userSpaceOnUse"
      >
        <circle cx="250.5" cy="250.5" r="78.5" fill="#C4C4C4"></circle>
      </mask>
      <g mask="url(#e)">
        <g filter="url(#filter3_d)">
          <path
            fill="#ADB7BE"
            fillRule="evenodd"
            d="M257.055 270.734c.26-1.323.281-2.125.008-2.125-31.118 0-31.145-9.867-31.241-44.567a1359.88 1359.88 0 00-.061-11.11c-.412-39.275 71.8-44.245 57.817 11.433 12.049-5.468 15.588 8.947-2.246 16.901-2.313 1.032-5.819 16.528-5.667 27.614 19.848 1.153 37.133 12.65 37.335 35.032-5.191 4.255-10.014 8.524-14.6 12.584-16.876 14.936-30.559 27.048-47.645 25.293l-17.77 15.316c-2.773-9.067-4.31-17.338-4.853-24.832-3.502-2.459-7.174-5.432-11.048-8.973-1.535-29.181 18.085-46.938 39.971-52.566z"
            clipRule="evenodd"
          ></path>
        </g>
        <path
          fill="#9EDAF7"
          d="M215.5 323.503C212 259 314.498 250 315 304.003c-36.464 28.982-55.414 58.568-99.5 19.5z"
        ></path>
        <path
          fill="#B5E6FC"
          d="M265.501 329.5c64.999-76-66-75-33.507 28.002l33.507-28.002z"
        ></path>
        <path
          fill="#88CCEF"
          d="M213.194 261.739l-18-6.5c-.447-.165-.798-.283-1-.223L192 257l16.5 45.5 16.194-11.761-9-27c-.501-1-1.001-1.5-2.5-2z"
        ></path>
        <path
          fill="#B5E6FC"
          d="M211.001 263.5l-18-6.5c-.985-.363-1.501-.5-1 1l15 45 15.5-10.5-9-27c-.501-1-1.001-1.5-2.5-2z"
        ></path>
        <path
          fill="#88CCEF"
          d="M227.492 308.5c-.426 2.776 7.268 14.688 12.996 22.5-9.979 4.57-15.06 2.434-24-2.5-.582-5.402-.487-15.823-4.488-17.5-15.499-6.497-17.001-29-14.504-31.5 3.259-3.262 0 14.5 15.492 14.5 11.504 0 7.507-16.523 9.004-16.5 3.045.047 5.003.5 4 14.5-.168 2.342 2.879 7.5 1.5 16.5zM277.176 276c9.324 30.5-35.705 28.5-26.176 10.5 4.104-7.753 6.982-18.002 5.972-18.002-35.493-.001-31.996-11.998-32.472-55.998-.428-39.5 74.483-44.498 59.977 11.5 12.5-5.5 16.171 8.997-2.329 16.997-3 1.298-7.931 25.321-4.972 35.003z"
        ></path>
      </g>
      <circle cx="300" cy="197" r="21" fill="#34A8FE"></circle>
      <path
        fill="#fff"
        d="M297.231 202.59v5.53h-2.35v-16.4c0-1.45.43-2.59 1.28-3.44.84-.85 2.01-1.28 3.45-1.28 1.39 0 2.46.34 3.26 1 .79.68 1.18 1.62 1.18 2.81 0 .82-.26 1.59-.78 2.3-.52.71-1.19 1.2-2.02 1.47v.04c1.25.2 2.22.65 2.88 1.38.66.71.99 1.62.99 2.74 0 1.32-.46 2.4-1.37 3.23-.92.83-2.12 1.24-3.62 1.24-1.06 0-2.03-.21-2.9-.62zm1.49-6.84v-1.92c.87-.11 1.58-.43 2.15-.97.56-.55.84-1.16.84-1.86 0-1.38-.71-2.08-2.11-2.08-.76 0-1.35.24-1.76.73-.41.49-.61 1.17-.61 2.06v8.79c.91.53 1.8.79 2.66.79.84 0 1.5-.22 1.97-.65.47-.44.7-1.06.7-1.85 0-1.79-1.28-2.79-3.84-3.04z"
      ></path>
      <circle cx="422.5" cy="250.961" r="78.5" fill="#D0EEFD"></circle>
      <mask
        id="f"
        width="157"
        height="158"
        x="344"
        y="172"
        maskUnits="userSpaceOnUse"
      >
        <circle cx="422.5" cy="250.961" r="78.5" fill="#C4C4C4"></circle>
      </mask>
      <g mask="url(#f)">
        <g filter="url(#filter4_d)">
          <path
            fill="#fff"
            fillRule="evenodd"
            d="M387.926 228.331c-3.442-6.202-3.469-13.206-2.208-24.37 1.314-11.63 10.111-15.974 17.807-19.774 6.728-3.322 12.614-6.229 11.92-13.226-.396-3.993 11.698 5.258 6.939 13 16.791.062 20.892 6.489 25.842 14.245 1.904 2.984 3.934 6.164 6.86 9.255 1.571 1.659 5.457 1.67 7.886 1.677 1.399.004 2.315.007 2.024.323-2.136 2.321-4.243 3.168-6.842 4.214-1.226.493-2.561 1.03-4.059 1.786-1.584.799 2.377.713 6.317.627 3.458-.076 6.901-.151 6.565.373-5.847 9.137-14.208 10.52-22.403 8.812.301 4.421-.13 10.045-.879 18.188l-.006.017c-1.157 14.513-2.898 22.435-10.056 25.499 7.495 3.762 10.348 9.336 3.12 16.484 22.081 7.102 24.278 21 23.783 38.5-35.616 28.099-54.189 14.441-80.065-4.586v-.001c-5.019-3.691-10.313-7.583-16.059-11.413-4.895-14.939-2.776-21.779 10.405-31-2.594-8.225 8.872-12.536 22.952-13.43a311.414 311.414 0 00-.395-3.268c-2.764-4.922-2.824-10.828-2.864-15.859-16.428-8.396-13.282-17.346-6.584-16.073zm54.795 17.812c.34-.852.653-1.716.926-2.551l-.926 2.551z"
            clipRule="evenodd"
          ></path>
        </g>
        <path
          fill="#88CCEF"
          d="M374.5 276.961c-13.301 9.221-15.439 16.061-10.5 31 35.693 23.572 54.088 49.55 97 16 .5-17.5-1.718-31.398-24-38.5 25-24.5-69.5-30.499-62.5-8.5z"
        ></path>
        <path
          fill="#9EDAF7"
          d="M437.498 334.462l-30.998-5.5c-12.199-15.072-16.075-30.638-15.047-42.417l34.566.974c4.673 10.685 8.78 25.981 11.479 46.943z"
        ></path>
        <path
          fill="#B5E6FC"
          d="M470.931 280.984l-13.041 14.532-6.995-.256-9.099 16.177 8.646 9.823c11.597-16.085 16.965-12.386 20.348-22.769 1.58-4.848 4.212-19.359.141-17.507z"
        ></path>
        <path
          fill="#88CCEF"
          d="M454.452 266.448c-2.761 7.07-8.286 21.311-8.301 21.71l7.132 10.19 14.853-9.384 10.614-30.21-2.943-1.608-18.247 6.414c-1.517.444-2.367.991-3.108 2.888z"
        ></path>
        <path
          fill="#9EDAF7"
          d="M456.899 267.962c-2.761 7.07-8.286 21.31-8.301 21.71.262 6.514 13.162 9.489 19.542-.785l10.002-27.152c.554-1.48 1.127-3.461-.39-3.016l-17.745 6.354c-1.517.445-2.367.992-3.108 2.889z"
        ></path>
        <path
          fill="#B5E6FC"
          d="M444.083 290.007c.877 6.036-4.291 7.848-2.839 22.911l16.646-17.402c-12.993-.476-7.5-13.784-9.886-16.874-1.783-2.307-5.091 3.316-3.921 11.365z"
        ></path>
        <path
          fill="#9EDAF7"
          d="M454.049 318.39c-5.093-4.888-8.525-5.786-13.716-8.007l-13.334 22.526 22.558-1.174 4.492-13.345z"
        ></path>
      </g>
      <path
        fill="#B5E6FC"
        d="M443.506 218.461c2.092 5.495 1.699 12.075.5 25l-5.5 15-18.006 6.5c-1 14.5-2.006 17.5 5.5 22 1.5.9-8 24.5-12.494 24.5-2.506 0-14.121-13.177-22-24-.617-.847 1.494-5.898 2.5-6 7.894-.795 2.779-19.785 1.498-36.5-24.004-11.5-10.003-24.5.002-11v-17l12-13 36 14.5z"
      ></path>
      <path
        fill="#88CCEF"
        d="M417.501 212.461c-3.501 3.5-.501 8.344-2 8-7.13-1.637-13.699-10.604-16.001-6-8.5 17 4 45.908 18.5 40 8.907-3.628 14-5.5 19-1.5 2.218 1.775 5.345-4.436 7.001-9.5-1.508 18.765-3.999 26.517-17.999 27-43.5 1.5-27.003-29.5-33.501-36-8.08-8.082-8.702-15.572-7-30.5 2.485-21.799 31.499-18 29.998-33-.4-3.993 11.804 5.258 7.002 13 23.461.086 22.371 12.376 33 23.5 2.499 2.616 10.803 1.135 10 2-3.172 3.415-6.282 3.641-11.001 6-3 1.5 13.724-.121 13 1-15.501 24.001-48.5-5.499-49.999-4z"
      ></path>
      <path
        fill="#88CCEF"
        fillRule="evenodd"
        d="M429.715 235.961c-1.132 4.871-5.5 8.5-10.715 8.5-6.075 0-11-4.924-11-11 0-6.075 4.925-11 11-11 5.908 0 10.727 4.657 10.989 10.5h3.023c.261-5.29 4.633-9.5 9.988-9.5 5.523 0 10 4.478 10 10 0 5.523-4.477 10-10 10-4.66 0-8.575-3.187-9.685-7.5h-3.6zm-10.715 5.5a8 8 0 100-16 8 8 0 000 16zm24-1a7 7 0 100-14 7 7 0 000 14z"
        clipRule="evenodd"
      ></path>
      <circle cx="473" cy="197" r="21" fill="#34A8FE"></circle>
      <path
        fill="#fff"
        d="M470.231 202.59v5.53h-2.35v-16.4c0-1.45.43-2.59 1.28-3.44.84-.85 2.01-1.28 3.45-1.28 1.39 0 2.46.34 3.26 1 .79.68 1.18 1.62 1.18 2.81 0 .82-.26 1.59-.78 2.3-.52.71-1.19 1.2-2.02 1.47v.04c1.25.2 2.22.65 2.88 1.38.66.71.99 1.62.99 2.74 0 1.32-.46 2.4-1.37 3.23-.92.83-2.12 1.24-3.62 1.24-1.06 0-2.03-.21-2.9-.62zm1.49-6.84v-1.92c.87-.11 1.58-.43 2.15-.97.56-.55.84-1.16.84-1.86 0-1.38-.71-2.08-2.11-2.08-.76 0-1.35.24-1.76.73-.41.49-.61 1.17-.61 2.06v8.79c.91.53 1.8.79 2.66.79.84 0 1.5-.22 1.97-.65.47-.44.7-1.06.7-1.85 0-1.79-1.28-2.79-3.84-3.04z"
      ></path>
      <circle cx="165.5" cy="78.5" r="78.5" fill="#D0EEFD"></circle>
      <mask
        id="g"
        width="157"
        height="157"
        x="87"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <circle cx="165.5" cy="78.5" r="78.5" fill="#D0EEFD"></circle>
      </mask>
      <g mask="url(#g)">
        <g filter="url(#filter5_d)">
          <path
            fill="#FCFEFF"
            fillRule="evenodd"
            d="M129.074 19.687c-11.589-37.13-49.756 19.083-11.342 18.826.269 2.412 1.107 4.409 2.469 5.488-2.873 18.747 3.512 40.558 16.603 46.447.432 6.087.324 10.291-.19 13.835-7.165 1.612-12.874 6.333-14.18 15.325-10.922 1.792-17.28 7.196-17.28 14.393 28.485 36.552 51.021 32.143 89.535 24.606l.04-.008 3.06-.598c-.25-1.025-.5-2.023-.75-2.996l3.213-11.004c-3.248-2.939-5.8-4.342-8.526-5.477-4.551-10.691-10.114-15.752-18.945-18.35-2.33-5.896-8.629-10.863-16.026-13.772-.241-3.176.192-5.674 1.613-5.404 20.073 3.819 24.175-2.446 27.367-25.522 4.33-2.31 7.128-5.9 8.628-13.474 12.811 1.5 31.043-45.999-44.839-47.5-6.316-.125-11.555 1.568-15.791 4.53-1.694-.004-3.245.23-4.659.655z"
            clipRule="evenodd"
          ></path>
        </g>
        <path
          fill="#88CCEF"
          d="M104.5 134c0-8.5 9-14.5 24-15H168c16.909 3.013 23.618 11.227 30.5 39-41.019 7.88-64.328 13.523-94-24z"
        ></path>
        <path
          fill="#9EDAF7"
          d="M121.981 119.999c4-30.499 58.5-12.999 51.5 9-7.16 22.501-54.974 17.501-51.5-9z"
        ></path>
        <path
          fill="#B5E6FC"
          d="M216.5 106L204 121h-7l-8.5 16.5 9 9.5c11-16.5 16.5-13 19.5-23.5 1.401-4.902 3.5-19.5-.5-17.5z"
        ></path>
        <path
          fill="#88CCEF"
          d="M199.5 92.078c-2.5 7.166-7.5 21.599-7.5 21.999l7.5 9.922 14.5-9.922 9.5-30.578-3-1.499-18 7.078c-1.5.5-2.329 1.077-3 3z"
        ></path>
        <path
          fill="#9EDAF7"
          d="M202 93.5c-2.5 7.167-7.5 21.6-7.5 22 .5 6.5 13.5 9 19.5-1.5l9-27.5c.5-1.5 1-3.5-.5-3l-17.5 7c-1.5.5-2.329 1.078-3 3z"
        ></path>
        <path
          fill="#B5E6FC"
          d="M190 116c1.098 6-4 8-1.998 23L204 121c-13.002 0-8-13.5-10.498-16.5-1.866-2.241-4.965 3.5-3.502 11.5z"
        ></path>
        <path
          fill="#9EDAF7"
          d="M201 144c-5.269-4.698-8.732-5.471-14-7.5l-12.5 23 22.5-2 4-13.5z"
        ></path>
      </g>
      <path
        fill="#88CCEF"
        d="M149.524 14.501c-39.501-.77-37.501 67.499-11.5 76.5l38-12c10.527-2.89 16.517-4.642 19-17 13 1.5 31.5-45.999-45.5-47.5z"
      ></path>
      <path
        fill="#88CCEF"
        d="M118.022 38.5c-40.522 1-.522-57 11-18l-11 18z"
      ></path>
      <path
        fill="#9EDAF7"
        d="M119.769 44c1.601-10.296 6.035-19.667 13.731-24.97C117.5 19 114 39.5 119.769 44z"
      ></path>
      <path
        fill="#B5E6FC"
        d="M135.498 79.498c2.531 20.363 1.354 25.732-1.5 34 5.999 8.999 24 10.499 24.5.5-1.963-5.275-2.555-13.48 0-13.001 23.999 4.5 25.499-5 29.5-39.499C180 60 171.995 38.501 148 42.5c-12 2-.498 35.5-6.5 26-15.409-24.39-22.506.498-6.002 10.998z"
      ></path>
      <circle cx="218" cy="27" r="21" fill="#34A8FE"></circle>
      <path
        fill="#fff"
        d="M215.231 32.59v5.53h-2.35v-16.4c0-1.45.43-2.59 1.28-3.44.84-.85 2.01-1.28 3.45-1.28 1.39 0 2.46.34 3.26 1 .79.68 1.18 1.62 1.18 2.81 0 .82-.26 1.59-.78 2.3-.52.71-1.19 1.2-2.02 1.47v.04c1.25.2 2.22.65 2.88 1.38.66.71.99 1.62.99 2.74 0 1.32-.46 2.4-1.37 3.23-.92.83-2.12 1.24-3.62 1.24-1.06 0-2.03-.21-2.9-.62zm1.49-6.84v-1.92c.87-.11 1.58-.43 2.15-.97.56-.55.84-1.16.84-1.86 0-1.38-.71-2.08-2.11-2.08-.76 0-1.35.24-1.76.73-.41.49-.61 1.17-.61 2.06v8.79c.91.53 1.8.79 2.66.79.84 0 1.5-.22 1.97-.65.47-.44.7-1.06.7-1.85 0-1.79-1.28-2.79-3.84-3.04z"
      ></path>
      <circle cx="337.5" cy="422.5" r="78.5" fill="#F4F6F8"></circle>
      <mask
        id="h"
        width="157"
        height="157"
        x="259"
        y="344"
        maskUnits="userSpaceOnUse"
      >
        <circle cx="337.5" cy="422.5" r="78.5" fill="#C4C4C4"></circle>
      </mask>
      <g mask="url(#h)">
        <g filter="url(#filter6_d)">
          <path
            fill="#E3E7EB"
            fillRule="evenodd"
            d="M342.289 446.591c2.788.236 5.845.367 9.2.408 14.417.175 17.66-8.058 19.495-30.443l.014-.056c1.322-16.716.909-27.33-.5-47l-.01-.007C368.493 341 303 344.002 308.001 379.5c.614 5.424 1.129 10.439 1.602 15.073-14.006-8.616-15.926 11.099 2.479 21.429 1.312 8.63 3.036 15.062 6.157 19.761 1.594 6.913 2.229 12.498 1.034 16.896-21.326 2.326-39.526 11.868-42.773 21.841 1.223 1.177 2.426 2.34 3.614 3.486l.002.003c34.419 33.243 54.798 52.926 107.384 6.011-9.924-18.224-26.23-27.332-43.347-30.52-.605-1.887-1.144-4.026-1.752-6.444l-.112-.445zm28.183-77.11l-.069-.071c-1.019-1.061-2.207-2.298-3.4-2.434l3.469 2.505z"
            clipRule="evenodd"
          ></path>
        </g>
        <path
          fill="#ADB7BE"
          d="M276.5 474.5c7-21.5 83.5-41 111 9.5-54.401 48.535-74.334 25.794-111-9.5z"
        ></path>
        <path
          fill="#fff"
          d="M370.998 416.5c1.322-16.716.909-27.33-.5-47l-9-6.5h-43.5c-4.876 12.65-6.206 19.768-6.998 32.5-15.499-11-18 10.5 2 21 6.866 24.359 11.867 38.467-1.5 45-3.5 1.711 19.5 32 26 32 3.998 0 17.144-29.117 14.998-30-7.498-3.084-8.16-9.162-11-20l25-8.5 4.5-18.5z"
        ></path>
        <path
          fill="#ADB7BE"
          d="M351.489 446.999c-40.987-.499-37.485-14.498-43.488-67.499C303 344 368.502 341 370.489 369.499c-1.467-1.526-3.301-3.501-5-2-5.502 4.86-8.486 5.498-15.993 6.499-8.154 1.087-14.5-7.365-20.507-7.499-22.493-.501-15.493 70.143 5.507 61.499 8.5-3.499 17.398-7.157 28.493-2.499 2.507 1.053 5.708-3.855 8-9-1.834 22.428-5.071 30.676-19.5 30.5z"
        ></path>
      </g>
      <defs>
        <filter
          id="filter0_d"
          width="318.83"
          height="356.181"
          x="12.17"
          y="257.967"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="4"></feOffset>
          <feGaussianBlur stdDeviation="50"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0.67451 0 0 0 0 0.717647 0 0 0 0 0.741176 0 0 0 0.5 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow"
            result="shape"
          ></feBlend>
        </filter>
        <filter
          id="filter1_d"
          width="309.351"
          height="381.344"
          x="-76"
          y="91.744"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="4"></feOffset>
          <feGaussianBlur stdDeviation="50"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0.67451 0 0 0 0 0.717647 0 0 0 0 0.741176 0 0 0 0.5 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow"
            result="shape"
          ></feBlend>
        </filter>
        <filter
          id="filter2_d"
          width="307"
          height="359.988"
          x="184.502"
          y="-83.459"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="4"></feOffset>
          <feGaussianBlur stdDeviation="50"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0.67451 0 0 0 0 0.717647 0 0 0 0 0.741176 0 0 0 0.5 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow"
            result="shape"
          ></feBlend>
        </filter>
        <filter
          id="filter3_d"
          width="296"
          height="374.105"
          x="117"
          y="87"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="4"></feOffset>
          <feGaussianBlur stdDeviation="50"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.5 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow"
            result="shape"
          ></feBlend>
        </filter>
        <filter
          id="filter4_d"
          width="305"
          height="369.973"
          x="262"
          y="74"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="4"></feOffset>
          <feGaussianBlur stdDeviation="50"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow"
            result="shape"
          ></feBlend>
        </filter>
        <filter
          id="filter5_d"
          width="305"
          height="356.772"
          x="1"
          y="-89"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="4"></feOffset>
          <feGaussianBlur stdDeviation="50"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.5 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow"
            result="shape"
          ></feBlend>
        </filter>
        <filter
          id="filter6_d"
          width="311"
          height="361.549"
          x="176.5"
          y="254.252"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="4"></feOffset>
          <feGaussianBlur stdDeviation="50"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0.67451 0 0 0 0 0.717647 0 0 0 0 0.741176 0 0 0 0.5 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow"
            result="shape"
          ></feBlend>
        </filter>
      </defs>
    </svg>
  );
}

export default BetaPeople;
