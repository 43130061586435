import React from "react";

//Styling
import "./buttonStyle.css";

const Button = props => (
  <button onClick={props.onClick} className={`button ${props.className}`}>
    {props.children}
  </button>
);

export default Button;
