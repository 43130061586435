import React, { Component } from "react";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

import Button from "../buttons/Button";

//Styling
import "./CardHeaderStyle.css";

class CardHeader extends Component {
  render() {
    return (
      <div className="pricing-table-wrap-free">
        <h3>Join for free!</h3>
        <p>
          Flipit is a community of like-minded real estate investors, builders,
          and flippers. Join for free and get full access to the{" "}
          <Link to="/forum">Forum and Private Messaging</Link>!
        </p>
        <HashLink to="/auth/signup">
          <Button className="button Join-button button-blue">
            Join the Community
          </Button>
        </HashLink>
      </div>
    );
  }
}

export default CardHeader;
