import React, { Component } from "react";
import { NavLink, withRouter } from "react-router-dom";
// import { browserHistory } from "react-router";

//Styling
import "./TabBarStyle.css";

class Banner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lineWidth: 0,
      linePosition: 0,
    };

    let keyarray = Object.keys(this.props.content);
    keyarray.forEach((thing) => {
      this[`${thing}_ref`] = React.createRef();
    });

    this.tabContainer = React.createRef();
    this.activeState = this.activeState.bind(this);
  }

  componentDidMount() {
    this.activeState();
  }
  componentDidUpdate(prevProps) {
    const currentPath = this.props.location.pathname;
    if (currentPath !== prevProps.location.pathname) {
      this.activeState(currentPath);
    }
  }

  activeState() {
    let location = this.props.location.pathname.replace(this.props.root, "");
    let revLocation = location.replace(/\//g, "");
    let componentRef = `${
      revLocation.charAt(0).toUpperCase() + revLocation.slice(1)
    }Tab_ref`;

    if (this[componentRef]) {
      let container = this.tabContainer.current.getBoundingClientRect().left;
      const element = this[componentRef].current.getBoundingClientRect();

      this.setState({
        lineWidth: element.width - 32,
        linePosition: element.left - container,
      });
    }
  }

  render() {
    let tabArray = this.props.content;
    return (
      <div className="banner-tabs-left centered" ref={this.tabContainer}>
        <div className="banner-tabs-wrap centered">
          {Object.keys(tabArray).map((tab) => (
            <NavLink
              key={tab}
              exact
              ref={this[`${tab}_ref`]}
              to={tabArray[tab].path}
              className={tabArray[tab].className}
              activeClassName={tabArray[tab].activeClassName}
            >
              {tabArray[tab].text}
            </NavLink>
          ))}
          <div
            style={{
              width: this.state.lineWidth,
              left: this.state.linePosition,
            }}
            className="tab-seleciton-lin"
          ></div>
        </div>
      </div>
    );
  }
}
export default withRouter(Banner);
