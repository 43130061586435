import React from "react";

import "./BubbleBackStyle.css";

const BubbleBakc = props => (
  <div className="Back-container">
    <div className="sizing Back-wrap">
      <div className="back-blob-4" aria-hidden="true">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="3463.97px"
          height="3947.35px"
          viewBox="0 0 3463.97 3947.35"
          preserveAspectRatio="xMinYMin"
        >
          <defs>
            <style fill="none" clipRule="evenodd" />
            <clipPath id="landing-header-nacho_svg__clipPath">
              <path
                className="landing-header-nacho_svg__cls-1"
                d="M-3593.5-2560.43c286.06-63.44 1878.61 2088.8 1793.43 2324.85s-2266.29 720.51-2442.7 542.47c-26-26.3-36.38-105.87-34.34-222.62 11.69-674.45 439.65-2590.53 683.61-2644.7z"
              ></path>
            </clipPath>
            <clipPath id="landing-header-nacho_svg__clipPath-2">
              <path fill="none" d="M-5206.6-3363.17h4882v3709h-4882z"></path>
            </clipPath>
            <clipPath id="landing-header-nacho_svg__clipPath-3">
              <path
                className="landing-header-nacho_svg__cls-1"
                d="M33.7 3869.38C259.33 4130.56 3298.85 3677.6 3437.6 3362c20.45-46.59-5.48-153.88-65.65-302.79C3024.47 2198.87 1530.45-50.73 1190.5 0 791.92 59.49-192 3608.24 33.7 3869.38z"
              ></path>
            </clipPath>
          </defs>
          <g
            clipPath="url(#landing-header-nacho_svg__clipPath)"
            id="landing-header-nacho_svg__header-nacho"
          >
            <g clipPath="url(#landing-header-nacho_svg__clipPath-2)">
              <path d="M-6731.56-24594.94l-221.11 27884.15L4247.1 3719.53l221.12-27884.15-11199.78-430.32z"></path>
            </g>
          </g>
          <g
            clipPath="url(#landing-header-nacho_svg__clipPath-3)"
            id="landing-header-nacho_svg__landing-header-nacho"
          >
            <path
              transform="rotate(-22 1175.988 2165.688)"
              id="landing-header-background"
              fill="#F4F6F8"
              d="M-530.36 155.86h3412.69v4019.63H-530.36z"
            ></path>
          </g>
        </svg>
      </div>
      <div className="back-blob-1" aria-hidden="true">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="3463.97px"
          height="3947.35px"
          viewBox="0 0 3463.97 3947.35"
          preserveAspectRatio="xMinYMin"
        >
          <defs>
            <style fill="none" clipRule="evenodd" />
            <clipPath id="landing-header-nacho_svg__clipPath">
              <path
                className="landing-header-nacho_svg__cls-1"
                d="M-3593.5-2560.43c286.06-63.44 1878.61 2088.8 1793.43 2324.85s-2266.29 720.51-2442.7 542.47c-26-26.3-36.38-105.87-34.34-222.62 11.69-674.45 439.65-2590.53 683.61-2644.7z"
              ></path>
            </clipPath>
            <clipPath id="landing-header-nacho_svg__clipPath-2">
              <path fill="none" d="M-5206.6-3363.17h4882v3709h-4882z"></path>
            </clipPath>
            <clipPath id="landing-header-nacho_svg__clipPath-3">
              <path
                className="landing-header-nacho_svg__cls-1"
                d="M33.7 3869.38C259.33 4130.56 3298.85 3677.6 3437.6 3362c20.45-46.59-5.48-153.88-65.65-302.79C3024.47 2198.87 1530.45-50.73 1190.5 0 791.92 59.49-192 3608.24 33.7 3869.38z"
              ></path>
            </clipPath>
          </defs>
          <g
            clipPath="url(#landing-header-nacho_svg__clipPath)"
            id="landing-header-nacho_svg__header-nacho"
          >
            <g clipPath="url(#landing-header-nacho_svg__clipPath-2)">
              <path d="M-6731.56-24594.94l-221.11 27884.15L4247.1 3719.53l221.12-27884.15-11199.78-430.32z"></path>
            </g>
          </g>
          <g
            clipPath="url(#landing-header-nacho_svg__clipPath-3)"
            id="landing-header-nacho_svg__landing-header-nacho"
          >
            <path
              transform="rotate(-22 1175.988 2165.688)"
              id="landing-header-background"
              fill="currentColor"
              d="M-530.36 155.86h3412.69v4019.63H-530.36z"
            ></path>
          </g>
        </svg>
      </div>
    </div>
  </div>
);

export default BubbleBakc;
