import React, { Component } from "react";

import "./BetaCardStyle.css";

//compopnents

class ContentCard extends Component {
  render() {
    return (
      <div className="sizing">
        <div className="card-shdow-sml pricing-free-card pricing-beta-card">
          <div className="">
            <h3>{this.props.title}</h3>
            <p>{this.props.text}</p>
            {this.props.button}
          </div>

          <div className="betacard-svg-container">{this.props.media}</div>
        </div>
      </div>
    );
  }
}

export default ContentCard;
