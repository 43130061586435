import React from "react";

import "./SplashPopStyle.css";
import SplashImage from "../../media/images/FlipitPopGraphic.png";

function SplashPop() {
  return (
    <div className="splash-pop-container">
      <div className="splash-pop-wrap anim-up-splash hover-splash">
        <div className="splash-pop-header outskrts">
          <div className="">Flippability</div>
          <div className="splash-pop-dots centered">
            <div className="splash-pop-dots-filled"></div>
            <div className="splash-pop-dots-filled"></div>
            <div className="splash-pop-dots-filled"></div>
            <div></div>
            <div></div>
          </div>
        </div>
        <div className="">
          <img src={SplashImage} alt="Flipped" />
        </div>

        <div>
          <div className="outskrts">
            <div>Purchase Price</div>
            <div>$ 197,000</div>
          </div>
          <div className="outskrts">
            <div>After Repair</div>
            <div>$ 290,000</div>
          </div>
          <div className="slash-pop-line"></div>
          <div className="outskrts">
            <b>Est. Profit</b>
            <b>+ $ 39,700</b>
          </div>
        </div>
      </div>
      <div className="splash-pop-triangle anim-up-splash hover-splash"></div>
      <div className="splash-pop-shadow anim-grow-splash "></div>
    </div>
  );
}

export default SplashPop;
