import React from "react";

import "./BubbleBackStyle.css";

const AuthBack = props => (
  <div className="Back-container">
    <div className="back-blob-auth" aria-hidden="true">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="3463.97px"
        height="3947.35px"
        viewBox="0 0 3463.97 3947.35"
        preserveAspectRatio="xMinYMin"
      >
        <defs>
          <style fill="none" clipRule="evenodd" />
          <clipPath id="landing-header-nacho_svg__clipPath">
            <path
              className="landing-header-nacho_svg__cls-1"
              d="M-3593.5-2560.43c286.06-63.44 1878.61 2088.8 1793.43 2324.85s-2266.29 720.51-2442.7 542.47c-26-26.3-36.38-105.87-34.34-222.62 11.69-674.45 439.65-2590.53 683.61-2644.7z"
            ></path>
          </clipPath>
          <clipPath id="landing-header-nacho_svg__clipPath-2">
            <path fill="none" d="M-5206.6-3363.17h4882v3709h-4882z"></path>
          </clipPath>
          <clipPath id="landing-header-nacho_svg__clipPath-3">
            <path
              className="landing-header-nacho_svg__cls-1"
              d="M33.7 3869.38C259.33 4130.56 3298.85 3677.6 3437.6 3362c20.45-46.59-5.48-153.88-65.65-302.79C3024.47 2198.87 1530.45-50.73 1190.5 0 791.92 59.49-192 3608.24 33.7 3869.38z"
            ></path>
          </clipPath>
        </defs>
        <g
          clipPath="url(#landing-header-nacho_svg__clipPath)"
          id="landing-header-nacho_svg__header-nacho"
        >
          <g clipPath="url(#landing-header-nacho_svg__clipPath-2)">
            <path d="M-6731.56-24594.94l-221.11 27884.15L4247.1 3719.53l221.12-27884.15-11199.78-430.32z"></path>
          </g>
        </g>
        <g
          clipPath="url(#landing-header-nacho_svg__clipPath-3)"
          id="landing-header-nacho_svg__landing-header-nacho"
        >
          <path
            transform="rotate(-22 1175.988 2165.688)"
            id="landing-header-background"
            fill="currentColor"
            d="M-530.36 155.86h3412.69v4019.63H-530.36z"
          ></path>
        </g>
      </svg>
    </div>
  </div>
);

export default AuthBack;

//
// <div class="back-blob-2 back-blob-forum" aria-hidden="true">
//   <svg
//     xmlns="http://www.w3.org/2000/svg"
//     width="3000px"
//     viewBox="0 0 3738.3 5685.14"
//   >
//     <defs>
//       <clipPath id="background-default_svg__clip-path">
//         <path
//           class="background-default_svg__cls-1"
//           d="M459.17 1763.55c-32.09 216 1486.42 1536.08 1700.6 1478.3 31.61-8.55 73.25-64.3 120.75-153.63C2555 2572.21 3023 933.79 2853.12 786.15 2653.93 613.1 491.2 1547.52 459.17 1763.55z"
//         />
//       </clipPath>
//       <clipPath id="background-default_svg__clip-path-2">
//         <path
//           class="background-default_svg__cls-1"
//           d="M1121.29 2464.67C896.42 2617.89 1530 5163.46 1760.41 5264.55S3706 4197.94 3681.25 3956.73c-3.68-35.6-57.16-96.45-146.28-173.92-514.83-447.64-2221.85-1448.76-2413.68-1318.14z"
//         />
//       </clipPath>
//       <style fill="none" clip-rule="evenodd" />
//     </defs>
//     <g id="background-default_svg__Layer_2" data-name="Layer 2">
//       <g id="background-default_svg__background-products-2">
//         <g clip-path="url(#background-default_svg__clip-path)">
//           <path
//             transform="rotate(-62 1783.107 1645.85)"
//             fill="#F4F6F8"
//             d="M515.29 524.51h2535.63v2242.68H515.29z"
//           />
//         </g>
//         <g clip-path="url(#background-default_svg__clip-path-2)">
//           <path
//             transform="rotate(-49 1869.15 3848.696)"
//             fill="#E3E7EB"
//             d="M721.55 2369.64h2295.2v2958.11H721.55z"
//           />
//         </g>
//       </g>
//     </g>
//   </svg>
// </div>
//
