import React, { Component } from "react";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";
import { withRouter } from "react-router-dom";

import { HashLink as Link } from "react-router-hash-link";

//axios
// import axios from "axios";
//redux stuff
import { connect } from "react-redux";
// import PropTypes from "prop-types";

//Styling
import "./UserBannerStyle.css";

//compnents
import Dropdown from "../../dropdowns/Dropdown";
import TabBar from "../../tabs/TabBar";
import SearchBar from "../../search/SearchBar";
import Spinner from "../../loading/Spinner";
import ProfileBadge from "../../allPages/ProfileBadge";

//icon
// import Search from "../../../media/icons/Search";
// import Beta from "../../../media/icons/Beta";
import Filter from "../../../media/icons/Filter";
import ChevronDown from "../../../media/icons/ChevronDown";

class Banner extends Component {
  constructor(props) {
    super(props);

    this.state = {
      lineWidth: 0,
      linePosition: 0
    };
  }

  componentDidMount() {
    //if unauthenticated rerout to auth/signin
    !this.props.user.authenticated && this.props.history.push("/auth/signin");
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      console.log("location changed");
      // this.isThisTheRightPage() && this.retreveUser();
    }
  }

  render() {
    TimeAgo.addLocale(en);

    // const isProfile = this.props.profile;
    const {
      user: {
        credentials: { username, imageUrl, accountType, createdAt, betaTester },
        loadingUser
      }
    } = this.props;

    const timeAgo = new TimeAgo("en-US");
    const tabPathRoot = "/profile";
    const UserProfileTables = {
      Tab: {
        text: "Posts",
        path: `${tabPathRoot}`,
        className: "banner-tab",
        activeClassName: "active-banner-tab"
      },
      CommentsTab: {
        text: "Comments",
        path: `${tabPathRoot}/comments`,
        className: "banner-tab",
        activeClassName: "active-banner-tab"
      },
      FlipsTab: {
        text: "Flips",
        path: `${tabPathRoot}/flips`,
        className: "banner-tab",
        activeClassName: "active-banner-tab"
      },

      TicketsTab: {
        text: "Tickets",
        path: `${tabPathRoot}/tickets`,
        className: "banner-tab",
        activeClassName: "active-banner-tab"
      },
      SettingsTab: {
        text: "Settings",
        path: `${tabPathRoot}/settings`,
        className: "banner-tab",
        activeClassName: "active-banner-tab"
      }
    };

    let userAccntType = (
      <p
        style={{
          color: "#BDBDBD",
          opacity: "1",
          fontSize: "0.875rem"
        }}
      >
        Beta User
        <span
          style={{
            color: "#34a8fe",
            fontSize: "0.75rem",
            marginLeft: "4px"
          }}
        >
          - Upgrade
        </span>
      </p>
    );

    return (
      <div>
        <div className="banner-header-user outskrts">
          <div className="centered">
            <div className="banner-profile-pic-container">
              <div className="banner-profile-pic-wrap centered-col">
                {this.state.loadingUser ? (
                  <Spinner color="#939eab" size="24px" />
                ) : (
                  <img src={imageUrl} alt="Profile Pic" />
                )}
              </div>
              <ProfileBadge
                accountType={accountType}
                beta={betaTester}
                loading={loadingUser}
                outline="#323a42"
                classAdg="prof-badg-cont-adj"
              />
            </div>
            <div className="banner-user-info">
              <h2>{this.state.loadingUser ? "... " : `@${username}`}</h2>
              <p style={{ fontSize: "0.75rem" }}>
                {this.state.loadingUser
                  ? "..."
                  : `member since ${timeAgo.format(new Date(createdAt))}`}
              </p>

              {this.state.loadingUser ? "..." : userAccntType}
            </div>
          </div>
          <div className="banner-numbs-container centered">
            <div className="banner-numbs-wrap">
              <div className="">0</div>
              <div className="">Flips</div>
            </div>
            <div className="banner-numbs-div"></div>
            <div className="banner-numbs-wrap">
              <div className="">0</div>
              <div className="">Answers</div>
            </div>
            <div className="banner-numbs-div"></div>
            <div className="banner-numbs-wrap">
              <div className="">0</div>
              <div className="">Posts</div>
            </div>
          </div>
        </div>
        <div className="banner-tabs outskrts sizing">
          <div className="banner-profile-button-wrap">
            <Link to="forum/messages">
              <button className="button button-blue">Message</button>
            </Link>
            <button className="button button-blue-outline">Follow</button>
          </div>
          <button className="banner-profile-button-wrap-noessential button button-silver-text">
            Report
          </button>
        </div>
        <div className="banner-tabs outskrts sizing">
          <div style={{ height: "100%" }}>
            <TabBar content={UserProfileTables} root={`/profile`} />
          </div>
          <div className="banner-tabs-rit centered">
            <Dropdown
              className="mobile-only"
              list=""
              primary="Trending"
              placeholder=""
            >
              <div className="centered">
                Category
                <ChevronDown colorPrimary="#fff" size="24px" />
              </div>
            </Dropdown>
            <Dropdown list="" primary="Trending" placeholder="">
              <Filter className="mobile-only" colorPrimary="#fff" size="24px" />
            </Dropdown>
            <div className="banenr-searchbar-container">
              <SearchBar />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user
});

export default connect(mapStateToProps)(withRouter(Banner));
