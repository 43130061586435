import React from "react";

//Styling
import "./buttonStyle.css";

const Button = props => (
  <button className={`button ${props.className}`}>
    <a className="button-link-wrap" href={props.link}>
      {props.children}
    </a>
  </button>
);

export default Button;
