import React, { Component } from "react";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";
import { Link } from "react-router-dom";

//styling
import "./cardStyle.css";

//icons
import MoreDots from "../../media/icons/MoreDots";
import ArrowUp from "../../media/icons/ArrowUp";
import ArrowDown from "../../media/icons/ArrowDown";
import Comment from "../../media/icons/Comment";
import Button from "../buttons/Button";
import Check from "../../media/SVGs/Check";

// let adjustment = window.innerWidth < 1200 ? 250 : 5;

class ForumPost extends Component {
  render() {
    const {
      post: {
        forumPostId,
        // title,
        body,
        username,
        createdAt,
        commentCount,
        likeCount,
        userImage,
        titleImage,
        bestAnswer
      }
    } = this.props;

    TimeAgo.addLocale(en);
    const timeAgo = new TimeAgo("en-US");
    // const blogImage = titleImage ? (
    //   <div className="post-img-wrap">
    //     <img src={titleImage} alt="" />
    //   </div>
    // ) : (
    //   ""
    // );
    const title = "This is the Post title";
    const bestBadge = !bestAnswer ? (
      ""
    ) : (
      <div className="forum-best-answer-badge centered">
        <Check colorPrimary="#fff" size="18px" />
        <div>Best Answer</div>
      </div>
    );

    return (
      <Link
        to={`/forum/post/${forumPostId}`}
        className="card card-shdow-sml post-grid anim-cardload forum-content-card"
      >
        <div className="c-cat outskrts">
          <p>category</p>
          <div className="outskrts">
            {bestBadge}
            <Button className="svg-button">
              <MoreDots className="" colorPrimary="#D2D6D9" size="24px" />
            </Button>
          </div>
        </div>
        <div className="c-tit centered">
          <h3>{truncate(title, 50)}</h3>
        </div>
        <div
          className="c-cont"
          style={{
            marginTop: "8px",
            marginBottom: "12px"
          }}
        >
          <div className=""></div>
          <p
            style={{
              marginLeft: "11px",
              paddingLeft: "12px",
              borderLeft: "1px solid #eceff1"
            }}
          >
            {truncate(body, 100)}
          </p>
        </div>
        <div className="c-user flex-lft">
          <div className="centered">
            <Link to={`/user/${username}`} className="centered">
              <img
                src={userImage}
                className="prof-pic-24"
                alt="profilePicture"
              />
              <p style={{ color: "#34a8fe" }}>@{username}</p>
            </Link>
            <p>{timeAgo.format(new Date(createdAt), "twitter")}</p>
            <p>•</p>
            <div className="centered">
              <p>{commentCount}</p>
              <p className="desktop-only">comments</p>
              <Comment
                className="mobile-only"
                colorPrimary="#B1B4C7"
                size="18px"
              />
            </div>
          </div>
        </div>
        <div className="c-vote">
          <Button className="svg-button">
            <ArrowUp className="" colorPrimary="#D2D6D9" size="24px" />
          </Button>
          <p>{likeCount}</p>
          <Button className="svg-button">
            <ArrowDown className="" colorPrimary="#D2D6D9" size="24px" />
          </Button>
        </div>
      </Link>
    );

    function truncate(text, length) {
      if (text) {
        if (text.length > length) return text.substring(0, length) + "...";
        else return text;
      }
    }
  }
}

export default ForumPost;
