import React from "react";

function SupportTicket() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 397 346">
      <g clipPath="url(#clip0)">
        <circle cx="258.5" cy="259.5" r="115.5" fill="#F6FCFE"></circle>
        <path fill="#fff" d="M31 292H397V346H31z"></path>
      </g>
      <g clipPath="url(#clip1)">
        <path
          fill="#B5E6FC"
          d="M191.501 269.5c11.11-7.811 14.5-2.5 25.5 11l6.998 8.5-5.999 2c-11.52 1.001-16.365.076-24-2.5-10.742-4.171-14.376-7.468-8.754-13.605l5.755-1.895c-.155.462 14 6.5 14 5 0-2.5-1.821-4.148-5-7-.906-.813-6.2 1.5-7 1.5l-2.37-2.344c.282-.216.572-.434.87-.656z"
        ></path>
        <path
          fill="#88CCEF"
          d="M227.5 201.5c-22.731 9.794-29.6 24.017-45 43-5.94 12.789-2.38 15.131 1.499 23.5l39-34.5 50.5-27c11.5 89.5 11.5 61-57 74 1.848 4.078 2.781 6.37 1 10.5 28.415 1.46 45.189 3.312 70.5 1 23 2.5 21.5-91.543-5.5-98-23-5.5-31.355-1.272-54.999 7.5z"
        ></path>
        <path
          fill="#B5E6FC"
          d="M205.877 158.777c-.321 4.419 1.591 9.049 5.684 18.047l8.002 9.505 15.839.557c4.317 10.173 6.137 14.747 5.113 16.754-.624 1.222-1.743 5.515 2.001 5.499 3.745-.015 17.204-3.951 21.366-12.105 1.025-2.007-.882-4.034-3.489-4.354-6.594-.808-7.012-13.557-10.062-25.839 16.211-13.722 1.962-19.855-2.676-7.89l-4.134-12.194-12.664-6.589-24.98 18.609z"
        ></path>
        <path
          fill="#88CCEF"
          d="M224.859 148.313c3.62 1.752 2.452 5.955 3.548 5.365 5.211-2.808 8.172-10.819 11.121-7.992 10.885 10.437 8.169 34.295-4.706 33.287-7.908-.62-12.38-.826-15.331 3.217-1.31 1.794-5.303-2.015-7.856-5.322 5.815 13.305 9.687 18.379 20.831 15.556 34.625-8.773 13.991-27.579 17.505-33.78 4.369-7.71 3.012-13.299-2.008-23.769-7.333-15.291-29.243-5.949-31.76-17.199-.669-2.995-7.998 6.5-2.308 11.042-18.454 5.383-14.565 14.074-20.192 24.575-1.323 2.469-8.227 3.275-7.382 3.722 3.34 1.764 5.844 1.223 10.143 1.869 2.732.411-10.838 3.024-9.991 3.675 18.124 13.941 36.836-14.997 38.386-14.246z"
        ></path>
        <path
          fill="#9EDAF7"
          d="M269.002 200c-8.502 17-36.499 18.5-32.999 6.5.216-.741-1.731-.559-2.499-.5-27.094 2.088-1.775 53.238 9.498 90l76 2.5c-8.191-42.649-19.455-85.315-24.502-88.5l-20.998-3c.501-8-4.072-8-4.5-7z"
        ></path>
        <path
          fill="#88CCEF"
          d="M288.5 292c23 2.5 21.5-91.543-5.5-98l-9 12.5c11.5 89.5 11.5 61-57 74 1.848 4.078 2.781 6.37 1 10.5 28.415 1.46 45.189 3.311 70.5 1z"
        ></path>
        <path
          fill="#F4F6F8"
          d="M106.432 238c-3.179.012-2.501 2.5-2 4l18.5 53.5h78l-17.5-52.5c-.5-1.5-1-5-4.5-5h-72.5z"
        ></path>
        <path
          fill="#E3E7EB"
          d="M103.002 238c-3.178.012-2.501 2.5-2 4l18.5 53.5h78l-17.5-52.5c-.5-1.5-1-5-4.5-5h-72.5z"
        ></path>
      </g>
      <path
        stroke="#88CCEF"
        strokeLinecap="round"
        strokeWidth="2"
        d="M1 292h9.516M365 292H23.68m-5.075 0h-4.44"
      ></path>
      <path
        fill="#B5E6FC"
        fillRule="evenodd"
        d="M203.523 46c5.523 0 10 4.477 10 10v30.153c0 5.523-4.477 10-10 10h-14.089v8.731l-7.068-8.731H95.049c-5.523 0-10-4.477-10-10V56c0-5.523 4.477-10 10-10h108.474z"
        clipRule="evenodd"
      ></path>
      <rect
        width="99.619"
        height="3.435"
        fill="#34A8FE"
        rx="1.718"
        transform="matrix(-1 0 0 1 199.098 60.427)"
      ></rect>
      <rect
        width="99.619"
        height="3.435"
        fill="#34A8FE"
        rx="1.718"
        transform="matrix(-1 0 0 1 199.098 69.359)"
      ></rect>
      <rect
        width="41.909"
        height="3.435"
        fill="#34A8FE"
        rx="1.718"
        transform="matrix(-1 0 0 1 199.098 78.29)"
      ></rect>
      <path
        fill="#E3E7EB"
        fillRule="evenodd"
        d="M129.473 121.835c5.523 0 10 4.478 10 10v30.153c0 5.523-4.477 10-10 10H37.608l-7.428 9.177v-9.177h-9.181c-5.523 0-10-4.477-10-10v-30.153c0-5.522 4.477-10 10-10h108.474z"
        clipRule="evenodd"
      ></path>
      <rect
        width="99.619"
        height="3.435"
        fill="#fff"
        rx="1.718"
        transform="matrix(-1 0 0 1 125.047 136.263)"
      ></rect>
      <rect
        width="99.619"
        height="3.435"
        fill="#fff"
        rx="1.718"
        transform="matrix(-1 0 0 1 125.047 145.194)"
      ></rect>
      <rect
        width="41.909"
        height="3.435"
        fill="#fff"
        rx="1.718"
        transform="matrix(-1 0 0 1 125.047 154.125)"
      ></rect>
      <defs>
        <clipPath id="clip0">
          <path
            fill="#fff"
            d="M0 0H366V346H0z"
            transform="matrix(-1 0 0 1 397 0)"
          ></path>
        </clipPath>
        <clipPath id="clip1">
          <path
            fill="#fff"
            d="M0 0H223V193H0z"
            transform="matrix(-1 0 0 1 319 99)"
          ></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default SupportTicket;
