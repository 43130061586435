import React, { Component } from "react";
import { Link } from "react-router-dom";

//styling
import "./MobilePricingStyle.css";

//components
import MobileDropdown from "./MobileDropdown";

class MobilePricing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false
    };
  }

  render() {
    let planName = Object.keys(this.props.content);
    let { color, tagLine, price, features } = this.props.content[planName];

    let item = Object.keys(features).map((feature, i) => (
      <MobileDropdown
        key={`mobile-pricing-${feature}`}
        color={color}
        icon={features[feature].icon ? features[feature].icon : ""}
        itemName={feature.replace(/([A-Z])/g, " $1").trim()}
        section={features[feature].section ? features[feature].section : ""}
        description={
          features[feature].description ? features[feature].description : ""
        }
        planLimit={
          features[feature].planLimit ? features[feature].planLimit : ""
        }
      />
    ));

    return (
      <div className="pricing-mobile-section">
        <div
          className="pricing-mobile-section-head"
          style={{ background: color }}
        >
          <div>{planName[0].replace(/([A-Z])/g, " $1").trim()} Plan</div>
          <div>{tagLine}</div>
          <div>
            {price ? `$${price}/m` : <Link to="/support">Contact us</Link>}
          </div>
        </div>
        <div className="pricing-mobile-section-body">{item}</div>
        <div
          className="pricing-mobile-section-foot"
          style={{ background: color }}
        >
          <button className="button button-white">Start now</button>
        </div>
      </div>
    );
  }
}

export default MobilePricing;
