import React from "react";

import { convertFromRaw } from "draft-js";
import renderHTML from "react-render-html";
import { stateToHTML } from "draft-js-export-html";

//import spinner
import Spinner from "../../loading/Spinner";

const Child = (props) => {
  let body = props.bodyStyled;
  if (!body) return <Spinner size="24px" />;

  let jsx = renderHTML(
    stateToHTML(convertFromRaw(JSON.parse(props.bodyStyled)))
  );
  console.log(jsx);
  console.log(JSON.parse(props.bodyStyled));
  console.log(convertFromRaw(JSON.parse(props.bodyStyled)));
  return jsx;
};

export default Child;
