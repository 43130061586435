import React from "react";

const AppStore = props => (
  <svg
    className={props.className}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 108.58 32"
    height={props.size}
  >
    <rect fill="none" />
    <path
      fill={props.colorPrimary}
      d="M24.25,15.82c-0.02-2.58,2.12-3.83,2.22-3.89c-1.22-1.76-3.1-2-3.76-2.02c-1.58-0.17-3.12,0.94-3.93,0.94
          c-0.82,0-2.06-0.93-3.4-0.9c-1.72,0.03-3.33,1.02-4.22,2.56c-1.82,3.14-0.46,7.75,1.28,10.29c0.87,1.24,1.9,2.63,3.23,2.58
          c1.31-0.05,1.8-0.83,3.38-0.83c1.56,0,2.02,0.83,3.39,0.8c1.4-0.02,2.29-1.25,3.13-2.5c1.01-1.42,1.42-2.83,1.43-2.9
          C26.97,19.94,24.28,18.91,24.25,15.82z M21.68,8.24c0.7-0.87,1.18-2.06,1.05-3.27c-1.02,0.04-2.29,0.7-3.03,1.56
          c-0.65,0.75-1.23,1.99-1.08,3.15C19.77,9.76,20.94,9.1,21.68,8.24z"
    />
    <path
      fill={props.colorPrimary}
      d="M43.2,25.2h-1.83l-1-3.13h-3.48l-0.95,3.13h-1.78l3.45-10.65h2.13L43.2,25.2z M40.07,20.76l-0.91-2.78
          c-0.1-0.28-0.28-0.95-0.54-2.01h-0.03c-0.11,0.45-0.28,1.12-0.51,2.01l-0.89,2.78H40.07z M52.05,21.27c0,1.31-0.36,2.34-1.07,3.1
          c-0.64,0.67-1.43,1.01-2.38,1.01c-1.02,0-1.76-0.36-2.21-1.09v4.03h-1.72v-8.27c0-0.82-0.02-1.66-0.06-2.52h1.51l0.1,1.22h0.03
          c0.57-0.92,1.44-1.38,2.61-1.38c0.91,0,1.67,0.36,2.28,1.07C51.75,19.16,52.05,20.1,52.05,21.27z M50.3,21.33
          c0-0.75-0.17-1.36-0.51-1.85c-0.37-0.5-0.87-0.76-1.49-0.76c-0.42,0-0.81,0.14-1.15,0.42c-0.34,0.28-0.57,0.65-0.68,1.1
          c-0.05,0.17-0.07,0.34-0.08,0.52v1.28c0,0.56,0.17,1.03,0.52,1.41c0.34,0.38,0.79,0.58,1.34,0.58c0.65,0,1.15-0.25,1.51-0.74
          C50.12,22.8,50.3,22.14,50.3,21.33z M60.94,21.27c0,1.31-0.36,2.34-1.07,3.1c-0.64,0.67-1.43,1.01-2.38,1.01
          c-1.02,0-1.76-0.36-2.21-1.09v4.03h-1.72v-8.27c0-0.82-0.02-1.66-0.06-2.52h1.51l0.1,1.22h0.03c0.57-0.92,1.44-1.38,2.61-1.38
          c0.91,0,1.67,0.36,2.28,1.07C60.63,19.16,60.94,20.1,60.94,21.27z M59.19,21.33c0-0.75-0.17-1.36-0.51-1.85
          c-0.37-0.5-0.87-0.76-1.49-0.76c-0.42,0-0.81,0.14-1.15,0.42c-0.34,0.28-0.57,0.65-0.67,1.1c-0.05,0.21-0.08,0.38-0.08,0.52v1.28
          c0,0.56,0.17,1.03,0.52,1.41c0.34,0.38,0.79,0.58,1.34,0.58c0.65,0,1.15-0.25,1.51-0.74C59.01,22.8,59.19,22.14,59.19,21.33z
           M70.87,22.22c0,0.91-0.32,1.64-0.95,2.21c-0.7,0.62-1.67,0.93-2.92,0.93c-1.15,0-2.08-0.22-2.77-0.66l0.4-1.42
          c0.75,0.44,1.58,0.66,2.48,0.66c0.65,0,1.15-0.15,1.51-0.43c0.36-0.29,0.54-0.68,0.54-1.16c0-0.43-0.15-0.8-0.45-1.09
          c-0.3-0.29-0.79-0.57-1.48-0.82c-1.88-0.7-2.81-1.71-2.81-3.05c0-0.87,0.33-1.59,0.99-2.15c0.66-0.56,1.54-0.84,2.63-0.84
          c0.97,0,1.79,0.17,2.43,0.51l-0.43,1.39c-0.61-0.33-1.29-0.49-2.06-0.49c-0.61,0-1.08,0.15-1.42,0.44
          c-0.29,0.26-0.43,0.58-0.43,0.97c0,0.42,0.17,0.77,0.49,1.04c0.28,0.25,0.8,0.53,1.56,0.82c0.92,0.37,1.6,0.8,2.03,1.29
          C70.65,20.86,70.87,21.48,70.87,22.22z M76.56,18.8h-1.89v3.73c0,0.95,0.33,1.42,1,1.42c0.31,0,0.56-0.03,0.76-0.08l0.05,1.3
          c-0.34,0.13-0.78,0.19-1.33,0.19c-0.68,0-1.21-0.21-1.59-0.62c-0.38-0.41-0.57-1.1-0.57-2.07V18.8h-1.13v-1.28h1.13v-1.41
          l1.69-0.51v1.91h1.89V18.8z M85.08,21.3c0,1.18-0.34,2.15-1.02,2.91c-0.71,0.78-1.65,1.17-2.83,1.17c-1.13,0-2.04-0.37-2.71-1.12
          c-0.67-0.75-1.01-1.69-1.01-2.83c0-1.19,0.35-2.16,1.04-2.92c0.69-0.76,1.63-1.14,2.81-1.14c1.13,0,2.04,0.37,2.73,1.12
          C84.75,19.22,85.08,20.15,85.08,21.3z M83.3,21.34c0-0.7-0.15-1.31-0.46-1.81c-0.36-0.61-0.88-0.91-1.54-0.91
          c-0.69,0-1.21,0.3-1.57,0.91c-0.31,0.5-0.46,1.12-0.46,1.84c0,0.7,0.15,1.31,0.46,1.81c0.37,0.61,0.89,0.91,1.56,0.91
          c0.66,0,1.17-0.31,1.54-0.93C83.14,22.65,83.3,22.04,83.3,21.34z M90.66,19.02c-0.18-0.03-0.36-0.05-0.54-0.05
          c-0.6,0-1.07,0.23-1.4,0.68c-0.28,0.4-0.43,0.91-0.43,1.52v4.03h-1.72v-5.26c0-0.81-0.02-1.61-0.05-2.42h1.49l0.06,1.47h0.05
          c0.18-0.5,0.47-0.91,0.86-1.22c0.36-0.27,0.79-0.41,1.24-0.41c0.16,0,0.3,0.01,0.43,0.03L90.66,19.02z M98.33,21
          c0,0.26-0.02,0.52-0.06,0.77h-5.15c0.02,0.76,0.27,1.34,0.75,1.74c0.43,0.36,0.99,0.54,1.68,0.54c0.76,0,1.46-0.12,2.08-0.36
          l0.27,1.18c-0.73,0.32-1.59,0.47-2.59,0.47c-1.2,0-2.14-0.35-2.82-1.05c-0.68-0.7-1.02-1.64-1.02-2.82c0-1.16,0.32-2.12,0.95-2.89
          c0.67-0.82,1.57-1.23,2.7-1.23c1.11,0,1.96,0.41,2.53,1.23C98.11,19.24,98.33,20.04,98.33,21z M96.7,20.56
          c0.01-0.51-0.1-0.94-0.33-1.31c-0.3-0.48-0.75-0.71-1.37-0.71c-0.56,0-1.02,0.23-1.37,0.7c-0.29,0.37-0.46,0.81-0.51,1.33
          L96.7,20.56z"
    />
    <path
      fill={props.colorPrimary}
      d="M36.39,10.79c-0.48,0-0.89-0.02-1.23-0.06V5.58c0.48-0.07,0.97-0.11,1.45-0.11c1.97,0,2.87,0.96,2.87,2.53
          C39.49,9.81,38.42,10.79,36.39,10.79z M36.68,6.13c-0.27,0-0.49,0.02-0.68,0.05v3.91c0.1,0.02,0.3,0.02,0.57,0.02
          c1.29,0,2.02-0.73,2.02-2.1C38.6,6.81,37.93,6.13,36.68,6.13z M42.31,10.83c-1.11,0-1.83-0.82-1.83-1.94c0-1.17,0.73-2,1.89-2
          c1.09,0,1.83,0.78,1.83,1.93C44.2,10.01,43.45,10.83,42.31,10.83z M42.34,7.51c-0.61,0-1,0.57-1,1.36c0,0.78,0.4,1.34,0.99,1.34
          c0.59,0,0.99-0.61,0.99-1.36C43.33,8.08,42.94,7.51,42.34,7.51z M50.53,6.97l-1.19,3.77h-0.77L48.08,9.1
          c-0.12-0.4-0.22-0.81-0.31-1.22h-0.02c-0.06,0.41-0.18,0.82-0.3,1.22l-0.52,1.64h-0.78l-1.12-3.77h0.87l0.43,1.79
          c0.1,0.43,0.19,0.83,0.26,1.21h0.02c0.06-0.32,0.16-0.71,0.31-1.2l0.54-1.8h0.69l0.52,1.76c0.12,0.43,0.23,0.85,0.31,1.24h0.02
          c0.05-0.39,0.14-0.8,0.26-1.24l0.46-1.76L50.53,6.97z M54.9,10.74h-0.84V8.58c0-0.67-0.26-1-0.77-1c-0.5,0-0.84,0.43-0.84,0.92
          v2.24H51.6V8.05c0-0.33-0.01-0.69-0.03-1.08h0.74l0.04,0.58h0.02c0.23-0.4,0.69-0.66,1.2-0.66c0.8,0,1.32,0.61,1.32,1.59
          L54.9,10.74z M57.23,10.74h-0.84V5.24h0.84V10.74z M60.3,10.83c-1.11,0-1.83-0.82-1.83-1.94c0-1.17,0.73-2,1.89-2
          c1.09,0,1.83,0.78,1.83,1.93C62.19,10.01,61.43,10.83,60.3,10.83z M60.33,7.51c-0.61,0-1,0.57-1,1.36c0,0.78,0.4,1.34,0.99,1.34
          c0.59,0,0.99-0.61,0.99-1.36C61.32,8.08,60.93,7.51,60.33,7.51z M65.52,10.74l-0.06-0.43h-0.02c-0.26,0.35-0.63,0.52-1.11,0.52
          c-0.68,0-1.16-0.47-1.16-1.11c0-0.93,0.81-1.41,2.22-1.41V8.24c0-0.5-0.26-0.75-0.79-0.75c-0.37,0-0.7,0.09-0.99,0.28l-0.17-0.55
          c0.35-0.22,0.79-0.33,1.3-0.33c0.99,0,1.49,0.52,1.49,1.56v1.39c0,0.38,0.02,0.68,0.05,0.9L65.52,10.74z M65.4,8.86
          c-0.94,0-1.41,0.23-1.41,0.76c0,0.4,0.24,0.59,0.58,0.59c0.43,0,0.83-0.33,0.83-0.77V8.86z M70.32,10.74l-0.04-0.61h-0.02
          c-0.24,0.46-0.65,0.69-1.22,0.69c-0.92,0-1.59-0.8-1.59-1.93c0-1.18,0.7-2.01,1.66-2.01c0.51,0,0.87,0.17,1.07,0.51h0.02V5.24
          h0.84v4.49c0,0.36,0.01,0.71,0.03,1.02H70.32z M70.19,8.52c0-0.53-0.35-0.98-0.89-0.98c-0.63,0-1.01,0.55-1.01,1.33
          c0,0.76,0.4,1.28,0.99,1.28c0.53,0,0.91-0.46,0.91-1V8.52z M76.39,10.83c-1.11,0-1.83-0.82-1.83-1.94c0-1.17,0.73-2,1.89-2
          c1.09,0,1.83,0.78,1.83,1.93C78.28,10.01,77.53,10.83,76.39,10.83z M76.42,7.51c-0.61,0-1,0.57-1,1.36c0,0.78,0.4,1.34,0.99,1.34
          c0.59,0,0.99-0.61,0.99-1.36C77.41,8.08,77.02,7.51,76.42,7.51z M82.82,10.74h-0.84V8.58c0-0.67-0.26-1-0.77-1
          c-0.5,0-0.84,0.43-0.84,0.92v2.24h-0.84V8.05c0-0.33-0.01-0.69-0.03-1.08h0.74l0.04,0.58h0.02c0.23-0.4,0.69-0.66,1.2-0.66
          c0.8,0,1.32,0.61,1.32,1.59V10.74z M88.5,7.6h-0.93v1.83c0,0.47,0.16,0.7,0.49,0.7c0.15,0,0.27-0.02,0.37-0.04l0.02,0.64
          c-0.16,0.06-0.38,0.09-0.65,0.09c-0.66,0-1.06-0.36-1.06-1.32V7.6h-0.55V6.97h0.55V6.28l0.83-0.25v0.94h0.93V7.6z M92.96,10.74
          h-0.84V8.59c0-0.68-0.26-1.02-0.77-1.02c-0.44,0-0.84,0.3-0.84,0.89v2.27h-0.84V5.24h0.84v2.27h0.02
          c0.27-0.41,0.65-0.61,1.14-0.61c0.8,0,1.29,0.62,1.29,1.61V10.74z M97.54,9.06h-2.53c0.02,0.71,0.49,1.12,1.2,1.12
          c0.37,0,0.72-0.06,1.02-0.18l0.13,0.58c-0.36,0.16-0.78,0.23-1.27,0.23c-1.19,0-1.89-0.75-1.89-1.9c0-1.16,0.72-2.03,1.8-2.03
          c0.97,0,1.58,0.71,1.58,1.79C97.57,8.81,97.56,8.93,97.54,9.06z M96.76,8.46c0-0.58-0.3-0.99-0.83-0.99
          c-0.48,0-0.87,0.42-0.92,0.99H96.76z"
    />
    <path
      fill="none"
      stroke={props.colorPrimary}
      d="M5,0.5h98.58c2.49,0,4.5,2.01,4.5,4.5v22c0,2.49-2.01,4.5-4.5,4.5H5c-2.49,0-4.5-2.01-4.5-4.5V5
          C0.5,2.51,2.51,0.5,5,0.5z"
    />
  </svg>
);

export default AppStore;
