import React, { Component } from "react";

class AuthModal extends Component {
  render() {
    const message = this.props.errMsg ? this.props.errMsg : "";
    return (
      <div
        style={{
          fontSize: "0.625rem",
          margin: "4px 0 8px",
          color: "#ff4e71"
        }}
        className="signup-disclaimer"
      >{`${message}`}</div>
    );
  }
}

export default AuthModal;
