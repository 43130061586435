import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./App.css";
import "./fonts/fontStyle.css";
// import jwtDecode from "jwt-decode";
import axios from "axios";

//firebase
import firebase from "./firebase";

//redux
import { Provider } from "react-redux";
import store from "./redux/store";
// import { SET_AUTHENTICATED } from "./redux/types";
import { authStateChng } from "./redux/actions/userActions";

//components
import Header from "./components/Header";
import Footer from "./components/Footer";

//utilities
import AuthRoute from "./util/AuthRoute";
import UnauthRoute from "./util/UnauthRoute";

//Pages
import Home from "./pages/home";
import Forum from "./pages/forum";
import Auth from "./pages/Auth";
import Products from "./pages/products";
import Pricing from "./pages/pricing";
import Support from "./pages/support";
import User from "./pages/userProfile";
import Profile from "./pages/profile";
import BetaForm from "./pages/BetaForm";
import ContactForm from "./pages/ContactForm";
import Plans from "./pages/plans";
import Post from "./pages/Post";

axios.defaults.baseURL =
  "https://us-central1-flipit-238117.cloudfunctions.net/api";

class App extends Component {
  componentWillMount() {
    this.authListener = this.authListener.bind(this);
    this.authListener();
  }

  authListener() {
    firebase.auth().onAuthStateChanged((user) => {
      store.dispatch(authStateChng(user));
    });
  }

  render() {
    console.log(
      " ----------------------------------------",
      "\n",
      "\n",
      "......   ..      ..   .....     ((   (( ",
      "\n",
      "...      ..      ..   ..  ...   ((   (( ",
      "\n",
      "......   ..      ..   .......   ((  ((((",
      "\n",
      "...      ..      ..   ..        ((   (( ",
      "\n",
      "...      ......  ..   ..        ((   (( ",
      "\n",
      "\n",
      "If you're reding this, We're hiring.",
      "\n",
      "https://us-flipit.com/contact",
      "\n",
      "\n",
      "----------------------------------------"
    );

    //  <AuthRoute path="/auth" component={Auth} />
    return (
      <Provider store={store}>
        <Router>
          <div className="god-wrapper">
            <Header />
            <div className="content-wrapper">
              <Switch>
                <Route exact path="/" component={Home} />
                <Route path="/forum/post/:pathParam1?" component={Post} />
                <Route path="/forum" component={Forum} />
                <AuthRoute
                  path="/auth/:pathParam1?/:pathParam2?"
                  component={Auth}
                />
                <Route exact path="/products" component={Products} />
                <Route exact path="/pricing" component={Pricing} />

                <Route exact path="/support" component={Support} />
                <Route path="/user/:pathParam1?" component={User} />
                <UnauthRoute path="/profile" component={Profile} />

                <Route path="/beta" component={BetaForm} />
                <Route path="/contact" component={ContactForm} />
                <Route path="/plans" component={Plans} />
              </Switch>
            </div>
            <Footer />
          </div>
        </Router>
      </Provider>
    );
  }
}

export default App;

// <div style={{ minHeight: "100vh" }}>
