import React, { Component } from "react";

import "./SplashPopStyle.css";

class MapIcon extends Component {
  constructor(props) {
    super(props);
  }

  //style={{animation: `map-icon-dots-rev 0.75s cubic-bezier(0, 1, 0.1, 1) ${1 + this.props.delay}s normal forwards`}}

  render() {
    let { active, delay } = this.props;
    return (
      <div
        className="map-icon-container centered-col"
        style={{
          right: this.props.right,
          left: this.props.left,
          top: this.props.top,
          bottom: this.props.bottom,
          animation: `map-icon-rev 0.75s cubic-bezier(0, 1, 0.1, 1) ${this.props.delay}s normal forwards`
        }}
      >
        <div className="map-icon-dots-wrap centered">
          <div
            className={Number(active) >= 1 ? "map-icon-dots-active" : ""}
            style={{
              animation: ` map-icon-dots-rev 0.5s ease-in ${0.8 +
                Number(delay)}s normal forwards`
            }}
          ></div>
          <div
            className={Number(active) >= 2 ? "map-icon-dots-active" : ""}
            style={{
              animation: ` map-icon-dots-rev 0.5s ease-in ${1 +
                Number(delay)}s normal forwards`
            }}
          ></div>
          <div
            className={Number(active) >= 3 ? "map-icon-dots-active" : ""}
            style={{
              animation: ` map-icon-dots-rev 0.5s ease-in ${1.1 +
                Number(delay)}s normal forwards`
            }}
          ></div>
          <div
            className={Number(active) >= 4 ? "map-icon-dots-active" : ""}
            style={{
              animation: ` map-icon-dots-rev 0.5s ease-in ${1.25 +
                Number(delay)}s normal forwards`
            }}
          ></div>
          <div
            className={Number(active) >= 5 ? "map-icon-dots-active" : ""}
            style={{
              animation: ` map-icon-dots-rev 0.5s ease-in ${1.35 +
                Number(delay)}s normal forwards`
            }}
          ></div>
        </div>
        <svg style={{ width: 28, height: 28 }} viewBox="0 0 24 24">
          <path
            fill="#323a42"
            d="M12 11.5A2.5 2.5 0 019.5 9 2.5 2.5 0 0112 6.5 2.5 2.5 0 0114.5 9a2.5 2.5 0 01-2.5 2.5M12 2a7 7 0 00-7 7c0 5.25 7 13 7 13s7-7.75 7-13a7 7 0 00-7-7z"
          ></path>
        </svg>
        <div className="splash-pop-shadow"></div>
      </div>
    );
  }
}

export default MapIcon;
