import React from "react";

const arrowDown = props => (
  <svg
    className={props.className}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
  >
    <path fill={props.colorPrimary} d="M5 4v3h5v12h3V7h5V4H5z" />
  </svg>
);

export default arrowDown;
