import React, { Component } from "react";

import Close from "../../../media/SVGs/None";

class planCard extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleSubmit = (e) => {
    this.props.closePop(e);
  };

  render() {
    return (
      <div
        className="pop-save-wrap popup-container anim-up"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="outskrts">
          <h3>Save this post to a collection</h3>
          <div
            onClick={(e) => {
              this.handleSubmit(e);
            }}
            className="pop-close-button"
          >
            <Close size="24px" />
          </div>
        </div>
        <input type="text" placeholder="Filter collections" />
        <div className="save-pop-collection-wrap"></div>
        <div className="outskrts">
          <button className="button button-white">New Colleciton</button>
          <button className="button button-blue">Save</button>
        </div>
      </div>
    );
  }
}

export default planCard;
