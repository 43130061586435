import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="742"
      height="633"
      fill="none"
      viewBox="0 0 742 633"
    >
      <path stroke="#F5F5F5" strokeWidth="2" d="M43 191H64V212H43z"></path>
      <path stroke="#F5F5F5" strokeWidth="2" d="M43 254H64V275H43z"></path>
      <path stroke="#F5F5F5" strokeWidth="2" d="M657 317H678V338H657z"></path>
      <path stroke="#F5F5F5" strokeWidth="2" d="M148 254H169V275H148z"></path>
      <path stroke="#F5F5F5" strokeWidth="2" d="M64 191H85V212H64z"></path>
      <path stroke="#F5F5F5" strokeWidth="2" d="M64 254H85V275H64z"></path>
      <path stroke="#F5F5F5" strokeWidth="2" d="M678 317H699V338H678z"></path>
      <path stroke="#F5F5F5" strokeWidth="2" d="M169 254H190V275H169z"></path>
      <path stroke="#F5F5F5" strokeWidth="2" d="M1 191H22V212H1z"></path>
      <path stroke="#F5F5F5" strokeWidth="2" d="M127 191H148V212H127z"></path>
      <path stroke="#F5F5F5" strokeWidth="2" d="M85 317H106V338H85z"></path>
      <path stroke="#F5F5F5" strokeWidth="2" d="M127 443H148V464H127z"></path>
      <path stroke="#F5F5F5" strokeWidth="2" d="M169 506H190V527H169z"></path>
      <path stroke="#F5F5F5" strokeWidth="2" d="M127 569H148V590H127z"></path>
      <path stroke="#F5F5F5" strokeWidth="2" d="M421 506H442V527H421z"></path>
      <path stroke="#F5F5F5" strokeWidth="2" d="M127 380H148V401H127z"></path>
      <path stroke="#F5F5F5" strokeWidth="2" d="M169 380H190V401H169z"></path>
      <path stroke="#F5F5F5" strokeWidth="2" d="M190 380H211V401H190z"></path>
      <path stroke="#F5F5F5" strokeWidth="2" d="M211 380H232V401H211z"></path>
      <path stroke="#F5F5F5" strokeWidth="2" d="M232 380H253V401H232z"></path>
      <path stroke="#F5F5F5" strokeWidth="2" d="M127 317H148V338H127z"></path>
      <path stroke="#F5F5F5" strokeWidth="2" d="M169 443H190V464H169z"></path>
      <path stroke="#F5F5F5" strokeWidth="2" d="M211 506H232V527H211z"></path>
      <path stroke="#F5F5F5" strokeWidth="2" d="M169 569H190V590H169z"></path>
      <path stroke="#F5F5F5" strokeWidth="2" d="M148 317H169V338H148z"></path>
      <path stroke="#F5F5F5" strokeWidth="2" d="M190 443H211V464H190z"></path>
      <path stroke="#F5F5F5" strokeWidth="2" d="M232 506H253V527H232z"></path>
      <path stroke="#F5F5F5" strokeWidth="2" d="M190 569H211V590H190z"></path>
      <path stroke="#F5F5F5" strokeWidth="2" d="M169 317H190V338H169z"></path>
      <path stroke="#F5F5F5" strokeWidth="2" d="M211 443H232V464H211z"></path>
      <path stroke="#F5F5F5" strokeWidth="2" d="M253 506H274V527H253z"></path>
      <path stroke="#F5F5F5" strokeWidth="2" d="M211 569H232V590H211z"></path>
      <path stroke="#F5F5F5" strokeWidth="2" d="M190 317H211V338H190z"></path>
      <path stroke="#F5F5F5" strokeWidth="2" d="M232 443H253V464H232z"></path>
      <path stroke="#F5F5F5" strokeWidth="2" d="M274 506H295V527H274z"></path>
      <path stroke="#F5F5F5" strokeWidth="2" d="M232 569H253V590H232z"></path>
      <path stroke="#F5F5F5" strokeWidth="2" d="M211 317H232V338H211z"></path>
      <path stroke="#F5F5F5" strokeWidth="2" d="M253 443H274V464H253z"></path>
      <path stroke="#F5F5F5" strokeWidth="2" d="M295 506H316V527H295z"></path>
      <path stroke="#F5F5F5" strokeWidth="2" d="M232 317H253V338H232z"></path>
      <path stroke="#F5F5F5" strokeWidth="2" d="M274 443H295V464H274z"></path>
      <path stroke="#F5F5F5" strokeWidth="2" d="M316 506H337V527H316z"></path>
      <path stroke="#F5F5F5" strokeWidth="2" d="M253 317H274V338H253z"></path>
      <path stroke="#F5F5F5" strokeWidth="2" d="M85 191H106V212H85z"></path>
      <path stroke="#F5F5F5" strokeWidth="2" d="M85 254H106V275H85z"></path>
      <path stroke="#F5F5F5" strokeWidth="2" d="M699 317H720V338H699z"></path>
      <path stroke="#F5F5F5" strokeWidth="2" d="M190 254H211V275H190z"></path>
      <path stroke="#F5F5F5" strokeWidth="2" d="M106 254H127V275H106z"></path>
      <path stroke="#F5F5F5" strokeWidth="2" d="M720 317H741V338H720z"></path>
      <path stroke="#F5F5F5" strokeWidth="2" d="M211 254H232V275H211z"></path>
      <path stroke="#F5F5F5" strokeWidth="2" d="M232 254H253V275H232z"></path>
      <path stroke="#F5F5F5" strokeWidth="2" d="M64 170H85V191H64z"></path>
      <path stroke="#F5F5F5" strokeWidth="2" d="M64 233H85V254H64z"></path>
      <path stroke="#F5F5F5" strokeWidth="2" d="M678 296H699V317H678z"></path>
      <path stroke="#F5F5F5" strokeWidth="2" d="M169 233H190V254H169z"></path>
      <path stroke="#F5F5F5" strokeWidth="2" d="M1 170H22V191H1z"></path>
      <path stroke="#F5F5F5" strokeWidth="2" d="M127 170H148V191H127z"></path>
      <path stroke="#F5F5F5" strokeWidth="2" d="M85 296H106V317H85z"></path>
      <path stroke="#F5F5F5" strokeWidth="2" d="M127 422H148V443H127z"></path>
      <path stroke="#F5F5F5" strokeWidth="2" d="M169 485H190V506H169z"></path>
      <path stroke="#F5F5F5" strokeWidth="2" d="M127 548H148V569H127z"></path>
      <path stroke="#F5F5F5" strokeWidth="2" d="M85 611H106V632H85z"></path>
      <path stroke="#F5F5F5" strokeWidth="2" d="M361 548H382V569H361z"></path>
      <path stroke="#F5F5F5" strokeWidth="2" d="M421 485H442V506H421z"></path>
      <path stroke="#F5F5F5" strokeWidth="2" d="M127 359H148V380H127z"></path>
      <path stroke="#F5F5F5" strokeWidth="2" d="M169 359H190V380H169z"></path>
      <path stroke="#F5F5F5" strokeWidth="2" d="M190 359H211V380H190z"></path>
      <path stroke="#F5F5F5" strokeWidth="2" d="M211 359H232V380H211z"></path>
      <path stroke="#F5F5F5" strokeWidth="2" d="M232 359H253V380H232z"></path>
      <path stroke="#F5F5F5" strokeWidth="2" d="M127 296H148V317H127z"></path>
      <path stroke="#F5F5F5" strokeWidth="2" d="M169 422H190V443H169z"></path>
      <path stroke="#F5F5F5" strokeWidth="2" d="M211 485H232V506H211z"></path>
      <path stroke="#F5F5F5" strokeWidth="2" d="M169 548H190V569H169z"></path>
      <path stroke="#F5F5F5" strokeWidth="2" d="M148 296H169V317H148z"></path>
      <path stroke="#F5F5F5" strokeWidth="2" d="M190 422H211V443H190z"></path>
      <path stroke="#F5F5F5" strokeWidth="2" d="M232 485H253V506H232z"></path>
      <path stroke="#F5F5F5" strokeWidth="2" d="M190 548H211V569H190z"></path>
      <path stroke="#F5F5F5" strokeWidth="2" d="M169 296H190V317H169z"></path>
      <path stroke="#F5F5F5" strokeWidth="2" d="M211 422H232V443H211z"></path>
      <path stroke="#F5F5F5" strokeWidth="2" d="M253 485H274V506H253z"></path>
      <path stroke="#F5F5F5" strokeWidth="2" d="M211 548H232V569H211z"></path>
      <path stroke="#F5F5F5" strokeWidth="2" d="M190 296H211V317H190z"></path>
      <path stroke="#F5F5F5" strokeWidth="2" d="M232 422H253V443H232z"></path>
      <path stroke="#F5F5F5" strokeWidth="2" d="M274 485H295V506H274z"></path>
      <path stroke="#F5F5F5" strokeWidth="2" d="M232 548H253V569H232z"></path>
      <path stroke="#F5F5F5" strokeWidth="2" d="M211 296H232V317H211z"></path>
      <path stroke="#F5F5F5" strokeWidth="2" d="M253 422H274V443H253z"></path>
      <path stroke="#F5F5F5" strokeWidth="2" d="M295 485H316V506H295z"></path>
      <path stroke="#F5F5F5" strokeWidth="2" d="M232 296H253V317H232z"></path>
      <path stroke="#F5F5F5" strokeWidth="2" d="M274 422H295V443H274z"></path>
      <path stroke="#F5F5F5" strokeWidth="2" d="M316 485H337V506H316z"></path>
      <path stroke="#F5F5F5" strokeWidth="2" d="M253 296H274V317H253z"></path>
      <path stroke="#F5F5F5" strokeWidth="2" d="M169 170H190V191H169z"></path>
      <path stroke="#F5F5F5" strokeWidth="2" d="M64 128H106V149H64z"></path>
      <path stroke="#F5F5F5" strokeWidth="2" d="M43 86H85V107H43z"></path>
      <path stroke="#F5F5F5" strokeWidth="2" d="M43 1H64V22H43z"></path>
      <path stroke="#F5F5F5" strokeWidth="2" d="M106 22H127V43H106z"></path>
      <path stroke="#F5F5F5" strokeWidth="2" d="M85 170H106V191H85z"></path>
      <path stroke="#F5F5F5" strokeWidth="2" d="M85 233H106V254H85z"></path>
      <path stroke="#F5F5F5" strokeWidth="2" d="M699 296H720V317H699z"></path>
      <path stroke="#F5F5F5" strokeWidth="2" d="M190 233H211V254H190z"></path>
      <path stroke="#F5F5F5" strokeWidth="2" d="M106 233H127V254H106z"></path>
      <path stroke="#F5F5F5" strokeWidth="2" d="M720 296H741V317H720z"></path>
      <path stroke="#F5F5F5" strokeWidth="2" d="M211 233H232V254H211z"></path>
      <path stroke="#F5F5F5" strokeWidth="2" d="M232 233H253V254H232z"></path>
      <path stroke="#F5F5F5" strokeWidth="2" d="M43 170H64V191H43z"></path>
      <path stroke="#F5F5F5" strokeWidth="2" d="M43 233H64V254H43z"></path>
      <path stroke="#F5F5F5" strokeWidth="2" d="M657 296H678V317H657z"></path>
      <path stroke="#F5F5F5" strokeWidth="2" d="M148 233H169V254H148z"></path>
      <g filter="url(#filter0_dd)">
        <path fill="#fff" d="M43 170H64V191H43z"></path>
        <path
          stroke="#F0F0F0"
          strokeWidth="0.75"
          d="M43.375 170.375H63.625V190.625H43.375z"
        ></path>
      </g>
      <g filter="url(#filter1_dd)">
        <path fill="#fff" d="M169 359H211V380H169z"></path>
        <path
          stroke="#F0F0F0"
          strokeWidth="0.75"
          d="M169.375 359.375H210.625V379.625H169.375z"
        ></path>
      </g>
      <g filter="url(#filter2_dd)">
        <path fill="#fff" d="M106 22H127V43H106z"></path>
        <path
          stroke="#F0F0F0"
          strokeWidth="0.75"
          d="M106.375 22.375H126.625V42.625H106.375z"
        ></path>
      </g>
      <defs>
        <filter
          id="filter0_dd"
          width="71"
          height="71"
          x="18"
          y="160"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="15"></feOffset>
          <feGaussianBlur stdDeviation="12.5"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow"
          ></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="5"></feOffset>
          <feGaussianBlur stdDeviation="5"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.09 0"></feColorMatrix>
          <feBlend
            in2="effect1_dropShadow"
            result="effect2_dropShadow"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect2_dropShadow"
            result="shape"
          ></feBlend>
        </filter>
        <filter
          id="filter1_dd"
          width="92"
          height="71"
          x="144"
          y="349"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="15"></feOffset>
          <feGaussianBlur stdDeviation="12.5"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow"
          ></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="5"></feOffset>
          <feGaussianBlur stdDeviation="5"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.09 0"></feColorMatrix>
          <feBlend
            in2="effect1_dropShadow"
            result="effect2_dropShadow"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect2_dropShadow"
            result="shape"
          ></feBlend>
        </filter>
        <filter
          id="filter2_dd"
          width="71"
          height="71"
          x="81"
          y="12"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="15"></feOffset>
          <feGaussianBlur stdDeviation="12.5"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow"
          ></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="5"></feOffset>
          <feGaussianBlur stdDeviation="5"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.09 0"></feColorMatrix>
          <feBlend
            in2="effect1_dropShadow"
            result="effect2_dropShadow"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect2_dropShadow"
            result="shape"
          ></feBlend>
        </filter>
      </defs>
    </svg>
  );
}

export default Icon;
