import React, { Component } from "react";
// import { Link } from "react-router-dom";

import { HashLink as Link } from "react-router-hash-link";

import "./pricingStyle.css";
import "./productsStyle.css";

//compopnents
import Card from "../components/cards/Card";
import BubbleBack from "../components/backgrounds/BubbleBack";
import ProductGrid from "../components/products/ProductGrid";
import PageHeader from "../components/allPages/PageHeader";

//IOcons
import ProductsFind from "../media/SVGs/ProductsFind";
import ProductsFlip from "../media/SVGs/ProductsFlip";
import ProductsSell from "../media/SVGs/ProductsSell";

//consoleIcons
// import Dashboard from "../media/icons/console/Dashboard";
import FlippabilitySearch from "../media/icons/console/FlippabilitySearch";
import TrendAnalysis from "../media/icons/console/TrendAnalysis";
import Watchlist from "../media/icons/console/Watchlist";
import ProjectBuilder from "../media/icons/console/ProjectBuilder";
import Financing from "../media/icons/console/Financing";
import Todo from "../media/icons/console/Todo";
import Budgeting from "../media/icons/console/Budgeting";
import Contractors from "../media/icons/console/Contractors";
import Suppliers from "../media/icons/console/Suppliers";
import Listing from "../media/icons/console/Listing";
import Financials from "../media/icons/console/Financials";
import Archive from "../media/icons/console/Archive";
import Realtors from "../media/icons/console/Realtors";

// <img src="https://firebase.google.com/images/usecases/new-features_2x.png" />
// <PeopleDots />

class pricing extends Component {
  render() {
    const pagetext = {
      find: {
        title: "Find better flips",
        text:
          "Flipit makes it easy to leverage Big Data, Artificial Intelligence, and Machine Learning in your search for the perfect flipping opportunity."
      },
      flip: {
        title: "Fix things fast",
        text:
          "Flipit doesn’t just help you find the perfect opportunity; it walks you through the entire flipping process."
      },
      sell: {
        title: "Make selling easy",
        text:
          "Flipit takes all the guess work out of selling making it easy to not only sell a property but keep track of all the important data."
      }
    };

    const finding = {
      FlippabilitySearch: {
        icon: <FlippabilitySearch size="24px" colorPrimary="#fff" />,
        plans: ["Flipper", "Pro-Flipper", "Enterprise"],
        description:
          "An unrivaled house flipping tool, the Flippability Search analyzes millions of properties – comparing local trends with renovation and permitting data to find the “worst house in the best neighborhood.” The resulting Flippability Score highlights properties that are prime for maximizing profit."
      },
      TrendAnalysis: {
        icon: <TrendAnalysis size="24px" colorPrimary="#fff" />,
        plans: ["Flipper", "Pro-Flipper", "Enterprise"],
        description:
          "Local, state, and industry trend analysis is extremely valuable in property acquisitions. Not only can it steer you towards prime flipping opportunities, but also away from costly pitfalls. The Trend Analysis tool makes identifying and navigating these industry tides much more manageable."
      },
      Watchlist: {
        icon: <Watchlist size="24px" colorPrimary="#fff" />,
        plans: ["Flipper", "Pro-Flipper", "Enterprise"],
        description:
          "Your short list. Add and organize your own properties or ones that aren’t yet on the market. Customize your watchlist to track the properties that catch your eye. Your watchlist helps narrow your focus on only the opportunities with the highest potential to guarantee the best returns."
      },
      ProjectBuilder: {
        icon: <ProjectBuilder size="24px" colorPrimary="#fff" />,
        plans: ["Enterprise"],
        description:
          "Have a system or a renovation you use on all your properties? Build it here, and virtually apply it to millions of homes. In addition to finding properties that most benefit from your customized project, the Project Builder will estimate building costs and returns based on location."
      },
      RealtorPortal: {
        icon: <Realtors size="24px" colorPrimary="#fff" />,
        plans: ["Flipper", "Pro-Flipper", "Enterprise"],
        description:
          "Opportunities sometimes lie outside your area of operation. The Realtor Portal connects you with local realtors who are experts in the area. If you don’t already have one, the portal is the perfect tool for finding the best boots-on-the-ground agent for your next flipping project."
      },
      FinancingPortal: {
        icon: <Financing size="24px" colorPrimary="#fff" />,
        plans: ["Flipper", "Pro-Flipper", "Enterprise"],
        description:
          "Financing can make or break a deal. Use the Financing portal to connect with financiers that specialize in funding house flips and renovations. The financing portal is a great place to gain knowledge from the experts on how to get creative in financing your next flip."
      }
    };
    const flipping = {
      Todo: {
        icon: <Todo size="24px" colorPrimary="#fff" />,
        plans: ["Flipper", "Pro-Flipper", "Enterprise"],
        description:
          "Utilize the To-Do tool to help streamline the flipping process. To-Do breaks down all property-specific projects into smaller steps, making large, value-adding opportunities more manageable. Furthermore, To-do provides helpful tips and pricing suggestions and it is fully customizable."
      },
      Budgeting: {
        icon: <Budgeting size="24px" colorPrimary="#fff" />,
        plans: ["Flipper", "Pro-Flipper", "Enterprise"],
        description:
          "The Budgeting tool tracts expenses and provides dynamic forecasts to help you stay on track and under budget. Auto-generating budgets for each property, the Budgeting tool factors in local material and labor costs and allows you to customize existing estimates or import your own."
      },
      ContractorPortal: {
        icon: <Contractors size="24px" colorPrimary="#fff" />,
        plans: ["Flipper", "Pro-Flipper", "Enterprise"],
        description:
          "Don’t have a team yet, no problem! Find the best talent in your area with the Contractor Portal. Peer reviewed by other flippers; the portal helps you find the right people for the project at hand. Find contractors that specialize in investment properties and house flipping."
      },
      SupplierPortal: {
        icon: <Suppliers size="24px" colorPrimary="#fff" />,
        plans: ["Flipper", "Pro-Flipper", "Enterprise"],
        description:
          "Eliminate hours of searching shelves and expensive material pricing mistakes. With the supplier portal, you can find the best suppliers in your area with the materials you need. No more spending hours making multiple trips to the hardware stores, now it’s just a search away."
      }
    };
    const selling = {
      AutoListing: {
        icon: <Listing size="24px" colorPrimary="#fff" />,
        plans: ["Flipper", "Pro-Flipper", "Enterprise"],
        description:
          "The Auto Listing tool generates a detailed listing, incorporating all the recently completed projects and value added. With a recommended listing price as well as automatically generated descriptions, Auto Listing allows you to simply edit and export the perfect listing for a finished flip. "
      },
      Financials: {
        icon: <Financials size="24px" colorPrimary="#fff" />,
        plans: ["Flipper", "Pro-Flipper", "Enterprise"],
        description:
          "Keep track of all of a project’s financials in one place. The Financials tool tracks holding, project, and contingency costs, as well as all buying/selling fees. Use this tool—which works seamlessly with the Budgeting tool—to accurately track financials and generate accounting forms."
      },
      ListingAgentPortal: {
        icon: <Realtors size="24px" colorPrimary="#fff" />,
        plans: ["Flipper", "Pro-Flipper", "Enterprise"],
        description:
          "The Listing Agent Portal connects you with local sell-side realtors who are experts in your area. Whether you’re a buy-and-hold investor, homeowner, or experienced flipper our agent portal will help you find an agent experienced in showcasing renovated projects in your local market."
      },
      Archive: {
        icon: <Archive size="24px" colorPrimary="#fff" />,
        plans: ["Flipper", "Pro-Flipper", "Enterprise"],
        description:
          "Keep track of all your past projects with the Archive Tool. The Archive tool organizes your flipping portfolio to help raise money from private investors, provided structured documentation for financial institutions, and for sharing your successes and lessons learned on the Flipit forum."
      }
    };

    return (
      <div className="pricing-page-wrap">
        <a className="page-link-top" id="products-top" href=""></a>
        <BubbleBack />
        <div className="sizing pricing-header">
          <PageHeader
            title="Products"
            line1="Tools for real estate investing"
          />
          <div className="centered-col">
            <div className="prising-header-links">
              <Link smooth to="#findingit" className="centered-col">
                <div className="">
                  <ProductsFind />
                </div>
                <h4>{pagetext.find.title}</h4>
                <div className="producct-link">Jump to flinding tools</div>
              </Link>

              <Link smooth to="#flippingit" className="centered-col">
                <div className="">
                  <ProductsFlip />
                </div>
                <h4>{pagetext.flip.title}</h4>
                <div className="producct-link">Jump to flipping tools</div>
              </Link>
              <Link smooth to="#sellingit" className="centered-col">
                <div className="">
                  <ProductsSell />
                </div>
                <h4>{pagetext.sell.title}</h4>
                <div className="producct-link">Jump to selling tools</div>
              </Link>
            </div>
          </div>
          <a id="findingit" />
        </div>
        <section className="sizing">
          <Card className="card-shdow-sml table-card-wrap">
            <div className="pricing-table-wrap">
              <div className="pricing-table-wrap-free">
                <h3>{pagetext.find.title}</h3>
                <p>{pagetext.find.text}</p>
              </div>
              <ProductGrid color="#34a8fe" content={finding} />
            </div>
            <a id="flippingit" />
          </Card>
          <Card className="card-shdow-sml table-card-wrap">
            <div className="pricing-table-wrap">
              <div className="pricing-table-wrap-free">
                <h3>{pagetext.flip.title}</h3>
                <p>{pagetext.flip.text}</p>
              </div>
              <ProductGrid color="#34a8fe" content={flipping} />
            </div>
            <a id="sellingit" />
          </Card>
          <Card className="card-shdow-sml table-card-wrap">
            <div className="pricing-table-wrap">
              <div className="pricing-table-wrap-free">
                <h3>{pagetext.sell.title}</h3>
                <p>{pagetext.sell.text}</p>
              </div>
              <ProductGrid color="#34a8fe" content={selling} />
            </div>
          </Card>
          <Card className="card-shdow-sml table-card-wrap">
            <div className="pricing-table-wrap centered-col">
              <div className="pricing-table-wrap-free ">
                <h3>Forum</h3>
                <p>
                  With everything from market research to questions on specific
                  projects, the Flipit forum is a community of real estate
                  investors, builders, and flippers. Best of all, it’s
                  completely free!
                </p>
                <div className="product-icon-container centered">
                  <div className="centered-col">
                    <svg viewBox="0 0 24 24">
                      <path
                        fill="#fff"
                        d="M21,6V8H3V6H21M3,18H12V16H3V18M3,13H21V11H3V13Z"
                      />
                    </svg>
                  </div>
                  <div className="centered-col">
                    <svg viewBox="0 0 24 24">
                      <path
                        fill="#fff"
                        d="M19,19H5V5H19M19,3H5A2,2 0 0,0 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5A2,2 0 0,0 19,3M13.96,12.29L11.21,15.83L9.25,13.47L6.5,17H17.5L13.96,12.29Z"
                      />
                    </svg>
                  </div>
                  <div className="centered-col">
                    <svg viewBox="0 0 24 24">
                      <path
                        fill="#fff"
                        d="M9,22A1,1 0 0,1 8,21V18H4A2,2 0 0,1 2,16V4C2,2.89 2.9,2 4,2H20A2,2 0 0,1 22,4V16A2,2 0 0,1 20,18H13.9L10.2,21.71C10,21.9 9.75,22 9.5,22V22H9M10,16V19.08L13.08,16H20V4H4V16H10M6,7H18V9H6V7M6,11H15V13H6V11Z"
                      />
                    </svg>
                  </div>
                  <div className="centered-col">
                    <svg viewBox="0 0 24 24">
                      <path
                        fill="#fff"
                        d="M15,14C12.33,14 7,15.33 7,18V20H23V18C23,15.33 17.67,14 15,14M6,10V7H4V10H1V12H4V15H6V12H9V10M15,12A4,4 0 0,0 19,8A4,4 0 0,0 15,4A4,4 0 0,0 11,8A4,4 0 0,0 15,12Z"
                      />
                    </svg>
                  </div>

                  <div className="centered-col">
                    <svg viewBox="0 0 24 24">
                      <path
                        fill="#fff"
                        d="M15,14C12.33,14 7,15.33 7,18V20H23V18C23,15.33 17.67,14 15,14M6,10V7H4V10H1V12H4V15H6V12H9V10M15,12A4,4 0 0,0 19,8A4,4 0 0,0 15,4A4,4 0 0,0 11,8A4,4 0 0,0 15,12Z"
                      />
                    </svg>
                  </div>
                </div>
              </div>

              <div className="product-button-container centered">
                <Link to="/signup">
                  <button className="button button-blue">Sign up</button>
                </Link>
                <Link to="/forum">
                  <button className="button button-white">Go to forum</button>
                </Link>
              </div>
            </div>
          </Card>
        </section>
        <div className="spacer"></div>
      </div>
    );
  }
}

export default pricing;

//  <img src={Beta} />
//              <span style={{ fontSize: ".75rem" }}>*limited to 100 spots.</span>

//free standalone card
// <div className="sizing">
//   <Card className="card-shdow-sml pricing-free-card">
//     <h3>Join for free!</h3>
//     <p>
//       Flipit is a community of likeminded real estate investors,
//       builders, and flippers. Join for free and get full access to the{" "}
//       <Link className="link-color" to="/forum">
//         Forum and Private Messaging
//       </Link>
//       .
//     </p>
//     <Button className="button Join-button button-blue">
//       <div className="">Join the Community</div>
//     </Button>
//     <img src="https://firebase.google.com/images/usecases/new-features_2x.png" />
//   </Card>
// </div>
//----------------------------------------------------------------

//free header
// <div className="pricing-table-wrap-free">
//   <h3>Join for free!</h3>
//   <p>
//     Flipit is a community of likeminded real estate investors,
//     builders, and flippers. Join for free and get full access to
//     the <Link to="/forum">Forum and Private Messaging</Link>!
//   </p>
//   <Button className="button Join-button button-blue">
//     <div className="desktop-only">Join the Community</div>
//   </Button>
//   <div className="pricing-table-wrap-paidLink centered-col">
//     <p>Paid plans</p>
//     <ArrowDown colorPrimary="#939eab" size="24px" />
//   </div>
// </div>
// <div className="pricing-table-wrap-divider"></div>

//----------------------------------------------------------------
