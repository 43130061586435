import React, { Component } from "react";
import { Link } from "react-router-dom";

// import axios from "axios";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

//styling
import "./AuthFormStyle.css";
import "../cards/cardStyle.css";

//Components
import Button from "../buttons/Button";
import ErrHandler from "../errors/ErrHandler";
import Spinner from "../loading/Spinner";

//redux stuff
import { connect } from "react-redux";
import { signupUser, loginUser } from "../../redux/actions/userActions";

//icons
import Google from "../../media/icons/Google";
import Facebook from "../../media/icons/Facebook";
import Twitter from "../../media/icons/Twitter";
import Github from "../../media/icons/Github";

class signin extends Component {
  constructor() {
    super();
    this.state = {
      email: "",
      password: "",
      passwordCheck: "",
      username: "",
      loading: false,
      signin: false,
    };
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.UI.errors) {
      this.setState({
        errors: nextProps.UI.errors,
      });
    }
  }
  handleSubmit = (event) => {
    event.preventDefault();
    const newUserData = {
      email: this.state.email,
      password: this.state.password,
      confirmPassword: this.state.confirmPassword,
      username: this.state.username,
    };
    const userData = {
      email: this.state.email,
      password: this.state.password,
    };
    this.props.signupUser(newUserData, this.props.history);
    this.props.loginUser(userData, this.props.history);
  };

  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };

  render() {
    // const { errors, loading } = this.state;
    const {
      // classes,
      UI: { loading },
    } = this.props;
    const { errors } = this.state;

    const signinForm = (
      <form
        className="signin-form active-auth-form"
        onSubmit={this.handleSubmit}
      >
        <ErrHandler
          errMsg={!this.state.errors ? "" : this.state.errors.general}
        />
        <input
          className="auth-email"
          id="email"
          name="email"
          type="email"
          label="Email"
          placeholder="email"
          value={this.state.email}
          onChange={this.handleChange}
        />
        <ErrHandler
          errMsg={!this.state.errors ? "" : this.state.errors.email}
        />
        <input
          className="auth-pass"
          id="password"
          name="password"
          type="password"
          label="Password"
          placeholder="Password"
          value={this.state.password}
          onChange={this.handleChange}
        />
        <ErrHandler
          errMsg={!this.state.errors ? "" : this.state.errors.password}
        />

        <div className="auth-button-wrap outskrts">
          <Link
            className="button button-silver pop-b-adj auth-sign-in centered-col"
            to="/auth/signup"
            style={{ marginTop: "16px" }}
          >
            Sign up
          </Link>

          <button
            className="button button-submit pop-b-adj auth-sign-in "
            type="submit"
            name="button"
            style={{ marginTop: "16px" }}
          >
            {loading ? (
              <div className="">
                <Spinner color="#fff" bordWidth="3px" size="16px" />
              </div>
            ) : (
              "Sign in"
            )}
          </button>
        </div>
      </form>
    );
    let display = this.props.location.pathname;
    // function signinCheck() {
    //   if (display.includes("/auth/signin")) {
    //     return true;
    //   }
    //   return false;
    // }

    return (
      <div className="card card-shdow-sml signin-container anim-up">
        <div className="third-party-wrap">
          Sign in with
          <div className="third-party-button-wrap">
            <Button className="button button-silver">
              <Google className="" colorPrimary="#fff" size="24px" />
            </Button>
            <Button className="button button-silver">
              <Facebook className="" colorPrimary="#fff" size="24px" />
            </Button>
            <Button className="button button-silver">
              <Twitter className="" colorPrimary="#fff" size="24px" />
            </Button>
            <Button className="button button-silver">
              <Github className="" colorPrimary="#fff" size="24px" />
            </Button>
          </div>
        </div>
        <div className="signin-divider centered">
          <div className="signin-dividerline"></div>
          <div className="signin-divider-or">&nbsp; OR &nbsp;</div>
        </div>
        <div className="signin-up-form-wrap"></div>
        {signinForm}
      </div>
    );
  }
}

signin.propTypes = {
  // classes: PropTypes.object.isRequired,
  loginUser: PropTypes.func.isRequired,
  signupUser: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  UI: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user,
  UI: state.UI,
});

export default connect(mapStateToProps, { signupUser, loginUser })(
  withRouter(signin)
);
