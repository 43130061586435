import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import PropTypes from "prop-types";

//reduc stuff
import { connect } from "react-redux";
import { getForumPosts } from "../../../redux/actions/dataActions";

//styling
import "./HomeStyle.css";
import "../../cards/cardStyle.css";

//compnents
// import Button from "../buttons/Button";
import ForumPost from "../../cards/ForumPost";
import Spinner from "../../loading/Spinner";
// import Dropdown from "../../dropdowns/Dropdown";
import Switch from "../../allPages/Switch";
import MoreColumn from "./MoreColumn";
import FilterColumn from "./FilterColumn";

//icons
import CardsView from "../../../media/icons/CardsView";
// import ChevronDown from "../../../media/icons/ChevronDown";
import Filter from "../../../media/icons/Filter";

// import ChevronDown from "../../../media/icons/ChevronDown";

//images
// import NoPosts from "../../media/icons/NewPost";

class allpostforum extends Component {
  state = {
    forumPosts: null,
    cardView: true,
    sortFilter: "score"
  };

  componentDidMount() {
    let postArray = this.props.data.forumPosts;
    if (postArray === undefined || postArray.length === 0) {
      this.props.getForumPosts(this.state.sortFilter);
    }
  }

  filterPosts = sortFilter => {
    console.log(sortFilter);
    this.setState({ sortFilter: sortFilter });
    this.props.getForumPosts(sortFilter);
  };

  render() {
    const { BannerHeight } = this.props;
    const { forumPosts, loading } = this.props.data;
    let posts = !loading ? (
      forumPosts.map(forumPost => (
        // <div key={forumPost.forumPostId}>{forumPost.forumPostId}</div>
        <ForumPost
          cardView={this.state.cardView}
          key={forumPost.forumPostId}
          post={forumPost}
          hist={this.props.history}
        />
      ))
    ) : (
      <div style={{ padding: "8px" }}>
        <Spinner color="#939eab" size="24px" />
      </div>
    );

    return (
      <section className="sizing forum-wrap">
        <div
          className="forum-grid sizing "
          style={{ minHeight: `Calc(100% - ${BannerHeight}px)` }}
        >
          <FilterColumn
            toTop={this.props.toTop}
            filterFunc={this.filterPosts}
          />
          <div className="post-container">
            <div className="posts-section-header outskrts">
              <div className="posts-section-header-button post-filter-activation centered">
                <Filter colorPrimary="#939eab" size="24px" />
                <div className="desktop-only">Filter</div>
              </div>
              <div className="posts-section-header-button-focus centered">
                <div className="">Focused</div>
                <Switch colorPrimary="#939eab" size="24px" />
              </div>
              <div className="posts-section-header-button centered">
                <div className="desktop-only">View</div>
                <CardsView colorPrimary="#939eab" size="24px" />
              </div>
            </div>
            {posts}
          </div>
          <MoreColumn toTop={this.props.toTop} />
        </div>
      </section>
    );
  }
}

allpostforum.propTypes = {
  getForumPosts: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  data: state.data
});

export default connect(
  mapStateToProps,
  { getForumPosts }
)(withRouter(allpostforum));
