import React from "react";

function NoFlips() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 356 356">
      <path
        fill="#ACB7BD"
        d="M90 56.8a4.8 4.8 0 110-9.6 4.8 4.8 0 010 9.6zM90 46a6 6 0 100 12.002A6 6 0 0090 46zM240 62.8a4.8 4.8 0 110-9.599 4.8 4.8 0 010 9.599zm0-10.8a6 6 0 100 12 6 6 0 000-12zM73 182.571h-3.857V186h-1.286v-3.429H64v-1.142h3.857V178h1.286v3.429H73v1.142zM169 65.143h-3.857V69h-1.286v-3.857H160v-1.286h3.857V60h1.286v3.857H169v1.286zM304 95.143h-3.857V99h-1.286v-3.857H295v-1.286h3.857V90h1.286v3.857H304v1.286zM287 180a5.99 5.99 0 00-4.243 1.757 6.003 6.003 0 000 8.486A6.003 6.003 0 00293 186a5.99 5.99 0 00-1.757-4.243A6.003 6.003 0 00287 180zM58 111a5.992 5.992 0 00-4.243 1.757 6.002 6.002 0 001.947 9.786A6 6 0 1058 111z"
      ></path>
      <g filter="url(#filter0_d)">
        <path
          fill="#fff"
          d="M246 96H110c-5.523 0-10 4.477-10 10v136c0 5.523 4.477 10 10 10h136c5.523 0 10-4.477 10-10V106c0-5.523-4.477-10-10-10z"
        ></path>
        <rect
          width="60.303"
          height="6.19"
          x="107.428"
          y="228.477"
          fill="#E3E7EB"
          rx="3.095"
        ></rect>
        <rect
          width="113.905"
          height="6.19"
          x="107.428"
          y="212.381"
          fill="#C7D1D9"
          rx="3.095"
        ></rect>
        <g clip-path="url(#clip0)">
          <rect
            width="141.143"
            height="94.095"
            x="107.428"
            y="105.904"
            fill="#F4F6F8"
            rx="5"
          ></rect>
          <path
            fill="#E6EAED"
            d="M143.577 141.354c-5.657 7.579-16.305 5.167-19.633-2.067-12.977-2.067-13.309-17.915-.665-18.604 0-9.992 10.648-10.681 15.306-5.512 8.32-6.547 19.3-.69 17.636 9.646 14.309 5.167 3.994 28.939-12.644 16.537z"
          ></path>
          <path
            fill="#E3E7EB"
            d="M63.488 174C-76.87 173.999-63.746 192.872-190 198.436V204h645c-280.181-5.566-204.725-29.998-391.512-30z"
          ></path>
          <path
            fill="#C9D2D8"
            fillOpacity="0.5"
            d="M257 149.027c-60.374-20.452-81.014 43.042-145 54.973h145v-54.973z"
          ></path>
          <path fill="#E3E7EB" d="M210 131h-18v15.5h18V131z"></path>
          <path fill="#C7D1D9" d="M195 131h-6v15.5h6V131z"></path>
          <path fill="#F4F6F8" d="M198 169.5v24h40v-28h-40v4z"></path>
          <path
            fill="#C7D1D9"
            d="M157.5 165l-3.5-3.5 23.5-24 24 24H242l-4 3.5h-80.5z"
          ></path>
          <path
            fill="#E3E7EB"
            d="M157.5 193.5v-28l20-20.5 20.5 20.5v28h-40.5z"
          ></path>
          <path
            fill="#E6EAED"
            d="M217.833 137.5H177.5l24 24H242l-24.167-24z"
          ></path>
        </g>
      </g>
      <defs>
        <filter
          id="filter0_d"
          width="356"
          height="356"
          x="0"
          y="0"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="4"></feOffset>
          <feGaussianBlur stdDeviation="50"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0.67451 0 0 0 0 0.717647 0 0 0 0 0.741176 0 0 0 0.5 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow"
            result="shape"
          ></feBlend>
        </filter>
        <clipPath id="clip0">
          <rect
            width="141.143"
            height="94.095"
            x="107.428"
            y="105.904"
            fill="#fff"
            rx="5"
          ></rect>
        </clipPath>
      </defs>
    </svg>
  );
}

export default NoFlips;
