import {
  SET_USER,
  SET_AUTHENTICATED,
  SET_UNAUTHENTICATED,
  LOADING_USER,
  STOP_LOADING_USER,
  SET_MAILLISTSUB,
  SET_MAILLISTUNSUB,
  LOADING_SUB,
  // SET_TEMP,
  // SET_NEWUSER,
  // UNSET_NEWUSER
  // SET_BETA,
  LIKE_FORUMPOST,
  // UNLIKE_SCREAM,
  // MARK_NOTIFICATIONS_READ
} from "../types";

const initialState = {
  authenticated: false,
  checked: false,
  loadingUser: false,
  // loadingNav: false,
  newUser: false,
  loadingSub: false,
  mailingList: false,
  credentials: {},
  tempProPic: "",
  likes: [],
  notifications: [],
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_AUTHENTICATED:
      return {
        ...state,
        authenticated: true,
      };
    case SET_UNAUTHENTICATED:
      return initialState;
    case SET_USER:
      return {
        tempProPic: initialState.tempProPic,
        authenticated: true,
        checked: true,
        loadingUser: false,
        ...action.payload,
      };
    case LOADING_USER:
      return {
        ...state,
        loadingUser: true,
      };
    case STOP_LOADING_USER:
      return {
        ...state,
        loadingUser: false,
        checked: true,
      };
    //MAILINGLIST----------------------------------------------
    case LOADING_SUB:
      return {
        ...state,
        loadingSub: true,
      };
    case SET_MAILLISTSUB:
      return {
        ...state,
        loadingSub: false,
        mailingList: true,
      };
    case SET_MAILLISTUNSUB:
      return {
        ...state,
        loadingSub: false,
        mailingList: false,
      };
    //if the user is siged in
    // return {
    //   ...state,
    //   credentials: [
    //     ...state.credentials,
    //     {
    //       mailingList: true
    //     }
    //   ]
    // };
    //----------------------------------------------
    // case SET_BETA:
    //   return {
    //     console.log('SET_BETA user reducer:');
    //     console.log(action.payload);
    //     // ...state,
    //     // forumPosts: [action.payload, ...state.forumPosts]
    //   };
    //----------------------------------------------

    case LIKE_FORUMPOST:
      let index = state.likes.findIndex(
        (like) => like.compId === action.payload.id
      );

      if (index !== -1) {
        state.likes[index].state = action.payload.state;
        return { ...state };
      }

      return {
        ...state,
        likes: [
          ...state.likes,
          {
            username: state.credentials.username,
            compId: action.payload.id,
            compType: action.payload.type,
            state: action.payload.state,
          },
        ],
      };
    // case UNLIKE_FORUMPOST:
    // let index = state.likes.findIndex(
    //   (like) => like.compId === action.payload.id
    // );
    //
    // if (index !== -1) {
    //   state.likes[index].state = action.payload.state;
    //   return { ...state };
    // }
    //
    // return {
    //   ...state,
    //   likes: [
    //     ...state.likes,
    //     {
    //       username: state.credentials.username,
    //       compId: action.payload.id,
    //       compType: action.payload.type,
    //       state: action.payload.state,
    //     },
    //   ],
    // };
    // case MARK_NOTIFICATIONS_READ:
    //   state.notifications.forEach(not => (not.read = true));
    //   return {
    //     ...state
    //   };
    default:
      return state;
  }
}
