import React from "react";

const BellOutline = props => (
  <svg
    className={props.className}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
  >
    <path
      fill={props.colorPrimary}
      d="M5.435 2H9.07v13.997H5.435V2zm1.818 15.547c.623 0 1.155.217 1.594.65.448.435.672.96.672 1.576 0 .608-.224 1.133-.672 1.576-.44.434-.97.651-1.594.651a2.184 2.184 0 01-1.594-.651A2.134 2.134 0 015 19.773c0-.616.22-1.141.659-1.575.439-.434.97-.651 1.594-.651zM14.283 2h3.583v5.469H20v3.06h-2.134V22h-3.583V10.529h-1.845v-3.06h1.845V2z"
    />
  </svg>
);

export default BellOutline;
