import React, { Component } from "react";

import "./SpinnerStyle.css";

class Spinner extends Component {
  render() {
    const sizing = this.props.size ? this.props.size : "48px";
    const coloring = this.props.color ? this.props.color : "#e8eaed";
    const bordWidth = this.props.bordWidth ? this.props.bordWidth : "4px";
    return (
      <figure
        className="global-loading-spinner"
        style={{
          border: `5px solid ${coloring}`,
          borderRightColor: "transparent",
          borderWidth: bordWidth,
          width: sizing,
          height: sizing
        }}
      />
    );
  }
}
export default Spinner;
