import React from "react";

const Filter = props => (
  <svg
    className={props.className}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
  >
    <path
      fill={props.colorPrimary}
      d="M6,13H18V11H6M3,6V8H21V6M10,18H14V16H10V18Z"
    />
  </svg>
);

export default Filter;
