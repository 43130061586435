import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import {
  deleteForumPost,
  clearErrors,
  deleteComment,
} from "../../../redux/actions/dataActions";

import Spinner from "../../loading/Spinner";

class planCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.data.deleting !== this.props.data.deleting &&
      !this.props.data.deleting &&
      this.state.loading
    ) {
      this.setState({
        loading: false,
      });
      this.props.closePop();
    }

    return null;
  }

  deletePost(id, type) {
    this.setState({
      loading: true,
    });
    if (type === "post") return this.props.deleteForumPost(id);
    if (type === "comment") return this.props.deleteComment(id);
  }

  render() {
    const { compId, compType } = this.props;

    return (
      <div
        className="popup-container anim-up"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="pop-container-text">
          {`Are you sure you want to delete this ${compType}?`}
        </div>
        <div className="outskrts">
          <button
            className="button button-white"
            onClick={(e) => this.props.closePop(e)}
          >
            Cancel
          </button>
          <button
            className="button button-blue centered-col"
            onClick={(e) => this.deletePost(compId, compType)}
          >
            {this.state.loading ? (
              <Spinner color="#fff" size="24px" />
            ) : (
              "Delete"
            )}
          </button>
        </div>
      </div>
    );
  }
}

planCard.propTypes = {
  deleteForumPost: PropTypes.func.isRequired,
  deleteComment: PropTypes.func.isRequired,
  clearErrors: PropTypes.func.isRequired,
  UI: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user,
  UI: state.UI,
  data: state.data,
});

export default connect(mapStateToProps, {
  deleteForumPost,
  clearErrors,
  deleteComment,
})(planCard);
