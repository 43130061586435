import React, { Component } from "react";

class planCard extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  //onClick={(e) => delFunc(forumPostId)}
  render() {
    return (
      <div
        className="popup-container anim-up"
        onClick={(e) => e.stopPropagation()}
      >
        <h3>Share this post</h3>

        <div className="save-pop-collection-wrap"></div>
        <div>
          <button className="button button-white">New Colleciton</button>
          <button className="button button-blue">Save</button>
        </div>
      </div>
    );
  }
}

export default planCard;
