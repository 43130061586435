import React from "react";

//styling
import "./planCardStyle.css";

const planCard = props => {
  const {
    planInfo: { selector, name, price, features, disabled, icon, altText },
    active
  } = props;

  let actPrice = props.annBill ? price : (price * 1.12).toFixed(0);
  let annPrice = (actPrice * 12)
    .toFixed(0)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  return (
    <div
      className={`plan-item-container centered ${
        disabled ? "plan-item-diabled" : ""
      } ${selector !== active ? "" : "active-plan"}`}
      onClick={event => props.onChange(selector)}
    >
      <div className="plan-select-button">
        <div className="plan-select-button-icon-wrap centered-col">{icon}</div>
      </div>
      <div className="outskrts" style={{ width: "100%" }}>
        <div className="plan-select-desc">
          <h3>{name}</h3>
          <p>{features}</p>
        </div>
        <div className="plan-select-pricing">
          <h3>
            {disabled
              ? "SOLD OUT"
              : typeof price === "number"
              ? `$${actPrice}`
              : price}
          </h3>
          <p>{typeof price === "number" ? `$${annPrice} annually` : altText}</p>
        </div>
      </div>
    </div>
  );
};

export default planCard;
