import React from "react";

const FlippabilitySearch = props => (
  <svg
    className={props.className}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
  >
    <path
      fill={props.colorPrimary}
      d="M2.09 12L12 2.09 21.91 12H20v1.05a6.555 6.555 0 00-2-2.023v-.117l-6-6-6 6V19h2.492c.315.787.8 1.528 1.508 2H4v-9H2.09z"
    />
    <path
      fill={props.colorPrimary}
      fillRule="evenodd"
      d="M21.39 22L20 23.39l-3.12-3.07c-.69.43-1.51.68-2.38.68-2.5 0-4.5-2-4.5-4.5s2-4.5 4.5-4.5 4.5 2 4.5 4.5c0 .88-.25 1.71-.69 2.4l3.08 3.1zM17 16.5a2.5 2.5 0 00-5 0 2.5 2.5 0 005 0z"
      clipRule="evenodd"
    ></path>
  </svg>
);

export default FlippabilitySearch;
