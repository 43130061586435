import React from "react";

const arrowUp = props => (
  <svg
    className={props.className}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
  >
    <path
      fill={props.colorPrimary}
      d="M7.41,15.41L12,10.83L16.59,15.41L18,14L12,8L6,14L7.41,15.41Z"
    />
  </svg>
);

export default arrowUp;
