import React from "react";

function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 421 352">
      <path
        fill="#ACB7BD"
        d="M112 71.8a4.8 4.8 0 110-9.599 4.8 4.8 0 010 9.599zm0-10.8a6 6 0 100 12 6 6 0 000-12zM271 75.8a4.8 4.8 0 110-9.599 4.8 4.8 0 010 9.599zm0-10.8a6 6 0 100 12 6 6 0 000-12zM84 184.571h-3.857V188h-1.286v-3.429H75v-1.142h3.857V180h1.286v3.429H84v1.142zM179 69.143h-3.857V73h-1.286v-3.857H170v-1.286h3.857V64h1.286v3.857H179v1.286zM332 110.143h-3.857V114h-1.286v-3.857H323v-1.286h3.857V105h1.286v3.857H332v1.286zM329 180a5.99 5.99 0 00-4.243 1.757 6.003 6.003 0 000 8.486A6.003 6.003 0 00335 186a5.99 5.99 0 00-1.757-4.243A6.003 6.003 0 00329 180zM68 112a5.992 5.992 0 00-4.243 1.757 6.002 6.002 0 001.947 9.786A6 6 0 1068 112z"
      ></path>
      <g filter="url(#filter0_d)">
        <path
          fill="#E3E7EC"
          fillRule="evenodd"
          d="M191.378 110.525l115.226 41.6c7.792 2.813 11.828 11.41 9.015 19.202l-15.961 44.207c-2.813 7.792-11.41 11.828-19.202 9.015l-115.226-41.6a15 15 0 01-7.908-6.628l-18.142-31.535c-4.626-8.04-.919-18.31 7.776-21.541l34.103-12.672a14.999 14.999 0 0110.319-.048zm-34.68 23.05a5.982 5.982 0 014.319 1.559 5.998 5.998 0 01-1.492 9.866 6.002 6.002 0 01-7.981-2.882 6.002 6.002 0 015.154-8.543z"
          clipRule="evenodd"
        ></path>
      </g>
      <rect
        width="114"
        height="5"
        x="180.936"
        y="155.66"
        fill="#C7D1D9"
        rx="2.5"
        transform="rotate(19.851 180.936 155.66)"
      ></rect>
      <rect
        width="56"
        height="5"
        x="187.387"
        y="137.789"
        fill="#C7D1D9"
        rx="2.5"
        transform="rotate(19.851 187.387 137.789)"
      ></rect>
      <g filter="url(#filter1_d)">
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M143.495 171h122.506c8.284 0 15 6.716 15 15v47c0 8.284-6.716 15-15 15H143.495a15 15 0 01-9.689-3.549l-27.772-23.5c-7.082-5.992-7.082-16.91 0-22.902l27.772-23.5a15.006 15.006 0 019.689-3.549zm-24.791 33.457a5.99 5.99 0 014.592 0 6.003 6.003 0 013.247 3.247 6.003 6.003 0 01-1.3 6.539 6.003 6.003 0 01-8.486 0 6.003 6.003 0 010-8.486 6.003 6.003 0 011.947-1.3z"
          clipRule="evenodd"
        ></path>
      </g>
      <rect
        width="114"
        height="5"
        x="149"
        y="217"
        fill="#E3E7EB"
        rx="2.5"
      ></rect>
      <rect
        width="56"
        height="5"
        x="149"
        y="198"
        fill="#C7D1D9"
        rx="2.5"
      ></rect>
      <defs>
        <filter
          id="filter0_d"
          width="395.713"
          height="333.643"
          x="25"
          y="0"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="4"></feOffset>
          <feGaussianBlur stdDeviation="50"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0.67451 0 0 0 0 0.717647 0 0 0 0 0.741176 0 0 0 0.5 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow"
            result="shape"
          ></feBlend>
        </filter>
        <filter
          id="filter1_d"
          width="380.278"
          height="277"
          x="0.723"
          y="75"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="4"></feOffset>
          <feGaussianBlur stdDeviation="50"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0.67451 0 0 0 0 0.717647 0 0 0 0 0.741176 0 0 0 0.5 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow"
            result="shape"
          ></feBlend>
        </filter>
      </defs>
    </svg>
  );
}

export default Icon;
