import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

//redux stuff
import { connect } from "react-redux";
import {
  postForumPost,
  clearErrors,
} from "../../../../redux/actions/dataActions";

//components
import TopicInput from "./components/TopicInput";
import PostTextInput from "./components/PostTextInput";
import PostTitleinput from "./components/PostTitleinput";
import Spinner from "../../../loading/Spinner";
import LocationLft from "../../../../media/SVGs/location-back-lft";
import LocationRit from "../../../../media/SVGs/location-back-rit";
import YourLocation from "../../../../media/icons/YourLocation";
import MapIcon from "../../../../media/icons/MapIcon";

//for IMPUT
import Bold from "../../../../media/icons/richText/Bold";
import Italic from "../../../../media/icons/richText/Italic";
import Underline from "../../../../media/icons/richText/Underline";
import UnorderList from "../../../../media/icons/richText/UnorderList";
import OrderList from "../../../../media/icons/richText/OrderList";
import AddImage from "../../../../media/icons/richText/AddImage";
import AddLink from "../../../../media/icons/richText/AddLink";
import ImageLink from "../../../../media/icons/Link";

//city autocomplete
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";

class PostPop extends Component {
  constructor(props) {
    super(props);
    this.state = {
      topics: [],
      title: "",
      post: "",
      locatonModal: false,
      focused: false,
      address: "",
      selected: "",
      latLng: "",
    };
    this.adressInput = React.createRef();
  }

  setTopics = (topics) => {
    this.setState({ topics });
  };
  setext = (post) => {
    this.setState({
      post: {
        styled: JSON.stringify(post.styled),
        plane: post.raw,
      },
    });
  };
  seTitle = (title) => {
    this.setState({ title });
  };

  addlocation = (e) => {
    e.preventDefault();
    this.setState({ locatonModal: !this.state.locatonModal });
    setTimeout((func) => this.adressInput.focus(), 75);
  };

  handleChange = (address) => {
    this.setState({ address });
  };
  handleSelect = (address) => {
    geocodeByAddress(address)
      .then((results) => {
        this.setState({
          address: "",
          selected: results[0].formatted_address,
          locatonModal: false,
        });
        return getLatLng(results[0]);
      })
      .then((latLng) => {
        this.setState({ latLng: [latLng.lat, latLng.lng] });
      })
      .catch((error) => console.error("Error", error));
  };

  handleSubmit = (event) => {
    event.preventDefault();

    let postData = {
      docType: "Post",
      topics: this.state.topics,
      title: this.state.title,
      body: this.state.post,
      address: this.state.selected,
      latLang: this.state.latLng,
    };

    console.log(postData);

    this.props.postForumPost(postData, this.props.history);
  };

  render() {
    // const { errors } = this.state;
    const {
      classes,
      UI: { loading, errors },
    } = this.props;

    let errorMsg = (
      <div className="post-error-message centered">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          fill="none"
          viewBox="0 0 24 24"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M10.29 3.86L1.82 18a2 2 0 001.71 3h16.94a2 2 0 001.71-3L13.71 3.86a2 2 0 00-3.42 0v0zM12 9v4M12 17h.01"
          ></path>
        </svg>
        {!errors ? "" : errors.error}
      </div>
    );
    let locatonModal = (
      <div
        className="post-loc-input-wrap"
        style={{ display: !this.state.locatonModal ? "none" : "flex" }}
        onClick={(e) =>
          this.setState({ locatonModal: !this.state.locatonModal })
        }
      >
        <div
          className="post-loc-input-crd anim-up "
          onClick={(e) => e.stopPropagation()}
        >
          <div
            className="post-loc-input-crd-hed"
            style={!this.state.focused ? {} : { border: "1px solid #039be5" }}
          >
            <MapIcon size="24" />
            <PlacesAutocomplete
              value={this.state.address}
              onChange={this.handleChange}
              onSelect={this.handleSelect}
            >
              {({
                getInputProps,
                suggestions,
                getSuggestionItemProps,
                loading,
              }) => (
                <div style={{ width: "100%" }}>
                  <input
                    type="text"
                    id="locationInput"
                    name="locationInput"
                    {...getInputProps({
                      placeholder: "Enter a location...",
                      className: "location-search-input",
                    })}
                    style={{
                      color: !this.state.latLng ? "#28323c" : "#34a8fe",
                    }}
                    ref={(node) => (this.adressInput = node)}
                    onFocus={(e) => this.setState({ focused: true })}
                    onBlur={(e) => this.setState({ focused: false })}
                  />
                  <div
                    className={`autocomplete-dropdown-container form-input-suggestions ${
                      suggestions.length > 1 ? "autocomplet-drop-shadow" : ""
                    }`}
                  >
                    {loading && <div>Loading...</div>}
                    {suggestions.map((suggestion) => {
                      const className = suggestion.active
                        ? "suggestion-item--active"
                        : "suggestion-item";
                      // inline style for demonstration purpose
                      const style = suggestion.active
                        ? {
                            backgroundColor: "#f4f6f8",
                            cursor: "pointer",
                          }
                        : {
                            backgroundColor: "#ffffff",
                            cursor: "pointer",
                          };
                      return (
                        <div
                          {...getSuggestionItemProps(suggestion, {
                            className,
                            style,
                          })}
                        >
                          <span>{suggestion.description}</span>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </PlacesAutocomplete>
            <div onClick={(e) => this.adressInput.focus()}>
              <YourLocation size="24" />
            </div>
          </div>
          <div className="post-location-pop-back">
            <div className="">Give your post some geographic context</div>
            <LocationLft />
            <LocationRit />
          </div>
        </div>
      </div>
    );
    const toolbarData = [
      {
        action: "DIV1",
        type: "divider",
      },
      {
        action: "BOLD",
        lable: Bold,
        type: "style",
      },
      {
        action: "ITALIC",
        lable: Italic,
        type: "style",
      },
      {
        action: "UNDERLINE",
        lable: Underline,
        type: "style",
      },
      {
        action: "DIV2",
        type: "divider",
      },
      {
        action: "ORDERLIST",
        lable: OrderList,
        type: "block",
        style: "ordered-list-item",
      },
      {
        action: "UNORDER",
        lable: UnorderList,
        type: "block",
        style: "unordered-list-item",
      },
      {
        action: "DIV3",
        type: "divider",
      },
      {
        action: "IMAGE",
        lable: AddImage,
        onClick: "ImagePop",
      },
      {
        action: "LINK",
        lable: AddLink,
        type: "style",
        onClick: "LinkPop",
      },
      {
        action: "SPCER",
        type: "spacer",
      },
    ];

    return (
      <div>
        {locatonModal}
        <div className="content-pop-content">
          <div className="topic-container">
            <div className="topic-lable pop-lable">Topics</div>
            <TopicInput passTopics={this.setTopics} />
          </div>
          <div className="title-container">
            <div className="title-lable pop-lable">Title</div>
            <PostTitleinput passTitle={this.seTitle} title={"Title"} />
          </div>
          <div className="text-container">
            <div className="text-lable pop-lable">Content</div>
            <PostTextInput
              passText={this.setext}
              menu={true}
              toolbarData={toolbarData}
            />
          </div>
        </div>
        <form
          className="outskrts content-pop-footer"
          onSubmit={this.handleSubmit}
        >
          <div>{!errors ? "" : errorMsg}</div>
          <div className="centered">
            <button
              onClick={(e) => {
                this.addlocation(e);
              }}
              className="button button-white "
            >
              {!this.state.selected
                ? "Add Location"
                : `${this.state.selected.substring(0, 20)}...`}
            </button>
            <button
              type="submit"
              className="button button-blue post-submission-button"
              style={{ marginLeft: "12px" }}
            >
              {loading ? (
                <Spinner color="#fff" bordWidth="3px" size="16px" />
              ) : (
                "Submit"
              )}
            </button>
          </div>
        </form>
      </div>
    );
  }
}

postForumPost.propTypes = {
  postForumPost: PropTypes.func.isRequired,
  clearErrors: PropTypes.func.isRequired,
  UI: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  UI: state.UI,
});

export default connect(mapStateToProps, { postForumPost, clearErrors })(
  withRouter(PostPop)
);
