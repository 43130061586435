import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import debounce from "lodash.debounce";

// import axios from "axios";
import axios from "axios";
import PropTypes from "prop-types";

//firebase
import firebase from "../firebase";

//styling
import "./AuthStyle.css";
import "../components/cards/cardStyle.css";

//Components
import Button from "../components/buttons/Button";
import ErrHandler from "../components/errors/ErrHandler";
import Spinner from "../components/loading/Spinner";
import AuthBack from "../components/backgrounds/AuthBack";
import placeholder from "../media/images/placeholder.jpg";

//redux stuff
import { connect } from "react-redux";
import {
  signupUser,
  loginUser,
  profileCreate,
  uploadImage,
  loginGoogle,
} from "../redux/actions/userActions";

//icons
import Google from "../media/icons/Google";
import Facebook from "../media/icons/Facebook";
import Twitter from "../media/icons/Twitter";
import Github from "../media/icons/Github";
import Plus from "../media/icons/Plus";
import Information from "../media/icons/Information";

class signin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      passStrength: "",
      confirmPassword: "",
      emailSignin: "",
      passwordSignin: "",
      username: "",
      usernameMsg: "",
      usernameValididty: false,
      loading: false,
      errors: "",
      path: this.pathChoser(this.props.location.pathname),
      image: "",
      imageFile: "",
    };
    this.usernameAvalibility = debounce(this.usernameAvalibility, 250);
  }

  //page handlers
  componentDidMount() {
    let display = this.props.location.pathname;
    this.setState({
      path: this.pathChoser(display),
    });
  }
  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      let display = this.props.location.pathname;
      this.setState({
        path: this.pathChoser(display),
        errors: "",
      });
    }
    if (this.props.errors !== prevProps.errors) {
      this.setState({ errors: this.props.errors });
    }
  }
  pathChoser(display) {
    if (display.includes("/auth/signin")) return "signin";
    if (display == "/auth/signup/profile") return "profile";
    return "signup";
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.UI.errors) {
      this.setState({
        errors: nextProps.UI.errors,
      });
    }
  }

  //input handlers
  passwordStrength = (input) => {
    let weak = /^.{6,}$/;
    let med = /^(?=.*[A-Z])(?=.*[0-9])(?=.*[a-z]).{6,}/;
    let strong = /^(?=.*[A-Z])(?=.*[!@#$&*])(?=.*[0-9])(?=.*[a-z]).{8,}$/;

    if (!input) return "";
    if (input.match(strong)) return "Strong";
    if (input.match(med)) return "Medium";
    return "Weak";
  };
  emailValidaiton = (input) => {
    let emailCheck = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    return input.match(emailCheck) ? true : false;
  };
  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
  };
  handlePassChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
      passStrength: this.passwordStrength(event.target.value),
    });
  };
  onImageChange = (event) => {
    const image = event.target.files[0];
    if (event.target.files && event.target.files[0]) {
      const image = event.target.files[0];
      let reader = new FileReader();
      const formData = new FormData();
      formData.append("image", image, image.name);

      reader.onload = (e) => {
        this.setState({
          image: e.target.result,
          imageFile: formData,
        });
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  };
  handleUsernameInput = (event) => {
    let input = event.target.value.replace(/\s|[^\w\s]/g, "");
    this.setState({ username: input });
    this.usernameAvalibility(input);
  };

  usernameAvalibility = (input) => {
    //check avalivility and validation
    // let regEx = /^(?=.{4,20}$)(?![_.])(?!.*[_.]{2})[a-zA-Z0-9._]+(^[_.])$/; //does not work
    // let regEx = /^(?=.{4,20}$)(?![_.])(?!.*[_.]{2})[a-zA-Z0-9._]+(?<![_.])$/; //caises blank screen on safari
    let regEx = /^[a-zA-Z0-9]{4,20}$/;

    let usernamePkc = { username: input };

    if (input.match(regEx)) {
      console.log("checking username");
      axios.post("/signup/usernameCheck", usernamePkc).then((res) => {
        console.log("checked");
        return this.setState({
          usernameMsg: res.data.username,
          usernameValididty: res.data.username === "Available" ? true : false,
        });
      });
    } else {
      this.setState({
        usernameMsg: "4-20 characters",
        usernameValididty: false,
      });
    }
  };

  //submission
  handleSubmit = (event) => {
    event.preventDefault();

    if (this.state.path !== "signin") {
      if (!this.state.password || !this.state.email) {
        let errorObject = { message: "Please fill out all required fields" };
        return this.setState({ errors: errorObject });
      }
      if (this.state.password !== this.state.confirmPassword) {
        let errorObject = { message: "Passwords do not match" };
        return this.setState({ errors: errorObject });
      }
      if (["Strong", "Medium"].indexOf(this.state.passwordStrength) > -1) {
        let errorObject = {
          message:
            "Please chose a password with at least 6 characters, 1 number, and a combination of uppercase and lowercase letters",
        };
        return this.setState({ errors: errorObject });
      }
      const newUserData = {
        email: this.state.email,
        password: this.state.password,
        confirmPassword: this.state.confirmPassword,
      };
      this.props.signupUser(newUserData);
    } else {
      const userData = {
        email: this.state.emailSignin,
        password: this.state.passwordSignin,
      };
      this.props.loginUser(userData);
      // .then(res => {
      //   this.props.history.push("/forum");
      // });
    }
  };
  handleProfileSubmit = (event) => {
    event.preventDefault();

    //get the image url if ther is one
    const imageUrl = this.state.imageFile
      ? ""
      : document.getElementById("profile-img-prev").src;

    const payload = {
      userData: {
        username: this.state.username,
        imageUrl: imageUrl,
      },
      formData: this.state.imageFile,
    };
    console.log(payload);
    let history = this.props.history;
    console.log(history);
    this.props.profileCreate(payload, history);
  };
  handleGoogleAuth = (event) => {
    this.props.loginGoogle();
  };

  render() {
    const {
      UI: { loading },
      user: { loadingUser },
    } = this.props;
    const { errors, image } = this.state;
    let emailCheck = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

    let authUser = firebase.auth().currentUser;
    let authUserImg =
      authUser && authUser.photoURL ? authUser.photoURL : placeholder;
    let imageSrc = !image ? authUserImg : image;

    const signupForm = (
      <form className="signup-form" onSubmit={this.handleSubmit}>
        <ErrHandler errMsg={this.state.errors.message} />
        <ErrHandler errMsg={this.state.errors.username} />
        <input
          className="auth-email"
          id="email"
          name="email"
          type="email"
          label="Email"
          placeholder="email"
          value={this.state.email}
          onChange={this.handleChange}
          style={{
            border: `1px solid ${
              !this.state.email
                ? "rgba(255, 255, 255, 0)"
                : !this.state.email.match(emailCheck)
                ? "#f92953"
                : "#02bfa6"
            }`,
          }}
        />
        <ErrHandler errMsg={this.state.errors.email} />
        <input
          className="auth-pass"
          id="password-signup"
          name="password"
          type="password"
          label="Password"
          placeholder="Password"
          value={this.state.password}
          onChange={this.handlePassChange}
          style={{
            border: `1px solid ${
              !this.state.passStrength
                ? "rgba(255, 255, 255, 0)"
                : this.state.passStrength == "Strong"
                ? "#02bfa6"
                : this.state.passStrength == "Medium"
                ? "#FFCB2B"
                : "#f92953"
            }`,
          }}
        />
        <div className="password-feedbask-container outskrts">
          <div className="">
            Strength:{"  "}
            <span
              style={{
                marginLeft: "2px",
                fontWeight: 500,
                color: !this.state.passStrength
                  ? "rgba(255, 255, 255, 0.1)"
                  : this.state.passStrength == "Strong"
                  ? "#02bfa6"
                  : this.state.passStrength == "Medium"
                  ? "#FFCB2B"
                  : "#f92953",
              }}
            >
              {!this.state.passStrength ? "Password" : this.state.passStrength}
            </span>
          </div>
          <Information colorPrimary="rgba(255, 255, 255, 0.1)" size="20px" />
        </div>

        <ErrHandler errMsg={this.state.errors.password} />
        <input
          className="auth-pass"
          id="confirmPassword"
          name="confirmPassword"
          type="password"
          label="Confirm Password"
          placeholder="Re-enter password"
          value={this.props.searchString}
          onChange={this.handleChange}
          style={{
            border: `1px solid ${
              !this.state.confirmPassword
                ? "rgba(255, 255, 255, 0)"
                : this.state.password !== this.state.confirmPassword
                ? "#f92953"
                : "#02bfa6"
            }`,
          }}
        />
        <ErrHandler errMsg={this.state.errors.confirmPassword} />
        <div className="signup-disclaimer">
          By continuing, you are accepting our Terms of Service and Privacy
          Policy.
        </div>
        <div className="auth-button-wrap">
          <button
            className="button button-submit pop-b-adj auth-sign-in"
            type="submit"
            name="button"
          >
            {loading ? (
              <div className="">
                <Spinner color="#fff" bordWidth="3px" size="16px" />
              </div>
            ) : (
              "Sign up"
            )}
          </button>
        </div>
      </form>
    );
    const signinForm = (
      <form
        className="signin-form active-auth-form"
        onSubmit={this.handleSubmit}
      >
        <ErrHandler errMsg={this.state.errors.message} />
        <input
          className="auth-email"
          id="emailSignin"
          name="emailSignin"
          type="email"
          label="Email"
          placeholder="email"
          value={this.state.emailSignin}
          onChange={this.handleChange}
        />
        <ErrHandler errMsg={this.state.errors.email} />
        <input
          className="auth-pass"
          id="passwordSignin"
          name="passwordSignin"
          type="password"
          label="Password"
          placeholder="Password"
          value={this.state.passwordSignin}
          onChange={this.handleChange}
        />
        <ErrHandler errMsg={this.state.errors.password} />

        <div className="auth-button-wrap">
          <button
            className="button button-submit pop-b-adj auth-sign-in"
            type="submit"
            name="button"
            style={{ marginTop: "16px" }}
          >
            {loading ? (
              <div className="">
                <Spinner color="#fff" bordWidth="3px" size="16px" />
              </div>
            ) : (
              "Sign in"
            )}
          </button>
        </div>
      </form>
    );
    const signinup = (
      <div className="">
        <div className="signin-up-wrap centered">
          <Link to="/auth/signin" className="signin-up-button centered">
            Sign in
          </Link>
          <Link to="/auth/signup" className="signin-up-button centered">
            Sign up
          </Link>
        </div>
        <div
          className="signin-up-hilight"
          style={{ marginLeft: this.state.path !== "signin" ? "50%" : "0" }}
        ></div>
        <div className="third-party-wrap">
          {!this.state.signin ? "Sign up with" : "Sign in with"}
          <div className="third-party-button-wrap">
            <div
              className="button button-silver"
              onClick={this.handleGoogleAuth}
            >
              <Google className="" colorPrimary="#fff" size="24px" />
            </div>
            <Button className="button button-silver">
              <Facebook className="" colorPrimary="#fff" size="24px" />
            </Button>
            <Button className="button button-silver">
              <Twitter className="" colorPrimary="#fff" size="24px" />
            </Button>
            <Button className="button button-silver">
              <Github className="" colorPrimary="#fff" size="24px" />
            </Button>
          </div>
        </div>
        <div className="signin-divider centered">
          <div className="signin-dividerline"></div>
          <div className="signin-divider-or">&nbsp; OR &nbsp;</div>
        </div>
        <div className="signin-up-form-wrap"></div>
        {this.state.path !== "signin" ? signupForm : signinForm}
      </div>
    );
    const profileInput = (
      <div className="">
        <div className="signin-up-wrap">
          <div className="centered" style={{ height: "36px" }}>
            Profile
          </div>
        </div>
        <form className="signup-form" onSubmit={this.handleProfileSubmit}>
          <div className="centered-col">
            <div className="signup-img-cont">
              <div className="signup-img-wrap">
                <img id="profile-img-prev" src={imageSrc} alt="Profile Pic" />
              </div>
              <div className="signup-imgChng-wrap centered-col">
                <div className="signup-imgChng-button centered-col">
                  <Plus colorPrimary="#fff" size="24px" />
                </div>
                <input
                  type="file"
                  id="avatar"
                  name="image"
                  accept="image/png, image/jpeg"
                  onChange={this.onImageChange}
                />
              </div>
            </div>
          </div>
          <div className="outskrts">
            <div className="errorMessage-wrap">Username</div>
            <div
              className="errorMessage-wrap"
              style={{
                color: this.state.usernameValididty ? "#02bfa6" : "#f92953",
              }}
            >
              {this.state.usernameMsg}
            </div>
          </div>

          <input
            autoComplete="off"
            value={this.state.username}
            className="auth-pass"
            style={{
              marginBottom: "24px",
              border: this.state.usernameValididty ? "1px solid #02bfa6" : "",
            }}
            id="username"
            name="username"
            type="username"
            label="Username"
            placeholder="Enter a username..."
            onChange={this.handleUsernameInput}
          />
          <div className="auth-button-wrap">
            <button
              disabled={!this.state.usernameValididty}
              className={`button pop-b-adj auth-sign-in usernam-button-sub ${
                this.state.usernameValididty ? "button-submit" : "button-silver"
              }`}
              type="submit"
              name="button"
              style={{
                color: this.state.usernameValididty ? "#fff" : "#28323c",
              }}
            >
              {loading ? (
                <div className="">
                  <Spinner color="#fff" bordWidth="3px" size="16px" />
                </div>
              ) : (
                "Continue"
              )}
            </button>
          </div>
        </form>
      </div>
    );

    return (
      <div className="auth-page-wrapper centered-col">
        <AuthBack />
        <div className="card card-shdow-sml signin-container anim-up">
          {this.state.path !== "profile" ? signinup : profileInput}
        </div>
      </div>
    );
  }
}

signin.propTypes = {
  // loginGoogle: PropTypes.object.isRequired,
  loginGoogle: PropTypes.func.isRequired,
  loginUser: PropTypes.func.isRequired,
  signupUser: PropTypes.func.isRequired,
  uploadImage: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  UI: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user,
  UI: state.UI,
});

const mapActionsToProps = {
  signupUser,
  loginUser,
  profileCreate,
  uploadImage,
  loginGoogle,
};

export default connect(mapStateToProps, {
  signupUser,
  loginUser,
  profileCreate,
  uploadImage,
  loginGoogle,
})(withRouter(signin));
