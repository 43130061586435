import React, { Component } from "react";

//Styling
import "./FilterColumnStyle.css";

//icons
import Filter from "../../../media/icons/Filter";
import Collapse from "../../../media/icons/ChevronDown";
import Checkbox from "../../../media/icons/Square";
import CheckedCheckbox from "../../../media/icons/CheckedCheckbox";

class ContSection extends Component {
  constructor(props) {
    super(props);
    this.state = { collapsed: false, more: false };
  }
  toggleTopic = topic => {
    this.setState({
      [topic]: !this.state[topic]
    });
  };
  compare = (a, b) => {
    if (a.follows < b.follows) {
      return -1;
    }
    if (a.follows > b.follows) {
      return 1;
    }
    return 0;
  };

  render() {
    const { collapsed } = this.state;
    const { topics, title } = this.props;

    let shortList = topics
      .sort(this.compare)
      .slice(0, 5)
      .sort();
    let activeArr = topics.length < 5 || this.state.more ? topics : shortList;

    let chekBoxItem = activeArr.map(topic => (
      <div
        key={Math.random()}
        className={`filter-col-cont-blk-cnt outskrts ${
          this.state[topic.label.replace(" ", "")] ? "selected-filter" : ""
        }`}
        onClick={e => this.toggleTopic([topic.label.replace(" ", "")])}
      >
        <div className="centered">
          {this.state[topic.label.replace(" ", "")] ? (
            <CheckedCheckbox size="24" />
          ) : (
            <Checkbox size="24" colorPrimary="#949ca6" />
          )}

          <div>{topic.label}</div>
        </div>
        <div>{topic.follows}</div>
      </div>
    ));

    let height =
      activeArr.length < 5
        ? activeArr.length * 32 + 24
        : activeArr.length * 32 + 60;

    return (
      <div className="">
        <div className="filter-col-cont-blk">
          <div
            className="filter-col-cont-blk-hd outskrts"
            onClick={e => this.setState({ collapsed: !collapsed })}
          >
            <div>{title}</div>
            <Collapse size="24" />
          </div>
          <div
            className="filter-col-cont-blk-cnt-wrap"
            style={{
              height: !collapsed ? `${height}px` : "0px"
            }}
          >
            {chekBoxItem}
            {topics.length < 5 ? (
              ""
            ) : (
              <div
                className="filter-topics-more centered-col"
                onClick={e => this.setState({ more: !this.state.more })}
              >
                <div className="filter-topics-more-lin"></div>
                <div className="filter-topics-more-cont centered-col">
                  {!this.state.more ? "more" : "less"}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default ContSection;
