import React, { Component } from "react";

import NoFlips from "../../media/SVGs/NoFlips";

//style
import "./ForumContStyle.css";

class Messages extends Component {
  render() {
    return (
      <div className="posts-container">
        <div className="No-content noContent-anim ">
          <div style={{ marginBottom: "-32px" }}>
            <NoFlips />
          </div>
          <div className="nocont-text">No public flips</div>
          <p>...maybe they're just private</p>
        </div>
      </div>
    );
  }
}

export default Messages;
