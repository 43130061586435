import React from "react";

function ProductsFind() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"

      fill="none"
      viewBox="0 0 525 525"
    >
      <circle cx="262.5" cy="262.5" r="262.5" fill="#F6FCFE"></circle>
      <mask
        id="a"
        width="525"
        height="525"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <circle cx="262.5" cy="262.5" r="262.5" fill="#C4C4C4"></circle>
      </mask>
      <g mask="url(#a)">
        <path
          fill="#FCFEFF"
          d="M357 523.5C268.5 471 370.501 453 308 394h-91.999c62.501 59-82.001 76-44 142 91.505 21.647 141.345 16.389 184.999-12.5z"
        ></path>
        <path
          fill="#88CCEF"
          fillRule="evenodd"
          d="M45.053 215.837h12.316L28.556 251.51c-1.584 1.962-.188 4.885 2.334 4.885h17.018l-33.869 39.408c-1.672 1.946-.29 4.956 2.275 4.956h54.554v89.051h30.953v-89.051h50.167c2.565 0 3.947-3.01 2.275-4.956l-33.869-39.408h17.131c2.522 0 3.919-2.923 2.334-4.885l-28.813-35.673h12.316c2.382 0 3.814-2.643 2.513-4.639L87.857 152.89c-.881-1.352-2.421-1.635-3.65-1.139-1.228-.496-2.768-.213-3.65 1.139L42.54 211.198c-1.301 1.996.13 4.639 2.513 4.639z"
          clipRule="evenodd"
        ></path>
        <circle cx="58.5" cy="350.5" r="43.5" fill="#9EDAF7"></circle>
        <path
          stroke="#FCFEFF"
          strokeLinecap="round"
          strokeWidth="3"
          d="M57.787 324.115v19.61m0 35.3v-14.263m0 0l12.123-12.123m-12.123 12.123v-21.037m0 0l-12.123-12.479"
        ></path>
      </g>
      <path fill="#B5E6FC" d="M97 110H427V394H97z"></path>
      <path
        fill="#88CCEF"
        d="M432.225 95.405l-31.384-68a5.001 5.001 0 00-4.54-2.905H127.199a5.001 5.001 0 00-4.54 2.905l-31.384 68c-1.53 3.313.89 7.095 4.54 7.095h331.87c3.649 0 6.069-3.782 4.54-7.095z"
      ></path>
      <path
        fill="#D0EEFD"
        d="M86 106h350v2a5 5 0 01-5 5H91a5 5 0 01-5-5v-2z"
      ></path>
      <rect width="360" height="13" x="81" y="93" fill="#FCFEFF" rx="5"></rect>
      <path fill="#FCFEFF" d="M128 258H169V327H128z"></path>
      <path fill="#88CCEF" d="M114 261H128V324H114z"></path>
      <path fill="#88CCEF" d="M169 261H183V324H169z"></path>
      <path fill="#34A8FE" d="M128 289H169V296H128z"></path>
      <path fill="#D0EEFD" d="M241 258H282V352H241z"></path>
      <path fill="#88CCEF" d="M227 258H241V352H227z"></path>
      <path fill="#88CCEF" d="M282 258H296V352H282z"></path>
      <path
        fill="#B5E6FC"
        stroke="#9EDAF7"
        strokeWidth="4"
        d="M261.5 326c2.015 0 3.749-.997 5.129-2.41 1.373-1.407 2.518-3.338 3.437-5.563C271.908 313.568 273 307.547 273 301c0-6.547-1.092-12.568-2.934-17.027-.919-2.225-2.064-4.156-3.437-5.563-1.38-1.413-3.114-2.41-5.129-2.41-2.015 0-3.749.997-5.129 2.41-1.373 1.407-2.518 3.338-3.437 5.563C251.092 288.432 250 294.453 250 301c0 6.547 1.092 12.568 2.934 17.027.919 2.225 2.064 4.156 3.437 5.563 1.38 1.413 3.114 2.41 5.129 2.41z"
      ></path>
      <path fill="#FCFEFF" d="M354 258H395V327H354z"></path>
      <path fill="#88CCEF" d="M340 261H354V324H340z"></path>
      <path fill="#88CCEF" d="M395 261H409V324H395z"></path>
      <path fill="#34A8FE" d="M354 289H395V296H354z"></path>
      <path fill="#FCFEFF" d="M241 121H282V190H241z"></path>
      <path fill="#88CCEF" d="M227 124H241V187H227z"></path>
      <path fill="#88CCEF" d="M282 124H296V187H282z"></path>
      <path fill="#34A8FE" d="M241 152H282V159H241z"></path>
      <path fill="#FCFEFF" d="M128 121H169V190H128z"></path>
      <path fill="#88CCEF" d="M114 124H128V187H114z"></path>
      <path fill="#88CCEF" d="M169 124H183V187H169z"></path>
      <path fill="#34A8FE" d="M128 152H169V159H128z"></path>
      <path fill="#FCFEFF" d="M354 121H395V190H354z"></path>
      <path fill="#88CCEF" d="M340 124H354V187H340z"></path>
      <path fill="#88CCEF" d="M395 124H409V187H395z"></path>
      <path fill="#34A8FE" d="M354 152H395V159H354z"></path>
      <rect width="49" height="19" x="151" fill="#34A8FE" rx="5"></rect>
      <path fill="#B5E6FC" d="M157 19H194V59H157z"></path>
      <path
        fill="#88CCEF"
        d="M215 384a5 5 0 015-5h83a5 5 0 015 5v10h-93v-10z"
      ></path>
      <path
        fill="#9EDAF7"
        d="M222 368a5 5 0 015-5h69a5 5 0 015 5v11h-79v-11z"
      ></path>
      <path
        fill="#B5E6FC"
        d="M229 353a5 5 0 015-5h55a5 5 0 015 5v10h-65v-10z"
      ></path>
      <circle cx="107.5" cy="381.5" r="30.5" fill="#34A8FE"></circle>
      <path
        stroke="#FCFEFF"
        strokeLinecap="round"
        strokeWidth="3"
        d="M107 363v13.75m0 24.75v-10m0 0l8.5-8.5m-8.5 8.5v-14.75m0 0L98.5 368"
      ></path>
      <circle cx="435.5" cy="381.5" r="30.5" fill="#34A8FE"></circle>
      <path
        stroke="#FCFEFF"
        strokeLinecap="round"
        strokeWidth="3"
        d="M435 363v13.75m0 24.75v-10m0 0l8.5-8.5m-8.5 8.5v-14.75m0 0l-8.5-8.75"
      ></path>
    </svg>
  );
}

export default ProductsFind;
