import React from "react";

const Facebook = props => (
  <svg
    className={props.className}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
  >
    <path
      fill={props.colorPrimary}
      d="M21,21h-4v-6.75c0-1.06-1.19-1.94-2.25-1.94S13,13.19,13,14.25V21H9V9h4v2c0.66-1.07,2.36-1.76,3.5-1.76
      	c2.5,0,4.5,2.04,4.5,4.51V21z M7,21H3V9h4V21z M5,3c0.53,0,1.04,0.21,1.41,0.59C6.79,3.96,7,4.47,7,5S6.79,6.04,6.41,6.41
      	C6.04,6.79,5.53,7,5,7S3.96,6.79,3.59,6.41C3.21,6.04,3,5.53,3,5s0.21-1.04,0.59-1.41C3.96,3.21,4.47,3,5,3L5,3z"
    />
  </svg>
);

export default Facebook;
