import React from "react";

const planCard = (props) => {
  const {
    bodyTxt,
    preData: { url, title, favicon, image, description, author },
  } = props;
  let shortUrl = url.split("/")[2];
  return (
    <div>
      <div
        className="forum-card-link-prev-wrap outskrts"
        onClick={(e) => {
          e.stopPropagation();
          var win = window.open(url, "_blank");
          win.focus();
        }}
      >
        <div className="LinkPreview-image-container">
          <img src={image} alt="LinkPreview" />
          <div className="LinkPreview-url-container centered-col">
            <p style={{ color: "#fff" }}>{shortUrl}</p>
          </div>
        </div>

        <div className="forum-card-link-prev-text-wrap ">
          <h4>{title}</h4>
          <p className=".desktop-only">
            {description.length > 100
              ? description.substring(0, 100) + "..."
              : description}
          </p>
        </div>
      </div>
    </div>
  );
};

export default planCard;

// <p className=".desktop-only">
//   {description.length > 100
//     ? description.substring(0, 100) + "..."
//     : description}
// </p>

//  <p>{bodyTxt.length > 70 ? bodyTxt.substring(0, 70) + "..." : bodyTxt}</p>
