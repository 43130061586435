import React from "react";

function ProductsFlip() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"

      fill="none"
      viewBox="0 0 525 525"
    >
      <circle cx="262.5" cy="262.5" r="262.5" fill="#F6FCFE"></circle>
      <mask
        id="a"
        width="525"
        height="525"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <circle cx="262.5" cy="262.5" r="262.5" fill="#F6FCFE"></circle>
      </mask>
      <g mask="url(#a)">
        <path
          fill="#FCFEFF"
          d="M170.469 536c91.505 21.647 141.346 16.389 185-12.5-67.556-40.076-24.517-67.548-27.969-101 0 0 52.5-29.94 121.5-7.22 69 22.72 64-7.78 64-7.78v-25h-73L306.469 394H21.5l9.5 9.5c45.27 13.872 102.987 8.383 139.469.441 27.031-5.885 59.824 2.059 59.904 18.559.185 38.278-91.012 59.47-59.904 113.5z"
        ></path>
        <path fill="#B5E6FC" d="M97 110H427V371H97z"></path>
        <path fill="#FCFEFF" d="M128 258H169V327H128z"></path>
        <path fill="#34A8FE" d="M128 289H169V296H128z"></path>
        <path fill="#FCFEFF" d="M241 258H282V352H241z"></path>
        <path fill="#88CCEF" d="M227 258H241V352H227z"></path>
        <path fill="#88CCEF" d="M282 258H296V352H282z"></path>
        <path fill="#FCFEFF" d="M354 258H395V327H354z"></path>
        <path fill="#34A8FE" d="M354 289H395V296H354z"></path>
        <path
          fill="#D0EEFD"
          d="M445.725 231.519l-14.285-25a5 5 0 00-4.342-2.519H98.902a5 5 0 00-4.342 2.519l-14.285 25c-1.905 3.334.502 7.481 4.34 7.481h356.769c3.839 0 6.246-4.147 4.341-7.481z"
        ></path>
        <path
          fill="#88CCEF"
          d="M84.616 239h197.436c3.266 0 5.656-3.081 4.842-6.245l-3.923-15.255-2.505-9.745a5.001 5.001 0 00-4.843-3.755H98.902a5 5 0 00-4.342 2.519l-14.285 25c-1.905 3.334.502 7.481 4.34 7.481z"
        ></path>
        <path
          fill="#D0EEFD"
          d="M76.305 242.5h371.389v2a5 5 0 01-5 5H81.304a5 5 0 01-5-5v-2z"
        ></path>
        <rect
          width="382"
          height="13"
          x="71"
          y="229.5"
          fill="#FCFEFF"
          rx="5"
        ></rect>
        <path fill="#FCFEFF" d="M241 121H282V190H241z"></path>
        <path fill="#88CCEF" d="M227 124H241V187H227z"></path>
        <path fill="#88CCEF" d="M282 124H296V187H282z"></path>
        <path fill="#34A8FE" d="M241 152H282V159H241z"></path>
        <path fill="#FCFEFF" d="M128 121H169V190H128z"></path>
        <path fill="#88CCEF" d="M114 124H128V187H114z"></path>
        <path fill="#88CCEF" d="M169 124H183V187H169z"></path>
        <path fill="#34A8FE" d="M128 152H169V159H128z"></path>
        <path fill="#FCFEFF" d="M354 121H395V190H354z"></path>
        <path fill="#88CCEF" d="M340 124H354V187H340z"></path>
        <path fill="#88CCEF" d="M395 124H409V187H395z"></path>
        <path fill="#34A8FE" d="M354 152H395V159H354z"></path>
        <rect
          width="360"
          height="42"
          x="81"
          y="352"
          fill="#34A8FE"
          rx="5"
        ></rect>
        <path
          fill="#88CCEF"
          d="M215 384a5 5 0 015-5h83a5 5 0 015 5v10h-93v-10z"
        ></path>
        <path
          fill="#9EDAF7"
          d="M222 368a5 5 0 015-5h69a5 5 0 015 5v11h-79v-11z"
        ></path>
        <path
          fill="#B5E6FC"
          d="M229 353a5 5 0 015-5h55a5 5 0 015 5v10h-65v-10z"
        ></path>
        <rect
          width="6"
          height="201"
          x="464"
          y="212"
          fill="#88CCEF"
          rx="3"
        ></rect>
        <rect
          width="5"
          height="201"
          x="342"
          y="212"
          fill="#88CCEF"
          rx="2.5"
        ></rect>
        <path
          stroke="#D0EEFD"
          strokeWidth="3"
          d="M372 272l119 123m0-123L372 395"
        ></path>
        <rect
          width="5.417"
          height="201"
          x="490.332"
          y="212"
          fill="#88CCEF"
          rx="2.708"
        ></rect>
        <rect
          width="5.417"
          height="201"
          x="367.914"
          y="212"
          fill="#88CCEF"
          rx="2.708"
        ></rect>
        <rect
          width="5.417"
          height="182"
          x="330"
          y="234.75"
          fill="#88CCEF"
          rx="2.708"
          transform="rotate(-90 330 234.75)"
        ></rect>
        <rect
          width="5.417"
          height="182"
          x="330"
          y="279.167"
          fill="#88CCEF"
          rx="2.708"
          transform="rotate(-90 330 279.167)"
        ></rect>
        <path
          fill="#9EDAF7"
          d="M86 355c28.634-.129 48 57.905 102 57.905H-16c54 0 73.366-58.034 102-57.905z"
        ></path>
      </g>
      <path
        fill="#88CCEF"
        d="M432.225 95.405l-31.384-68a5.001 5.001 0 00-4.54-2.905H127.199a5.001 5.001 0 00-4.54 2.905l-31.384 68c-1.53 3.313.89 7.095 4.54 7.095h331.87c3.649 0 6.069-3.782 4.54-7.095z"
      ></path>
      <path
        fill="#D0EEFD"
        d="M86 106h350v2a5 5 0 01-5 5H91a5 5 0 01-5-5v-2z"
      ></path>
      <rect width="360" height="13" x="81" y="93" fill="#FCFEFF" rx="5"></rect>
      <rect width="49" height="19" x="151" fill="#34A8FE" rx="5"></rect>
      <path fill="#B5E6FC" d="M157 19H194V59H157z"></path>
    </svg>
  );
}

export default ProductsFlip;
