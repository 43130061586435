import React, { Component } from "react";
import axios from "axios";

//styling
import "./forum.css";
import "../components/cards/cardStyle.css";

//redux stuff
import { connect } from "react-redux";

//compnents
import Button from "../components/buttons/Button";
import ForumPost from "../components/cards/ForumPost";
import Spinner from "../components/loading/Spinner";
import Banner from "../components/header/Banner";
import ForumBack from "../components/backgrounds/ForumBack";

//icons
import NewPost from "../media/icons/NewPost";

class forum extends Component {
  constructor() {
    super();
    this.banElement = React.createRef();
    this.bannerScroll = this.bannerScroll.bind(this);
  }
  state = {
    forumPosts: null,
    lastScrollUp: 0,
    BannerHeight: 0,
    BannerTop: 0,
    BannerDis: 1,
    tabHeight: 0
  };

  componentDidMount() {
    window.addEventListener("scroll", this.bannerScroll, false);

    this.setState({
      BannerHeight: this.banElement.current.getBoundingClientRect().height
    });

    // axios
    //   .get("/forumPosts")
    //   .then(res => {
    //     this.setState({
    //       forumPosts: res.data
    //     });
    //   })
    //   .catch(err => console.log(err));
  }
  componentWillUnmount() {
    window.removeEventListener("scroll", this.bannerScroll, false);
  }

  render() {
    const {
      user: {
        credentials: { imageUrl },
        loadingUser,
        authenticated,
        checked
      }
    } = this.props;

    // console.log("from porofile: ", !checked || authenticated);
    //
    // let redirect =
    //   !checked || authenticated ? "" : this.props.history.push("/auth");

    let recentPost = "posts";

    return (
      <div>
        <ForumBack />
        <div
          ref={this.banElement}
          className="banner-behavior-wrap"
          style={{
            top: this.state.BannerTop,
            opacity: this.state.BannerDis
          }}
        >
          <Banner />
        </div>
        <div
          className="forum-content"
          style={{
            paddingTop: this.state.BannerHeight
          }}
        >
          <section className="forum-wrap sizing">
            <div className="posts-container">{recentPost}</div>
            <div className="more-container">
              <div
                className="more-container-wrap"
                style={{
                  top: this.state.BannerTop + this.state.BannerHeight + 72
                }}
              >
                <div className="desktop-only forum-more-container ">
                  <h2 className="">Top Posts</h2>
                  <div className="more-subcat">category</div>
                </div>
                <div className="desktop-only forum-more-container ">
                  <h2 className="">Top Trends</h2>
                  <div className="more-post-container">
                    <div className="more-subcat">post</div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div className="spacer"></div>
        </div>
      </div>
    );
  }
  bannerScroll() {
    var banner = this.banElement.current.getBoundingClientRect(),
      lastScrollUp = this.state.lastScrollUp,
      scroll = window.scrollY;

    if (scroll > banner.height - this.state.tabHeight) {
      if (!lastScrollUp) {
        lastScrollUp = scroll;
      }

      var tabHeightRaw = lastScrollUp - scroll;
      var tabHeight = Math.max(Math.min(tabHeightRaw, 48), 0);

      if (tabHeightRaw > 48) {
        lastScrollUp = scroll + 48;
      }

      if (tabHeightRaw < 0) {
        lastScrollUp = 0;
      }
      this.setState({
        BannerTop: -banner.height + tabHeight,
        tabHeight: tabHeight,
        BannerDis: tabHeight ? 1 : 0,
        lastScrollUp: lastScrollUp
      });
    } else {
      this.setState({
        BannerTop: -scroll,
        BannerDis: 1
      });
    }
  }
}
const mapStateToProps = state => ({
  user: state.user
});

export default connect(mapStateToProps)(forum);
