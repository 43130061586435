import React, { Component } from "react";
import PropTypes from "prop-types";
import axios from "axios";

//algoliasearch
import algoliasearch from "algoliasearch/lite";

import {
  // Editor,
  EditorState,
  RichUtils,
  AtomicBlockUtils,
  convertToRaw
} from "draft-js";
import Editor from "draft-js-plugins-editor";
import { getSelectionEntity } from "draftjs-utils";
import { Link } from "react-router-dom";

import createMentionPlugin, {
  defaultSuggestionsFilter
} from "draft-js-mention-plugin";
import editorStyles from "./editorStyles.css";

import debounce from "lodash.debounce";
import { Resizable, ResizableBox } from "react-resizable";

//style
import "./PostTextInputStyle.css";

//components
import Spinner from "../../../../loading/Spinner";
import CharRing from "./CharRing";
import mediaBlockRenderer from "./mediaBlockRenderer";
import ImagInput from "./PostImageInput";
import PostLinkInput from "./PostLinkInput";
import addLinkPlugin from "./plugins/addLinkPlugin";

//consoleIcons
import Close from "../../../../../media/SVGs/None";
import TextSize from "../../../../../media/icons/richText/TextSize";
import ChevronDown from "../../../../../media/icons/ChevronDown";

import Header from "../../../../../media/icons/richText/Header";
import SubHeader from "../../../../../media/icons/richText/SubHeader";
import TextUnstyl from "../../../../../media/icons/richText/TextUnstl";
import ImageLink from "../../../../../media/icons/Link";

const client = algoliasearch("JOLDALUHRH", "7cab22f7a649852e534f612323fbfaa1");
const index = client.initIndex("srch_USERS");

const userIndex = client.initIndex("srch_USERS");
const topicIndex = client.initIndex("srch_TOPICS");

class PostPop extends Component {
  constructor(props) {
    super(props);
    this.state = {
      charCount: 0,
      linkPreviews: "",
      editorState: EditorState.createEmpty(),
      textAreaHeight: 0,
      menuOpen: false,
      linkOpen: false,
      linkArr: [],
      loading: false,
      mentionList1: [],
      mentionList2: []
    };
    this.handleClick = this.handleClick.bind(this);
    this.imageInput = React.createRef();
    this.linkPop = React.createRef();
    this.handleKeyCommand = this.handleKeyCommand.bind(this);

    // this.mentionPlugin = createMentionPlugin({ mentionTrigger: "@" });
    this.mentionPlugin = createMentionPlugin({
      mentionTrigger: "@",
      entityMutability: "IMMUTABLE",
      mentionPrefix: "@",
      supportWhitespace: true
    });
    this.mentionPlugin2 = createMentionPlugin({
      mentionTrigger: "#",
      entityMutability: "IMMUTABLE",
      // mentionPrefix: "#",
      supportWhitespace: true
    });
    // this.mentionPlugin = createMentionPlugin();
    // this.mentionPlugin = createMentionPlugin({
    //   mentions,
    //   mentionComponent: (mentionProps) => (
    //     <span
    //       className={mentionProps.className}
    //       onClick={() => alert("Clicked on the Mention!")}
    //     >
    //       {mentionProps.children}
    //     </span>
    //   ),
    // });

    this.addLinkPlugin = addLinkPlugin;
    // this.plugins = [addLinkPlugin, this.mentionPlugin];
    this.previewCheck = debounce(this.previewCheck, 200);
  }
  //Dropdown-------------------------------------------------------------------
  componentDidUpdate() {}
  getSnapshotBeforeUpdate(prevProps, prevState) {
    if (prevState.menuOpen !== this.state.menuOpen) {
      this.state.menuOpen
        ? document.addEventListener("click", this.handleClick)
        : document.removeEventListener("click", this.handleClick);
    }
    if (prevState.linkOpen !== this.state.linkOpen) {
      this.state.linkOpen
        ? document.addEventListener("click", this.handleClick)
        : document.removeEventListener("click", this.handleClick);
    }
    /////////////////////////////////////////////////////
    if (prevProps.loading !== this.props.loading && !this.props.loading) {
      console.log("changed");
      this.setState({ loading: false });
    }
    return null;
  }
  handleClick(e) {
    this.state.menuOpen && this.setState({ menuOpen: false });

    this.state.linkOpen &&
      !this.linkPop.contains(e.target) &&
      this.closeLinkPop();
  }

  //Link preview---------------------------------------------------------------
  linkPrev = input => {
    let jsonText = {
      text: input
    };

    input &&
      axios
        .post("/linkPreview", jsonText)
        .then(res => {
          if (!res.data) return;
          let primaryLink = res.data[0];
          var pathArray = primaryLink.url.split("/");
          var protocol = pathArray[0];
          var host = pathArray[2];
          primaryLink.shortUrl = host;

          this.setState({
            linkPreviews: primaryLink
          });
        })
        .catch(err => console.log(err));
  };
  removeLinkPrev = e => {
    e.stopPropagation();
    console.log("stopping");

    this.setState({
      linkPreviews: ""
    });
  };
  handleLinkClick = url => {
    var win = window.open(url, "_blank");
    win.focus();
    //href={primeLink.url}
  };

  //TextEditor-----------------------------------------------------------------
  handleKeyCommand = command => {
    const newState = RichUtils.handleKeyCommand(
      this.state.editorState,
      command
    );
    if (newState) {
      this.onChange(newState);
      return "handled";
    }
    return "not-handled";
  };
  onChange = editorState => {
    const plainText = this.state.editorState.getCurrentContent().getPlainText();
    this.setState({
      post: plainText,
      charCount: plainText != 0 ? plainText.length : 0,
      editorState
    });

    this.previewCheck();

    // !this.state.linkPreviews && this.linkPrev(plainText);
    this.props.passText({
      styled: convertToRaw(this.state.editorState.getCurrentContent()),
      raw: plainText
    });
  };
  previewCheck = () => {
    if (this.state.linkPreview) return;
    let entityMap = convertToRaw(this.state.editorState.getCurrentContent())
      .entityMap;
    //should probably make sure this in an array of link entities first
    entityMap[0] && this.linkPrev(entityMap[0].data.url);
  };
  focus = () => {
    this.editor.focus();
  };
  menuAsign = (action, currentInlineStyle, currentBlockType) => {
    return (
      <button
        key={Math.random()}
        onClick={() => {
          this.onClick(action);
        }}
        className={`
            ${
              (currentInlineStyle && currentInlineStyle.has(action)) ||
              currentBlockType === action.style
                ? "active-toolbar-button"
                : ""
            }

          `}
      >
        <action.lable size="24px" colorPrimary=" #cfd8dc" />
        <div
          className={`
              ${
                (currentInlineStyle && currentInlineStyle.has(action)) ||
                currentBlockType === action.style
                  ? "active-toolbar-text"
                  : ""
              }`}
        >
          {action.text}
        </div>
      </button>
    );
  };
  buttonAsign = (
    action,
    currentInlineStyle,
    currentBlockType,
    currentEntity
  ) => {
    if (action.type === "divider")
      return (
        <div className="toolbar-div-cont" key={Math.random()}>
          <div className="toolbar-divider"></div>
        </div>
      );
    if (action.type === "spacer")
      return (
        <div
          className="toolbar-div-cont"
          key={Math.random()}
          style={{ minWidth: "32px" }}
        ></div>
      );

    let ButtonComp = action.lable;
    return (
      <button
        onClick={e => {
          !action.onClick ? this.onClick(action) : this[action.onClick](e);
        }}
        key={action.action}
        className={`
            ${
              currentInlineStyle.has(action) ||
              currentBlockType === action.style
                ? "active-toolbar-button"
                : ""
            } ${
          currentInlineStyle.has(action.action) ? "active-toolbar-button" : ""
        }
          ${
            action.action === "LINK" && currentEntity.url
              ? "active-toolbar-button"
              : ""
          }`}
      >
        <ButtonComp size="24px" colorPrimary=" #cfd8dc" />
      </button>
    );
  };
  onClick = action => {
    this.onChange(
      RichUtils.toggleInlineStyle(this.state.editorState, action.action)
    );
    if (action.type === "block")
      this.onChange(
        RichUtils.toggleBlockType(this.state.editorState, action.style)
      );
  };
  toggleBlockType = blockType => {
    this.onChange(RichUtils.toggleBlockType(this.state.editorState, blockType));
  };
  getIcon(active, styles, objects) {
    let Comp = !styles.includes(active)
      ? TextUnstyl
      : objects.find(x => x.style === active).lable;
    return <Comp size="24" />;
  }

  //Mentions----------------------------------------------------------------------
  onSearchChange = ({ value }) => {
    userIndex
      .search(value, {
        attributesToRetrieve: ["username", "imageUrl"],
        hitsPerPage: 5
      })
      .then(({ hits }) => {
        let list = [];
        hits.forEach((item, i) => {
          list.push({
            name: item.username,
            link: `https://us-flipit.com/user/${item.username}`,
            avatar: item.imageUrl
          });
        });
        this.setState({
          mentionList1: defaultSuggestionsFilter(value, list)
        });
      });
  };
  onSearchChange2 = ({ value }) => {
    topicIndex
      .search(value, {
        attributesToRetrieve: ["topic", "Followers"],
        hitsPerPage: 5
      })
      .then(({ hits }) => {
        let list = [];
        hits.forEach((item, i) => {
          list.push({
            name: `#${item.topic}`,
            link: `https://us-flipit.com/user/${item.username}`
          });
        });
        this.setState({
          mentionList2: defaultSuggestionsFilter(value, list)
        });
      });
  };
  onAddMention = () => {
    let link = "https://www.behance.net/mattwolfe";
    // get the mention object selected
    console.log("activated!");
    // const { editorState } = this.state;
    // const content = editorState.getCurrentContent();
    // const contentWithEntity = content.createEntity("LINK", "MUTABLE", {
    //   url: link,
    // });
  };

  //Image----------------------------------------------------------------------
  ImagePop = e => {
    e.preventDefault();
    this.setState({ imageModal: !this.state.imageModal });
  };
  AddImageToEditor = imageValue => {
    const editorState = this.state.editorState;
    const contentState = editorState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity(
      "image",
      "IMMUTABLE",
      { src: imageValue }
    );
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const newEditorState = EditorState.set(
      editorState,
      { currentContent: contentStateWithEntity },
      "create-entity"
    );
    this.setState(
      {
        editorState: AtomicBlockUtils.insertAtomicBlock(
          newEditorState,
          entityKey,
          " "
        )
      },
      () => {
        setTimeout(() => this.focus(), 0);
      }
    );
  };

  //Link-----------------------------------------------------------------------
  LinkPop = e => {
    this.setState({ linkOpen: !this.state.linkOpen });
  };
  closeLinkPop = () => {
    this.setState({ linkOpen: !this.state.linkOpen });
  };
  onAddLink = link => {
    this.closeLinkPop();
    const { editorState } = this.state;
    const selection = editorState.getSelection();

    if (!link) {
      this.onChange(RichUtils.toggleLink(editorState, selection, null));
      return "handled";
    }
    const content = editorState.getCurrentContent();
    const contentWithEntity = content.createEntity("LINK", "MUTABLE", {
      url: link
    });
    const newEditorState = EditorState.push(
      editorState,
      contentWithEntity,
      "create-entity"
    );
    const entityKey = contentWithEntity.getLastCreatedEntityKey();
    this.onChange(RichUtils.toggleLink(newEditorState, selection, entityKey));
  };

  //RENDER---------------------------------------------------------------------
  render() {
    const MentionSuggestions = this.mentionPlugin.MentionSuggestions;
    const MentionSuggestions2 = this.mentionPlugin2.MentionSuggestions;
    // const { MentionSuggestions } = this.mentionPlugin;
    const plugins = [
      this.addLinkPlugin,
      this.mentionPlugin,
      this.mentionPlugin2
    ];

    const currentInlineStyle = this.state.editorState.getCurrentInlineStyle();
    const currentBlockType = this.state.editorState
      .getCurrentContent()
      .getBlockForKey(this.state.editorState.getSelection().getStartKey())
      .getType();
    const currentEntity = !getSelectionEntity(this.state.editorState)
      ? "nothing"
      : this.state.editorState
          .getCurrentContent()
          .getEntity(getSelectionEntity(this.state.editorState))
          .getData();

    let primeLink = !this.state.linkPreviews ? "" : this.state.linkPreviews;
    let aprevLink = !primeLink ? "" : primeLink.ur;
    let linkPreview = (
      <div
        className="preview-container centered anim-drpdwn "
        target="_blank"
        onClick={e => {
          this.handleLinkClick(primeLink.url);
        }}
      >
        <button className="button svg-button" onClick={this.removeLinkPrev}>
          <Close size="24px" colorPrimary="rgba(96, 103, 110, 0.5)" />
        </button>
        <img
          src={primeLink.image ? primeLink.image : primeLink.favicon}
          alt="preview"
        />
        <div style={{ width: "100%" }}>
          <h4>{primeLink.title}</h4>
          <p>{primeLink.description}</p>
          <p style={{ color: "#039be5" }}>{primeLink.shortUrl}</p>
        </div>
      </div>
    );

    const MenuData = [
      {
        lable: Header,
        type: "block",
        style: "header-two",
        text: "Header"
      },
      {
        lable: SubHeader,
        type: "block",
        style: "header-three",
        text: "Subheader"
      },
      {
        lable: TextUnstyl,
        type: "block",
        style: "unstyled",
        text: "Text"
      }
    ];
    const menuStyles = MenuData.map(a => a.style);
    const ButtonData = this.props.toolbarData;
    const menus = !this.props.menu
      ? ""
      : MenuData.map(action =>
          this.menuAsign(action, currentInlineStyle, currentBlockType)
        );
    const buttons = ButtonData.map(action =>
      this.buttonAsign(
        action,
        currentInlineStyle,
        currentBlockType,
        currentEntity
      )
    );
    const ResizeHandle = (
      <div className="input-dragbar centered-col">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="12"
          fill="none"
          viewBox="0 0 24 12"
        >
          <path d="M3 9V7h2v2H3zm0-4V3h2v2H3zm4 4V7h2v2H7zm0-4V3h2v2H7zm4 4V7h2v2h-2zm0-4V3h2v2h-2zm4 4V7h2v2h-2zm0-4V3h2v2h-2zm4 4V7h2v2h-2zm0-4V3h2v2h-2z"></path>
        </svg>
      </div>
    );

    return (
      <div className="textiput-container">
        <ImagInput
          AddImageToEditor={this.AddImageToEditor}
          ImagePop={this.ImagePop}
          imageModal={this.state.imageModal}
          mapImagestoEditor={this.mapImagestoEditor}
        />

        <div
          ref={e => {
            this.linkPop = e;
          }}
          style={{ display: !this.state.linkOpen ? "none" : "block" }}
        >
          <PostLinkInput
            addLink={this.onAddLink}
            close={this.closeLinkPop}
            currentLink={currentEntity.url}
          />
        </div>
        <div
          className="toolbar-menu-drop"
          style={{
            height: !this.state.menuOpen
              ? 0
              : `${Object.keys(MenuData).length * 32 + 16}px`
          }}
        >
          {menus}
        </div>
        <div className="textiput-toolbar outskrts">
          <div className="toolbar-tools centered">
            {!this.props.menu ? (
              ""
            ) : (
              <div className="toolbar-menu-wrap">
                <div
                  className="toolbar-menu-active outskrts"
                  onClick={e =>
                    this.setState({ menuOpen: !this.state.menuOpen })
                  }
                >
                  <div className="centered">
                    {this.getIcon(currentBlockType, menuStyles, MenuData)}
                    <div className="desktop-only">
                      {!menuStyles.includes(currentBlockType)
                        ? "Text"
                        : MenuData.find(x => x.style === currentBlockType).text}
                    </div>
                  </div>
                  <ChevronDown size="24" />
                </div>
              </div>
            )}

            {buttons}
          </div>
          <div className="centered">
            {!this.props.toolbarSubmit ? (
              ""
            ) : (
              <button
                style={{
                  width: "auto",
                  padding: "0 16px"
                }}
                className="button button-white anim-left centered-col"
                onClick={e => {
                  console.log("clicked");
                  this.setState({
                    editorState: EditorState.createEmpty(),
                    loading: true
                  });
                  this.props.toolbarSubmit.func(e);
                }}
              >
                {!this.state.loading ? (
                  this.props.toolbarSubmit.text
                ) : (
                  <Spinner color="#34a8fe" size="24px" />
                )}
              </button>
            )}
            {this.props.hideCharRing ? (
              ""
            ) : (
              <CharRing
                radius={12}
                charCount={this.state.charCount}
                limit={5000}
              />
            )}
          </div>
        </div>

        <ResizableBox
          height={!this.props.height ? 200 : this.props.height}
          handle={ResizeHandle}
          axis={"y"}
          minConstraints={[
            !this.props.height ? 200 : this.props.height,
            !this.props.height ? 200 : this.props.height
          ]}
          onResizeStop={this.focus}
        >
          <div
            className="input-editor-wrap"
            style={{ height: "100%" }}
            onClick={this.focus}
          >
            <Editor
              blockRendererFn={mediaBlockRenderer}
              editorState={this.state.editorState}
              handleKeyCommand={this.handleKeyCommand}
              onChange={this.onChange}
              plugins={plugins}
              spellCheck={true}
              placeholder={
                this.props.placeholder
                  ? this.props.placeholder
                  : "What's on your mind..."
              }
              ref={element => {
                this.editor = element;
              }}
            />

            <MentionSuggestions
              onSearchChange={this.onSearchChange}
              suggestions={this.state.mentionList1}
            />
            <MentionSuggestions2
              onSearchChange={this.onSearchChange2}
              suggestions={this.state.mentionList2}
            />
          </div>
        </ResizableBox>
        {!primeLink ? "" : linkPreview}
      </div>
    );
  }
}

export default PostPop;
