import React, { Component, Fragment } from "react";
import MoreDots from "../../../media/icons/MoreDots";

class planCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      popup: false
    };
    this.handleClick = this.handleClick.bind(this);
    this.closePop = this.closePop.bind(this);
    this.node = React.createRef();
  }

  componentDidUpdate(prevState) {
    if (prevState.open !== this.state.open) {
      this.state.open
        ? document.addEventListener("click", this.handleClick)
        : document.removeEventListener("click", this.handleClick);
    }
  }

  handleClick(e) {
    if (this.node && !this.node.contains(e.target)) {
      this.setState({ open: false });
    }
  }

  click = e => {
    e.stopPropagation();
    this.setState({
      open: !this.state.open
    });
  };

  closePop(e) {
    e && e.stopPropagation();
    this.setState({
      popup: false
    });
  }

  render() {
    const { elements, compId, compType, delFunc } = this.props;
    const dropCont = elements.map(item => {
      return (
        <div
          key={Math.random()}
          className="more-dots-drop-item centered"
          onClick={e =>
            this.setState({
              open: false,
              popup: !this.state.popup,
              popCont: (
                <item.comp
                  compId={compId}
                  compType={compType}
                  closePop={this.closePop}
                />
              )
            })
          }
        >
          <item.icon size="24px" />
          {item.lable}
        </div>
      );
    });

    return (
      <Fragment>
        <div
          className={`more-dots-drop ${
            this.props.buttonContainer === "styled" ? "styled-more-dots" : ""
          }`}
        >
          <div
            className="more-dots-button centered"
            onClick={e => this.click(e)}
          >
            <MoreDots
              className="more-dots-icon"
              colorPrimary="#D2D6D9"
              size="24px"
            />
          </div>
          <div
            ref={node => (this.node = node)}
            onClick={e => {
              e.stopPropagation();
            }}
            style={{ display: this.state.open ? "block" : "none" }}
            className="more-dots-drop-dropdown anim-drpdwn "
          >
            {dropCont}
          </div>
        </div>
        <div
          className="options-modal centered-col"
          style={{ display: this.state.popup ? "flex" : "none" }}
          onClick={e => this.closePop(e)}
        >
          {eval(this.state.popCont)}
        </div>
      </Fragment>
    );
  }
}

export default planCard;
