import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="747"
      height="573"
      fill="none"
      viewBox="0 0 747 573"
    >
      <path
        stroke="#F5F5F5"
        strokeWidth="2"
        d="M0 0H21V21H0z"
        transform="matrix(-1 0 0 1 638 1)"
      ></path>
      <path
        stroke="#F5F5F5"
        strokeWidth="2"
        d="M0 0H21V21H0z"
        transform="matrix(-1 0 0 1 638 43)"
      ></path>
      <path
        stroke="#F5F5F5"
        strokeWidth="2"
        d="M0 0H21V21H0z"
        transform="matrix(-1 0 0 1 638 85)"
      ></path>
      <path
        stroke="#F5F5F5"
        strokeWidth="2"
        d="M0 0H21V21H0z"
        transform="matrix(-1 0 0 1 722 127)"
      ></path>
      <path
        stroke="#F5F5F5"
        strokeWidth="2"
        d="M0 0H21V21H0z"
        transform="matrix(-1 0 0 1 597 1)"
      ></path>
      <path
        stroke="#F5F5F5"
        strokeWidth="2"
        d="M0 0H21V21H0z"
        transform="matrix(-1 0 0 1 555 1)"
      ></path>
      <path
        stroke="#F5F5F5"
        strokeWidth="2"
        d="M0 0H21V21H0z"
        transform="matrix(-1 0 0 1 638 22)"
      ></path>
      <path
        stroke="#F5F5F5"
        strokeWidth="2"
        d="M0 0H21V21H0z"
        transform="matrix(-1 0 0 1 638 64)"
      ></path>
      <path
        stroke="#F5F5F5"
        strokeWidth="2"
        d="M0 0H21V21H0z"
        transform="matrix(-1 0 0 1 638 106)"
      ></path>
      <path
        stroke="#F5F5F5"
        strokeWidth="2"
        d="M0 0H21V21H0z"
        transform="matrix(-1 0 0 1 638 275)"
      ></path>
      <path
        stroke="#F5F5F5"
        strokeWidth="2"
        d="M0 0H21V21H0z"
        transform="matrix(-1 0 0 1 659 254)"
      ></path>
      <path
        stroke="#F5F5F5"
        strokeWidth="2"
        d="M0 0H21V21H0z"
        transform="matrix(-1 0 0 1 680 233)"
      ></path>
      <path
        stroke="#F5F5F5"
        strokeWidth="2"
        d="M0 0H21V21H0z"
        transform="matrix(-1 0 0 1 43 275)"
      ></path>
      <path
        stroke="#F5F5F5"
        strokeWidth="2"
        d="M0 0H21V21H0z"
        transform="matrix(-1 0 0 1 722 148)"
      ></path>
      <path
        stroke="#F5F5F5"
        strokeWidth="2"
        d="M0 0H21V21H0z"
        transform="matrix(-1 0 0 1 638 127)"
      ></path>
      <path
        stroke="#F5F5F5"
        strokeWidth="2"
        d="M0 0H21V21H0z"
        transform="matrix(-1 0 0 1 638 296)"
      ></path>
      <path
        stroke="#F5F5F5"
        strokeWidth="2"
        d="M0 0H21V21H0z"
        transform="matrix(-1 0 0 1 43 296)"
      ></path>
      <path
        stroke="#F5F5F5"
        strokeWidth="2"
        d="M0 0H21V21H0z"
        transform="matrix(-1 0 0 1 722 169)"
      ></path>
      <path
        stroke="#F5F5F5"
        strokeWidth="2"
        d="M0 0H21V21H0z"
        transform="matrix(-1 0 0 1 597 22)"
      ></path>
      <path
        stroke="#F5F5F5"
        strokeWidth="2"
        d="M0 0H21V21H0z"
        transform="matrix(-1 0 0 1 555 22)"
      ></path>
      <path
        stroke="#F5F5F5"
        strokeWidth="2"
        d="M0 0H21V21H0z"
        transform="matrix(-1 0 0 1 617 1)"
      ></path>
      <path
        stroke="#F5F5F5"
        strokeWidth="2"
        d="M0 0H21V21H0z"
        transform="matrix(-1 0 0 1 617 43)"
      ></path>
      <path
        stroke="#F5F5F5"
        strokeWidth="2"
        d="M0 0H21V21H0z"
        transform="matrix(-1 0 0 1 617 85)"
      ></path>
      <path
        stroke="#F5F5F5"
        strokeWidth="2"
        d="M0 0H21V21H0z"
        transform="matrix(-1 0 0 1 701 127)"
      ></path>
      <path
        stroke="#F5F5F5"
        strokeWidth="2"
        d="M0 0H21V21H0z"
        transform="matrix(-1 0 0 1 576 1)"
      ></path>
      <path
        stroke="#F5F5F5"
        strokeWidth="2"
        d="M0 0H21V21H0z"
        transform="matrix(-1 0 0 1 534 1)"
      ></path>
      <path
        stroke="#F5F5F5"
        strokeWidth="2"
        d="M0 0H21V21H0z"
        transform="matrix(-1 0 0 1 513 1)"
      ></path>
      <path
        stroke="#F5F5F5"
        strokeWidth="2"
        d="M0 0H21V21H0z"
        transform="matrix(-1 0 0 1 617 22)"
      ></path>
      <path
        stroke="#F5F5F5"
        strokeWidth="2"
        d="M0 0H21V21H0z"
        transform="matrix(-1 0 0 1 617 64)"
      ></path>
      <path
        stroke="#F5F5F5"
        strokeWidth="2"
        d="M0 0H21V21H0z"
        transform="matrix(-1 0 0 1 362 64)"
      ></path>
      <path
        stroke="#F5F5F5"
        strokeWidth="2"
        d="M0 0H21V21H0z"
        transform="matrix(-1 0 0 1 320 64)"
      ></path>
      <path
        stroke="#F5F5F5"
        strokeWidth="2"
        d="M0 0H21V21H0z"
        transform="matrix(-1 0 0 1 278 64)"
      ></path>
      <path
        stroke="#F5F5F5"
        strokeWidth="2"
        d="M0 0H21V21H0z"
        transform="matrix(-1 0 0 1 236 64)"
      ></path>
      <path
        stroke="#F5F5F5"
        strokeWidth="2"
        d="M0 0H21V21H0z"
        transform="matrix(-1 0 0 1 152 64)"
      ></path>
      <path
        stroke="#F5F5F5"
        strokeWidth="2"
        d="M0 0H21V21H0z"
        transform="matrix(-1 0 0 1 194 64)"
      ></path>
      <path
        stroke="#F5F5F5"
        strokeWidth="2"
        d="M0 0H21V21H0z"
        transform="matrix(-1 0 0 1 236 22)"
      ></path>
      <path
        stroke="#F5F5F5"
        strokeWidth="2"
        d="M0 0H21V21H0z"
        transform="matrix(-1 0 0 1 194 22)"
      ></path>
      <path
        stroke="#F5F5F5"
        strokeWidth="2"
        d="M0 0H21V21H0z"
        transform="matrix(-1 0 0 1 215 22)"
      ></path>
      <path
        stroke="#F5F5F5"
        strokeWidth="2"
        d="M0 0H21V21H0z"
        transform="matrix(-1 0 0 1 173 22)"
      ></path>
      <path
        stroke="#F5F5F5"
        strokeWidth="2"
        d="M0 0H21V21H0z"
        transform="matrix(-1 0 0 1 152 22)"
      ></path>
      <path
        stroke="#F5F5F5"
        strokeWidth="2"
        d="M0 0H21V21H0z"
        transform="matrix(-1 0 0 1 236 85)"
      ></path>
      <path
        stroke="#F5F5F5"
        strokeWidth="2"
        d="M0 0H21V21H0z"
        transform="matrix(-1 0 0 1 152 85)"
      ></path>
      <path
        stroke="#F5F5F5"
        strokeWidth="2"
        d="M0 0H21V21H0z"
        transform="matrix(-1 0 0 1 236 43)"
      ></path>
      <path
        stroke="#F5F5F5"
        strokeWidth="2"
        d="M0 0H21V21H0z"
        transform="matrix(-1 0 0 1 152 43)"
      ></path>
      <path
        stroke="#F5F5F5"
        strokeWidth="2"
        d="M0 0H21V21H0z"
        transform="matrix(-1 0 0 1 617 106)"
      ></path>
      <path
        stroke="#F5F5F5"
        strokeWidth="2"
        d="M0 0H21V21H0z"
        transform="matrix(-1 0 0 1 617 275)"
      ></path>
      <path
        stroke="#F5F5F5"
        strokeWidth="2"
        d="M0 0H21V21H0z"
        transform="matrix(-1 0 0 1 576 446)"
      ></path>
      <path
        stroke="#F5F5F5"
        strokeWidth="2"
        d="M0 0H21V21H0z"
        transform="matrix(-1 0 0 1 492 446)"
      ></path>
      <path
        stroke="#F5F5F5"
        strokeWidth="2"
        d="M0 0H21V21H0z"
        transform="matrix(-1 0 0 1 576 488)"
      ></path>
      <path
        stroke="#F5F5F5"
        strokeWidth="2"
        d="M0 0H21V21H0z"
        transform="matrix(-1 0 0 1 492 488)"
      ></path>
      <path
        stroke="#F5F5F5"
        strokeWidth="2"
        d="M0 0H21V21H0z"
        transform="matrix(-1 0 0 1 22 275)"
      ></path>
      <path
        stroke="#F5F5F5"
        strokeWidth="2"
        d="M0 0H21V21H0z"
        transform="matrix(-1 0 0 1 701 148)"
      ></path>
      <path
        stroke="#F5F5F5"
        strokeWidth="2"
        d="M0 0H21V21H0z"
        transform="matrix(-1 0 0 1 617 127)"
      ></path>
      <path
        stroke="#F5F5F5"
        strokeWidth="2"
        d="M0 0H21V21H0z"
        transform="matrix(-1 0 0 1 617 296)"
      ></path>
      <path
        stroke="#F5F5F5"
        strokeWidth="2"
        d="M0 0H21V21H0z"
        transform="matrix(-1 0 0 1 617 467)"
      ></path>
      <path
        stroke="#F5F5F5"
        strokeWidth="2"
        d="M0 0H21V21H0z"
        transform="matrix(-1 0 0 1 576 467)"
      ></path>
      <path
        stroke="#F5F5F5"
        strokeWidth="2"
        d="M0 0H21V21H0z"
        transform="matrix(-1 0 0 1 534 467)"
      ></path>
      <path
        stroke="#F5F5F5"
        strokeWidth="2"
        d="M0 0H21V21H0z"
        transform="matrix(-1 0 0 1 492 467)"
      ></path>
      <path
        stroke="#F5F5F5"
        strokeWidth="2"
        d="M0 0H21V21H0z"
        transform="matrix(-1 0 0 1 450 467)"
      ></path>
      <path
        stroke="#F5F5F5"
        strokeWidth="2"
        d="M0 0H21V21H0z"
        transform="matrix(-1 0 0 1 408 467)"
      ></path>
      <path
        stroke="#F5F5F5"
        strokeWidth="2"
        d="M0 0H21V21H0z"
        transform="matrix(-1 0 0 1 576 509)"
      ></path>
      <path
        stroke="#F5F5F5"
        strokeWidth="2"
        d="M0 0H21V21H0z"
        transform="matrix(-1 0 0 1 555 509)"
      ></path>
      <path
        stroke="#F5F5F5"
        strokeWidth="2"
        d="M0 0H21V21H0z"
        transform="matrix(-1 0 0 1 534 509)"
      ></path>
      <path
        stroke="#F5F5F5"
        strokeWidth="2"
        d="M0 0H21V21H0z"
        transform="matrix(-1 0 0 1 513 509)"
      ></path>
      <path
        stroke="#F5F5F5"
        strokeWidth="2"
        d="M0 0H21V21H0z"
        transform="matrix(-1 0 0 1 320 509)"
      ></path>
      <path
        stroke="#F5F5F5"
        strokeWidth="2"
        d="M0 0H21V21H0z"
        transform="matrix(-1 0 0 1 107 509)"
      ></path>
      <path
        stroke="#F5F5F5"
        strokeWidth="2"
        d="M0 0H21V21H0z"
        transform="matrix(-1 0 0 1 107 488)"
      ></path>
      <path
        stroke="#F5F5F5"
        strokeWidth="2"
        d="M0 0H21V21H0z"
        transform="matrix(-1 0 0 1 107 467)"
      ></path>
      <path
        stroke="#F5F5F5"
        strokeWidth="2"
        d="M0 0H21V21H0z"
        transform="matrix(-1 0 0 1 107 530)"
      ></path>
      <path
        stroke="#F5F5F5"
        strokeWidth="2"
        d="M0 0H21V21H0z"
        transform="matrix(-1 0 0 1 65 509)"
      ></path>
      <path
        stroke="#F5F5F5"
        strokeWidth="2"
        d="M0 0H21V21H0z"
        transform="matrix(-1 0 0 1 65 488)"
      ></path>
      <path
        stroke="#F5F5F5"
        strokeWidth="2"
        d="M0 0H21V21H0z"
        transform="matrix(-1 0 0 1 65 467)"
      ></path>
      <path
        stroke="#F5F5F5"
        strokeWidth="2"
        d="M0 0H21V21H0z"
        transform="matrix(-1 0 0 1 65 446)"
      ></path>
      <path
        stroke="#F5F5F5"
        strokeWidth="2"
        d="M0 0H21V21H0z"
        transform="matrix(-1 0 0 1 65 425)"
      ></path>
      <path
        stroke="#F5F5F5"
        strokeWidth="2"
        d="M0 0H21V21H0z"
        transform="matrix(-1 0 0 1 65 404)"
      ></path>
      <path
        stroke="#F5F5F5"
        strokeWidth="2"
        d="M0 0H21V21H0z"
        transform="matrix(-1 0 0 1 65 530)"
      ></path>
      <path
        stroke="#F5F5F5"
        strokeWidth="2"
        d="M0 0H21V21H0z"
        transform="matrix(-1 0 0 1 65 551)"
      ></path>
      <path
        stroke="#F5F5F5"
        strokeWidth="2"
        d="M0 0H21V21H0z"
        transform="matrix(-1 0 0 1 492 509)"
      ></path>
      <path
        stroke="#F5F5F5"
        strokeWidth="2"
        d="M0 0H21V21H0z"
        transform="matrix(-1 0 0 1 299 509)"
      ></path>
      <path
        stroke="#F5F5F5"
        strokeWidth="2"
        d="M0 0H21V21H0z"
        transform="matrix(-1 0 0 1 86 509)"
      ></path>
      <path
        stroke="#F5F5F5"
        strokeWidth="2"
        d="M0 0H21V21H0z"
        transform="matrix(-1 0 0 1 86 488)"
      ></path>
      <path
        stroke="#F5F5F5"
        strokeWidth="2"
        d="M0 0H21V21H0z"
        transform="matrix(-1 0 0 1 86 467)"
      ></path>
      <path
        stroke="#F5F5F5"
        strokeWidth="2"
        d="M0 0H21V21H0z"
        transform="matrix(-1 0 0 1 86 530)"
      ></path>
      <path
        stroke="#F5F5F5"
        strokeWidth="2"
        d="M0 0H21V21H0z"
        transform="matrix(-1 0 0 1 44 509)"
      ></path>
      <path
        stroke="#F5F5F5"
        strokeWidth="2"
        d="M0 0H21V21H0z"
        transform="matrix(-1 0 0 1 44 488)"
      ></path>
      <path
        stroke="#F5F5F5"
        strokeWidth="2"
        d="M0 0H21V21H0z"
        transform="matrix(-1 0 0 1 44 530)"
      ></path>
      <path
        stroke="#F5F5F5"
        strokeWidth="2"
        d="M0 0H21V21H0z"
        transform="matrix(-1 0 0 1 44 551)"
      ></path>
      <path
        stroke="#F5F5F5"
        strokeWidth="2"
        d="M0 0H21V21H0z"
        transform="matrix(-1 0 0 1 22 296)"
      ></path>
      <path
        stroke="#F5F5F5"
        strokeWidth="2"
        d="M0 0H21V21H0z"
        transform="matrix(-1 0 0 1 701 169)"
      ></path>
      <path
        stroke="#F5F5F5"
        strokeWidth="2"
        d="M0 0H21V21H0z"
        transform="matrix(-1 0 0 1 576 22)"
      ></path>
      <path
        stroke="#F5F5F5"
        strokeWidth="2"
        d="M0 0H21V21H0z"
        transform="matrix(-1 0 0 1 534 22)"
      ></path>
      <path
        stroke="#F5F5F5"
        strokeWidth="2"
        d="M0 0H21V21H0z"
        transform="matrix(-1 0 0 1 513 22)"
      ></path>
      <g filter="url(#filter0_dd)">
        <path
          fill="#fff"
          d="M0 0H21V21H0z"
          transform="matrix(-1 0 0 1 194 64)"
        ></path>
        <path
          stroke="#F0F0F0"
          strokeWidth="0.75"
          d="M-0.375 0.375H19.875V20.625H-0.375z"
          transform="matrix(-1 0 0 1 193.25 64)"
        ></path>
      </g>
      <g filter="url(#filter1_dd)">
        <path
          fill="#fff"
          d="M0 0H42V21H0z"
          transform="matrix(-1 0 0 1 722 127)"
        ></path>
        <path
          stroke="#F0F0F0"
          strokeWidth="0.75"
          d="M-0.375 0.375H40.875V20.625H-0.375z"
          transform="matrix(-1 0 0 1 721.25 127)"
        ></path>
      </g>
      <g filter="url(#filter2_dd)">
        <path
          fill="#fff"
          d="M0 0H21V21H0z"
          transform="matrix(-1 0 0 1 534 467)"
        ></path>
        <path
          stroke="#F0F0F0"
          strokeWidth="0.75"
          d="M-0.375 0.375H19.875V20.625H-0.375z"
          transform="matrix(-1 0 0 1 533.25 467)"
        ></path>
      </g>
      <defs>
        <filter
          id="filter0_dd"
          width="71"
          height="71"
          x="148"
          y="54"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="15"></feOffset>
          <feGaussianBlur stdDeviation="12.5"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow"
          ></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="5"></feOffset>
          <feGaussianBlur stdDeviation="5"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.09 0"></feColorMatrix>
          <feBlend
            in2="effect1_dropShadow"
            result="effect2_dropShadow"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect2_dropShadow"
            result="shape"
          ></feBlend>
        </filter>
        <filter
          id="filter1_dd"
          width="92"
          height="71"
          x="655"
          y="117"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="15"></feOffset>
          <feGaussianBlur stdDeviation="12.5"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow"
          ></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="5"></feOffset>
          <feGaussianBlur stdDeviation="5"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.09 0"></feColorMatrix>
          <feBlend
            in2="effect1_dropShadow"
            result="effect2_dropShadow"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect2_dropShadow"
            result="shape"
          ></feBlend>
        </filter>
        <filter
          id="filter2_dd"
          width="71"
          height="71"
          x="488"
          y="457"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="15"></feOffset>
          <feGaussianBlur stdDeviation="12.5"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow"
          ></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="5"></feOffset>
          <feGaussianBlur stdDeviation="5"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.09 0"></feColorMatrix>
          <feBlend
            in2="effect1_dropShadow"
            result="effect2_dropShadow"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect2_dropShadow"
            result="shape"
          ></feBlend>
        </filter>
      </defs>
    </svg>
  );
}

export default Icon;
