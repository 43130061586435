import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";

// import { browserHistory, Router, Route } from "react-router";

//styling
import "./forum.css";
import "../components/cards/cardStyle.css";

//compnents
import ForumPost from "../components/cards/ForumPost";
import Spinner from "../components/loading/Spinner";
import Banner from "../components/header/Banner";
import ForumBack from "../components/backgrounds/ForumBack";

//contnent
import Home from "../components/forum/ForumHome/Home";
import Following from "../components/forum/Following";
import Messages from "../components/forum/ForumMessages/Messages";
import Forum404 from "../components/forum/Forum404";
// import PostContent from "../components/forum/PostContent/PostContent";

//icons

class forum extends Component {
  constructor() {
    super();
    this.banElement = React.createRef();
    this.bannerScroll = this.bannerScroll.bind(this);
  }
  state = {
    lastScrollUp: 0,
    BannerHeight: 0,
    BannerTop: 0,
    BannerDis: 1,
    tabHeight: 0,
    activeTab: 0
  };

  componentDidMount() {
    window.addEventListener("scroll", this.bannerScroll, false);
    this.setState({
      BannerHeight: this.banElement.current.getBoundingClientRect().height
    });
  }
  componentWillUnmount() {
    window.removeEventListener("scroll", this.bannerScroll, false);
  }

  render() {
    let recentPost = this.state.forumPosts ? (
      this.state.forumPosts.map((forumPost, i) => (
        <ForumPost key={forumPost.forumPostId} post={forumPost} />
      ))
    ) : (
      <Spinner color="#939eab" size="24px" />
    );

    let ActivePage = this.state.page ? this.state.page : Forum404;

    return (
      <div>
        <ForumBack />
        <div
          ref={this.banElement}
          className="banner-behavior-wrap shadow"
          style={{
            top: this.state.BannerTop,
            opacity: this.state.BannerDis
          }}
        >
          <Banner />
        </div>

        <div
          className="forum-content"
          style={{
            paddingTop: this.state.BannerHeight
          }}
        >
          <Switch>
            <Route
              exact
              path={`/forum`}
              render={props => (
                <Home
                  {...props}
                  BannerHeight={this.state.BannerHeight}
                  toTop={this.state.BannerTop + this.state.BannerHeight + 72}
                />
              )}
            />
            <Route path={`/forum/new`} component={Home} />
            <Route path={`/forum/topic`} component={Home} /> ///get rid of
            <Route path={`/forum/topics`} component={Following} />
            <Route path={`/forum/messages`} component={Messages} />
          </Switch>
        </div>
      </div>
    );
  }

  bannerScroll() {
    var banner = this.banElement.current.getBoundingClientRect(),
      lastScrollUp = this.state.lastScrollUp,
      scroll = window.scrollY;

    if (scroll > banner.height - this.state.tabHeight) {
      if (!lastScrollUp) {
        lastScrollUp = scroll;
      }

      var tabHeightRaw = lastScrollUp - scroll;
      var tabHeight = Math.max(Math.min(tabHeightRaw, 48), 0);

      if (tabHeightRaw > 48) {
        lastScrollUp = scroll + 48;
      }

      if (tabHeightRaw < 0) {
        lastScrollUp = 0;
      }
      this.setState({
        BannerTop: -banner.height + tabHeight,
        tabHeight: tabHeight,
        BannerDis: tabHeight ? 1 : 0,
        lastScrollUp: lastScrollUp
      });
    } else {
      this.setState({
        BannerTop: -scroll,
        BannerDis: 1
      });
    }
  }
}

export default forum;
