import React, { Component } from "react";
import { withRouter } from "react-router-dom";

//Styling
import "./HeaderStyle.css";

//component
import Navbar from "./header/Navbar";
import HeaderALert from "./header/HeaderALert";
// import Banner from "./header/Banner";

class Header extends Component {
  state = {
    headStatus: null,
    uesrprop: null
  };

  componentDidUpdate(prevProps) {
    const currentPath = this.props.location.pathname;
    if (currentPath !== prevProps.location.pathname) {
      this.addShadow();
    }
  }

  render() {
    return (
      <div className={`head-container ${this.state.headStatus}`}>
        <Navbar />
      </div>
    );
  }

  componentDidMount() {
    window.addEventListener("scroll", this.addShadow.bind(this));
  }

  //!display.includes("/forum") !display.includes("/signin")
  addShadow() {
    let pages = ["/forum", "/auth", "/plan"];
    let display = this.props.location.pathname;

    window.scrollY > 0 ||
    (display !== "/" && !pages.some(el => display.includes(el)))
      ? this.setState({ headStatus: "active" })
      : this.setState({ headStatus: "" });
  }
}

export default withRouter(Header);
