import React from "react";

const check = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={props.size ? props.size : "24"}
    viewBox="0 0 24 24"
  >
    <path
      fill={props.colorPrimary ? props.colorPrimary : "#02bfa6"}
      d="M21 7L9 19l-5.5-5.5 1.41-1.41L9 16.17 19.59 5.59 21 7z"
    ></path>
  </svg>
);

export default check;
