import React, { Component } from "react";
import { NavLink } from "react-router-dom";

//component
import Logo from "../../media/Logo";
import MenuClose from "../../media/icons/MenuClose";
import ChevronRight from "../../media/icons/ChevronRight";
import Button from "../buttons/Button";

//Styling
import "./SideNavStyle.css";

class SideNav extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: this.props.show
    };
    this.closeNav = this.closeNav.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.node = React.createRef();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.show !== this.state.show) {
      this.hideSideNav(!this.props.show);
    }
  }

  hideSideNav = comand => {
    this.setState({
      show: comand
    });
    if (comand) {
      document.addEventListener("click", this.handleClick);
    } else {
      document.removeEventListener("click", this.handleClick);
    }
  };

  handleClick(e) {
    if (!this.node.contains(e.target)) {
      this.closeNav(false);
    }
  }

  closeNav() {
    console.log("closeNav");
    this.hideSideNav(false);
    this.props.handler();
  }

  render() {
    const activeNavBack = this.state.show ? "active-sideNav-back" : "";
    const activeNav = this.state.show ? "active-sideNav" : "";

    return (
      <div className={`side-nav-back ${activeNavBack}`}>
        <div
          ref={node => (this.node = node)}
          className={`side-nav ${activeNav}`}
        >
          <div className="sidenav-header">
            <Button onClick={this.closeNav} className="svg-button nav-menu">
              <MenuClose className="" colorPrimary="#1C252E" size="24px" />
            </Button>
            <NavLink
              style={{ paddingLeft: "0" }}
              to="/"
              onClick={this.closeNav}
            >
              <Logo
                className="nav-Logo"
                colorPrimary="#1C252E"
                colorSecondary="#34A8FE"
                height="22"
              />
            </NavLink>
          </div>
          <NavLink
            onClick={this.closeNav}
            className="outskrts"
            to="/forum"
            activeClassName="sidenav-selected"
          >
            Forum
            <ChevronRight className="" colorPrimary="#D2D6D9" size="24px" />
          </NavLink>
          <div className="sidenav-subnav">
            <NavLink
              onClick={this.closeNav}
              to="/forum/following"
              activeClassName="sidenav-selected"
            >
              Following
            </NavLink>
            <NavLink
              onClick={this.closeNav}
              to="/forum/saved"
              activeClassName="sidenav-selected"
            >
              Saved
            </NavLink>
            <NavLink
              onClick={this.closeNav}
              to="/forum/messages"
              activeClassName="sidenav-selected"
            >
              Messages
            </NavLink>
          </div>
          <NavLink
            onClick={this.closeNav}
            className="outskrts"
            to="/products"
            activeClassName="sidenav-selected"
          >
            Products
            <ChevronRight className="" colorPrimary="#D2D6D9" size="24px" />
          </NavLink>
          <NavLink
            onClick={this.closeNav}
            className="outskrts"
            to="/pricing"
            activeClassName="sidenav-selected"
          >
            Pricing
            <ChevronRight className="" colorPrimary="#D2D6D9" size="24px" />
          </NavLink>
          <NavLink
            onClick={this.closeNav}
            className="outskrts"
            to="/support"
            activeClassName="sidenav-selected"
          >
            Support
            <ChevronRight className="" colorPrimary="#D2D6D9" size="24px" />
          </NavLink>
          <div className="sidenav-subnav">
            <NavLink
              onClick={this.closeNav}
              to="/support/tickets"
              activeClassName="sidenav-selected"
            >
              Tickets
            </NavLink>
          </div>
          <a
            href="https://console.us-flipit.com"
            to="/"
            onClick={this.closeNav}
            className="outskrts"
            style={{ color: "#34a8fe", borderBottom: "0.5px solid #e8eaed" }}
          >
            Go to Console
            <ChevronRight className="" colorPrimary="#34a8fe" size="24px" />
          </a>
          <p className="sidenav-legal">© FLIPit inc. All rights reserved</p>
        </div>
      </div>
    );
  }
}

export default SideNav;
