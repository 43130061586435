import React, { Component } from "react";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import Button from "../buttons/Button";

//Styling
import "./ContentSectionStyle.css";
import "../buttons/buttonStyle.css";

import forumImg from "../../media/images/FliitForum.png";

class ContSection extends Component {
  constructor(props) {
    super(props);
    this.state = { height: 0, animate: false };

    this.contentbox = React.createRef();
    this.bannerScroll = this.bannerScroll.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    window.addEventListener("scroll", this.bannerScroll, false);
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.bannerScroll, false);
    window.removeEventListener("resize", this.updateWindowDimensions);
  }
  updateWindowDimensions() {
    let trigger =
      window.innerWidth < 768
        ? window.innerHeight + window.innerHeight * 0.3
        : window.innerHeight + 48;
    this.setState({ height: trigger });
  }

  bannerScroll() {
    const { height, animate } = this.state;
    var container = this.contentbox.current.getBoundingClientRect();

    if (container.bottom < height && !animate) {
      return this.setState({
        animate: true,
      });
    }
    if (container.bottom > height && animate) {
      return this.setState({
        animate: false,
      });
    }
  }

  render() {
    const { title, text, linkTo, buttonText, backgroundSvg } = this.props;

    return (
      <section
        ref={this.contentbox}
        className={`content-section-container centered ${
          this.state.animate ? "content-section-anim-active" : ""
        }`}
      >
        <div className="content-section-background">{backgroundSvg}</div>
        <div className="content-section-cont-wrap sizing centered">
          <div className="content-section-image-wrap content-section-image-wrap-1 centered-col">
            <img src={forumImg} />
          </div>
          <div className="content-section-text-wrap ">
            <div className="content-sec-fade-anim">
              <h2>{title}</h2>
              <p>{text}</p>
            </div>
            <div className="content-sec-fade-anim-pop">
              <HashLink to={`${linkTo}#`} className="button button-blue ">
                {buttonText}
              </HashLink>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default ContSection;
