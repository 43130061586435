import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="93"
      height="74"
      fill="none"
      viewBox="0 0 93 74"
    >
      <g clip-path="url(#clip0)">
        <path fill="#fff" d="M0 0H93V74H0z"></path>
        <path
          fill="#E5EAEE"
          d="M10.623 44.787l8.568-5.729c.965-.645 1.989-1.2 3.057-1.654l1.059-.45a20.003 20.003 0 015.326-1.441l21.662-2.74a20 20 0 0116.268 5.328l2.44 2.312a13.733 13.733 0 007.813 3.669l4.868.583c12.36 1.482 15.007 18.253 3.703 23.468a12.345 12.345 0 01-8.126.777l-26.109-6.434a20 20 0 00-15.254 2.377l-8.903 5.47a15.169 15.169 0 01-8.81 2.219c-14.53-.835-19.66-19.666-7.562-27.755z"
        ></path>
        <ellipse
          cx="79.236"
          cy="52.846"
          fill="#CDD1D4"
          fillOpacity="0.5"
          rx="5.345"
          ry="3.318"
        ></ellipse>
        <path
          fill="#CDD1D4"
          d="M77.758 52.478v-5.345l1.659-.738v6.083c-.369.553-1.475.553-1.66 0z"
        ></path>
        <path
          fill="#F0F3F6"
          d="M78.684 15.984c-2.949 9.277-8.92 27.905-9.215 28.2 2.949 5.16 15.666 5.529 18.43 0l-9.215-28.2z"
        ></path>
        <path
          fill="#CDD1D4"
          d="M87.9 44.184l-9.215-28.2-3.318 31.823c4.782 1.064 10.743-.044 12.533-3.623z"
        ></path>
        <ellipse
          cx="52.578"
          cy="70.54"
          fill="#E5EAEE"
          rx="4.581"
          ry="2.844"
        ></ellipse>
        <path
          fill="#CDD1D4"
          d="M51.32 70.225v-4.582l1.422-.632v5.214c-.316.474-1.264.474-1.422 0z"
        ></path>
        <path
          fill="#F0F3F6"
          d="M52.11 38.944c-2.528 7.952-7.646 23.919-7.9 24.171 2.529 4.424 13.43 4.74 15.8 0l-7.9-24.17z"
        ></path>
        <path
          fill="#CDD1D4"
          d="M60.008 63.115l-7.899-24.17-2.843 27.276c4.098.912 9.208-.037 10.742-3.106z"
        ></path>
        <ellipse
          cx="42.774"
          cy="53.295"
          fill="#CDD1D4"
          fillOpacity="0.5"
          rx="3.731"
          ry="2.296"
        ></ellipse>
        <path
          fill="#CDD1D4"
          d="M40.766 53.294V46.98l2.296-2.009v8.323c-.574.574-1.722.574-2.296 0z"
        ></path>
        <path
          fill="#F0F3F6"
          d="M48.803 40.093c0 4.12-3.084 7.462-6.888 7.462s-6.888-3.341-6.888-7.462c0-4.121 3.084-19.23 6.888-19.23s6.888 15.109 6.888 19.23z"
        ></path>
        <path
          fill="#CDD1D4"
          d="M41.919 47.554c3.804 0 6.888-3.34 6.888-7.462 0-4.12-3.084-19.229-6.888-19.229 2.474 15.643 2.69 22.66-3.157 25.863.945.53 2.019.828 3.157.828z"
        ></path>
        <ellipse
          cx="29"
          cy="42.057"
          fill="#CDD1D4"
          fillOpacity="0.5"
          rx="3.731"
          ry="1.765"
        ></ellipse>
        <path
          fill="#CDD1D4"
          d="M26.98 42.058v-4.853l2.296-1.544v6.397c-.573.44-1.721.44-2.296 0z"
        ></path>
        <path
          fill="#F0F3F6"
          d="M35.022 31.911c0 3.168-3.084 5.735-6.888 5.735s-6.888-2.567-6.888-5.735c0-3.167 3.084-14.78 6.888-14.78s6.888 11.613 6.888 14.78z"
        ></path>
        <path
          fill="#CDD1D4"
          d="M28.141 37.646c3.805 0 6.888-2.568 6.888-5.735 0-3.168-3.084-14.78-6.888-14.78 2.475 12.023 2.69 17.417-3.157 19.878.946.407 2.02.637 3.157.637z"
        ></path>
        <ellipse
          cx="68.036"
          cy="55.26"
          fill="#CDD1D4"
          fillOpacity="0.5"
          rx="3.731"
          ry="1.765"
        ></ellipse>
        <path
          fill="#CDD1D4"
          d="M66.02 55.26v-4.852l2.296-1.544v6.397c-.575.44-1.722.44-2.297 0z"
        ></path>
        <path
          fill="#F0F3F6"
          d="M74.061 45.113c0 3.168-3.084 5.736-6.888 5.736s-6.888-2.568-6.888-5.736c0-3.167 3.084-14.779 6.888-14.779s6.888 11.612 6.888 14.78z"
        ></path>
        <path
          fill="#CDD1D4"
          d="M67.177 50.849c3.804 0 6.887-2.568 6.887-5.736 0-3.167-3.083-14.779-6.887-14.779 2.474 12.023 2.69 17.416-3.158 19.878.946.407 2.02.636 3.157.636z"
        ></path>
        <ellipse
          cx="15.796"
          cy="57.886"
          fill="#CDD1D4"
          fillOpacity="0.5"
          rx="8.323"
          ry="5.166"
        ></ellipse>
        <path
          fill="#CDD1D4"
          d="M13.5 57.312V48.99l2.583-1.148v9.471c-.574.861-2.296.861-2.583 0z"
        ></path>
        <path
          fill="#F0F3F6"
          d="M14.936.486C10.344 14.932 1.046 43.938.586 44.397c4.592 8.036 24.395 8.61 28.7 0L14.936.487z"
        ></path>
        <path
          fill="#CDD1D4"
          d="M29.29 44.397L14.94.487 9.772 50.038c7.446 1.657 16.73-.068 19.516-5.642z"
        ></path>
        <ellipse
          cx="38.758"
          cy="70.514"
          fill="#E5EAEE"
          rx="4.305"
          ry="2.87"
        ></ellipse>
      </g>
      <defs>
        <clipPath id="clip0">
          <path fill="#fff" d="M0 0H93V74H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default Icon;
