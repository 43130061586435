import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { Link, withRouter, Route, Switch } from "react-router-dom";

//redux stuff
import { connect } from "react-redux";

//components
import NewPostModal from "./NewPostModal";
import Post from "./ContTypes/Post";
import Question from "./ContTypes/Question";
import Job from "./ContTypes/Job";
import topicInputter from "./ContTypes/topicInputter";

import TabBar from "../../tabs/TabBar";

import MoreDropDown from "../../cards/components/MoreDropDown";
import BulkTopicInput from "../../forum/PostCont/BulkTopicInput";
import AddTopics from "../../../media/icons/AddTopics";

//consoleIcons
import NewPost from "../../../media/icons/NewPost";
import Close from "../../../media/SVGs/None";
import Plus from "../../../media/icons/Plus";

class Following extends Component {
  constructor() {
    super();
  }

  state = {
    open: false,
    modalTab: "",
    body: "",
    errors: {}
  };
  componentWillMount() {
    this.modalCheck();
  }
  componentDidMount() {
    window.addEventListener("scroll", this.buttonTrunc, false);
  }
  componentWillUnmount() {
    window.removeEventListener("scroll", this.buttonTrunc, false);
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.modalCheck();
    }
  }

  modalCheck(path) {
    let locPieces = this.props.location.pathname.split("/");

    if (locPieces[2] === "new") {
      return this.setState({
        open: true,
        modalTab: locPieces[3]
      });
    }
    return this.setState({
      open: false,
      modalTab: ""
    });
  }

  render() {
    const {
      user: { authenticated }
    } = this.props;

    let fullButton = window.scrollY > 24 ? false : true;
    const modalTabs = {
      PostTab: {
        text: "Post",
        path: "/forum/new/post",
        className: "banner-tab",
        activeClassName: "active-banner-tab"
      },
      QuestionTab: {
        text: "Question",
        path: "/forum/new/question",
        className: "banner-tab",
        activeClassName: "active-banner-tab"
      },
      JobTab: {
        text: "Job",
        path: "/forum/new/job",
        className: "banner-tab",
        activeClassName: "active-banner-tab"
      }
    };

    if (this.props.user.credentials.accountType === "admin")
      modalTabs.TopicTab = {
        text: "Topics",
        path: "/forum/new/topic",
        className: "banner-tab",
        activeClassName: "active-banner-tab"
      };

    const dopElm = [
      {
        icon: AddTopics,
        lable: "Add Topics",
        comp: BulkTopicInput
      }
    ];

    let popContent = (
      <NewPostModal
        open={this.state.open}
        handleClose={this.handleClose}
        hist={this.props.history}
      >
        <div className="outskrts content-pop-header">
          <TabBar content={modalTabs} root="/forum/new/" />
          <button
            className="button button-silver-text content-pop-header-close"
            onClick={() => this.props.history.push("/forum")}
          >
            <Close size="24px" />
            <div className="desktop-only">Close</div>
          </button>
        </div>

        <Switch>
          <Route exact path={`/forum/new/post`} component={Post} />
          <Route path={`/forum/new/question`} component={Question} />
          <Route path={`/forum/new/job`} component={Job} />
          <Route path={`/forum/new/topic`} component={topicInputter} />
        </Switch>
      </NewPostModal>
    );

    return (
      <Fragment>
        <Link
          className={`button button-blue centered post-botton ${
            fullButton ? "" : "post-botton-sml "
          }`}
          to="/forum/new/post"
        >
          <NewPost className="" colorPrimary="#fff" size="24px" />
          <div
            className={`button-text ${fullButton ? "button-mobile-adj" : ""}`}
          >
            New Post
          </div>
        </Link>

        {this.state.open ? popContent : ""}
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user
});

export default connect(mapStateToProps)(withRouter(Following));
