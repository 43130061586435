import React, { Component } from "react";

import { HashLink as Link } from "react-router-hash-link";

import "./ConsoleWidgetStyle.css";

// components
import ConsoleSection from ".//ConsoleSection";

//images
import consoleImg from "../../media/images/Flipit-Console.png";
// import Console from "./console/Console";

//icons
import ChevronRight from "../../media/icons/ChevronRight";
import FlippabilitySearch from "../../media/icons/console/FlippabilitySearch";
import ProjectBuilder from "../../media/icons/console/ProjectBuilder";
import Todo from "../../media/icons/console/Todo";
import Listing from "../../media/icons/console/Listing";
import LeftTile from "../../media/SVGs/console-back-left";
import RightTile from "../../media/SVGs/console-back-right";

class ConsoleWidget extends Component {
  constructor(props) {
    super(props);
    this.state = {
      top: 0,
      position: "absolute",
      bottom: "auto",
      activeTab: "flip",
    };
    this.homeConsoleContainer = React.createRef();
    this.pageScroll = this.pageScroll.bind(this);
    this.productSelect = this.productSelect.bind(this);
  }

  componentDidMount() {
    window.addEventListener("scroll", this.pageScroll, false);
  }
  componentWillUnmount() {
    window.removeEventListener("scroll", this.pageScroll, false);
  }
  productSelect(e) {
    this.setState({
      activeTab: e.target.id,
    });
  }

  render() {
    let { description } = this.props;
    return (
      <section
        className="home-console-container"
        ref={this.homeConsoleContainer}
      >
        <div className="home-console-container-background">
          <LeftTile />
          <RightTile />
        </div>
        <div className="sizing home-console">
          <div className="home-console-header">
            <h2>Meet the Console</h2>
            <p className="">
              All the tools and features you need in one place! The Console is
              home to FlipIt’s product suite, organized to help you intuitively
              manage every aspect of your projects.
            </p>
          </div>
          <ConsoleSection />
          <Link to="/products#products-top" className="centered-col">
            <button
              className="button button-blue centered"
              style={{ fontSize: "1rem" }}
            >
              More prodcuts
              <ChevronRight colorPrimary="#fff" size="24px" />
            </button>
          </Link>
        </div>
      </section>
    );
  }

  pageScroll() {
    // console.log("scrolled");
    var container = this.homeConsoleContainer.current.getBoundingClientRect();

    // if (48 > container.top && container.bottom > window.innerHeight) {
    //   // console.log("active");
    //   this.setState({
    //     top: -container.top + 48
    //   });
    // }

    if (48 <= container.top) {
      // console.log("active");
      this.setState({
        position: "absolute",
        top: 0,
        bottom: "auto",
      });
    }
    if (48 > container.top && container.bottom > window.innerHeight) {
      // console.log("active");
      this.setState({
        position: "fixed",
        top: 48,
        bottom: "auto",
      });
    }
    if (container.bottom <= window.innerHeight) {
      this.setState({
        position: "absolute",
        top: "auto",
        bottom: 0,
      });
    }
  }
}

export default ConsoleWidget;

// <img src={consoleImg} alt="Flipit-Console " />
//
// <div className="console-product-button-wrap">
//   <ConsoleProduct
//     id="flip"
//     product="Flippability Search"
//     description="Very brief description"
//     activation={this.state.activeTab}
//     paddedFunction={this.productSelect}
//     icon={<FlippabilitySearch size="24px" colorPrimary="#fff" />}
//   />
//   <ConsoleProduct
//     id="build"
//     paddedFunction={this.productSelect}
//     product="Project Builder"
//     description="Very brief description"
//     activation={this.state.activeTab}
//     icon={<ProjectBuilder size="24px" colorPrimary="#fff" />}
//   />
//   <ConsoleProduct
//     id="todo"
//     paddedFunction={this.productSelect}
//     product="To-do"
//     description="Very brief description"
//     activation={this.state.activeTab}
//     icon={<Todo size="24px" colorPrimary="#fff" />}
//   />
//   <ConsoleProduct
//     id="list"
//     paddedFunction={this.productSelect}
//     product="Auto Listing"
//     description="Very brief description"
//     activation={this.state.activeTab}
//     icon={<Listing size="24px" colorPrimary="#fff" />}
//   />
