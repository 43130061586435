//User reducer types
export const SET_AUTHENTICATED = "SET_AUTHENTICATED";
export const SET_UNAUTHENTICATED = "SET_UNAUTHENTICATED";
export const SET_USER = "SET_USER";
export const LOADING_USER = "LOADING_USER";
export const STOP_LOADING_USER = "STOP_LOADING_USER";
export const MARK_NOTIFICATIONS_READ = "MARK_NOTIFICATIONS_READ";

export const SET_MAILLISTSUB = "SET_MAILLISTSUB";
export const SET_MAILLISTUNSUB = "SET_MAILLISTUNSUB";
export const LOADING_SUB = "LOADING_SUB";

// export const CHECK_USERNAM = "CHECK_USERNAM";
export const SET_NEWUSER = "SET_NEWUSER";
export const UNSET_NEWUSER = "UNSET_NEWUSER";

//UI reducer types
export const SET_ERRORS = "SET_ERRORS";
export const CLEAR_ERRORS = "CLEAR_ERRORS";
export const LOADING_UI = "LOADING_UI";
export const LOADING_DATA = "LOADING_DATA";
export const STOP_LOADING_UI = "STOP_LOADING_UI";

//Data reducer types
export const SET_FORUMPOSTS = "SET_FORUMPOSTS";
export const SET_FORUMPOST = "SET_FORUMPOST";
export const LIKE_FORUMPOST = "LIKE_FORUMPOST";
export const UNLIKE_FORUMPOST = "UNLIKE_FORUMPOST";
// export const ADD_LIKE = "ADD_LIKE";
export const DELETE_PREVIEW = "DELETE_PREVIEW";
export const DELETE_FORUMPOST = "DELETE_FORUMPOST";
export const POST_FORUMPOST = "POST_FORUMPOST";
export const SUBMIT_COMMENT = "SUBMIT_COMMENT";
export const DELETE_FORUMCOM = "DELETE_FORUMCOM";
export const LOADING_COMMENT = "LOADING_COMMENT";
