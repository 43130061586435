import React from "react";
// import { Link } from "react-router-dom";

// import axios from "axios";
import axios from "axios";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

// //firebase
// import firebase from "../firebase";

//styling
import "./SwitchStyle.css";

//Components
import Button from "../buttons/Button";

//redux stuff
import { connect } from "react-redux";
import { setPlan } from "../../redux/actions/userActions";

// icons
import BetaUser from "../../media/icons//Beta";
import FreeUser from "../../media/icons/badges/FreeUser";
import Flipper from "../../media/icons/badges/Flipper";
import ProFlipper from "../../media/icons/badges/ProFlipper";
import Enterprise from "../../media/icons/badges/Enterprise";

const planCard = props => {
  const { test, active } = props;
  return (
    <div
      className={`switch-comp-container centered-col ${
        !active ? "" : "active-switch "
      }`}
    >
      <div className="switch-comp-wrapper">
        <div className="switch-comp-dot"></div>
      </div>
    </div>
  );
};

export default planCard;
