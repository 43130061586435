import React from "react";

const Archive = (props) => (
  <svg
    className={props.className}
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
  >
    <path
      fill={props.colorPrimary}
      d="M20 21H4V10h2v9h12v-9h2v11zM3 3h18v6H3V3zm6.5 8h5c.28 0 .5.22.5.5V13H9v-1.5c0-.28.22-.5.5-.5zM5 5v2h14V5H5z"
    />
  </svg>
);

export default Archive;
