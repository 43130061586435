import React from "react";

const PlayStore = props => (
  <svg
    className={props.className}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 107.96 32"
    height={props.size}
  >
    <rect fill="none" />
    <path
      fill="none"
      stroke={props.colorPrimary}
      d="M103.96,31.5H4c-1.96,0-3.5-1.54-3.5-3.5V4.01c0-1.96,1.54-3.5,3.5-3.5h99.97c1.96,0,3.5,1.54,3.5,3.5V28
      	C107.46,29.96,105.93,31.5,103.96,31.5z"
    />
    <path
      fill={props.colorPrimary}
      d="M37.91,8.16c0,0.64-0.16,1.2-0.56,1.6c-0.48,0.48-1.04,0.72-1.76,0.72c-0.72,0-1.28-0.24-1.76-0.72
      	C33.35,9.28,33.11,8.72,33.11,8s0.24-1.28,0.72-1.76c0.48-0.48,1.04-0.72,1.76-0.72c0.32,0,0.64,0.08,0.96,0.24
      	c0.32,0.16,0.56,0.32,0.72,0.56l-0.4,0.4c-0.32-0.4-0.72-0.56-1.28-0.56c-0.48,0-0.96,0.16-1.28,0.56c-0.4,0.32-0.56,0.8-0.56,1.36
      	c0,0.56,0.16,1.04,0.56,1.36c0.4,0.32,0.8,0.56,1.28,0.56c0.56,0,0.96-0.16,1.36-0.56c0.24-0.24,0.4-0.56,0.4-0.96h-1.76V7.84h2.32
      	V8.16z M41.59,6.16h-2.16v1.52h2v0.56h-2v1.52h2.16v0.64h-2.8V5.6h2.8V6.16z M44.23,10.4h-0.64V6.16h-1.36V5.6h3.36v0.56h-1.36V10.4
      	z M47.9,10.4V5.6h0.64v4.8H47.9z M51.26,10.4h-0.64V6.16h-1.36V5.6h3.28v0.56h-1.36v4.24H51.26z M58.86,9.76
      	c-0.48,0.48-1.04,0.72-1.76,0.72c-0.72,0-1.28-0.24-1.76-0.72C54.86,9.28,54.62,8.72,54.62,8s0.24-1.28,0.72-1.76
      	c0.48-0.48,1.04-0.72,1.76-0.72c0.72,0,1.28,0.24,1.76,0.72c0.48,0.48,0.72,1.04,0.72,1.76S59.34,9.28,58.86,9.76z M55.82,9.36
      	c0.32,0.32,0.8,0.56,1.28,0.56c0.48,0,0.96-0.16,1.28-0.56c0.32-0.32,0.56-0.8,0.56-1.36s-0.16-1.04-0.56-1.36
      	c-0.32-0.32-0.8-0.56-1.28-0.56c-0.48,0-0.96,0.16-1.28,0.56C55.5,6.96,55.26,7.44,55.26,8S55.42,9.04,55.82,9.36z M60.46,10.4V5.6
      	h0.72l2.32,3.76V5.6h0.64v4.8H63.5l-2.48-3.92v3.92H60.46z"
    />
    <path
      fill={props.colorPrimary}
      d="M54.46,17.44c-1.92,0-3.44,1.44-3.44,3.44c0,1.92,1.52,3.44,3.44,3.44s3.44-1.44,3.44-3.44
      	C57.9,18.8,56.38,17.44,54.46,17.44z M54.46,22.88c-1.04,0-1.92-0.88-1.92-2.08c0-1.2,0.88-2.08,1.92-2.08
      	c1.04,0,1.92,0.8,1.92,2.08C56.38,22,55.5,22.88,54.46,22.88z M47.02,17.44c-1.92,0-3.44,1.44-3.44,3.44c0,1.92,1.52,3.44,3.44,3.44
      	s3.44-1.44,3.44-3.44C50.46,18.8,48.94,17.44,47.02,17.44z M47.02,22.88c-1.04,0-1.92-0.88-1.92-2.08c0-1.2,0.88-2.08,1.92-2.08
      	c1.04,0,1.92,0.8,1.92,2.08C48.94,22,48.06,22.88,47.02,22.88z M38.15,18.48v1.44h3.44c-0.08,0.8-0.4,1.44-0.8,1.84
      	c-0.48,0.48-1.28,1.04-2.64,1.04c-2.16,0-3.76-1.68-3.76-3.84c0-2.16,1.68-3.84,3.76-3.84c1.12,0,2,0.48,2.64,1.04l1.04-1.04
      	c-0.88-0.8-2-1.44-3.6-1.44c-2.88,0-5.36,2.4-5.36,5.28s2.48,5.28,5.36,5.28c1.6,0,2.72-0.48,3.68-1.52
      	c0.96-0.96,1.28-2.32,1.28-3.36c0-0.32,0-0.64-0.08-0.88H38.15z M74.45,19.6c-0.32-0.8-1.12-2.16-2.88-2.16s-3.2,1.36-3.2,3.44
      	c0,1.92,1.44,3.44,3.36,3.44c1.52,0,2.48-0.96,2.8-1.52L73.41,22c-0.4,0.56-0.88,0.96-1.68,0.96c-0.8,0-1.28-0.32-1.68-1.04
      	L74.61,20L74.45,19.6z M69.81,20.72c0-1.28,1.04-2,1.76-2c0.56,0,1.12,0.32,1.28,0.72L69.81,20.72z M66.06,24h1.52V14h-1.52V24z
      	 M63.66,18.16c-0.4-0.4-1.04-0.8-1.84-0.8c-1.68,0-3.28,1.52-3.28,3.44s1.52,3.36,3.28,3.36c0.8,0,1.44-0.4,1.76-0.8h0.08v0.48
      	c0,1.28-0.72,2-1.84,2c-0.88,0-1.52-0.64-1.68-1.2l-1.28,0.56c0.4,0.88,1.36,2,3.04,2c1.76,0,3.2-1.04,3.2-3.52V17.6h-1.44V18.16z
      	 M61.9,22.88c-1.04,0-1.92-0.88-1.92-2.08c0-1.2,0.88-2.08,1.92-2.08s1.84,0.88,1.84,2.08C63.74,22,62.94,22.88,61.9,22.88z
      	 M81.41,14h-3.6v10h1.52v-3.76h2.08c1.68,0,3.28-1.2,3.28-3.12S83.09,14,81.41,14z M81.49,18.8h-2.16v-3.44h2.16
      	c1.12,0,1.76,0.96,1.76,1.68C83.17,17.92,82.53,18.8,81.49,18.8z M90.69,17.36c-1.12,0-2.24,0.48-2.64,1.52l1.36,0.56
      	c0.32-0.56,0.8-0.72,1.36-0.72c0.8,0,1.52,0.48,1.6,1.28v0.08c-0.24-0.16-0.88-0.4-1.52-0.4c-1.44,0-2.88,0.8-2.88,2.24
      	c0,1.36,1.2,2.24,2.48,2.24c1.04,0,1.52-0.48,1.92-0.96h0.08V24h1.44v-3.84C93.73,18.4,92.37,17.36,90.69,17.36z M90.53,22.88
      	c-0.48,0-1.2-0.24-1.2-0.88c0-0.8,0.88-1.04,1.6-1.04c0.64,0,0.96,0.16,1.36,0.32C92.13,22.24,91.33,22.88,90.53,22.88z M98.92,17.6
      	l-1.68,4.32h-0.08l-1.76-4.32h-1.6l2.64,6.08l-1.52,3.36h1.52l4.08-9.44H98.92z M85.49,24h1.52V14h-1.52V24z"
    />
    <path
      fill={props.colorPrimary}
      d="M7.42,24.5v-17c0-0.59,0.34-1.11,0.84-1.35L18.11,16l-9.85,9.85C7.76,25.6,7.42,25.09,7.42,24.5z M21.23,19.12
      	l-10.76,6.22l8.49-8.49L21.23,19.12z M24.58,14.81c0.34,0.27,0.59,0.69,0.59,1.19c0,0.5-0.22,0.9-0.57,1.18l-2.29,1.32l-2.5-2.5
      	l2.5-2.5L24.58,14.81z M10.47,6.66l10.76,6.22l-2.27,2.27L10.47,6.66z"
    />
  </svg>
);

export default PlayStore;
