import React, { Component } from "react";

//Styling
import "./FilterColumnStyle.css";

//components
import FilterBlock from "./FilterColumnBlock";
import DropBlock from "./FilterColumnDropBlock";
import SrchBlock from "./FilterColumnSrchBlock";

//icons
import Filter from "../../../media/icons/Filter";
import Collapse from "../../../media/icons/ChevronDown";
import Checkbox from "../../../media/icons/Square";
import CheckedCheckbox from "../../../media/icons/CheckedCheckbox";

import Flame from "../../../media/icons/Flame";
import New from "../../../media/icons/New";
import Likes from "../../../media/icons/Like";

class ContSection extends Component {
  constructor(props) {
    super(props);
    this.state = { collapsed: false };
    // this.contentbox = React.createRef();
    // this.bannerScroll = this.bannerScroll.bind(this);
    // this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  toggleTopic = topic => {
    this.setState({
      [topic]: !this.state[topic]
    });
  };

  render() {
    let sortArr = [
      {
        label: "Hot",
        postFilter: "score",
        icon: <Flame size="24" colorPrimary="#949ca6" />
      },
      {
        label: "New",
        postFilter: "createdAt",
        icon: <New size="24" colorPrimary="#949ca6" />
      },
      {
        label: "Likes",
        postFilter: "likeCount",
        icon: <Likes size="24" colorPrimary="#949ca6" />
      }
    ];
    let typeArr = [
      { label: "Posts", follows: "" },
      { label: "Questions", follows: "" },
      { label: "Research", follows: "" }
    ];
    let topicsArr = [
      { label: "Topic1", follows: 0 },
      { label: "Topic2", follows: 0 },
      { label: "Topic3", follows: 0 },
      { label: "Topic4", follows: 0 },
      { label: "Topic5", follows: 0 },
      { label: "Topic6", follows: 0 },
      { label: "Topic7", follows: 0 },
      { label: "Topic8", follows: 0 },
      { label: "Topic9", follows: 0 },
      { label: "Topic10", follows: 0 },
      { label: "Topic1", follows: 0 },
      { label: "Topic2", follows: 0 },
      { label: "Topic3", follows: 0 },
      { label: "Topic4", follows: 0 },
      { label: "Topic5", follows: 0 },
      { label: "Topic6", follows: 0 },
      { label: "Topic7", follows: 0 },
      { label: "Topic8", follows: 0 },
      { label: "Topic9", follows: 0 },
      { label: "Topic10", follows: 0 }
    ];
    const { collapsed } = this.state;
    // toTop

    return (
      <div
        className=" filter-container filter-col-cont"
        style={{
          top: this.props.toTop,
          height: window.innerHeight + 24 - this.props.toTop
        }}
      >
        <div className="filter-col-head centered">
          <Filter size="24" colorPrimary="#28323C" />
          Filter
        </div>
        <div className="filter-cont-wrap">
          <DropBlock
            sorts={sortArr}
            title={"Sort"}
            filterFunc={this.props.filterFunc}
          />
          <FilterBlock topics={typeArr} title={"Type"} />
          <SrchBlock title={"Location"} />
          <FilterBlock topics={topicsArr} title={"Topics"} />
        </div>
      </div>
    );
  }
}

export default ContSection;
