import React from "react";

//Styling
import "./cardStyle.css";

const Card = props => (
  <div className={`card ${props.className}`} style={props.extStyle}>
    {props.children}
  </div>
);

export default Card;
