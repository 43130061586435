import React, { Component } from "react";

import { HashLink as Link } from "react-router-hash-link";

import "./ConsoleSectionStyle.css";

// components
import Button from "../buttons/Button";

//images
import consoleLg from "../../media/images/consoleLg.png";
import consoleSml from "../../media/images/consoleSml.png";
import consoleSml1 from "../../media/images/consoleSml1.png";

//icons
import Todo from "../../media/icons/console/Todo";
import FlippabilitySearch from "../../media/icons/console/FlippabilitySearch";
import ProjectBuilder from "../../media/icons/console/ProjectBuilder";

class ConsoleWidget extends Component {
  constructor(props) {
    super(props);
    this.state = { height: 0, animate: false };

    this.contentbox = React.createRef();
    this.bannerScroll = this.bannerScroll.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    window.addEventListener("scroll", this.bannerScroll, false);
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.bannerScroll, false);
    window.removeEventListener("resize", this.updateWindowDimensions);
  }
  updateWindowDimensions() {
    let trigger =
      window.innerWidth < 768
        ? window.innerHeight + window.innerHeight * 0.3
        : window.innerHeight + 48;
    this.setState({ height: trigger });
  }

  bannerScroll() {
    const { height, animate } = this.state;
    var container = this.contentbox.current.getBoundingClientRect();
    var adjustment = height - window.innerWidth * 0.1;

    if (container.bottom < adjustment && !animate) {
      return this.setState({
        animate: true
      });
    }
    if (container.bottom > adjustment && animate) {
      return this.setState({
        animate: false
      });
    }
  }

  render() {
    let { description } = this.props;
    return (
      <div
        className="home-console-new-container sizing centered"
        ref={this.contentbox}
      >
        <Link
          to="/products/#Todo"
          className="console-slider-container console-slider-left console-tab-wrap-lft"
        >
          <img
            className={`sml-console-img console-img sml-console-img-left ${
              this.state.animate ? "console-image-active" : ""
            }`}
            src={consoleSml1}
            alt="Flipit-Console "
          />
          <span
            className={`badge-ilnk-text centered-col sml-console-img-left ${
              this.state.animate ? "console-image-active" : ""
            }`}
          >
            To-do
          </span>

          <div
            className={`console-img-badge-constainer console-img-badge-lft  ${
              this.state.animate ? "console-img-badge-active" : ""
            }`}
          >
            <div
              className={`console-img-badge-wrap console-img-badge-wrap-sml centered-col ${
                this.state.animate ? "animate-badge1" : ""
              }`}
            >
              <div className="console-img-badge centered-col">
                <Todo size="70%" colorPrimary="#fff" />
              </div>
            </div>
          </div>
        </Link>
        <div className="lrg-console-img  console-tab-wrap console-tab-wrap-cent">
          <Link to="/products/#FlippabilitySearch">
            <img
              className=" console-img"
              src={consoleLg}
              alt="Flipit-Console "
            />
            <span className="badge-ilnk-text centered-col">
              Flippability
              <br />
              Search
            </span>

            <div
              className={`console-img-badge-constainer console-img-badge-constainer-lg ${
                this.state.animate ? "console-img-badge-active" : ""
              }`}
            >
              <div
                className={`console-img-badge-wrap centered-col ${
                  this.state.animate ? "animate-badge2" : ""
                }`}
              >
                <div className="console-img-badge centered-col">
                  <FlippabilitySearch size="70%" colorPrimary="#fff" />
                </div>
              </div>
            </div>
          </Link>
        </div>
        <Link
          to="/products/#ProjectBuilder"
          className="console-slider-container  console-slider-right console-tab-wrap-rit"
        >
          <img
            className={`sml-console-img console-img sml-console-img-right  ${
              this.state.animate ? "console-image-active" : ""
            }`}
            src={consoleSml}
            alt="Flipit-Console "
          />
          <span
            className={`badge-ilnk-text centered-col sml-console-img-right ${
              this.state.animate ? "console-image-active" : ""
            }`}
          >
            Project <br />
            Builder
          </span>
          <div
            className={`console-img-badge-constainer console-img-badge-rit ${
              this.state.animate ? "console-img-badge-active" : ""
            }`}
          >
            <div
              className={`console-img-badge-wrap console-img-badge-wrap-sml centered-col ${
                this.state.animate ? "animate-badge3" : ""
              }`}
            >
              <div className="console-img-badge centered-col">
                <ProjectBuilder size="70%" colorPrimary="#fff" />
              </div>
            </div>
          </div>
        </Link>
      </div>
    );
  }
}

export default ConsoleWidget;

//<img src={console} alt="Flipit-Console " />

// style={{
//   position: this.state.position,
//   top: this.state.top,
//   bottom: this.state.bottom
// }}

// <div className="console-img-wrap">
//   <img src={console} alt="Flipit-Console " />
// </div>
// <div className="console-product-button-wrap">
//   <ConsoleProduct
//     product="Flippability Search"
//     description="Very brief description"
//     default="active"
//     icon={<FlippabilitySearch size="24px" colorPrimary="#fff" />}
//   />
//   <ConsoleProduct
//     product="Project Builder"
//     description="Very brief description"
//     default=""
//     icon={<FlippabilitySearch size="24px" colorPrimary="#fff" />}
//   />
//   <ConsoleProduct
//     product="To-do"
//     description="Very brief description"
//     default=""
//     icon={<FlippabilitySearch size="24px" colorPrimary="#fff" />}
//   />
//   <ConsoleProduct
//     product="Auto Listing"
//     description="Very brief description"
//     default=""
//     icon={<FlippabilitySearch size="24px" colorPrimary="#fff" />}
//   />
//   <Link to="/products">
//     <button
//       className="button button-white centered"
//       style={{ fontSize: "1rem" }}
//     >
//       More prodcuts
//       <ChevronRight colorPrimary="#34a8fe" size="24px" />
//     </button>
//   </Link>
// </div>
//
