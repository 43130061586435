import React, { Component } from "react";
import { HashLink as Link } from "react-router-hash-link";
import PropTypes from "prop-types";

//redux stuff
import { connect } from "react-redux";
import {
  subscribeToMailist,
  unsubscribeToMailist,
} from "../redux/actions/userActions";

//Styling
import "./FooterStyle.css";
// import "./buttons/buttonStyle.css";

//component
import Logo from "../media/Logo";
import ContactForm from "../media/icons/ContactForm";
import PinLine from "../media/icons/PinLine";
import MailLine from "../media/icons/MailLine";
import Phone from "../media/icons/Phone";
import MapMarkerLine from "../media/icons/MapMarkerLine";

import Spinner from "./loading/Spinner";

import Facebook from "../media/icons/Facebook";
import Twitter from "../media/icons/Twitter";
import YouTube from "../media/icons/YouTube";
import LinkedIn from "../media/icons/LinkedIn";
import Check from "../media/icons/Check";

import AppStore from "../media/badges/AppStore";
import PlayStore from "../media/badges/PlayStore";

import ButtonLnk from "./buttons/ButtonLnk";

class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: this.props.mailingList,
    };
  }

  handleradioSelect = (e) => {
    this.setState({
      [e.currentTarget.name]: e.currentTarget.value,
    });
  };
  handleSubmit = (event) => {
    event.preventDefault();
    const mailListPackage = {
      email: this.state.email,
    };
    this.props.subscribeToMailist(mailListPackage);
  };
  handleUnsubSubmit = (event) => {
    event.preventDefault();
    const mailListPackage = {
      email: this.state.email,
    };
    this.props.unsubscribeToMailist(mailListPackage);
  };

  render() {
    const {
      user: { authenticated, mailingList, loadingSub },
    } = this.props;

    let unsubForm = (
      <form className="centered " onSubmit={this.handleUnsubSubmit}>
        <button className="button button-silver" type="submit">
          {loadingSub ? (
            <div className="">
              <Spinner color="#fff" bordWidth="3px" size="16px" />
            </div>
          ) : (
            "unsubscribe"
          )}
        </button>
      </form>
    );

    let emailForm = (
      <form className="centered sub-form" onSubmit={this.handleSubmit}>
        <input
          type="email"
          name="email"
          placeholder="email"
          onChange={this.handleradioSelect}
        />
        <button className="svg-button-20" type="submit">
          {loadingSub ? (
            <div className="">
              <Spinner color="#fff" bordWidth="3px" size="16px" />
            </div>
          ) : (
            <Check colorPrimary="#fff" size="24px" />
          )}
        </button>
      </form>
    );

    let subButton = (
      <button
        className="button button-blue futter-sub-button-adj"
        style={{ height: "32px", width: "120px", boxSizing: "border-box" }}
        type="submit"
      >
        Get updates
      </button>
    );

    let subscribeInput = !mailingList
      ? !authenticated
        ? emailForm
        : subButton
      : unsubForm;

    return (
      <div className="footer-container">
        <div className="sizing">
          <div className="footer-wrap">
            <div className="footer-social outskrts-vert">
              <div>
                <Logo
                  className="footer-logo"
                  colorPrimary="#fff"
                  colorSecondary="#34A8FE"
                  height="24"
                />
                <p style={{ marginTop: "16px" }}>
                  Follow us on your favorite platform for news, tips, and marjor
                  updates!
                </p>
              </div>
              <div className="footer-social-icons">
                <ButtonLnk className="svg-button-20 link='google.com'">
                  <Facebook colorPrimary="#8093A2" size="20px" />
                </ButtonLnk>
                <ButtonLnk className="svg-button-20">
                  <Twitter colorPrimary="#8093A2" size="20px" />
                </ButtonLnk>
                <ButtonLnk className="svg-button-20">
                  <LinkedIn colorPrimary="#8093A2" size="20px" />
                </ButtonLnk>
                <ButtonLnk className="svg-button-20">
                  <YouTube colorPrimary="#8093A2" size="20px" />
                </ButtonLnk>
              </div>
            </div>
            <div>
              <h4>Contact</h4>
              <ul>
                <li className="centered">
                  <ContactForm colorPrimary="#8093A2" size="20px" />
                  <Link to="/contact#contact-top">
                    <p>Contact form</p>
                  </Link>
                </li>
                <li className="centered">
                  <MailLine colorPrimary="#8093A2" size="20px" />
                  <a to="mailto: contact@us-flipit.com">
                    <p>contact@us-flipit.com</p>
                  </a>
                </li>
                <li className="centered">
                  <Phone colorPrimary="#8093A2" size="20px" />
                  <p>+1 917 - 719 - 1436</p>
                </li>
                <li className="centered">
                  <MapMarkerLine colorPrimary="#8093A2" size="20px" />
                  <p>Indianapolis, IN 47906</p>
                </li>
              </ul>
            </div>
            <div className="footer-subscribe outskrts-vert">
              <div>
                <h4>Subscribe</h4>
                <p>
                  Flipit is a growing fast. To keep up with all the exciting
                  changes and big news, subscribe to our newsletter.{" "}
                </p>
              </div>

              {subscribeInput}
            </div>
            <div className="footer-download outskrts-vert">
              <div>
                <h4>Download</h4>
                <p>
                  For the best mobile experience download the Flipit mobile app!
                </p>
              </div>
              <div className="footer-app-badges centered">
                <ButtonLnk
                  className="badge-button"
                  link="https://www.apple.com/ios/app-store/"
                >
                  <AppStore className="" colorPrimary="#fff" size="32px" />
                </ButtonLnk>
                <ButtonLnk
                  className="badge-button"
                  link="https://play.google.com/store?&utm_source=na_Med&utm_medium=hasem&utm_content=Jul0119&utm_campaign=Evergreen&pcampaignid=MKT-DR-na-us-1000189-Med-hasem-py-Evergreen-Jul0119-Text_Search_BKWS-id_100740_%7cEXA%7cONSEM_kwid_43700045371544913&gclid=CjwKCAiA7t3yBRADEiwA4GFlIypSjpeqi7qIMwZfp5tNh3XfCGCKamkUR89qTR3j7h9eo3-U02g-oRoCqe8QAvD_BwE&gclsrc=aw.ds"
                >
                  <PlayStore className="" colorPrimary="#fff" size="32px" />
                </ButtonLnk>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-legal">
          <p>© Flipit Inc. All rights reserved</p>
        </div>
      </div>
    );
  }

  openInNewTab(url) {
    var win = window.open(url, "_blank");
    win.focus();
  }
}

Footer.propTypes = {
  subscribeToMailist: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user,
});
const mapActionsToProps = {
  subscribeToMailist,
};

export default connect(mapStateToProps, mapActionsToProps)(Footer);
