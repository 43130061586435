import React from "react";

import "./BubbleBackStyle.css";

const ForumBack = props => (
  <div className="Back-container">
    <div className="back-blob-forum"></div>
  </div>
);

export default ForumBack;

//
// <div class="back-blob-2 back-blob-forum" aria-hidden="true">
//   <svg
//     xmlns="http://www.w3.org/2000/svg"
//     width="3000px"
//     viewBox="0 0 3738.3 5685.14"
//   >
//     <defs>
//       <clipPath id="background-default_svg__clip-path">
//         <path
//           class="background-default_svg__cls-1"
//           d="M459.17 1763.55c-32.09 216 1486.42 1536.08 1700.6 1478.3 31.61-8.55 73.25-64.3 120.75-153.63C2555 2572.21 3023 933.79 2853.12 786.15 2653.93 613.1 491.2 1547.52 459.17 1763.55z"
//         />
//       </clipPath>
//       <clipPath id="background-default_svg__clip-path-2">
//         <path
//           class="background-default_svg__cls-1"
//           d="M1121.29 2464.67C896.42 2617.89 1530 5163.46 1760.41 5264.55S3706 4197.94 3681.25 3956.73c-3.68-35.6-57.16-96.45-146.28-173.92-514.83-447.64-2221.85-1448.76-2413.68-1318.14z"
//         />
//       </clipPath>
//       <style fill="none" clip-rule="evenodd" />
//     </defs>
//     <g id="background-default_svg__Layer_2" data-name="Layer 2">
//       <g id="background-default_svg__background-products-2">
//         <g clip-path="url(#background-default_svg__clip-path)">
//           <path
//             transform="rotate(-62 1783.107 1645.85)"
//             fill="#F4F6F8"
//             d="M515.29 524.51h2535.63v2242.68H515.29z"
//           />
//         </g>
//         <g clip-path="url(#background-default_svg__clip-path-2)">
//           <path
//             transform="rotate(-49 1869.15 3848.696)"
//             fill="#E3E7EB"
//             d="M721.55 2369.64h2295.2v2958.11H721.55z"
//           />
//         </g>
//       </g>
//     </g>
//   </svg>
// </div>
//
