import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
// import axios from "axios";
// import { withRouter } from "react-router-dom";

//styling
import "./ModalStyle.css";

//redux stuff
import { connect } from "react-redux";
import { loginUser, logoutUser } from "../../redux/actions/userActions";

//Components
import ErrHandler from "../errors/ErrHandler";
import Spinner from "../loading/Spinner";
import ProfileBadge from "../allPages/ProfileBadge";

//icons
import Github from "../../media/icons/Github";
import Google from "../../media/icons/Google";
import BellOutline from "../../media/icons/BellOutline";
// import Beta from "../../media/icons/Beta";

class AuthModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: this.props.show,
      email: "",
      password: "",
      errors: ""
    };
    this.closeAuth = this.closeAuth.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.node = React.createRef();
    this.handleLogout = this.handleLogout.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.UI.errors) {
      this.setState({
        errors: nextProps.UI.errors
      });
    }
    if (nextProps.show !== this.state.show) {
      this.hideAuthModal(!this.props.show);
    }
  }

  handleSubmit = event => {
    event.preventDefault();
    const userData = {
      email: this.state.email,
      password: this.state.password
    };
    this.props.loginUser(userData, this.props.history);
    this.closeAuth();
  };

  handleChange = event => {
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  handleLogout = () => {
    console.log("logging out");
    this.closeAuth();

    // this.props.logoutUser();
    this.props.logoutUser(this.props.history);
  };

  render() {
    const {
      // classes,
      UI: { loading },
      user: {
        credentials: { username, imageUrl, email, accountType, betaTester },
        loadingUser,
        authenticated
      }
    } = this.props;
    // const { errors } = this.state;

    const signinJSX = (
      <div style={{ width: "100%" }} className="centered-col">
        <div className="pop-sec centered-col">
          <div className="flex-lft h-pop-sml" style={{ width: "100%" }}>
            Sign in with
          </div>
          <div className="outskrts" style={{ margin: "12px 0", width: "100%" }}>
            <button
              id="Google"
              className="button button-blue-outline svg-text-button pop-b-adj"
              type="button"
              name="button"
            >
              <Google colorPrimary="#34a8fe" size="20px" />
              Google
            </button>
            <button
              id="github"
              className="button button-blue-outline svg-text-button pop-b-adj"
              type="button"
              name="button"
            >
              <Github colorPrimary="#34a8fe" size="20px" />
              Github
            </button>
          </div>
          <div className="centered-col" style={{ width: "100%" }}>
            <div className="pop-or-lin"></div>
            <span
              style={{
                color: "#919498",
                background: " #fff",
                padding: "0 4px"
              }}
            >
              OR
            </span>
          </div>
        </div>
        <form
          className="pop-sec centered-col-rt auth-form"
          style={{ background: "#F0F4FC" }}
          action=""
          method=""
          onSubmit={this.handleSubmit}
        >
          <ErrHandler errMsg={this.state.errors.general} />
          <input
            className="auth-email"
            id="pop-email"
            name="email"
            type="email"
            label="Email"
            placeholder="email"
            value={this.state.email}
            onChange={this.handleChange}
          />
          <ErrHandler errMsg={this.state.errors.email} />
          <input
            className="auth-pass"
            id="pop-password"
            name="password"
            type="password"
            label="Password"
            placeholder="Password"
            value={this.state.password}
            onChange={this.handleChange}
          />
          <ErrHandler errMsg={this.state.errors.password} />
          <div className="outskrts h-pop-sml" style={{ width: "100%" }}>
            <span>Forgot?</span>
            <button
              className="button button-submit pop-b-adj auth-sign-in"
              type="submit"
              name="button"
            >
              {loading ? (
                <div className="">
                  <Spinner color="#fff" bordWidth="3px" size="16px" />
                </div>
              ) : (
                "Sign in"
              )}
            </button>
          </div>
        </form>
        <Link
          onClick={this.closeAuth}
          to="/auth/signup"
          style={{
            height: "56px"
          }}
          className="pop-sec centered pop-sinup"
        >
          Not a member,{" "}
          <span
            style={{
              color: "#34a8FE",
              fontWeight: "500",
              margin: "0 4px",
              padding: "0"
            }}
          >
            Sign up
          </span>{" "}
          here!
        </Link>
      </div>
    );

    const profileJSX = (
      <div style={{ width: "100%" }} className="centered-col">
        <div className="pop-sec centered-col">
          <div className="prof">
            <div className="userProPic">
              <img src={imageUrl} alt="Profile" />
            </div>
            <ProfileBadge
              accountType={accountType}
              beta={betaTester}
              loading={loadingUser}
            />
          </div>
          <div className="pop-sec-txt centered-col">
            <span className="centered">{`@${username}`}</span>
            <div className="user-email">{email}</div>
          </div>
          <Link
            className="button button-profile-skinny"
            to={`/profile`}
            style={{ fontSize: ".75rem", margin: 0 }}
          >
            Go to Profile
          </Link>
        </div>
        <div className="pop-sec centered-col">
          <div className="pop-alert-emp centered">
            <div className="centered-col">
              <BellOutline colorPrimary="#fff" size="24px" />
            </div>
            No New Alerts
          </div>
        </div>
        <div className="pop-sec centered-col">
          <button
            onClick={this.handleLogout}
            id="LogoutButton"
            className="button button-profile-skinny button-signout-skinny"
            type="button"
            name="button"
          >
            Sign out
          </button>
        </div>
      </div>
    );

    let profileStatus = !loadingUser ? (
      authenticated ? (
        profileJSX
      ) : (
        signinJSX
      )
    ) : (
      <p>Loading...</p>
    );

    return (
      <div
        className="h-pop-wrap"
        style={{
          display: !this.state.show ? "none" : "flex",
          width: this.props.placement
        }}
      >
        <div
          ref={node => (this.node = node)}
          className="anim-drpdwn modal h-pop"
        >
          <div className=" centered-col">
            {profileStatus}

            <div className="pop-sec pop-foot centered h-pop-sml">
              <Link
                style={{
                  fontSize: "0.75rem",
                  padding: 0
                }}
                to="/Privacy"
              >
                Privacy Policy
              </Link>
              ・
              <Link
                style={{
                  fontSize: "0.75rem",
                  padding: 0
                }}
                to="/terms"
              >
                Terms of Use
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }

  hideAuthModal = comand => {
    this.setState({
      show: comand
    });
    if (comand) {
      document.addEventListener("click", this.handleClick);
    } else {
      document.removeEventListener("click", this.handleClick);
    }
  };
  handleClick(e) {
    if (!this.node.contains(e.target)) {
      this.closeAuth(false);
    }
  }
  closeAuth() {
    this.hideAuthModal(false);
    this.props.handler();
  }
}

AuthModal.propTypes = {
  // classes: PropTypes.object.isRequired,
  loginUser: PropTypes.func.isRequired,
  // logoutUser: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  UI: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  user: state.user,
  UI: state.UI
});

const mapActionsToProps = {
  loginUser,
  logoutUser
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(withRouter(AuthModal));
