import React, { Component } from "react";
import firebase from "../../../../../firebase";
import debounce from "lodash.debounce";

//styling
import "./TopicInputStyle.css";

//consoleIcons
import Close from "../../../../../media/SVGs/None";

//algolia
import algoliasearch from "algoliasearch/lite";

const client = algoliasearch("JOLDALUHRH", "7cab22f7a649852e534f612323fbfaa1");
const index = client.initIndex("srch_USERS");
const topicIndex = client.initIndex("srch_TOPICS");

class TopicInput extends Component {
  constructor() {
    super();

    this.state = {
      topics: [],
      topicComp: "",
      value: "",
      suggestions: "",
      charLimit: 14,
      charCount: 0,
      radius: 12,
      ringStyle: {
        stroke: "",
        strokeDasharray: "",
      },
    };
    this.topicInputRef = React.ref;
    // this.topicsSearch = debounce(this.topicsSearch, 200);
  }

  handleInputChange = (e) => {
    let modifiedInput = e.target.value
      .replace(/[^a-z\d ]+/i, "")
      .replace(/\s\s+/g, " ")
      .substr(0, this.state.charLimit);

    // Immediately update the state
    this.setState({
      value: modifiedInput,
      charCount: e.target.value != 0 ? modifiedInput.length : 0,
    });
    this.styleRing();
    this.topicsSearch(e);
  };

  topicsSearch = (event) => {
    let value = this.state.value;

    let suggestions = [];
    topicIndex
      .search(value, {
        attributesToRetrieve: ["topic", "Followers"],
        hitsPerPage: 5,
      })
      .then(({ hits }) => {
        hits.forEach((item, i) => {
          suggestions.push({
            name: item.topic,
            follows: !item.follows ? "" : item.follows,
          });
        });
        this.setState({
          suggestions,
        });
      });
  };
  handleBlur = (event) => {
    if (event.target.value) {
      this.setState({
        value: event.target.value,
      });
      this.handleSubmit(event, event.target.value);
    }
  };
  handleDropClick = (event) => {
    this.setState({
      value: event.target.textContent,
    });
    this.handleSubmit(event, event.target.textContent);
  };
  handleTagClick = (topic) => {
    let newTopics = this.state.topics;
    newTopics = newTopics.filter((e) => e !== topic);

    this.setState({
      topics: newTopics,
      topicComp: newTopics.map((topic) => (
        <div
          className="topic-tag centered"
          onMouseDown={() => this.handleTagClick(topic)}
          key={Math.random()}
        >
          <div className="topic-tag-topic">{topic}</div>
          <Close size="16px" />
        </div>
      )),
    });
    this.props.passTopics(newTopics);
  };
  handleSubmit = (event, altValue) => {
    event.preventDefault();

    let incomingVal = !altValue ? this.state.value : altValue;
    let finaVal = incomingVal
      .trim()
      .toLowerCase()
      .split(" ")
      .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
      .join(" ");

    let curretTopics = this.state.topics;
    let newTopics = curretTopics.concat(finaVal);
    let arraycontainsturtles = curretTopics.indexOf(finaVal) > -1;
    let finalTopicArr = !arraycontainsturtles ? newTopics : curretTopics;
    this.setState({
      topics: finalTopicArr,
      topicComp: finalTopicArr.map((topic) => (
        <div
          className="topic-tag centered"
          onMouseDown={() => this.handleTagClick(topic)}
          key={Math.random()}
        >
          <div className="topic-tag-topic">{topic}</div>
          <Close size="16px" />
        </div>
      )),
      value: "",
      suggestions: "",
      charCount: "",
      ringStyle: {
        stroke: "",
        strokeDasharray: "",
      },
    });
    this.props.passTopics(finalTopicArr);
    this.widthCheck();
  };

  styleRing() {
    const r = this.state.radius;
    const circleLength = 2 * Math.PI * r;
    let colored = (circleLength * this.state.charCount) / this.state.charLimit;
    let gray = circleLength - colored;

    this.setState((state) => ({
      ringStyle: {
        stroke: !state.charCount
          ? "#b5c0c5"
          : state.charLimit - state.charCount <= 0
          ? "rgb(249, 41, 83)"
          : state.charLimit - state.charCount <= 4
          ? "rgb(255, 203, 43)"
          : "#039be5",
        strokeDasharray: `${colored} ${gray}`,
      },
    }));
  }

  widthCheck() {
    let tagmWidth = this.state.tagmWidth;
    let containerWidth = this.state.containerWidth;

    this.setState({
      wrapped: containerWidth - tagmWidth < 300 ? true : false,
    });
  }

  render() {
    let topicLimit = !this.props.limit ? 3 : this.props.limit;
    let flexClass = this.state.wrapped ? "fex-wrap" : "";

    let countStyle =
      this.state.charCount < this.state.charLimit - 4
        ? ""
        : this.state.charCount >= this.state.charLimit
        ? "tag-input-err"
        : "tag-input-warning";

    return (
      <div
        className={`topic-wrapper ${flexClass}`}
        ref={(node) =>
          node &&
          node.offsetWidth != this.state.containerWidth &&
          this.setState({ containerWidth: node.offsetWidth })
        }
      >
        <div
          className={`topic-tag-wrapper centered ${flexClass}`}
          ref={(node) =>
            node &&
            node.offsetWidth != this.state.tagmWidth &&
            this.setState({ tagmWidth: node.offsetWidth })
          }
        >
          {this.state.topicComp}
        </div>

        <form
          name="tag-from"
          className="topic-input-wrapper"
          onSubmit={this.handleSubmit}
          style={{
            display: this.state.topics.length < topicLimit ? "flex" : "none",
          }}
        >
          <input
            autoFocus
            autoComplete="off"
            id="tag-input"
            value={this.state.value}
            type="text"
            onBlur={this.handleBlur}
            onChange={this.handleInputChange}
            placeholder="Add a topic..."
          />
          <button
            className="button button-white anim-left"
            style={{
              display: this.state.value ? "flex" : "none",
            }}
          >
            Add
          </button>
          <div className={`tag-input-chrecterCount ${countStyle}`}>
            <svg>
              <circle
                id="gray"
                cx="50%"
                cy="50%"
                r={this.state.radius}
              ></circle>
              <circle
                id="colored"
                style={this.state.ringStyle}
                cx="50%"
                cy="50%"
                r={this.state.radius}
              ></circle>
            </svg>
          </div>
          <div
            className={`topic-input-suggestions anim-drpdwn ${
              this.state.suggestions && "autocomplet-drop-shadow"
            }`}
          >
            {this.state.suggestions &&
              this.state.suggestions.map((suggestion) => (
                <div
                  className="topic-sugection-wrap outskrts"
                  key={Math.random()}
                  onMouseDown={this.handleDropClick}
                >
                  <div className="topic-sugection">{suggestion.name}</div>
                  <div className="topic-sugection-follows">
                    {suggestion.follows}
                  </div>
                </div>
              ))}
          </div>
        </form>
      </div>
    );
  }
}

export default TopicInput;
