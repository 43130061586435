import React from "react";

function Icon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 326 340">
      <path
        fill="#ACB7BD"
        d="M76 39.8a4.8 4.8 0 110-9.6 4.8 4.8 0 010 9.6zM76 29a6 6 0 100 12.002A6 6 0 0076 29zM226 45.8a4.8 4.8 0 110-9.599 4.8 4.8 0 010 9.599zm0-10.8a6 6 0 100 12 6 6 0 000-12zM59 165.571h-3.857V169h-1.286v-3.429H50v-1.142h3.857V161h1.286v3.429H59v1.142zM155 48.143h-3.857V52h-1.286v-3.857H146v-1.286h3.857V43h1.286v3.857H155v1.286zM290 78.143h-3.857V82h-1.286v-3.857H281v-1.286h3.857V73h1.286v3.857H290v1.286zM273 163a5.99 5.99 0 00-4.243 1.757 6.003 6.003 0 000 8.486A6.003 6.003 0 00279 169a5.99 5.99 0 00-1.757-4.243A6.003 6.003 0 00273 163zM44 94a6 6 0 100 12.002A6 6 0 0044 94z"
      ></path>
      <g filter="url(#filter0_d)">
        <path
          fill="#fff"
          d="M216 96H110c-5.523 0-10 4.477-10 10v120c0 5.523 4.477 10 10 10h106c5.523 0 10-4.477 10-10V106c0-5.523-4.477-10-10-10z"
        ></path>
      </g>
      <rect
        width="36"
        height="5"
        x="146"
        y="203"
        fill="#E3E7EB"
        rx="2.5"
      ></rect>
      <rect
        width="68"
        height="5"
        x="130"
        y="189"
        fill="#C7D1D9"
        rx="2.5"
      ></rect>
      <mask
        id="a"
        width="51"
        height="51"
        x="139"
        y="118"
        maskUnits="userSpaceOnUse"
      >
        <path
          fill="#C7D1D9"
          d="M164.5 118a25.503 25.503 0 00-25.5 25.5 25.5 25.5 0 1025.5-25.5z"
        ></path>
      </mask>
      <g fill="#E3E7EB" mask="url(#a)">
        <path d="M164.499 124.559a11.442 11.442 0 00-11.444 11.444 11.44 11.44 0 0011.444 11.445 11.445 11.445 0 100-22.889zM164.864 150.059c-3.492 0-6.95.546-10.176 1.608-3.227 1.062-6.158 2.618-8.628 4.58-4.987 3.962-7.789 9.337-7.789 14.94 0 5.604 2.802 10.978 7.789 14.94 2.47 1.962 5.401 3.519 8.628 4.58a32.646 32.646 0 0010.176 1.609c7.053 0 13.817-2.226 18.804-6.189 4.987-3.962 7.789-9.336 7.789-14.94 0-2.774-.688-5.522-2.024-8.085-1.336-2.564-3.295-4.893-5.765-6.855-2.469-1.962-5.401-3.518-8.627-4.58a32.647 32.647 0 00-10.177-1.608z"></path>
      </g>
      <path
        stroke="#C7D1D9"
        strokeWidth="4"
        d="M164.5 116a27.504 27.504 0 00-19.445 8.055 27.497 27.497 0 000 38.89A27.491 27.491 0 00164.5 171a27.497 27.497 0 0019.445-8.055 27.497 27.497 0 000-38.89A27.491 27.491 0 00164.5 116z"
      ></path>
      <defs>
        <filter
          id="filter0_d"
          width="326"
          height="340"
          x="0"
          y="0"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="4"></feOffset>
          <feGaussianBlur stdDeviation="50"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0.67451 0 0 0 0 0.717647 0 0 0 0 0.741176 0 0 0 0.5 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect1_dropShadow"
            result="shape"
          ></feBlend>
        </filter>
      </defs>
    </svg>
  );
}

export default Icon;
