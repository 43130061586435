import React from "react";

const Budgeting = props => (
  <svg
    className={props.className}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
  >
    <path
      fill={props.colorPrimary}
      fillRule="evenodd"
      d="M10 4h5v16h-5V4zM3 14h5v6H3v-6zm8.5-3.5v8h2v-13h-2v5zm-7 8h2v-3h-2v3zM17 9h5v11h-5V9zm1.5 1.5v8h2v-8h-2z"
      clipRule="evenodd"
    />
  </svg>
);

export default Budgeting;
