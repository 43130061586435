import React, { Component } from "react";
import { HashLink as Link } from "react-router-hash-link";

//Styling
import Card from "../cards/Card";
import Button from "../buttons/Button";

import "./PriceCardStyle.css";

// const PriceCard = this.props => (
class PriceCard extends Component {
  // state = {
  //   active: false
  // };

  constructor(props) {
    super(props);
    this.state = {
      isHovered: false,
      active: this.props.active
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.active !== prevProps.active) {
      console.log("updating");
      this.setState({
        active: this.props.active
      });
    }
  }

  //className={`${hovClass} ${this.getCardClass()} Price-Card-wrapper`}

  render() {
    return (
      <Card
        className={`${
          this.state.active ? "card-shdow-lg active-card-back" : ""
        } Price-Card-wrapper`}
        onMouseEnter={this.handleHover}
        onMouseLeave={this.handleHover}
      >
        <h5 className="priceCard-plan">{this.props.plan}</h5>
        <div className="priceCard-price">
          {this.props.price.includes("$", 0) ? (
            <div>
              {this.props.price}
              <span className="plan-timeferame">/m</span>
            </div>
          ) : (
            this.props.price
          )}
        </div>
        <div className="priceCard-timeframe">Includes</div>
        <div className="priceCard-text centered-col">{this.props.text}</div>
        <Link to={this.props.link}>
          <Button className="priceCard-button button-blue">
            {this.props.buttontext}
          </Button>
        </Link>
      </Card>
    );
  }
}
export default PriceCard;
