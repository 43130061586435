import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="725"
      height="634"
      fill="none"
      viewBox="0 0 725 634"
    >
      <path
        stroke="#F5F5F5"
        strokeWidth="2"
        d="M0 0H21V21H0z"
        transform="matrix(-1 0 0 1 721 1)"
      ></path>
      <path
        stroke="#F5F5F5"
        strokeWidth="2"
        d="M0 0H21V21H0z"
        transform="matrix(-1 0 0 1 700 1)"
      ></path>
      <path
        stroke="#F5F5F5"
        strokeWidth="2"
        d="M0 0H21V21H0z"
        transform="matrix(-1 0 0 1 679 43)"
      ></path>
      <path
        stroke="#F5F5F5"
        strokeWidth="2"
        d="M0 0H21V21H0z"
        transform="matrix(-1 0 0 1 679 106)"
      ></path>
      <path
        stroke="#F5F5F5"
        strokeWidth="2"
        d="M0 0H21V21H0z"
        transform="matrix(-1 0 0 1 658 106)"
      ></path>
      <path
        stroke="#F5F5F5"
        strokeWidth="2"
        d="M0 0H21V21H0z"
        transform="matrix(-1 0 0 1 337 106)"
      ></path>
      <path
        stroke="#F5F5F5"
        strokeWidth="2"
        d="M0 0H21V21H0z"
        transform="matrix(-1 0 0 1 337 127)"
      ></path>
      <path
        stroke="#F5F5F5"
        strokeWidth="2"
        d="M0 0H21V21H0z"
        transform="matrix(-1 0 0 1 316 127)"
      ></path>
      <path
        stroke="#F5F5F5"
        strokeWidth="2"
        d="M0 0H21V21H0z"
        transform="matrix(-1 0 0 1 316 213)"
      ></path>
      <path
        stroke="#F5F5F5"
        strokeWidth="2"
        d="M0 0H21V21H0z"
        transform="matrix(-1 0 0 1 316 276)"
      ></path>
      <path
        stroke="#F5F5F5"
        strokeWidth="2"
        d="M0 0H21V21H0z"
        transform="matrix(-1 0 0 1 274 276)"
      ></path>
      <path
        stroke="#F5F5F5"
        strokeWidth="2"
        d="M0 0H21V21H0z"
        transform="matrix(-1 0 0 1 232 276)"
      ></path>
      <path
        stroke="#F5F5F5"
        strokeWidth="2"
        d="M0 0H21V21H0z"
        transform="matrix(-1 0 0 1 295 255)"
      ></path>
      <path
        stroke="#F5F5F5"
        strokeWidth="2"
        d="M0 0H21V21H0z"
        transform="matrix(-1 0 0 1 253 255)"
      ></path>
      <path
        stroke="#F5F5F5"
        strokeWidth="2"
        d="M0 0H21V21H0z"
        transform="matrix(-1 0 0 1 211 255)"
      ></path>
      <path
        stroke="#F5F5F5"
        strokeWidth="2"
        d="M0 0H21V21H0z"
        transform="matrix(-1 0 0 1 190 255)"
      ></path>
      <path
        stroke="#F5F5F5"
        strokeWidth="2"
        d="M0 0H21V21H0z"
        transform="matrix(-1 0 0 1 211 213)"
      ></path>
      <path
        stroke="#F5F5F5"
        strokeWidth="2"
        d="M0 0H21V21H0z"
        transform="matrix(-1 0 0 1 211 276)"
      ></path>
      <path
        stroke="#F5F5F5"
        strokeWidth="2"
        d="M0 0H21V21H0z"
        transform="matrix(-1 0 0 1 190 276)"
      ></path>
      <path
        stroke="#F5F5F5"
        strokeWidth="2"
        d="M0 0H21V21H0z"
        transform="matrix(-1 0 0 1 169 276)"
      ></path>
      <path
        stroke="#F5F5F5"
        strokeWidth="2"
        d="M0 0H21V21H0z"
        transform="matrix(-1 0 0 1 169 318)"
      ></path>
      <path
        stroke="#F5F5F5"
        strokeWidth="2"
        d="M0 0H21V21H0z"
        transform="matrix(-1 0 0 1 211 318)"
      ></path>
      <path
        stroke="#F5F5F5"
        strokeWidth="2"
        d="M0 0H21V21H0z"
        transform="matrix(-1 0 0 1 211 339)"
      ></path>
      <path
        stroke="#F5F5F5"
        strokeWidth="2"
        d="M0 0H21V21H0z"
        transform="matrix(-1 0 0 1 274 339)"
      ></path>
      <path
        stroke="#F5F5F5"
        strokeWidth="2"
        d="M0 0H21V21H0z"
        transform="matrix(-1 0 0 1 274 360)"
      ></path>
      <path
        stroke="#F5F5F5"
        strokeWidth="2"
        d="M0 0H21V21H0z"
        transform="matrix(-1 0 0 1 274 381)"
      ></path>
      <path
        stroke="#F5F5F5"
        strokeWidth="2"
        d="M0 0H21V21H0z"
        transform="matrix(-1 0 0 1 700 402)"
      ></path>
      <path
        stroke="#F5F5F5"
        strokeWidth="2"
        d="M0 0H21V21H0z"
        transform="matrix(-1 0 0 1 700 444)"
      ></path>
      <path
        stroke="#F5F5F5"
        strokeWidth="2"
        d="M0 0H21V21H0z"
        transform="matrix(-1 0 0 1 721 444)"
      ></path>
      <path
        stroke="#F5F5F5"
        strokeWidth="2"
        d="M0 0H21V21H0z"
        transform="matrix(-1 0 0 1 721 423)"
      ></path>
      <path
        stroke="#F5F5F5"
        strokeWidth="2"
        d="M0 0H21V21H0z"
        transform="matrix(-1 0 0 1 358 381)"
      ></path>
      <path
        stroke="#F5F5F5"
        strokeWidth="2"
        d="M0 0H21V21H0z"
        transform="matrix(-1 0 0 1 379 528)"
      ></path>
      <path
        stroke="#F5F5F5"
        strokeWidth="2"
        d="M0 0H21V21H0z"
        transform="matrix(-1 0 0 1 421 528)"
      ></path>
      <path
        stroke="#F5F5F5"
        strokeWidth="2"
        d="M0 0H21V21H0z"
        transform="matrix(-1 0 0 1 658 507)"
      ></path>
      <path
        stroke="#F5F5F5"
        strokeWidth="2"
        d="M0 0H21V21H0z"
        transform="matrix(-1 0 0 1 295 381)"
      ></path>
      <path
        stroke="#F5F5F5"
        strokeWidth="2"
        d="M0 0H21V21H0z"
        transform="matrix(-1 0 0 1 379 381)"
      ></path>
      <path
        stroke="#F5F5F5"
        strokeWidth="2"
        d="M0 0H21V21H0z"
        transform="matrix(-1 0 0 1 400 528)"
      ></path>
      <path
        stroke="#F5F5F5"
        strokeWidth="2"
        d="M0 0H21V21H0z"
        transform="matrix(-1 0 0 1 442 528)"
      ></path>
      <path
        stroke="#F5F5F5"
        strokeWidth="2"
        d="M0 0H21V21H0z"
        transform="matrix(-1 0 0 1 463 528)"
      ></path>
      <path
        stroke="#F5F5F5"
        strokeWidth="2"
        d="M0 0H21V21H0z"
        transform="matrix(-1 0 0 1 484 528)"
      ></path>
      <path
        stroke="#F5F5F5"
        strokeWidth="2"
        d="M0 0H21V21H0z"
        transform="matrix(-1 0 0 1 594 570)"
      ></path>
      <path
        stroke="#F5F5F5"
        strokeWidth="2"
        d="M0 0H21V21H0z"
        transform="matrix(-1 0 0 1 295 528)"
      ></path>
      <path
        stroke="#F5F5F5"
        strokeWidth="2"
        d="M0 0H21V21H0z"
        transform="matrix(-1 0 0 1 274 402)"
      ></path>
      <path
        stroke="#F5F5F5"
        strokeWidth="2"
        d="M0 0H21V21H0z"
        transform="matrix(-1 0 0 1 700 423)"
      ></path>
      <path
        stroke="#F5F5F5"
        strokeWidth="2"
        d="M0 0H21V21H0z"
        transform="matrix(-1 0 0 1 658 423)"
      ></path>
      <path
        stroke="#F5F5F5"
        strokeWidth="2"
        d="M0 0H21V21H0z"
        transform="matrix(-1 0 0 1 700 465)"
      ></path>
      <path
        stroke="#F5F5F5"
        strokeWidth="2"
        d="M0 0H21V21H0z"
        transform="matrix(-1 0 0 1 679 465)"
      ></path>
      <path
        stroke="#F5F5F5"
        strokeWidth="2"
        d="M0 0H21V21H0z"
        transform="matrix(-1 0 0 1 658 465)"
      ></path>
      <path
        stroke="#F5F5F5"
        strokeWidth="2"
        d="M0 0H21V21H0z"
        transform="matrix(-1 0 0 1 658 297)"
      ></path>
      <path
        stroke="#F5F5F5"
        strokeWidth="2"
        d="M0 0H21V21H0z"
        transform="matrix(-1 0 0 1 658 276)"
      ></path>
      <path
        stroke="#F5F5F5"
        strokeWidth="2"
        d="M0 0H21V21H0z"
        transform="matrix(-1 0 0 1 658 255)"
      ></path>
      <path
        stroke="#F5F5F5"
        strokeWidth="2"
        d="M0 0H21V21H0z"
        transform="matrix(-1 0 0 1 658 234)"
      ></path>
      <path
        stroke="#F5F5F5"
        strokeWidth="2"
        d="M0 0H21V21H0z"
        transform="matrix(-1 0 0 1 679 297)"
      ></path>
      <path
        stroke="#F5F5F5"
        strokeWidth="2"
        d="M0 0H21V21H0z"
        transform="matrix(-1 0 0 1 679 276)"
      ></path>
      <path
        stroke="#F5F5F5"
        strokeWidth="2"
        d="M0 0H21V21H0z"
        transform="matrix(-1 0 0 1 721 465)"
      ></path>
      <path
        stroke="#F5F5F5"
        strokeWidth="2"
        d="M0 0H21V21H0z"
        transform="matrix(-1 0 0 1 358 402)"
      ></path>
      <path
        stroke="#F5F5F5"
        strokeWidth="2"
        d="M0 0H21V21H0z"
        transform="matrix(-1 0 0 1 379 549)"
      ></path>
      <path
        stroke="#F5F5F5"
        strokeWidth="2"
        d="M0 0H21V21H0z"
        transform="matrix(-1 0 0 1 421 549)"
      ></path>
      <path
        stroke="#F5F5F5"
        strokeWidth="2"
        d="M0 0H21V21H0z"
        transform="matrix(-1 0 0 1 552 591)"
      ></path>
      <path
        stroke="#F5F5F5"
        strokeWidth="2"
        d="M0 0H21V21H0z"
        transform="matrix(-1 0 0 1 700 591)"
      ></path>
      <path
        stroke="#F5F5F5"
        strokeWidth="2"
        d="M0 0H21V21H0z"
        transform="matrix(-1 0 0 1 552 612)"
      ></path>
      <path
        stroke="#F5F5F5"
        strokeWidth="2"
        d="M0 0H21V21H0z"
        transform="matrix(-1 0 0 1 700 612)"
      ></path>
      <path
        stroke="#F5F5F5"
        strokeWidth="2"
        d="M0 0H21V21H0z"
        transform="matrix(-1 0 0 1 531 591)"
      ></path>
      <path
        stroke="#F5F5F5"
        strokeWidth="2"
        d="M0 0H21V21H0z"
        transform="matrix(-1 0 0 1 679 591)"
      ></path>
      <path
        stroke="#F5F5F5"
        strokeWidth="2"
        d="M0 0H21V21H0z"
        transform="matrix(-1 0 0 1 679 612)"
      ></path>
      <path
        stroke="#F5F5F5"
        strokeWidth="2"
        d="M0 0H21V21H0z"
        transform="matrix(-1 0 0 1 379 402)"
      ></path>
      <path
        stroke="#F5F5F5"
        strokeWidth="2"
        d="M0 0H21V21H0z"
        transform="matrix(-1 0 0 1 400 549)"
      ></path>
      <path
        stroke="#F5F5F5"
        strokeWidth="2"
        d="M0 0H21V21H0z"
        transform="matrix(-1 0 0 1 442 549)"
      ></path>
      <path
        stroke="#F5F5F5"
        strokeWidth="2"
        d="M0 0H21V21H0z"
        transform="matrix(-1 0 0 1 463 549)"
      ></path>
      <path
        stroke="#F5F5F5"
        strokeWidth="2"
        d="M0 0H21V21H0z"
        transform="matrix(-1 0 0 1 484 549)"
      ></path>
      <path
        stroke="#F5F5F5"
        strokeWidth="2"
        d="M0 0H21V21H0z"
        transform="matrix(-1 0 0 1 700 549)"
      ></path>
      <path
        stroke="#F5F5F5"
        strokeWidth="2"
        d="M0 0H21V21H0z"
        transform="matrix(-1 0 0 1 253 475)"
      ></path>
      <path
        stroke="#F5F5F5"
        strokeWidth="2"
        d="M0 0H21V21H0z"
        transform="matrix(-1 0 0 1 232 475)"
      ></path>
      <path
        stroke="#F5F5F5"
        strokeWidth="2"
        d="M0 0H21V21H0z"
        transform="matrix(-1 0 0 1 148 276)"
      ></path>
      <path
        stroke="#F5F5F5"
        strokeWidth="2"
        d="M0 0H21V21H0z"
        transform="matrix(-1 0 0 1 106 276)"
      ></path>
      <g filter="url(#filter0_dd)">
        <path
          fill="#fff"
          d="M0 0H21V21H0z"
          transform="matrix(-1 0 0 1 64 276)"
        ></path>
        <path
          stroke="#F0F0F0"
          strokeWidth="0.75"
          d="M-0.375 0.375H19.875V20.625H-0.375z"
          transform="matrix(-1 0 0 1 63.25 276)"
        ></path>
      </g>
      <path
        stroke="#F5F5F5"
        strokeWidth="2"
        d="M0 0H21V21H0z"
        transform="matrix(-1 0 0 1 22 276)"
      ></path>
      <path
        stroke="#F5F5F5"
        strokeWidth="2"
        d="M0 0H21V21H0z"
        transform="matrix(-1 0 0 1 253 234)"
      ></path>
      <path
        stroke="#F5F5F5"
        strokeWidth="2"
        d="M0 0H21V21H0z"
        transform="matrix(-1 0 0 1 316 297)"
      ></path>
      <path
        stroke="#F5F5F5"
        strokeWidth="2"
        d="M0 0H21V21H0z"
        transform="matrix(-1 0 0 1 337 339)"
      ></path>
      <path
        stroke="#F5F5F5"
        strokeWidth="2"
        d="M0 0H21V21H0z"
        transform="matrix(-1 0 0 1 295 192)"
      ></path>
      <path
        stroke="#F5F5F5"
        strokeWidth="2"
        d="M0 0H21V21H0z"
        transform="matrix(-1 0 0 1 615 43)"
      ></path>
      <path
        stroke="#F5F5F5"
        strokeWidth="2"
        d="M0 0H21V21H0z"
        transform="matrix(-1 0 0 1 615 64)"
      ></path>
      <path
        stroke="#F5F5F5"
        strokeWidth="2"
        d="M0 0H21V21H0z"
        transform="matrix(-1 0 0 1 615 85)"
      ></path>
      <path
        stroke="#F5F5F5"
        strokeWidth="2"
        d="M0 0H21V21H0z"
        transform="matrix(-1 0 0 1 573 85)"
      ></path>
      <path
        stroke="#F5F5F5"
        strokeWidth="2"
        d="M0 0H21V21H0z"
        transform="matrix(-1 0 0 1 594 43)"
      ></path>
      <path
        stroke="#F5F5F5"
        strokeWidth="2"
        d="M0 0H21V21H0z"
        transform="matrix(-1 0 0 1 552 22)"
      ></path>
      <path
        stroke="#F5F5F5"
        strokeWidth="2"
        d="M0 0H21V21H0z"
        transform="matrix(-1 0 0 1 594 64)"
      ></path>
      <path
        stroke="#F5F5F5"
        strokeWidth="2"
        d="M0 0H21V21H0z"
        transform="matrix(-1 0 0 1 594 85)"
      ></path>
      <path
        stroke="#F5F5F5"
        strokeWidth="2"
        d="M0 0H21V21H0z"
        transform="matrix(-1 0 0 1 552 85)"
      ></path>
      <path
        stroke="#F5F5F5"
        strokeWidth="2"
        d="M0 0H21V21H0z"
        transform="matrix(-1 0 0 1 721 22)"
      ></path>
      <path
        stroke="#F5F5F5"
        strokeWidth="2"
        d="M0 0H21V21H0z"
        transform="matrix(-1 0 0 1 64 276)"
      ></path>
      <g filter="url(#filter1_dd)">
        <path
          fill="#fff"
          d="M0 0H21V42H0z"
          transform="matrix(-1 0 0 1 700 444)"
        ></path>
        <path
          stroke="#F0F0F0"
          strokeWidth="0.75"
          d="M-0.375 0.375H19.875V41.625H-0.375z"
          transform="matrix(-1 0 0 1 699.25 444)"
        ></path>
      </g>
      <defs>
        <filter
          id="filter0_dd"
          width="71"
          height="71"
          x="18"
          y="266"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="15"></feOffset>
          <feGaussianBlur stdDeviation="12.5"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow"
          ></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="5"></feOffset>
          <feGaussianBlur stdDeviation="5"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.09 0"></feColorMatrix>
          <feBlend
            in2="effect1_dropShadow"
            result="effect2_dropShadow"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect2_dropShadow"
            result="shape"
          ></feBlend>
        </filter>
        <filter
          id="filter1_dd"
          width="71"
          height="92"
          x="654"
          y="434"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="15"></feOffset>
          <feGaussianBlur stdDeviation="12.5"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow"
          ></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="5"></feOffset>
          <feGaussianBlur stdDeviation="5"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.09 0"></feColorMatrix>
          <feBlend
            in2="effect1_dropShadow"
            result="effect2_dropShadow"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect2_dropShadow"
            result="shape"
          ></feBlend>
        </filter>
      </defs>
    </svg>
  );
}

export default Icon;
