import React from "react";

function SupportPerson() {
  return (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 803 379"
    >
      <mask
        id="a"
        width="767"
        height="374"
        x="1"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <path
          fill="#fff"
          d="M0 0H767V374H0z"
          transform="matrix(1 0 0 -1 1 374)"
        ></path>
      </mask>
      <g mask="url(#a)">
        <circle cx="330" cy="280" r="240" fill="#F6FCFE"></circle>
        <path
          fill="#B5E6FC"
          d="M428.528 332.266l-51.477-118.999 2.674 101.952 31.421 55.154 49.137-2.006-.992-99.611c19.721-2.675 38.44-5.683 56.157-28.413-.679-7.02-11.041 2.601-22.406 9.025 7.354-4.552 14.039-9.694 14.039-12.702 0-6.818-17.382 6.685-23.064 11.031h-14.04c11.365-13.037 3.677-21.059 1.672-16.379-1.542 3.596-11.7 12.702-26.073 18.719-9.882 4.136-11.955 39.111-17.048 82.229z"
        ></path>
        <path
          fill="#9EDAF7"
          d="M428.528 332.266l-51.477-118.999 2.674 101.952 31.421 55.154 49.137-2.006-.9-90.367c-10.084-1.123-15.509-2.134-23.05-7.5-2.965 15.088-4.891 37.094-7.805 61.766z"
        ></path>
        <path
          fill="#fff"
          d="M384.405 310.873c13.125-17.382 2.006-52.48-7.354-97.94l-17.382-11.914 9.026 50.355c-22.367 5.185-34.346 5.601-55.155 4.011l-28.747-59.984-39.109 19.203-35.098 181.508 125.016-11.365 22.73-52.146c4.346-9.025 16.468-9.007 26.073-21.728z"
        ></path>
        <path
          fill="#88CCEF"
          d="M384.405 310.873c13.125-17.382 2.006-52.48-7.354-97.94l-17.382-11.914-2.661 25.97c-22.367 5.186-34.345 5.601-55.154 4.011l-17.061-35.599C266.301 202.516 257.922 205.526 238 217l-27.414 179.112 125.016-11.365 22.73-52.146c4.346-9.025 16.468-9.007 26.073-21.728z"
        ></path>
      </g>
      <path
        stroke="#88CCEF"
        strokeLinecap="round"
        strokeWidth="2"
        d="M1.336 372.713h20.056m747.089 0H49.136m-10.696 0h-9.36"
      ></path>
      <path
        fill="#34A8FE"
        d="M295.488 373.381h77.55c0-7.353-20.056-19.053-49.137-19.053l-28.413 19.053z"
      ></path>
      <path
        fill="#B5E6FC"
        d="M214.601 254.045c8.691-26.738 23.398-41.298 31.421-39.443 40.446 9.359 9.359 93.261-10.028 134.71l55.154 10.362c29.75-31.753 75.542 2.34 66.185 5.683-4.348 1.553-9.699-7.686-25.741-9.023 10.694 12.705 11.365 22.062 8.025 22.393-4.014 0-9.028-16.042-18.719-16.713 8.354 10.699 10.025 16.713 5.014 16.713-3.346 0-6.354-13.033-15.377-12.033 5.027 4.02 8.354 13.039 4.346 12.033-5.24-1.314-1.244-8.688-25.407-5.68l-107.297 1.001c-3.346 0-3.582-8.354 0-16.714 13.036-30.416 18.299-59.831 32.424-103.289z"
      ></path>
      <path
        fill="#9EDAF7"
        d="M214.601 254.045c8.691-26.738 23.398-41.298 31.421-39.443 40.446 9.359 9.359 93.261-10.028 134.71l49.506 9.301-3 14.499-100.323.936c-3.346 0-3.582-8.354 0-16.714 13.036-30.416 18.299-59.831 32.424-103.289zM280.334 207.318c7.411-56.503 108.38-24.083 95.411 16.674-13.264 41.686-101.848 32.423-95.411-16.674z"
      ></path>
      <path
        fill="#B5E6FC"
        d="M735.72 373.382H384.07c.335-6.351 1.672-10.363 9.026-10.363h143.4l54.152-130.698c2.005-4.68 2.674-7.354 10.028-7.354h188.861c5.683 0 7.688 4.011 6.351 7.354l-53.483 134.041-.092.212c-1.86 4.256-2.976 6.808-6.593 6.808z"
      ></path>
      <path
        fill="#88CCEF"
        d="M742.479 366.607c-1.861 4.256-2.976 6.808-6.593 6.808h-196.72c-5.5 0-6.5-1.203-3.515-8.382l55.163-132.679c2.005-4.68 2.674-7.354 10.028-7.354h188.861c5.683 0 7.688 4.011 6.351 7.354l-53.483 134.041-.092.212z"
      ></path>
      <path
        fill="#9EDAF7"
        d="M748.479 366.607c-1.861 4.256-2.976 6.808-6.593 6.808h-196.72c-5.5 0-6.5-1.203-3.515-8.382l55.163-132.679c2.005-4.68 2.674-7.354 10.028-7.354h188.861c5.683 0 7.688 4.011 6.351 7.354l-53.483 134.041-.092.212z"
      ></path>
      <ellipse
        cx="680.39"
        cy="302.164"
        fill="#fff"
        rx="22"
        ry="27.434"
        transform="rotate(39.206 680.39 302.164)"
      ></ellipse>
      <path
        fill="#88CCEF"
        d="M332.22 13.899c-73.179-1.427-69.475 125.048-21.305 141.725l70.399-22.232c19.503-5.356 30.6-8.599 35.2-31.494 24.084 2.779 58.358-85.218-84.294-88z"
      ></path>
      <path
        fill="#88CCEF"
        d="M273.86 58.358c-75.072 1.854-.967-105.598 20.378-33.347L273.86 58.358z"
      ></path>
      <path
        fill="#9EDAF7"
        d="M277.099 68.549c2.966-19.074 11.18-36.436 25.438-46.26-29.641-.056-36.126 37.923-25.438 46.26z"
      ></path>
      <path
        fill="#B5E6FC"
        d="M306.235 134.314c4.688 37.724 2.51 47.671-2.779 62.989 11.114 16.671 44.462 19.449 45.389.926-3.637-9.773-4.732-24.974 0-24.087 44.461 8.337 47.24-9.263 54.652-73.175-14.817-2.776-29.646-42.606-74.1-35.197-22.232 3.705-.923 65.766-12.043 48.166-28.547-45.184-41.693.925-11.119 20.378z"
      ></path>
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M309.489 90.311C309.602 59.066 327.779 31.138 364 15c-74.226 3.945-100.764 121.215-62.759 121.215 4.611 0 8.485-1.285 11.636-3.426 2.964 4.157 5.614 7.333 8.592 10.015 3.339 3.009 7.016 5.329 11.864 7.743.113 3.296 3.398 6.455 7.939 7.382 5.084 1.038 9.817-1.115 10.571-4.81.755-3.694-2.755-7.531-7.839-8.569-3.75-.766-7.309.205-9.222 2.249-4.471-2.248-7.722-4.341-10.636-6.967-2.739-2.467-5.243-5.46-8.193-9.62 11.828-12.065 9.241-38.248-6.464-39.9z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#34A8FE"
        fillRule="evenodd"
        d="M380.194 113.136c-2.211 10.593-11.602 18.55-22.851 18.55-12.892 0-23.343-10.451-23.343-23.343S344.451 85 357.343 85c12.823 0 23.231 10.34 23.342 23.136h10.639c.112-11.53 9.493-20.843 21.05-20.843 11.625 0 21.05 9.424 21.05 21.05 0 11.626-9.425 21.05-21.05 21.05-9.977 0-18.333-6.94-20.502-16.257h-11.678zm-22.851 13.55c10.131 0 18.343-8.212 18.343-18.343 0-10.13-8.212-18.343-18.343-18.343S339 98.213 339 108.343c0 10.131 8.212 18.343 18.343 18.343zm55.031-2.293c8.864 0 16.05-7.185 16.05-16.05 0-8.864-7.186-16.05-16.05-16.05-8.865 0-16.051 7.186-16.051 16.05 0 8.865 7.186 16.05 16.051 16.05z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
}

export default SupportPerson;
