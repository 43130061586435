import React from "react";

const BellOutline = props => (
  <svg
    className={props.className}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
  >
    <path
      fill={props.colorPrimary}
      d="M11 13h2v3h3v-5h2l-6-5-6 5h2v5h3v-3zm1-12l9 4v6c0 5.55-3.84 10.74-9 12-5.16-1.26-9-6.45-9-12V5l9-4z"
    />
  </svg>
);

export default BellOutline;
