import React, { Component } from "react";
import { NavLink, withRouter } from "react-router-dom";

//Styling
import "./ForumBannerStyle.css";

//compnents
import Dropdown from "../../dropdowns/Dropdown";
import TabBar from "../../tabs/TabBar";
import SearchBar from "../../search/SearchBar";
import MoreDropDown from "../../cards/components/MoreDropDown";

//icon
import Filter from "../../../media/icons/Filter";
import ChevronDown from "../../../media/icons/ChevronDown";
import ChevronRight from "../../../media/icons/ChevronRight";
class Banner extends Component {
  render() {
    const ForumTabs = {
      Tab: {
        text: "Feed",
        path: "/forum",
        className: "banner-tab",
        activeClassName: "active-banner-tab"
      },
      TopicsTab: {
        text: "Topics",
        path: "/forum/topics",
        className: "banner-tab",
        activeClassName: "active-banner-tab"
      },
      GroupsTab: {
        text: "Groups",
        path: "/forum/groups",
        className: "banner-tab",
        activeClassName: "active-banner-tab"
      },
      MessagesTab: {
        text: "Messages",
        path: "/forum/messages",
        className: "banner-tab",
        activeClassName: "active-banner-tab"
      }
    };

    return (
      <div>
        <div className="banner-header centered">
          <NavLink to="/forum">Forum</NavLink>
        </div>
        <div className="banner-tabs outskrts sizing">
          <TabBar content={ForumTabs} root="/forum" />

          <div className="banner-tabs-rit centered">
            <div className="banenr-searchbar-container outskrts">
              <SearchBar />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Banner);
