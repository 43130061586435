import React, { Component } from "react";
import axios from "axios";

import { Link } from "react-router-dom";

//styling
import "./MoreColumnStyle.css";

//compnents
import PostContent from "../PostCont/PostContent";

//icons
import CardsView from "../../../media/icons/CardsView";
import ChevronDown from "../../../media/icons/ChevronDown";
import Filter from "../../../media/icons/Filter";

import NewPost from "../../../media/icons/NewPost";

class allpostforum extends Component {
  state = {
    forumPosts: null
  };

  componentDidMount() {}

  render() {
    let fullButton = window.scrollY > 24 ? false : true;
    const { toTop } = this.props;

    const trendingPosts = (
      <div className="trendig-post-cont outskrts">
        <div>
          <p>Data</p>
          <h3>This is a test title for a trending post</h3>
        </div>
        <img
          src="https://firebasestorage.googleapis.com/v0/b/flipit-238117.appspot.com/o/images%2Fthumbnails%2FLinkThumb.png?alt=media&token=e348a2d4-2772-435d-9219-c32075e7ccc8"
          alt="Article Preview"
        />
      </div>
    );

    const trendingGroups = (
      <div className="trendig-group-cont outskrts">
        <div className="centered">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/flipit-238117.appspot.com/o/images%2Fthumbnails%2FLinkThumb.png?alt=media&token=e348a2d4-2772-435d-9219-c32075e7ccc8"
            alt="Article Preview"
          />
          <div>
            <h3>Group name</h3>
            <p>Followers</p>
          </div>
        </div>
        <div className="more-col-follow-btn centered-col">Follow</div>
      </div>
    );

    const trendingTopics = (
      <div className="trendig-group-cont outskrts">
        <div>
          <h3>Topic name</h3>
          <p>recent post count</p>
        </div>
        <div className="more-col-follow-btn centered-col">Follow</div>
      </div>
    );

    //  <CamsButton />

    return (
      <div className="more-container">
        <div
          className="more-container-wrap"
          style={{
            top: this.props.toTop
          }}
        >
          <PostContent />

          <div className="desktop-only forum-more-container ">
            <h4 className="">Trending Topics</h4>
            {trendingTopics}
            {trendingTopics}
            {trendingTopics}
            {trendingTopics}
            <Link
              to="/forum/topics"
              className="trending-more-wrap centered-col"
            >
              more
            </Link>
          </div>
          <div className="desktop-only forum-more-container ">
            <h4 className="">Trending Groups</h4>
            {trendingGroups}
            {trendingGroups}
            <Link
              to="/forum/groups"
              className="trending-more-wrap centered-col"
            >
              more
            </Link>
          </div>

          <div className="forum-more-legal centered desktop-only">
            <a href="/terms-of-use">Terms of Use</a>•
            <a href="/privacy-policy">Privacy policy</a>
          </div>
        </div>
      </div>
    );
  }
}

export default allpostforum;

//<PostContent test="test" />
