import React, { Component } from "react";
import PropTypes from "prop-types";

//redux stuff
import { connect } from "react-redux";

//style
import "./ForumContStyle.css";

//components
import AuthForm from "../auth/AuthForm";

//consoleIcons
import NoFollowing from "../../media/SVGs/NoFollowing";

class Following extends Component {
  render() {
    const {
      user: { authenticated }
    } = this.props;
    // console.log(this.props.user);

    let authResponse = (
      <div className="No-content noContent-anim">
        <div style={{ marginBottom: "-32px" }}>
          <NoFollowing />
        </div>
        <div className="nocont-text">You don't follow anyone</div>
        <p className="">...you must be a leader!</p>
      </div>
    );

    return (
      <div className="posts-container">
        {!authenticated ? <AuthForm /> : authResponse}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: state.user
});

export default connect(mapStateToProps)(Following);
