import React from "react";

function SupportTicket() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 377 377">
      <g clipPath="url(#clip0)">
        <path
          fill="#F4F6F8"
          fillRule="evenodd"
          d="M250.368 352.317C263.805 367.586 282.205 377 302.5 377c41.145 0 74.5-38.692 74.5-86.42 0-42.288-26.184-77.482-60.772-84.956.509-4.076.772-8.244.772-12.484 0-47.728-33.355-86.42-74.5-86.42-9.504 0-18.592 2.064-26.949 5.827C202.705 80.342 174.602 58 142 58c-44.735 0-81 42.067-81 93.96 0 7.572.772 14.935 2.23 21.988C27.043 183.344 0 220.829 0 265.64c0 51.893 36.265 93.96 81 93.96 15.017 0 29.079-4.74 41.136-13.001C140.522 361.958 164.183 371.2 190 371.2c22.432 0 43.237-6.977 60.368-18.883z"
          clipRule="evenodd"
        ></path>
        <path
          fill="url(#paint0_linear)"
          fillRule="evenodd"
          d="M205.44 360.55c6.325 5.527 14.16 9.27 22.928 10.424 24.99 3.289 48.098-15.697 51.614-42.408 3.516-26.71-13.892-51.029-38.882-54.319a44.59 44.59 0 00-2.163-.23 58.53 58.53 0 003.287-13.101c3.949-30.003-15.606-57.321-43.677-61.016-9.426-1.241-18.614.34-26.811 4.124-5.654-19.597-21.554-34.754-41.951-37.438-28.071-3.695-54.028 17.632-57.977 47.635a58.562 58.562 0 00-.29 12.723c-23.508 5.251-42.625 25.856-46.147 52.608-4.43 33.663 17.509 64.312 49.003 68.457a54.19 54.19 0 0024.124-2.278c15.01 7.126 33.42 12.548 53.63 15.208 19.218 2.53 37.51 2.226 53.312-.389z"
          clipRule="evenodd"
        ></path>
        <path
          fill="#9EDAF7"
          d="M157.059 237.093l-5.562-22.615a2 2 0 01.97-2.225l112.082-62.365a1.592 1.592 0 011.774 2.629l-106.066 85.655a2 2 0 01-3.198-1.079z"
        ></path>
        <path
          fill="#88CCEF"
          d="M164.701 219.304l-7.126 16.035c-.851 1.913 1.438 3.681 3.074 2.376l18.049-14.397c.222-.177.404-.4.533-.653l7.081-13.854c.941-1.842-1.192-3.727-2.904-2.565l-18.002 12.215c-.309.21-.553.502-.705.843z"
        ></path>
        <path
          fill="#B5E6FC"
          d="M150.185 212.941l-28.667-15.088c-1.568-.826-1.368-3.132.319-3.674l150.476-48.352c1.827-.588 3.352 1.486 2.247 3.055l-65.446 92.958a2 2 0 01-2.551.627l-40.41-20.805c-1.35-.695-1.463-2.584-.205-3.435l100.031-67.648-113.903 62.346a1.999 1.999 0 01-1.891.016z"
        ></path>
        <path
          fill="url(#paint1_linear)"
          d="M-65.199 322.943c72.112 3.116 145.099-21.366 219.436-82.795-88.611 52.357-138.856 57.184-206.543 61.429-9.267 4.599-16.096 8.459-12.893 21.366z"
        ></path>
      </g>
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="166.504"
          x2="166.004"
          y1="254.153"
          y2="310.653"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E3E7EB"></stop>
          <stop offset="1" stopColor="#E3E7EB" stopOpacity="0"></stop>
        </linearGradient>
        <linearGradient
          id="paint1_linear"
          x1="154.253"
          x2="4.776"
          y1="240.148"
          y2="307.808"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#B5E6FC"></stop>
          <stop offset="1" stopColor="#B5E6FC" stopOpacity="0"></stop>
        </linearGradient>
        <clipPath id="clip0">
          <path fill="#fff" d="M0 0H377V377H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default SupportTicket;

// <g clipPath="url(#clip0)">
//   <path
//     fill="#F4F6F8"
//     fillRule="evenodd"
//     d="M250.368 311.721C263.805 324.884 282.205 333 302.5 333c41.145 0 74.5-33.355 74.5-74.5 0-36.455-26.184-66.795-60.772-73.238.509-3.514.772-7.107.772-10.762 0-41.145-33.355-74.5-74.5-74.5-9.504 0-18.592 1.779-26.949 5.023C202.705 77.26 174.602 58 142 58c-44.735 0-81 36.265-81 81 0 6.528.772 12.875 2.23 18.955C27.043 166.055 0 198.37 0 237c0 44.735 36.265 81 81 81 15.017 0 29.079-4.086 41.136-11.208C140.522 320.033 164.183 328 190 328c22.432 0 43.237-6.015 60.368-16.279z"
//     clipRule="evenodd"
//   ></path>
//   <path
//     fill="url(#paint1_linear)"
//     fillRule="evenodd"
//     d="M205.44 360.55c6.325 5.527 14.16 9.27 22.928 10.424 24.99 3.289 48.098-15.697 51.614-42.408 3.516-26.71-13.892-51.029-38.882-54.319a44.59 44.59 0 00-2.163-.23 58.53 58.53 0 003.287-13.101c3.949-30.003-15.606-57.321-43.677-61.016-9.426-1.241-18.614.34-26.811 4.124-5.654-19.597-21.554-34.754-41.951-37.438-28.071-3.695-54.028 17.632-57.977 47.635a58.562 58.562 0 00-.29 12.723c-23.508 5.251-42.625 25.856-46.147 52.608-4.43 33.663 17.509 64.312 49.003 68.457a54.19 54.19 0 0024.124-2.278c15.01 7.126 33.42 12.548 53.63 15.208 19.218 2.53 37.51 2.226 53.312-.389z"
//     clipRule="evenodd"
//   ></path>
//   <path
//     fill="#9EDAF7"
//     d="M160.059 192.093l-5.562-22.615a2 2 0 01.97-2.225l112.082-62.365a1.592 1.592 0 011.774 2.629l-106.066 85.655a2 2 0 01-3.198-1.079z"
//   ></path>
//   <path
//     fill="#88CCEF"
//     d="M167.701 174.304l-7.126 16.035c-.851 1.913 1.438 3.681 3.074 2.376l18.049-14.397c.222-.177.404-.4.533-.653l7.081-13.854c.941-1.842-1.192-3.727-2.904-2.565l-18.002 12.215c-.309.21-.553.502-.705.843z"
//   ></path>
//   <path
//     fill="#B5E6FC"
//     d="M153.185 167.941l-28.667-15.088c-1.568-.826-1.368-3.132.319-3.674l150.476-48.352c1.827-.588 3.352 1.486 2.247 3.055l-65.446 92.958a2 2 0 01-2.551.627l-40.41-20.805c-1.35-.695-1.463-2.584-.205-3.435l100.031-67.648-113.903 62.346a1.999 1.999 0 01-1.891.016z"
//   ></path>
//   <path
//     fill="url(#paint2_linear)"
//     d="M-62.199 277.943c72.112 3.116 145.099-21.366 219.436-82.795-88.611 52.357-138.856 57.184-206.543 61.429-9.267 4.599-16.096 8.459-12.893 21.366z"
//   ></path>
// </g>
// <defs>
//   <linearGradient
//     id="paint1_linear"
//     x1="166.504"
//     x2="166.004"
//     y1="254.153"
//     y2="310.653"
//     gradientUnits="userSpaceOnUse"
//   >
//     <stop stopColor="#E3E7EB"></stop>
//     <stop offset="1" stopColor="#E3E7EB" stopOpacity="0"></stop>
//   </linearGradient>
//   <linearGradient
//     id="paint2_linear"
//     x1="157.253"
//     x2="7.776"
//     y1="195.148"
//     y2="262.808"
//     gradientUnits="userSpaceOnUse"
//   >
//     <stop stopColor="#B5E6FC"></stop>
//     <stop offset="1" stopColor="#B5E6FC" stopOpacity="0"></stop>
//   </linearGradient>
//   <clipPath id="clip0">
//     <path fill="#fff" d="M0 0H377V377H0z"></path>
//   </clipPath>
// </defs>
// </svg>
