import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="347"
      height="425"
      fill="none"
      viewBox="0 0 347 425"
    >
      <path stroke="#F5F5F5" strokeWidth="2" d="M301 52H322V73H301z"></path>
      <path stroke="#F5F5F5" strokeWidth="2" d="M301 31H322V52H301z"></path>
      <path stroke="#F5F5F5" strokeWidth="2" d="M301 10H322V31H301z"></path>
      <g filter="url(#filter0_dd)">
        <path fill="#fff" d="M301 10H322V52H301z"></path>
        <path
          stroke="#F0F0F0"
          strokeWidth="0.75"
          d="M301.375 10.375H321.625V51.625H301.375z"
        ></path>
      </g>
      <path stroke="#F5F5F5" strokeWidth="2" d="M174 222H195V243H174z"></path>
      <path stroke="#F5F5F5" strokeWidth="2" d="M174 243H195V264H174z"></path>
      <path stroke="#F5F5F5" strokeWidth="2" d="M174 201H195V222H174z"></path>
      <path stroke="#F5F5F5" strokeWidth="2" d="M132 243H153V264H132z"></path>
      <g filter="url(#filter1_dd)">
        <path fill="#fff" d="M174 201H195V222H174z"></path>
        <path
          stroke="#F0F0F0"
          strokeWidth="0.75"
          d="M174.375 201.375H194.625V221.625H174.375z"
        ></path>
      </g>
      <path stroke="#F5F5F5" strokeWidth="2" d="M174 364H195V385H174z"></path>
      <path stroke="#F5F5F5" strokeWidth="2" d="M132 343H153V364H132z"></path>
      <path stroke="#F5F5F5" strokeWidth="2" d="M111 322H132V343H111z"></path>
      <path stroke="#F5F5F5" strokeWidth="2" d="M22 322H43V343H22z"></path>
      <path stroke="#F5F5F5" strokeWidth="2" d="M1 322H22V343H1z"></path>
      <g filter="url(#filter2_dd)">
        <path fill="#fff" d="M174 364H195V385H174z"></path>
        <path
          stroke="#F0F0F0"
          strokeWidth="0.75"
          d="M174.375 364.375H194.625V384.625H174.375z"
        ></path>
      </g>
      <path stroke="#F5F5F5" strokeWidth="2" d="M280 137H322V158H280z"></path>
      <path stroke="#F5F5F5" strokeWidth="2" d="M280 116H301V137H280z"></path>
      <path stroke="#F5F5F5" strokeWidth="2" d="M259 73H280V94H259z"></path>
      <path stroke="#F5F5F5" strokeWidth="2" d="M259 158H280V179H259z"></path>
      <path stroke="#F5F5F5" strokeWidth="2" d="M280 201H301V222H280z"></path>
      <path stroke="#F5F5F5" strokeWidth="2" d="M301 201H322V222H301z"></path>
      <path stroke="#F5F5F5" strokeWidth="2" d="M322 201H343V222H322z"></path>
      <path stroke="#F5F5F5" strokeWidth="2" d="M280 222H301V243H280z"></path>
      <path stroke="#F5F5F5" strokeWidth="2" d="M259 222H280V243H259z"></path>
      <defs>
        <filter
          id="filter0_dd"
          width="71"
          height="92"
          x="276"
          y="0"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="15"></feOffset>
          <feGaussianBlur stdDeviation="12.5"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow"
          ></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="5"></feOffset>
          <feGaussianBlur stdDeviation="5"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.09 0"></feColorMatrix>
          <feBlend
            in2="effect1_dropShadow"
            result="effect2_dropShadow"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect2_dropShadow"
            result="shape"
          ></feBlend>
        </filter>
        <filter
          id="filter1_dd"
          width="71"
          height="71"
          x="149"
          y="191"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="15"></feOffset>
          <feGaussianBlur stdDeviation="12.5"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow"
          ></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="5"></feOffset>
          <feGaussianBlur stdDeviation="5"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.09 0"></feColorMatrix>
          <feBlend
            in2="effect1_dropShadow"
            result="effect2_dropShadow"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect2_dropShadow"
            result="shape"
          ></feBlend>
        </filter>
        <filter
          id="filter2_dd"
          width="71"
          height="71"
          x="149"
          y="354"
          colorInterpolationFilters="sRGB"
          filterUnits="userSpaceOnUse"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="15"></feOffset>
          <feGaussianBlur stdDeviation="12.5"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.12 0"></feColorMatrix>
          <feBlend
            in2="BackgroundImageFix"
            result="effect1_dropShadow"
          ></feBlend>
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          ></feColorMatrix>
          <feOffset dy="5"></feOffset>
          <feGaussianBlur stdDeviation="5"></feGaussianBlur>
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.09 0"></feColorMatrix>
          <feBlend
            in2="effect1_dropShadow"
            result="effect2_dropShadow"
          ></feBlend>
          <feBlend
            in="SourceGraphic"
            in2="effect2_dropShadow"
            result="shape"
          ></feBlend>
        </filter>
      </defs>
    </svg>
  );
}

export default Icon;
