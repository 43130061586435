import React from "react";
// import { Link } from "react-router-dom";
import { HashLink as Link } from "react-router-hash-link";
import Button from "../buttons/Button";
// import Icon from "../../media/SVGs/FloorPlan";

//Styling
import "./ProductBoxStyle.css";

//icons
import FullArrowRight from "../../media/icons/FullArrowRight";

const ContSection = props => (
  <li className="prodbox-container">
    <a className="product-page-anchor" id={props.id} />
    <div className="prodbox-icon-wrap centered-col">{props.icon}</div>
    <h2>{props.name}</h2>
    <div className="prodbox-plans-wrap centered">
      {props.plans.map((plan, i) => (
        <Link
          to="/pricing#pricingTableLink"
          className="prodbox-blan-box"
          key={`${Math.random()
            .toString(36)
            .substring(7)}-key`}
        >
          {plan}
        </Link>
      ))}
    </div>
    <p>{props.description}</p>
    <div className="prodbox-link-wrap centered">
      <Link to="/signin">Get started</Link>
      <div className="prodbox-link-pricing">
        <Link to="/pricing#pricing-top" className="centered">
          <div className="">Pricing</div>
          <FullArrowRight size="24px" colorPrimary="#757575" />
        </Link>
      </div>
    </div>
    <div className="prodbox-divLine">
      <div></div>
    </div>
  </li>
);

export default ContSection;
