import React, { Component } from "react";

import PostContent from "./PostContent";
import MoreDropDown from "../../cards/components/MoreDropDown";

//styling
import { convertFromRaw } from "draft-js";
import renderHTML from "react-render-html";
import { stateToHTML } from "draft-js-export-html";

import SharePop from "../../cards/components/SharePop";
import ReportPop from "../../cards/components/ReportPop";
import DeletePop from "../../cards/components/DeletePop";

import CommentTextInput from "./CommentTextInput";

//compnents
import Button from "../../buttons/Button";
import MoreDots from "../../../media/icons/MoreDots";
import ArrowUp from "../../../media/icons/ArrowUp";
import ArrowDown from "../../../media/icons/ArrowDown";
import Edit from "../../../media/icons/Edit";

import Share from "../../../media/icons/Share";
import Delete from "../../../media/icons/Delete";
import Report from "../../../media/icons/Report";

import Close from "../../../media/SVGs/None";

class PostComment extends Component {
  state = {
    replyOpen: false,
    usrChk: false,
  };
  componentDidMount() {
    this.commentBuilder(this.props.comments);
    if (this.props.user) {
      let credentials = this.props.user.credentials;
      this.setState({
        usrChk:
          this.props.comData.data === credentials.username ||
          credentials.accountType === "admin",
      });
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.comments !== this.props.comments ||
      prevProps.user !== this.props.user
    ) {
      let credentials = this.props.user.credentials;
      this.setState({
        usrChk:
          this.props.comData.data.username === credentials.username ||
          credentials.accountType === "admin",
      });
      this.commentBuilder(this.props.comments);
    }
    return null;
  }
  closeCom = () => {
    this.setState({ replyOpen: false });
  };
  commentBuilder = (comArr) => {
    if (!comArr) return;
    let comments = comArr
      .filter((com) => com.data.parentId === this.props.comData.id)
      .map((com) =>
        this.props.user ? (
          <PostComment
            key={com.id}
            comData={com}
            comments={comArr}
            user={this.props.user}
          />
        ) : null
      );
    this.setState({ comments });
  };

  render() {
    const {
      user,
      comData: {
        id,
        comments,
        data: {
          edited,
          bestAnswer,
          createdAt,
          userImage,
          username,
          likeCount,
          bodyStyled,
          forumPostId,
          toDelete,
        },
      },
      placeholder,
    } = this.props;

    const authorized = [
      {
        icon: Share,
        lable: "Share",
        comp: SharePop,
      },
      {
        icon: Edit,
        lable: "Edit",
        comp: ReportPop,
      },
      {
        icon: Delete,
        lable: "Delete",
        comp: DeletePop,
      },
    ];
    const unauthorized = [
      {
        icon: Share,
        lable: "Share",
        comp: SharePop,
      },
      {
        icon: Report,
        lable: "Repot",
        comp: ReportPop,
      },
    ];
    const dopElm = this.state.usrChk ? authorized : unauthorized;

    return (
      <div
        className={`post-page-comment-wrap ${
          toDelete === forumPostId ? "post-delete-animation" : ""
        }`}
        id={id}
        key={id}
      >
        <div>
          <div className="comment-vote-wrap centered-col">
            <button
              className="centered"
              onClick={(e) => console.log(this.props)}
            >
              <ArrowUp className="" colorPrimary="#D2D6D9" size="24px" />
            </button>
            {likeCount}
            <button
              className="centered"
              onClick={(e) => console.log(this.porps)}
            >
              <ArrowDown className="" colorPrimary="#D2D6D9" size="24px" />
            </button>
          </div>
          <div className="comment-cont-wrap">
            <PostContent bodyStyled={bodyStyled} />
            <div className="outskrts">
              <div className="comment-user-profile-wrap centered">
                <img src={userImage ? userImage : placeholder} alt="" />
                {username}
              </div>
              <div className="commment-buttons-wrap">
                <MoreDropDown
                  elements={dopElm}
                  compId={id}
                  delFunc={this.deletePost}
                  compType={"comment"}
                />
                <button
                  className="button button-white"
                  style={{ padding: this.state.replyOpen ? "0 6px" : "0 16px" }}
                  onClick={(e) =>
                    this.setState({ replyOpen: !this.state.replyOpen })
                  }
                >
                  {this.state.replyOpen ? <Close /> : "Reply"}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="comment-comment-wrap">
          {!this.state.replyOpen ? (
            ""
          ) : (
            <CommentTextInput
              comment={true}
              parentId={id}
              postId={forumPostId}
              closeCom={this.closeCom}
            />
          )}
          {!this.state.comments ? "" : this.state.comments}
        </div>
      </div>
    );
  }
}

export default PostComment;
