import React, { Component } from "react";
import axios from "axios";

//styling
import "./userProfileStyle.css";
import "../components/cards/cardStyle.css";

//compnents
// import Button from "../components/buttons/Button";
import ForumPost from "../components/cards/ForumPost";
import Spinner from "../components/loading/Spinner";
import Banner from "../components/userProfile/ProfileBanner";
import ForumBack from "../components/backgrounds/ForumBack";

//contents
import UsersPosts from "../components/forum/UsersPosts";
import UsersComments from "../components/forum/UsersComments";
// import Following from "../components/forum/Following";
// import Saved from "../components/forum/Saved";
import Flips from "../components/forum/Flips";
import Forum404 from "../components/forum/Forum404";

//icons
// import NewPost from "../media/icons/NewPost";

class forum extends Component {
  constructor() {
    super();
    this.banElement = React.createRef();
    this.bannerScroll = this.bannerScroll.bind(this);
  }
  state = {
    userCont: <UsersPosts />,
    // banner---------------
    lastScrollUp: 0,
    BannerHeight: 0,
    BannerTop: 0,
    BannerDis: 1,
    tabHeight: 0
  };

  componentDidMount() {
    window.addEventListener("scroll", this.bannerScroll, false);
    this.setState({
      BannerHeight: this.banElement.current.getBoundingClientRect().height
    });

    axios
      .get(`/user/${this.usernameFromURL()}`)
      .then(res => {
        console.log("retrived: " + res.data);
        this.setState({
          userData: res.data
        });
        this.userPop(this.props.location.pathname);
      })
      .catch(err => console.log(err));
  }
  componentWillMount() {
    this.userPop(this.props.location.pathname);
  }
  componentWillUnmount() {
    window.removeEventListener("scroll", this.bannerScroll, false);
  }

  componentDidUpdate(prevProps) {
    const currentPath = this.props.location.pathname;
    if (currentPath !== prevProps.location.pathname) {
      this.userPop(this.props.location.pathname);
    }
  }
  usernameFromURL() {
    return this.props.location.pathname.split("/")[2];
  }

  // userPop(path) {
  //   let location = !path.split("/")[3] ? "user" : path.split("/")[3];
  //
  //   if (location === "user") {
  //     return this.setState({
  //       userCont: <UsersPosts data={this.state.userData} />
  //     });
  //   }
  //   if (location === "comments") {
  //     return this.setState({
  //       userCont: <UsersComments data={this.state.userData} />
  //     });
  //   }
  //   if (location === "flips") {
  //     return this.setState({ userCont: <Flips data={this.state.userData} /> });
  //   } else {
  //     return this.setState({ userCont: <Forum404 /> });
  //   }
  // }

  userPop(path) {
    let location = !path.split("/")[3] ? "user" : path.split("/")[3];

    if (location === "user") {
      return this.setState({
        userCont: "User Posts"
      });
    }
    if (location === "comments") {
      return this.setState({
        userCont: "User Comments"
      });
    }
    if (location === "about") {
      return this.setState({
        userCont: "User About"
      });
    }
    if (location === "flips") {
      return this.setState({ userCont: "User Flips" });
    } else {
      return this.setState({ userCont: <Forum404 /> });
    }
  }

  render() {
    let recentPost = this.state.forumPosts ? (
      this.state.forumPosts.map((forumPost, i) => (
        <ForumPost key={forumPost.forumPostId} post={forumPost} />
      ))
    ) : (
      <Spinner color="#939eab" size="24px" />
    );

    return (
      <div>
        <ForumBack />
        <div
          ref={this.banElement}
          className="banner-behavior-wrap"
          style={{
            top: this.state.BannerTop,
            opacity: this.state.BannerDis
          }}
        >
          <Banner />
        </div>
        <div
          className="forum-content"
          style={{
            paddingTop: this.state.BannerHeight
          }}
        >
          <section className="forum-wrap sizing">{this.state.userCont}</section>
          <div className="spacer"></div>
        </div>
      </div>
    );
  }
  bannerScroll() {
    var banner = this.banElement.current.getBoundingClientRect(),
      lastScrollUp = this.state.lastScrollUp,
      scroll = window.scrollY;

    if (scroll > banner.height - this.state.tabHeight) {
      if (!lastScrollUp) {
        lastScrollUp = scroll;
      }

      var tabHeightRaw = lastScrollUp - scroll;
      var tabHeight = Math.max(Math.min(tabHeightRaw, 48), 0);

      if (tabHeightRaw > 48) {
        lastScrollUp = scroll + 48;
      }

      if (tabHeightRaw < 0) {
        lastScrollUp = 0;
      }
      this.setState({
        BannerTop: -banner.height + tabHeight,
        tabHeight: tabHeight,
        BannerDis: tabHeight ? 1 : 0,
        lastScrollUp: lastScrollUp
      });
    } else {
      this.setState({
        BannerTop: -scroll,
        BannerDis: 1
      });
    }
  }
}

export default forum;
