import dotProp from "dot-prop-immutable";
import {
  SET_FORUMPOSTS,
  LIKE_FORUMPOST,
  UNLIKE_FORUMPOST,
  LOADING_DATA,
  DELETE_FORUMPOST,
  DELETE_PREVIEW,
  POST_FORUMPOST,
  SET_FORUMPOST,
  SUBMIT_COMMENT,
  DELETE_FORUMCOM,
  LOADING_COMMENT,
} from "../types";

const initialState = {
  forumPosts: [],
  forumPost: {},
  loading: false,
  loadingCom: false,
  toDelete: "",
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LOADING_DATA:
      return {
        ...state,
        loading: true,
      };
    case SET_FORUMPOSTS:
      //append the new forum posts to the existing ones
      return {
        ...state,
        forumPosts: action.payload,
        loading: false,
      };
    case SET_FORUMPOST:
      return {
        ...state,
        forumPost: action.payload,
      };
    case LIKE_FORUMPOST:
    case UNLIKE_FORUMPOST:
      let index = state.forumPosts.findIndex(
        (forumPost) => forumPost.forumPostId === action.payload.id
      );
      state.forumPosts[index].likeCount = action.payload.count;
      if (state.forumPost.forumPostId === action.payload.id) {
        state.forumPost.likeCount = action.payload.count;
      }
      return {
        ...state,
      };
    case DELETE_PREVIEW:
      // state.toDelete = action.payload;
      return {
        ...state,
        toDelete: action.payload,
        deleting: true,
      };

    case DELETE_FORUMPOST:
      let indx = state.forumPosts.findIndex(
        (forumPost) => forumPost.forumPostId === action.payload
      );
      state.forumPosts.splice(indx, 1);
      state.toDelete = "";
      return {
        ...state,
      };
    case DELETE_FORUMCOM:
      return {
        ...state,
        deleting: false,
        forumPost: {
          ...state.forumPost,
          comments: state.forumPost.comments.filter(
            (comm) => comm.id !== action.payload
          ),
        },
      };
    case LOADING_COMMENT:
      return {
        ...state,
        loadingCom: true,
      };
    case POST_FORUMPOST:
      return {
        ...state,
        forumPosts: [action.payload, ...state.forumPosts],
      };
    case SUBMIT_COMMENT:
      return {
        ...state,
        loadingCom: false,
        forumPost: {
          ...state.forumPost,
          comments: [action.payload, ...state.forumPost.comments],
        },
      };

    default:
      return state;
  }
}

const findPath = (comments, parentId) => {
  let path = [];
  const findIndex = (arry, count) => {
    for (; count < arry.length; count++) {
      if (arry[count].id === parentId) {
        path = "forumPost.comments[" + count + "].comments";
        return true;
      }
      if (arry[count].comments && arry[count].comments.length > 0) {
        if (findIndex(arry[count].comments, 0)) {
          path = path
            .split("forumPost")
            .join("forumPost.comments[" + count + "]");
          return true;
        }
      }
    }
  };

  findIndex(comments, 0);
  return path;
};
