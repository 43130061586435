import React, { Component } from "react";

//consoleIcons
import AddImage from "../../../../../media/icons/richText/AddImage";
import ImageLink from "../../../../../media/icons/Link";
import Clipboard from "../../../../../media/icons/Clipboard";

class linkPop extends Component {
  constructor(props) {
    super(props);
    this.state = { url: "" };
    this.dropRef = React.createRef();
  }

  componentDidMount() {
    this.setState({ url: this.props.currentLink });
  }
  componentDidUpdate() {}
  getSnapshotBeforeUpdate(prevProps, prevState) {
    if (prevProps.currentLink !== this.props.currentLink) {
      this.setState({
        url: this.props.currentLink ? this.props.currentLink : "",
      });
    }
    return null;
  }

  addLink = (e) => {
    e.preventDefault();
    let url = this.state.url;
    this.setState({ url: "" });
    if (!this.state.url) return this.props.close();

    this.props.addLink(url);
  };

  //----------------------------------------------------------------------

  async paste(e) {
    const text = await navigator.clipboard.readText();
    this.setState({ url: text });
  }

  //RENDER-------------------------------------------------------------------------
  render() {
    return (
      <div className="toolbar-link-input anim-drpdwn">
        <form className="outskrts" onSubmit={(e) => this.addLink(e)}>
          <div onClick={(e) => this.paste(e)}>
            <Clipboard size="24" />
          </div>
          <input
            placeholder="Enter url..."
            onChange={(e) => this.setState({ url: e.target.value || "" })}
            value={this.state.url}
          />
          <button
            style={{
              display: !this.state.url ? "none" : "flex",
              height: "32px",
            }}
            onClick={(e) => this.addLink(e)}
            className="button button-white anim-left centered-col"
          >
            Add
          </button>
        </form>
      </div>
    );
  }
}

export default linkPop;
