import React, { Component } from "react";
import { HashLink as Link } from "react-router-hash-link";

import "./pricingStyle.css";

//compopnents
import BetaCard from "../components/cards/BetaCard";
import Card from "../components/cards/Card";
import Button from "../components/buttons/Button";
import BubbleBack from "../components/backgrounds/BubbleBack";
import MobilePricing from "../components/mobile/MobilePricing";

import PageHeader from "../components/allPages/PageHeader";
import CardHeader from "../components/allPages/CardHeader";
import ContentCard from "../components/cards/ContentCard";

//IOcons
import Check from "../media/SVGs/Check";
import None from "../media/SVGs/None";
import BetaPeople from "../media/SVGs/BetaPeople";
import ArrowDown from "../media/icons/ArrowDown";
import Information from "../media/icons/Information";
import PricingContact from "../media/SVGs/PricingContact";

//consoleIcons
import Dashboard from "../media/icons/console/Dashboard";
import FlippabilitySearch from "../media/icons/console/FlippabilitySearch";
import TrendAnalysis from "../media/icons/console/TrendAnalysis";
import Watchlist from "../media/icons/console/Watchlist";
import ProjectBuilder from "../media/icons/console/ProjectBuilder";
import Financing from "../media/icons/console/Financing";
import Todo from "../media/icons/console/Todo";
import Budgeting from "../media/icons/console/Budgeting";
import Contractors from "../media/icons/console/Contractors";
import Suppliers from "../media/icons/console/Suppliers";
import Listing from "../media/icons/console/Listing";
import Financials from "../media/icons/console/Financials";
import Archive from "../media/icons/console/Archive";
import Realtors from "../media/icons/console/Realtors";

class pricing extends Component {
  render() {
    const dummyText =
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries";
    const flipperPlan = {
      Flipper: {
        color: "#4ec2f7",
        tagLine: "Unleash the Console",
        price: 65,
        features: {
          FlippabilitySearch: {
            icon: <FlippabilitySearch size="24px" colorPrimary="#fff" />,
            section: "Find it",
            description: dummyText,
            planLimit: "1 Location"
          },
          Watchlist: {
            icon: <Watchlist size="24px" colorPrimary="#fff" />,
            section: "Find it",
            description: dummyText,
            planLimit: "5 Properties"
          },
          Todo: {
            icon: <Budgeting size="24px" colorPrimary="#fff" />,
            section: "Flip it",
            description: dummyText,
            planLimit: "1 Property"
          },
          Budgeting: {
            icon: <Todo size="24px" colorPrimary="#fff" />,
            section: "Flip it",
            description: dummyText,
            planLimit: "1 Property"
          },
          AutoListing: {
            icon: <Listing size="24px" colorPrimary="#fff" />,
            section: "Sell it",
            description: dummyText,
            planLimit: "1 Property"
          },
          Financials: {
            icon: <Financials size="24px" colorPrimary="#fff" />,
            section: "Sell it",
            description: dummyText,
            planLimit: "1 Property"
          }
        }
      }
    };
    const proFlipperPlan = {
      ProFlipper: {
        color: "#039be5",
        tagLine: "Multiple Properties",
        price: 199,
        features: {
          FlippabilitySearch: {
            icon: <FlippabilitySearch size="24px" colorPrimary="#fff" />,
            section: "Find it",
            description: dummyText,
            planLimit: "Unlimited"
          },
          TrendAnalysis: {
            icon: <TrendAnalysis size="24px" colorPrimary="#fff" />,
            section: "Find it",
            description: dummyText,
            planLimit: "Unlimited"
          },
          Watchlist: {
            icon: <Watchlist size="24px" colorPrimary="#fff" />,
            section: "Find it",
            description: dummyText,
            planLimit: "Unlimited"
          },
          Todo: {
            icon: <Budgeting size="24px" colorPrimary="#fff" />,
            section: "Flip it",
            description: dummyText,
            planLimit: "3 Property"
          },
          Budgeting: {
            icon: <Todo size="24px" colorPrimary="#fff" />,
            section: "Flip it",
            description: dummyText,
            planLimit: "3 Property"
          },
          AutoListing: {
            icon: <Listing size="24px" colorPrimary="#fff" />,
            section: "Sell it",
            description: dummyText,
            planLimit: "3 Property"
          },
          Financials: {
            icon: <Financials size="24px" colorPrimary="#fff" />,
            section: "Sell it",
            description: dummyText,
            planLimit: "Unlimited"
          }
        }
      }
    };
    const EnterprisePlan = {
      Enterprise: {
        color: "#2e3a4d",
        tagLine: "Customize projects",
        features: {
          ProjectBuilder: {
            icon: <ProjectBuilder size="24px" colorPrimary="#fff" />,
            section: "Find it",
            description: dummyText,
            planLimit: "Unlimited"
          },
          Todo: {
            icon: <Budgeting size="24px" colorPrimary="#fff" />,
            section: "Flip it",
            description: dummyText,
            planLimit: "Unlimited"
          },
          Budgeting: {
            icon: <Todo size="24px" colorPrimary="#fff" />,
            section: "Flip it",
            description: dummyText,
            planLimit: "Unlimited"
          },
          AutoListing: {
            icon: <Listing size="24px" colorPrimary="#fff" />,
            section: "Sell it",
            description: dummyText,
            planLimit: "Unlimited"
          }
        }
      }
    };

    return (
      <div className="pricing-page-wrap">
        <a className="page-link-top" id="pricing-top" />
        <BubbleBack />
        <div className="sizing pricing-header">
          <PageHeader title="Pricing plans" line1="Plans for every budget" />
        </div>
        <section className="sizing">
          <Card className="card-shdow-sml table-card-wrap">
            <div className="pricing-table-wrap">
              <CardHeader />

              <div className="pricing-table-wrap-divider"></div>
              <div className="pricing-table-wrap-free">
                <a className="page-item-link" id="pricingTableLink" />
                <h3>The best plan for you</h3>
                <p>
                  Flipit offers many powerful tools, but you don’t need to pay
                  for the ones you won’t use. Find the plan that works best for
                  what you are trying to do.
                </p>
              </div>
              <div className="pricing-mobile-wrap">
                <div className="prising-mobile-allPlans">
                  <div className="">All plans inlcude</div>
                  <div className="pricing-icons-wrapper">
                    <div className="pricing-icon">
                      <Dashboard colorPrimary="#fff" size="20px" />
                    </div>
                    <div className="pricing-icon">
                      <Financing colorPrimary="#fff" size="20px" />
                    </div>
                    <div className="pricing-icon">
                      <Realtors colorPrimary="#fff" size="20px" />
                    </div>
                    <div className="pricing-icon">
                      <Contractors colorPrimary="#fff" size="20px" />
                    </div>
                    <div className="pricing-icon">
                      <Suppliers colorPrimary="#fff" size="20px" />
                    </div>
                    <div className="pricing-icon">
                      <Listing colorPrimary="#fff" size="20px" />
                    </div>
                    <div className="pricing-icon">
                      <Archive colorPrimary="#fff" size="20px" />
                    </div>
                  </div>
                  Dashboard (News, Projects overview, Alerts), Financing portal,
                  Realtor portal, Contractor portal, Supplier portal, Listing
                  Agent portal, Archives and the Community.
                </div>
                <MobilePricing content={flipperPlan} />
                <MobilePricing content={proFlipperPlan} />
                <MobilePricing content={EnterprisePlan} />
              </div>
              <table cellSpacing="0" className="">
                <colgroup>
                  <col span="1" style={{ width: "31%" }} />
                  <col span="1" style={{ width: "23%" }} />
                  <col span="1" style={{ width: "23%" }} />
                  <col span="1" style={{ width: "23%" }} />
                </colgroup>

                <thead>
                  <tr>
                    <td className="header-product">Products</td>

                    <th className="header-flipper-plan">
                      <div className="">Flipper</div>
                      <div className="pricing-table-header-subtitle">
                        Unleash the Console
                      </div>
                      <div className="pricing-table-header-price">$65/m</div>
                    </th>
                    <th className="header-pro-plan">
                      <div className="">Pro Flipper</div>
                      <div className="pricing-table-header-subtitle">
                        Multiple properties
                      </div>
                      <div className="pricing-table-header-price">$199/m</div>
                    </th>
                    <th className="header-enterprise-plan">
                      <div className="">Enterprise</div>
                      <div className="pricing-table-header-subtitle">
                        Customize projects
                      </div>
                      <div className="pricing-table-header-price">
                        <Link to="/contact">Contact Us</Link>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th style={{ padding: "16px 20px" }}>
                      <div className="table-Products-header">Community</div>
                    </th>
                    <td
                      style={{ padding: "16px 20px" }}
                      className="table-content-free"
                      colSpan="3"
                    >
                      <p>Free</p>
                    </td>
                  </tr>
                  <tr>
                    <th style={{ padding: "16px 20px" }}>
                      <div className="table-Products-header">Dashboard</div>
                    </th>
                    <td
                      style={{ padding: "16px 20px" }}
                      className="table-content-free"
                      colSpan="3"
                    >
                      <p>Free</p>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <div className="table-Products-header">Find it</div>
                      <div className="table-Products-name centered">
                        Flippability search
                        <Information
                          colorPrimary="rgb(223, 226, 230)"
                          size="24px"
                        />
                      </div>
                      <div className="table-Products-name centered">
                        Trend Analysis
                        <Information
                          colorPrimary="rgb(223, 226, 230)"
                          size="24px"
                        />
                      </div>
                      <div>Watchlist</div>
                      <div className="table-Products-name centered">
                        Project Builder
                        <Information
                          colorPrimary="rgb(223, 226, 230)"
                          size="24px"
                        />
                      </div>
                      <div>Financing</div>
                      <div>Realtor Portal</div>
                    </th>

                    <td>
                      <div>1 Location</div>
                      <div>
                        <None />
                      </div>
                      <div>5 Properties</div>
                      <div>
                        <None />
                      </div>
                      <div>
                        <Check />
                      </div>
                      <div>
                        <Check />
                      </div>
                    </td>
                    <td>
                      <div>Unlimited</div>
                      <div>Unlimited</div>
                      <div>Unlimited</div>
                      <div>
                        <None />
                      </div>
                      <div>
                        <Check />
                      </div>
                      <div>
                        <Check />
                      </div>
                    </td>
                    <td>
                      <div>
                        <div>Unlimited</div>
                        <div>Unlimited</div>
                        <div>Unlimited</div>
                        <Check />
                      </div>
                      <div>
                        <Check />
                      </div>
                      <div>
                        <Check />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <div className="table-Products-header">Flip it</div>
                      <div className="table-Products-name centered">
                        To-do
                        <Information
                          colorPrimary="rgb(223, 226, 230)"
                          size="24px"
                        />
                      </div>
                      <div className="table-Products-name centered">
                        Budgeting
                        <Information
                          colorPrimary="rgb(223, 226, 230)"
                          size="24px"
                        />
                      </div>
                      <div>Contractor Portal</div>
                      <div>Supplier Portal</div>
                    </th>

                    <td>
                      <div>1 Property</div>
                      <div>1 Property</div>
                      <div>
                        <Check />
                      </div>
                      <div>
                        <Check />
                      </div>
                    </td>
                    <td>
                      <div>3 Properties</div>
                      <div>3 Properties</div>
                      <div>
                        <Check />
                      </div>
                      <div>
                        <Check />
                      </div>
                    </td>
                    <td>
                      <div>Unlimited</div>
                      <div>Unlimited</div>
                      <div>
                        <Check />
                      </div>
                      <div>
                        <Check />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <div className="table-Products-header">Sell it</div>
                      <div className="table-Products-name centered">
                        Auto Listing
                        <Information
                          colorPrimary="rgb(223, 226, 230)"
                          size="24px"
                        />
                      </div>
                      <div className="table-Products-name centered">
                        Financials
                        <Information
                          colorPrimary="rgb(223, 226, 230)"
                          size="24px"
                        />
                      </div>
                      <div>Listing Agent Portal</div>
                      <div>Archived</div>
                    </th>

                    <td>
                      <div>1 Property</div>
                      <div>1 Property</div>
                      <div>
                        <Check />
                      </div>
                      <div>3 Properties</div>
                    </td>
                    <td>
                      <div>3 Properties</div>
                      <div>Unlimited</div>
                      <div>
                        <Check />
                      </div>
                      <div>Unlimited</div>
                    </td>
                    <td>
                      <div>Unlimited</div>
                      <div>Unlimited</div>
                      <div>
                        <Check />
                      </div>
                      <div>Unlimited</div>
                    </td>
                  </tr>
                  <tr>
                    <th style={{ padding: "16px 20px" }}>
                      <div className="table-Products-header">Archive</div>
                    </th>
                    <td
                      style={{ padding: "16px 20px" }}
                      className="table-content-free"
                      colSpan="3"
                    >
                      Free
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <td className="foot-product">Choose a plan</td>
                    <th className="header-flipper-plan">
                      <div className="">Flipper</div>
                      <div className="pricing-table-header-subtitle">
                        Unleash the Console
                      </div>
                      <Link to="/auth/signup">
                        <button className="button button-white">
                          Start now
                        </button>
                      </Link>
                    </th>
                    <th className="header-pro-plan">
                      <div className="">Pro Flipper</div>
                      <div className="pricing-table-header-subtitle">
                        Unlimited properties
                      </div>
                      <Link to="/auth/signup">
                        <button className="button button-white">
                          Start now
                        </button>
                      </Link>
                    </th>
                    <th className="footer-enterprise-plan">
                      <div className="">Enterprise</div>
                      <div className="pricing-table-header-subtitle">
                        Customize projects
                      </div>
                      <div className="pricing-table-header-price">
                        <Link to="/contact">
                          <button className="button button-white">
                            Contact us
                          </button>
                        </Link>
                      </div>
                    </th>
                  </tr>
                </tfoot>
              </table>
              <div className="pricing-dislaimer">
                *All prices listed above reflect annual builling plans.
              </div>
            </div>
          </Card>
        </section>

        <BetaCard />
        <ContentCard
          title="Still have questions?"
          text="Still have questions about pricing or products, get in touch. We constantly have deals and promotions that we would be happy to share with you!"
          media={<PricingContact />}
          button={
            <Link to="/contact">
              <button
                style={{ height: "40px", padding: "6px 24px" }}
                className="button button-blue suppor-button"
              >
                Contact us
              </button>
            </Link>
          }
        />
        <div className="spacer"></div>
      </div>
    );
  }
}

export default pricing;
