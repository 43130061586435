import React from "react";

const Financing = props => (
  <svg
    className={props.className}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    height={props.size}
    width={props.size}
  >
    <path
      fill={props.colorPrimary}
      d="M6.947 10.295h-2.02v7.074h2.02v-7.074zm6.063 0h-2.02v7.074h2.02v-7.074zm8.59 9.095H2.4v2.02h19.2v-2.02zm-2.526-9.095h-2.022v7.074h2.022v-7.074zM12 3.484l5.265 2.769H6.735L12 3.484zM12 1.2L2.4 6.253v2.02h19.2v-2.02L12 1.2z"
    />
  </svg>
);

export default Financing;
