import React from "react";

function ProductsSell() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"

      fill="none"
      viewBox="0 0 525 525"
    >
      <circle cx="262.5" cy="262.5" r="262.5" fill="#F6FCFE"></circle>
      <mask
        id="a"
        width="525"
        height="525"
        x="0"
        y="0"
        maskUnits="userSpaceOnUse"
      >
        <circle cx="262.5" cy="262.5" r="262.5" fill="#F6FCFE"></circle>
      </mask>
      <g mask="url(#a)">
        <rect
          width="5"
          height="129"
          x="464"
          y="282"
          fill="#B5E6FC"
          rx="2.5"
        ></rect>
        <path
          fill="#FCFEFF"
          d="M459 296.5h15l-2.586 15.5h-9.828L459 296.5z"
        ></path>
        <path
          stroke="#B5E6FC"
          strokeLinecap="round"
          strokeWidth="3"
          d="M462 313h8.5"
        ></path>
        <path
          stroke="#34A8FE"
          strokeLinecap="round"
          strokeWidth="3"
          d="M463 379h7"
        ></path>
        <path
          fill="#34A8FE"
          d="M466.5 289a7.5 7.5 0 00-7.5 7.5h15a7.5 7.5 0 00-7.5-7.5z"
        ></path>
        <path
          stroke="#B5E6FC"
          strokeLinecap="round"
          strokeWidth="3"
          d="M459 296h15"
        ></path>
        <path
          fill="#FCFEFF"
          d="M356.469 522.5c-88.5-52.5 13.501-70.5-49-129.5h-92c62.501 59-82 76-44 142 91.505 21.647 141.346 16.389 185-12.5z"
        ></path>
      </g>
      <path fill="#B5E6FC" d="M97 110H427V371H97z"></path>
      <path
        fill="#88CCEF"
        d="M432.225 95.405l-31.384-68a5.001 5.001 0 00-4.54-2.905H127.199a5.001 5.001 0 00-4.54 2.905l-31.384 68c-1.53 3.313.89 7.095 4.54 7.095h331.87c3.649 0 6.069-3.782 4.54-7.095z"
      ></path>
      <path
        fill="#D0EEFD"
        d="M86 106h350v2a5 5 0 01-5 5H91a5 5 0 01-5-5v-2z"
      ></path>
      <rect width="360" height="13" x="81" y="93" fill="#FCFEFF" rx="5"></rect>
      <path fill="#D0EEFD" d="M128 258H169V327H128z"></path>
      <path fill="#88CCEF" d="M114 261H128V324H114z"></path>
      <path fill="#88CCEF" d="M169 261H183V324H169z"></path>
      <path fill="#34A8FE" d="M128 289H169V296H128z"></path>
      <path fill="#D0EEFD" d="M241 258H282V352H241z"></path>
      <path
        fill="#D0EEFD"
        stroke="#9EDAF7"
        strokeWidth="4"
        d="M249 289H273V337H249z"
      ></path>
      <path fill="#88CCEF" d="M227 258H241V352H227z"></path>
      <path fill="#88CCEF" d="M282 258H296V352H282z"></path>
      <path
        fill="#FCFEFF"
        stroke="#9EDAF7"
        strokeWidth="4"
        d="M249 280v2h24v-2c0-6.996-5.197-13-12-13s-12 6.004-12 13z"
      ></path>
      <path fill="#D0EEFD" d="M354 258H395V327H354z"></path>
      <path fill="#88CCEF" d="M340 261H354V324H340z"></path>
      <path fill="#88CCEF" d="M395 261H409V324H395z"></path>
      <path fill="#34A8FE" d="M354 289H395V296H354z"></path>
      <rect width="5" height="99" x="93" y="259" fill="#FCFEFF" rx="2.5"></rect>
      <rect
        width="5"
        height="99"
        x="158"
        y="259"
        fill="#FCFEFF"
        rx="2.5"
      ></rect>
      <rect
        width="5"
        height="99"
        x="223"
        y="259"
        fill="#FCFEFF"
        rx="2.5"
      ></rect>
      <rect width="5" height="33" x="93" y="313" fill="#FCFEFF" rx="2.5"></rect>
      <rect
        width="5"
        height="70"
        x="93"
        y="348"
        fill="#FCFEFF"
        rx="2.5"
        transform="rotate(-90 93 348)"
      ></rect>
      <rect
        width="5"
        height="70"
        x="93"
        y="316"
        fill="#FCFEFF"
        rx="2.5"
        transform="rotate(-90 93 316)"
      ></rect>
      <rect
        width="5"
        height="33"
        x="158"
        y="313"
        fill="#FCFEFF"
        rx="2.5"
      ></rect>
      <rect
        width="5"
        height="33"
        x="145"
        y="313"
        fill="#FCFEFF"
        rx="2.5"
      ></rect>
      <rect
        width="5"
        height="33"
        x="119"
        y="313"
        fill="#FCFEFF"
        rx="2.5"
      ></rect>
      <rect
        width="5"
        height="33"
        x="132"
        y="313"
        fill="#FCFEFF"
        rx="2.5"
      ></rect>
      <rect
        width="5"
        height="33"
        x="106"
        y="313"
        fill="#FCFEFF"
        rx="2.5"
      ></rect>
      <rect
        width="5"
        height="33"
        x="158"
        y="313"
        fill="#FCFEFF"
        rx="2.5"
      ></rect>
      <rect
        width="5"
        height="70"
        x="158"
        y="348"
        fill="#FCFEFF"
        rx="2.5"
        transform="rotate(-90 158 348)"
      ></rect>
      <rect
        width="5"
        height="70"
        x="158"
        y="316"
        fill="#FCFEFF"
        rx="2.5"
        transform="rotate(-90 158 316)"
      ></rect>
      <rect
        width="5"
        height="33"
        x="223"
        y="313"
        fill="#FCFEFF"
        rx="2.5"
      ></rect>
      <rect
        width="5"
        height="33"
        x="210"
        y="313"
        fill="#FCFEFF"
        rx="2.5"
      ></rect>
      <rect
        width="5"
        height="33"
        x="184"
        y="313"
        fill="#FCFEFF"
        rx="2.5"
      ></rect>
      <rect
        width="5"
        height="33"
        x="197"
        y="313"
        fill="#FCFEFF"
        rx="2.5"
      ></rect>
      <rect
        width="5"
        height="33"
        x="171"
        y="313"
        fill="#FCFEFF"
        rx="2.5"
      ></rect>
      <rect width="11" height="19" x="90" y="244" fill="#9EDAF7" rx="5"></rect>
      <rect width="11" height="19" x="155" y="244" fill="#9EDAF7" rx="5"></rect>
      <rect width="11" height="19" x="220" y="244" fill="#9EDAF7" rx="5"></rect>
      <rect
        width="5"
        height="99"
        x="296"
        y="259"
        fill="#FCFEFF"
        rx="2.5"
      ></rect>
      <rect
        width="5"
        height="99"
        x="361"
        y="259"
        fill="#FCFEFF"
        rx="2.5"
      ></rect>
      <rect
        width="5"
        height="99"
        x="426"
        y="259"
        fill="#FCFEFF"
        rx="2.5"
      ></rect>
      <rect
        width="5"
        height="33"
        x="296"
        y="313"
        fill="#FCFEFF"
        rx="2.5"
      ></rect>
      <rect
        width="5"
        height="70"
        x="296"
        y="348"
        fill="#FCFEFF"
        rx="2.5"
        transform="rotate(-90 296 348)"
      ></rect>
      <rect
        width="5"
        height="70"
        x="296"
        y="316"
        fill="#FCFEFF"
        rx="2.5"
        transform="rotate(-90 296 316)"
      ></rect>
      <rect
        width="5"
        height="33"
        x="361"
        y="313"
        fill="#FCFEFF"
        rx="2.5"
      ></rect>
      <rect
        width="5"
        height="33"
        x="348"
        y="313"
        fill="#FCFEFF"
        rx="2.5"
      ></rect>
      <rect
        width="5"
        height="33"
        x="322"
        y="313"
        fill="#FCFEFF"
        rx="2.5"
      ></rect>
      <rect
        width="5"
        height="33"
        x="335"
        y="313"
        fill="#FCFEFF"
        rx="2.5"
      ></rect>
      <rect
        width="5"
        height="33"
        x="309"
        y="313"
        fill="#FCFEFF"
        rx="2.5"
      ></rect>
      <rect
        width="5"
        height="33"
        x="361"
        y="313"
        fill="#FCFEFF"
        rx="2.5"
      ></rect>
      <rect
        width="5"
        height="70"
        x="361"
        y="348"
        fill="#FCFEFF"
        rx="2.5"
        transform="rotate(-90 361 348)"
      ></rect>
      <rect
        width="5"
        height="70"
        x="361"
        y="316"
        fill="#FCFEFF"
        rx="2.5"
        transform="rotate(-90 361 316)"
      ></rect>
      <rect
        width="5"
        height="33"
        x="426"
        y="313"
        fill="#FCFEFF"
        rx="2.5"
      ></rect>
      <rect
        width="5"
        height="33"
        x="413"
        y="313"
        fill="#FCFEFF"
        rx="2.5"
      ></rect>
      <rect
        width="5"
        height="33"
        x="387"
        y="313"
        fill="#FCFEFF"
        rx="2.5"
      ></rect>
      <rect
        width="5"
        height="33"
        x="400"
        y="313"
        fill="#FCFEFF"
        rx="2.5"
      ></rect>
      <rect
        width="5"
        height="33"
        x="374"
        y="313"
        fill="#FCFEFF"
        rx="2.5"
      ></rect>
      <rect width="11" height="19" x="293" y="244" fill="#9EDAF7" rx="5"></rect>
      <rect width="11" height="19" x="358" y="244" fill="#9EDAF7" rx="5"></rect>
      <rect width="11" height="19" x="423" y="244" fill="#9EDAF7" rx="5"></rect>
      <path
        fill="#88CCEF"
        d="M445.725 231.519l-14.285-25a5 5 0 00-4.342-2.519H98.902a5 5 0 00-4.342 2.519l-14.285 25c-1.905 3.334.502 7.481 4.34 7.481h356.769c3.839 0 6.246-4.147 4.341-7.481z"
      ></path>
      <path
        fill="#D0EEFD"
        d="M76.305 242.5h371.389v2a5 5 0 01-5 5H81.304a5 5 0 01-5-5v-2z"
      ></path>
      <rect
        width="382"
        height="13"
        x="71"
        y="229.5"
        fill="#FCFEFF"
        rx="5"
      ></rect>
      <path fill="#FCFEFF" d="M241 121H282V190H241z"></path>
      <path fill="#88CCEF" d="M227 124H241V187H227z"></path>
      <path fill="#88CCEF" d="M282 124H296V187H282z"></path>
      <path fill="#34A8FE" d="M241 152H282V159H241z"></path>
      <path fill="#FCFEFF" d="M128 121H169V190H128z"></path>
      <path fill="#88CCEF" d="M114 124H128V187H114z"></path>
      <path fill="#88CCEF" d="M169 124H183V187H169z"></path>
      <path fill="#34A8FE" d="M128 152H169V159H128z"></path>
      <path fill="#FCFEFF" d="M354 121H395V190H354z"></path>
      <path fill="#88CCEF" d="M340 124H354V187H340z"></path>
      <path fill="#88CCEF" d="M395 124H409V187H395z"></path>
      <path fill="#34A8FE" d="M354 152H395V159H354z"></path>
      <rect width="49" height="19" x="151" fill="#34A8FE" rx="5"></rect>
      <path fill="#B5E6FC" d="M157 19H194V59H157z"></path>
      <rect width="360" height="42" x="81" y="352" fill="#34A8FE" rx="5"></rect>
      <path
        fill="#88CCEF"
        d="M215 384a5 5 0 015-5h83a5 5 0 015 5v10h-93v-10z"
      ></path>
      <path
        fill="#9EDAF7"
        d="M222 368a5 5 0 015-5h69a5 5 0 015 5v11h-79v-11z"
      ></path>
      <path
        fill="#B5E6FC"
        d="M229 353a5 5 0 015-5h55a5 5 0 015 5v10h-65v-10z"
      ></path>
      <rect width="13" height="50" x="143" y="363" fill="#88CCEF" rx="3"></rect>
      <rect
        width="65.136"
        height="42.424"
        x="116.5"
        y="358.5"
        fill="#fff"
        stroke="#D0EEFD"
        strokeWidth="3"
        rx="1.5"
      ></rect>
      <path
        fill="#34A8FE"
        d="M133.64 377.852c-.037-.416-.206-.74-.505-.971-.296-.235-.718-.353-1.267-.353-.546 0-.955.105-1.228.313-.272.204-.411.466-.414.784-.012.345.138.616.449.813.31.193.729.346 1.255.46l1 .227c1.148.254 2.027.669 2.637 1.244.613.576.922 1.328.926 2.256-.004 1.106-.413 1.97-1.228 2.591-.814.621-1.947.932-3.397.932-1.462 0-2.633-.33-3.512-.989-.875-.663-1.322-1.674-1.341-3.034h2.694c.03.561.236.985.619 1.273.382.284.884.426 1.506.426.564 0 1.005-.112 1.323-.335.319-.224.48-.514.483-.87-.003-.325-.155-.585-.454-.778-.299-.197-.769-.369-1.409-.517l-1.216-.284c-1.004-.231-1.797-.604-2.381-1.12-.579-.518-.867-1.219-.863-2.102-.004-.723.189-1.356.579-1.898.39-.541.928-.962 1.614-1.261.689-.303 1.477-.454 2.363-.454.902 0 1.684.153 2.347.46.667.303 1.184.729 1.551 1.278.368.546.555 1.182.563 1.909h-2.694zm8.031 8.148h-3.023l3.926-11.636h3.745L150.245 386h-3.023l-.767-2.443h-4.017l-.767 2.443zm1.437-4.58h2.676l-1.295-4.125h-.091l-1.29 4.125zm9.99 4.58v-11.636h2.812v9.352h4.841V386h-7.653zm10.709 0v-11.636h8.114v2.284h-5.302v2.386h4.887v2.29h-4.887v2.392h5.302V386h-8.114z"
      ></path>
    </svg>
  );
}

export default ProductsSell;
