import React, { Component } from "react";
import axios from "axios";

//styling
import "./DefPostsStyle.css";
import "../cards/cardStyle.css";

import NoComments from "../../media/SVGs/NoComments";

//compnents
import Button from "../buttons/Button";
import ForumComment from "../cards/ForumComment";
import Spinner from "../loading/Spinner";
import Banner from "../header/Banner";

//images
// import NoPosts from "../../media/icons/NewPost";

class UsersComments extends Component {
  render() {
    console.log(this.props.data);

    let noData = (
      <div className="No-content noContent-anim">
        <div style={{ marginBottom: "-32px" }}>
          <NoComments />
        </div>
        <div className="nocont-text"> No Comments yet</div>

        <p className="">... but maybe one day</p>
      </div>
    );
    console.log(this.props.data);

    let recentPost = this.props.data ? (
      this.props.data.forumComments.length > 0 ? (
        this.props.data.forumComments.map((forumComment, i) => (
          <ForumComment key={forumComment.forumCommentId} post={forumComment} />
        ))
      ) : (
        noData
      )
    ) : (
      <div style={{ padding: "8px" }}>
        <Spinner color="#939eab" size="24px" />
      </div>
    );

    return <div className="posts-container">{recentPost}</div>;
  }
}

export default UsersComments;
