import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

const UnauthRoute = ({
  component: Component,
  authenticated,
  checked,
  ...rest
}) => (
  <Route
    {...rest}
    render={props =>
      !checked || authenticated ? (
        <Component
          {...props}
          prop={console.log(
            "comp: ",
            !checked,
            authenticated,
            !checked || authenticated
          )}
        />
      ) : (
        <Redirect
          to="/auth"
          prop={console.log(
            "redirect: ",
            !checked,
            authenticated,
            !checked || authenticated
          )}
        />
      )
    }
  />
);

const mapStateToProps = state => ({
  authenticated: state.user.authenticated,
  checked: state.user.checked
});

UnauthRoute.propTypes = {
  user: PropTypes.object
};

export default connect(mapStateToProps)(UnauthRoute);
