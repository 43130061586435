import React, { Component } from "react";

//components
import ProductBox from "./ProductBox";

//Styling
import "./ProductGridStyle.css";

class ProductGrid extends Component {
  render() {
    let Products = this.props.content;
    let ProductNames = Object.keys(Products);

    let item = ProductNames.map((product, i) => (
      <ProductBox
        key={product}
        color={this.props.color}
        id={product}
        icon={Products[product].icon ? Products[product].icon : ""}
        plans={Products[product].plans ? Products[product].plans : ""}
        name={product.replace(/([A-Z])/g, " $1").trim()}
        description={
          Products[product].description ? Products[product].description : ""
        }
      />
    ));

    return <div className="ProductGrid-container">{item}</div>;
  }
}

export default ProductGrid;
