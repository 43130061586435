import React, { Component } from "react";

//style
import "./PostTitleinputStyle.css";

//components
import CharRing from "./CharRing";

//consoleIcons
// import NewPost from "../../../../media/icons/NewPost";
import Close from "../../../../../media/SVGs/None";
import TextSize from "../../../../../media/icons/richText/TextSize";

class PostPop extends Component {
  constructor() {
    super();
    this.state = {
      title: "",
      charCount: 0,
      charLimit: 100,
    };
  }

  handleTitle = (e) => {
    this.setState({
      title:
        e.target.value.length <= this.state.charLimit
          ? e.target.value
          : this.state.title,
      charCount: e.target.value != 0 ? e.target.value.length : 0,
    });
    this.props.passTitle(e.target.value);
  };

  focus = () => {
    this.editor.focus();
  };

  render() {
    return (
      <div>
        <div className="title-input-container outskrts">
          <div className="title-input-input-wrap">
            <input
              autoComplete="off"
              className="title-input"
              type="text"
              placeholder={
                this.props.placeholder
                  ? this.props.placeholder
                  : "Add a title..."
              }
              onChange={this.handleTitle}
              value={this.state.title}
            />
            <span className="focus-border">
              <i></i>
            </span>
          </div>

          <CharRing
            radius={12}
            charCount={this.state.charCount}
            limit={this.state.charLimit}
          />
        </div>
      </div>
    );
  }
}

export default PostPop;
