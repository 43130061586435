import React, { Component } from "react";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import PropTypes from "prop-types";

//redux stuff
import { connect } from "react-redux";

//components
import AuthForm from "../../auth/AuthForm";

//style
import "./NewPostModalStyle.css";

class Following extends Component {
  state = {
    open: false,
    body: "",
    errors: {},
  };

  componentDidMount() {
    disableBodyScroll(this.targetElement);
  }
  componentWillUnmount() {
    enableBodyScroll(this.targetElement);
  }

  render() {
    const {
      user: { loadingUser, authenticated },
    } = this.props;
    return (
      <div
        className="post-content-contaienr"
        onClick={() => this.props.hist.push("/forum")}
      >
        <div className="modal-spacer"></div>
        {!authenticated ? (
          <div
            onClick={(e) => e.stopPropagation()}
            style={{ marginTop: "16px" }}
          >
            <AuthForm />
          </div>
        ) : (
          <div
            className="card-shdow-lg post-content-wrap anim-up"
            onClick={(e) => e.stopPropagation()}
          >
            {this.props.children}
          </div>
        )}
      </div>
    );
  }
}

Following.propTypes = {
  user: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps)(Following);
