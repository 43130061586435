import React, { Component } from "react";
import { NavLink, withRouter } from "react-router-dom";
import { browserHistory } from "react-router";

//Styling
import "./BannerStyle.css";

//compnents
import ForumBanner from "./banners/ForumBanner";
import UserBanner from "./banners/UserBanner";
import ProfileBanner from "./banners/ProfileBanner";
import PostBanner from "./banners/PostBanner";

class Banner extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  bannerCont() {
    let display = this.props.location.pathname;
    if (this.props.type === "post") {
      return <PostBanner />;
    }
    if (display.includes("/forum")) {
      return <ForumBanner />;
    }
    if (display.includes("/user")) {
      return <UserBanner />;
    }
    if (display.includes("/profile")) {
      return <ProfileBanner />;
    }
  }

  render() {
    // let bannerType = this.props.type;

    return (
      <div className="header-banner-container">
        <div className="sizing">{this.bannerCont()}</div>
      </div>
    );
  }
}

export default withRouter(Banner);
