import React, { Component } from "react";

//styling
import "./DropdownStyle.css";

//icons
import ChevronDown from "../../media/icons/ChevronDown";
// import Filter from "../../media/icons/Filter";

class ForumPost extends Component {
  state = {
    placeholder: 0,
    selected: this.props.primary,
    list: this.props.list,
    active: 0
  };

  render() {
    return (
      <div className={`dropdown-wrap centered ${this.props.className}`}>
        <div
          className="desktop-only"
          style={{ color: this.props.color ? this.props.color : "#fff" }}
        >
          {this.state.selected}
        </div>
        <ChevronDown
          className="desktop-only"
          colorPrimary={this.props.color ? this.props.color : "#fff"}
          size="24px"
        />
        {this.props.children}
      </div>
    );
  }
}

export default ForumPost;
