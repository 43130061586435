import React, { Component } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { submitComment } from "../../../redux/actions/dataActions";

//compnents
import PostTextInput from "../PostCont/ContTypes/components/PostTextInput";

//for IMPUT
import Bold from "../../../media/icons/richText/Bold";
import Italic from "../../../media/icons/richText/Italic";
import Underline from "../../../media/icons/richText/Underline";
import AddImage from "../../../media/icons/richText/AddImage";
import AddLink from "../../../media/icons/richText/AddLink";

class forumPost extends Component {
  constructor() {
    super();
    this.state = {};
  }

  submitCom = e => {
    e.preventDefault();
    const { parentId, postId } = this.props;

    let comData = {
      parentId: parentId,
      postId: postId,
      body: this.state.comment
    };
    // console.log(comData);

    this.props.submitComment(comData, this.props.closeCom);
  };
  setCom = post => {
    this.setState({
      comment: {
        styled: JSON.stringify(post.styled),
        plane: post.raw
      }
    });
  };

  render() {
    const {
      comment,
      UI: { loading },
      data: { loadingCom }
    } = this.props;
    const toolbarData = [
      {
        action: "BOLD",
        lable: Bold,
        type: "style"
      },
      {
        action: "ITALIC",
        lable: Italic,
        type: "style"
      },
      {
        action: "UNDERLINE",
        lable: Underline,
        type: "style"
      },
      {
        action: "DIV3",
        type: "divider"
      },
      {
        action: "IMAGE",
        lable: AddImage,
        onClick: "ImagePop"
      },
      {
        action: "LINK",
        lable: AddLink,
        type: "style",
        onClick: "LinkPop"
      },
      {
        action: "SPCER",
        type: "spacer"
      }
    ];

    return (
      <div
        style={{
          margin: comment ? "8px 0 16px" : "16px 0",
          paddingBottom: comment ? "" : "16px",
          borderBottom: comment ? "" : "1px solid #cfd8dc",
          width: "100%"
        }}
      >
        <PostTextInput
          height={32}
          passText={this.setCom}
          toolbarData={toolbarData}
          placeholder={"Write a reply..."}
          toolbarSubmit={{ text: "Reply", func: this.submitCom }}
          hideCharRing={true}
          loading={loadingCom}
        />
      </div>
    );
  }
}

forumPost.propTypes = {
  user: PropTypes.object.isRequired,
  submitComment: PropTypes.func.isRequired,
  UI: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  user: state.user,
  UI: state.UI,
  data: state.data
});

export default connect(
  mapStateToProps,
  { submitComment }
)(forumPost);
