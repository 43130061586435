import React, { Component } from "react";

import NoMsgSVG from "../../../media/SVGs/NoMessages";

//style
import "./MessagesStyle.css";

class Messages extends Component {
  render() {
    return (
      <div className="forum-msg-content">
        <div className="forum-msg-wrap">
          <div className="forum-msg-grid sizing">
            <div className="message-threads-container">
              <div className="message-threads-wrap"></div>
            </div>
            <div className="message-message-container">
              <div className="No-content noContent-anim ">
                <div style={{ marginBottom: "-24px" }}>
                  <NoMsgSVG />
                </div>
                <div className="nocont-text">You have no Messages</div>

                <p className="">...but you can start one!</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Messages;
