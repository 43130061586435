import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { Link, withRouter, Route, Switch } from "react-router-dom";

//redux stuff
import { connect } from "react-redux";

//components
import NewPostModal from "./NewPostModal";
import Post from "./ContTypes/Post";
import TopicInputter from "./ContTypes/topicInputter";

import TabBar from "../../tabs/TabBar";

//consoleIcons
import NewPost from "../../../media/icons/NewPost";
import Close from "../../../media/SVGs/None";
import Plus from "../../../media/icons/Plus";

class Following extends Component {
  state = {
    modalTab: "",
    body: "",
    errors: {}
  };

  render() {
    const {
      user: { authenticated }
    } = this.props;

    let popContent = (
      <NewPostModal
        open={this.state.open}
        handleClose={this.handleClose}
        hist={this.props.history}
      >
        <div className="outskrts content-pop-header">
          <div className="content-pop-title"></div>
          <button
            className="button button-silver-text content-pop-header-close"
            onClick={() => this.props.history.push("/forum")}
          >
            <Close size="24px" />
            <div className="desktop-only">Close</div>
          </button>
        </div>

        <TopicInputter />
      </NewPostModal>
    );

    return popContent;
  }
}

const mapStateToProps = state => ({
  user: state.user
});

export default connect(mapStateToProps)(withRouter(Following));
