import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import { connect } from "react-redux";
import { getForumPost, submitComment } from "../redux/actions/dataActions";

//timeage
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";

//styling
import "./forum.css";
import "./PostStyle.css";
import "../components/cards/cardStyle.css";

//compnents
import PostComment from "../components/forum/forumPost/PostComment";
import PostContent from "../components/forum/forumPost/PostContent";
// import CommentSection from "../components/forum/forumPost/CommentSection";
import CommentTextInput from "../components/forum/forumPost/CommentTextInput";
// import CommentTextInput from "../components/forum/PostCont/ContTypes/components/PostTextInput";

//icons
import MoreDots from "../media/icons/MoreDots";
import Spinner from "../components/loading/Spinner";
import Banner from "../components/header/Banner";
import ArrowUp from "../media/icons/ArrowUp";
import ArrowDown from "../media/icons/ArrowDown";
import MapIcon from "../media/icons/MapIcon";

//for IMPUT
import Bold from "../media/icons/richText/Bold";
import Italic from "../media/icons/richText/Italic";
import Underline from "../media/icons/richText/Underline";
// import UnorderList from "../../../../media/icons/richText/UnorderList";
// import OrderList from "../../../../media/icons/richText/OrderList";
import AddImage from "../media/icons/richText/AddImage";
import AddLink from "../media/icons/richText/AddLink";

//contnent
import Forum404 from "../components/forum/Forum404";

//images
import Placeholder from "../media/images/placeholder.jpg";

class forumPost extends Component {
  constructor() {
    super();
    this.state = {
      lastScrollUp: 0,
      BannerHeight: 0,
      BannerTop: 0,
      BannerDis: 1,
      tabHeight: 0,
      activeTab: 0,
      oldComments: [],
    };
    this.banElement = React.createRef();
    this.bannerScroll = this.bannerScroll.bind(this);
  }
  componentDidMount() {
    console.log("post mounted");
    window.addEventListener("scroll", this.bannerScroll, false);
    let pathArr = this.props.location.pathname.split("/");
    this.props.getForumPost(pathArr[3]);
    this.setState({
      BannerHeight: this.banElement.current.getBoundingClientRect().height,
    });
  }
  componentWillUnmount() {
    window.removeEventListener("scroll", this.bannerScroll, false);
  }

  // getSnapshotBeforeUpdate(prevProps, prevState) {}
  componentDidUpdate(prevProps, prevState) {
    let newComments = this.props.data.forumPost.comments;
    let oldComments = prevProps.data.forumPost.comments;
    // let oldComments = this.state.oldComments;
    console.log("new v old");
    console.log(oldComments);
    console.log(newComments);

    if (newComments && oldComments !== newComments) {
      let comments = newComments
        .filter((com) => com.data.parentId === this.props.data.forumPost.id)
        .map((com) => (
          <PostComment
            key={com.id}
            comData={com}
            comments={newComments}
            user={this.props.user}
          />
        ));
      this.setState({ comments });
      // this.setState({ comments, oldComments: newComments });
    }
    return null;
  }
  // mapComments = (startId, comments) => {
  //   if (!comments) return;
  //   this.setState({ comments: "" });
  //   let topLevel = {
  //     id: startId,
  //     comments: [],
  //   };
  //   const buildComments = async (object, data) => {
  //     data.forEach((comm, count) => {
  //       if (comm.data.parentId === object.id) {
  //         object.comments.push(comm);
  //         let comIndex = object.comments.findIndex((x) => x.id === comm.id);
  //         buildComments(object.comments[comIndex], data);
  //       }
  //       count++;
  //     });
  //     return topLevel.comments;
  //   };
  //   buildComments(topLevel, comments).then((res) => {
  //     console.log("FOR RETURN: ", res);
  //     let sorted = res.map((com) => (
  //       <PostComment key={com.id} comData={com} user={this.props.user} />
  //     ));
  //     console.log(sorted);
  //     this.setState({ comments: sorted });
  //   });
  // };

  render() {
    TimeAgo.addLocale(en);
    const timeAgo = new TimeAgo("en-US");
    const {
      user,
      data: {
        forumPost: {
          id,
          docType,
          topics,
          title,
          body,
          bodyStyled,
          createdAt,
          userImage,
          username,
          likeCount,
          commentCount,
          viewCount,
          comments,
          address,
        },
      },
      UI: { loading },
    } = this.props;

    let topicTags = !topics
      ? ""
      : topics.map((topic) => (
          <div
            key={Math.random()}
            className="post-top-type post-card-header-tag centered"
            onClick={this.tagClick}
          >
            {!topic ? "General" : topic}
          </div>
        ));

    const trendingPosts = (
      <div className="post-trending-wrap outskrts">
        <div>
          <p>Data</p>
          <h3>This is a test title for a trending post</h3>
        </div>
        <img
          src="https://firebasestorage.googleapis.com/v0/b/flipit-238117.appspot.com/o/images%2Fthumbnails%2FLinkThumb.png?alt=media&token=e348a2d4-2772-435d-9219-c32075e7ccc8"
          alt="Article Preview"
        />
      </div>
    );
    const trendingGroups = (
      <div className="trendig-group-cont outskrts">
        <div className="centered">
          <img
            src="https://firebasestorage.googleapis.com/v0/b/flipit-238117.appspot.com/o/images%2Fthumbnails%2FLinkThumb.png?alt=media&token=e348a2d4-2772-435d-9219-c32075e7ccc8"
            alt="Article Preview"
          />
          <div>
            <h3>Group name</h3>
            <p>Followers</p>
          </div>
        </div>
        <div className="more-col-follow-btn centered-col">Follow</div>
      </div>
    );
    const toolbarData = [
      {
        action: "BOLD",
        lable: Bold,
        type: "style",
      },
      {
        action: "ITALIC",
        lable: Italic,
        type: "style",
      },
      {
        action: "UNDERLINE",
        lable: Underline,
        type: "style",
      },
      {
        action: "DIV3",
        type: "divider",
      },
      {
        action: "IMAGE",
        lable: AddImage,
        onClick: "ImagePop",
      },
      {
        action: "LINK",
        lable: AddLink,
        type: "style",
        onClick: "LinkPop",
      },
      {
        action: "SPCER",
        type: "spacer",
      },
    ];

    let page = (
      <div className="post-grid-container">
        <div className="post-wrapper anim-up">
          <div className="post-page ">
            <div
              className="post-page-header outskrts"
              style={{ alignItems: "flex-start" }}
            >
              <div className="centered" style={{ flexWrap: "wrap" }}>
                <div
                  className="post-doc-type post-card-header-tag centered"
                  onClick={this.tagClick}
                >
                  {docType ? docType : "Post"}
                </div>
                {topicTags}
              </div>
              <div className="centered">
                <button className=" centered post-location-tag">
                  <div className="">{address ? address : "General"}</div>
                  <MapIcon className="" colorPrimary="#D2D6D9" size="24px" />
                </button>
              </div>
            </div>
            <div className="post-page-post">
              <h1>{title}</h1>
              <div className="post-page-post-sub centered">
                <h6>{timeAgo.format(new Date(createdAt))}</h6>
                <h6>
                  {`${!viewCount ? 0 : viewCount} ${
                    viewCount != 1 ? "views" : "view"
                  }`}
                </h6>
                <h6>{`${commentCount} comments`}</h6>
              </div>
              <PostContent bodyStyled={bodyStyled} />
            </div>
            <div className="post-page-more-section outskrts">
              <div className="post-page-author centered">
                <div className="post-page-author-img">
                  <img src={userImage ? userImage : Placeholder} alt=""></img>
                </div>
                <div>
                  {username}
                  <div style={{ color: "#b1b4c7", fontSize: ".75rem" }}>
                    REP • Accnt type
                  </div>
                </div>
              </div>
              <div className="post-card-footer-like-wrap centered">
                <button className="centered" onClick={this.likeClick}>
                  <ArrowUp className="" colorPrimary="#D2D6D9" size="24px" />
                </button>
                <p>{likeCount}</p>
                <button className="centered" onClick={this.unlikeClick}>
                  <ArrowDown className="" colorPrimary="#D2D6D9" size="24px" />
                </button>
              </div>
            </div>
            <div className="post-page-comment-sectoin">
              <div className="post-page-comments-sectoin-coments ">
                <CommentTextInput parentId={id} postId={id} />
                {this.state.comments ? this.state.comments : "No comments yet"}
              </div>
            </div>
          </div>
        </div>
        <div>
          <div
            className="post-sidebar-container"
            style={{
              top: this.state.BannerTop + 96 + 24,
            }}
          >
            <div className="post-sidebar-wrap">
              <h4 className="">Related posts</h4>
              {trendingPosts}
              {trendingPosts}
              {trendingPosts}
              <Link
                to="/forum/topics"
                className="trending-post-wrap centered-col"
              >
                more
              </Link>
            </div>
            <div className="post-sidebar-wrap">
              <h4 className="">This group</h4>
              {trendingGroups}
              <Link
                to="/forum/topics"
                className="trending-post-wrap centered-col"
              >
                Join
              </Link>
              <Link
                to="/forum/topics"
                className="trending-post-wrap centered-col"
              >
                Post
              </Link>
            </div>
          </div>
        </div>
      </div>
    );

    return (
      <div>
        <div className="post-back"></div>
        <div
          ref={this.banElement}
          className="banner-behavior-wrap shadow"
          style={{
            top: this.state.BannerTop,
            opacity: this.state.BannerDis,
          }}
        >
          <Banner type="post" />
        </div>

        <div
          className="forum-content"
          style={{
            paddingTop: this.state.BannerHeight,
          }}
        >
          <div className="sizing">
            {!loading ? (
              page
            ) : (
              <div style={{ padding: "56px" }}>
                <Spinner color="#939eab" size="24px" />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }

  bannerScroll() {
    var banner = this.banElement.current.getBoundingClientRect(),
      lastScrollUp = this.state.lastScrollUp,
      scroll = window.scrollY;

    if (scroll > banner.height - this.state.tabHeight) {
      if (!lastScrollUp) {
        lastScrollUp = scroll;
      }

      var tabHeightRaw = lastScrollUp - scroll;
      var tabHeight = Math.max(Math.min(tabHeightRaw, 48), 0);

      if (tabHeightRaw > 48) {
        lastScrollUp = scroll + 48;
      }

      if (tabHeightRaw < 0) {
        lastScrollUp = 0;
      }
      this.setState({
        BannerTop: -banner.height + tabHeight,
        tabHeight: tabHeight,
        BannerDis: tabHeight ? 1 : 0,
        lastScrollUp: lastScrollUp,
      });
    } else {
      this.setState({
        BannerTop: -scroll,
        BannerDis: 1,
      });
    }
  }
}

forumPost.propTypes = {
  user: PropTypes.object.isRequired,
  getForumPost: PropTypes.func.isRequired,
  submitComment: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  UI: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  data: state.data,
  user: state.user,
  UI: state.UI,
});

export default connect(mapStateToProps, { getForumPost, submitComment })(
  forumPost
);
